import axios from "axios";
import { SERV_API } from "../constant_config";

const odu_report = async (token) => {
  return await axios({
    url: SERV_API + "odu",
    method: "GET",
    // timeout: 5000,
    headers: {
      Accept: "application/json",
      "x-access-token": token,
    },
  });
};

const odu_report_realtime = async (token, cdu_no) => {
  return await axios({
    url: SERV_API + "odu/realtime",
    method: "GET",
    data: JSON.stringify({
      cdu_no: cdu_no,
    }),
    // timeout: 5000,
    headers: {
      Accept: "application/json",
      "x-access-token": token,
    },
  });
};

const odu_system_list = async (token) => {
  return await axios({
    url: SERV_API + "odu/systemlist",
    method: "GET",
    // timeout: 5000,
    headers: {
      Accept: "application/json",
      "x-access-token": token,
    },
  });
};

const indoor_report_realtime = async (token) => {
  return await axios({
    url: SERV_API + "indoor",
    method: "GET",
    // timeout: 5000,
    headers: {
      Accept: "application/json",
      "x-access-token": token,
    },
  });
};

const odu_report_history = async (token, gateway_sn, startDate, endDate) => {
  return await axios({
    url: SERV_API + "history/odu",
    method: "GET",
    params: {
      gateway_sn: gateway_sn,
      start_date: startDate,
      end_date: endDate,
    },
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
};

const odu_energy_report = async (token, gateway_sn, unit) => {
  return await axios({
    url: SERV_API + "energy/odusystem",
    method: "GET",
    params: {
      device_sn: gateway_sn,
      unit: unit,
    },
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
};

export {
  odu_report,
  indoor_report_realtime,
  odu_report_realtime,
  odu_system_list,
  odu_report_history,
  odu_energy_report,
};
