import React, { useState, useEffect } from "react";
import { Row, Col, Table, Modal, Form, Button } from "react-bootstrap";
import Header from "../components/header";
import Nav from "../components/nav";
import Pagination from "../components/pagination";
import BuildingIcon from "../assets/image/control/building-icon.png";
import FloorIcon from "../assets/image/control/floor-icon.png";
import { products, building_list } from "../api/product";
import moment from "moment";
import { add_ac } from "../api/setting";

const SettingAC = (props) => {
  const [acList, setAcList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage] = useState(20);
  const [addForm, setAddForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [acName, setAcName] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [uid, setUID] = useState("");
  const [building, setBuilding] = useState("");
  const [floor, setFloor] = useState("");
  const [serialNumberSearch, setSerialNumberSearch] = useState("");
  const [acNameSearch, setAcNameSearch] = useState("");
  const [roomSearch, setRoomSearch] = useState("");
  const [acListSearchResult, setAcListSearchResult] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [buildingFilter, setBuildingFilter] = useState("");
  const [floorFilter, setFloorFilter] = useState("");
  const [buildingList, setBuildingList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [buildingListModal, setBuildingListModal] = useState([]);
  const [floorListModal, setFloorListModal] = useState([]);
  const [roomListModal, setRoomListModal] = useState([]);
  const [buildingSelect, setBuildingSelect] = useState("");
  const [floorSelect, setFloorSelect] = useState("");
  const [roomSelect, setRoomSelect] = useState("");
  const [loaded, setLoaded] = useState(true);
  const [roomListSearch, setRoomListSearch] = useState([]);
  const [acNameListSearch, setAcNameListSearch] = useState([]);
  const [serialListSearch, setSerialListSearch] = useState([]);

  const [acNameValidate, setAcNameValidate] = useState(true);
  const [typeValidate, setTypeValidate] = useState(true);
  const [modelValidate, setModelValidate] = useState(true);
  const [serialNumberValidate, setSerialNumberValidate] = useState(true);
  const [gatewayValidate, setGateWayValidate] = useState(true);
  const [uidValidate, setUidValidate] = useState(true);
  const [cidValidate, setCidValidate] = useState(true);
  const [oduValidate, setOduValidate] = useState(true);
  const [buildingValidate, setBuildingValidate] = useState(true);
  const [floorValidate, setFloorValidate] = useState(true);
  const [roomValidate, setRoomValidate] = useState(true);
  const [typeSelect, setTypeSelect] = useState("");
  const [modelSelect, setModelSelect] = useState("");
  const [gateway, setGateWay] = useState("");
  const [cid, setCid] = useState("");
  const [odu, setOdu] = useState("");

  const indexOfLastPost = currentPage * PerPage;
  const indexOfFirstPost = indexOfLastPost - PerPage;
  const currentPosts = acListSearchResult.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const typeList = [
    {
      label: "FFU",
      value: "FFU",
    },
    {
      label: "PE",
      value: "PE",
    },
    {
      label: "IAQ",
      value: "IAQ",
    },
    {
      label: "FRESHAIR",
      value: "FRESHAIR",
    },
    {
      label: "MONOBLOCK",
      value: "MONOBLOCK",
    },
    {
      label: "AHU",
      value: "AHU",
    },
    {
      label: "FFU",
      value: "FFU",
    },
    {
      label: "SRV",
      value: "SRV",
    },
  ];

  const modelList = [
    {
      label: "Intelligent Inverter",
      value: "Intelligent Inverter",
    },
    {
      label: "Hygiene Inverter",
      value: "Hygiene Inverter",
    },
    {
      label: "ATW Heat Pump Monoblock",
      value: "ATW Heat Pump Monoblock",
    },
    {
      label: "ATW Heat Pump (Temperzone)",
      value: "ATW Heat Pump (Temperzone)",
    },
    {
      label: "Rooftop Package Unit",
      value: "Rooftop Package Unit",
    },
    {
      label: "Air Purifier",
      value: "Air Purifier",
    },
    {
      label: "Hot Water A/C",
      value: "Hot Water A/C",
    },
    {
      label: "Package Water Cool",
      value: "Package Water Cool",
    },
    {
      label: "RAC Inverter",
      value: "RAC Inverter",
    },
    {
      label: "UFT Inverter",
      value: "UFT Inverter",
    },
    {
      label: "Mini VRF (SRV)",
      value: "Mini VRF (SRV)",
    },
    {
      label: "Chiller",
      value: "Chiller",
    },
    {
      label: "Air Change",
      value: "Air Change",
    },
    {
      label: "UTF Extreme Ozone",
      value: "UTF Extreme Ozone",
    },
    {
      label: "Protective Environment",
      value: "Protective Environment",
    },
    {
      label: "Heat Recovery PE",
      value: "Heat Recovery PE",
    },
    {
      label: "FFU",
      value: "FFU",
    },
    {
      label: "Micro PE, IC",
      value: "Micro PE, IC",
    },
    {
      label: "PE/IC Mono Block",
      value: "PE/IC Mono Block",
    },
    {
      label: "Duct Type 35",
      value: "Duct Type 35",
    },
    {
      label: "IAQ",
      value: "IAQ",
    },
    {
      label: "Duct Re-Heat",
      value: "Duct Re-Heat",
    },
    {
      label: "GPS Inverter",
      value: "GPS Inverter",
    },
    {
      label: "SPLIT",
      value: "SPLIT",
    },
    {
      label: "SRV",
      value: "SRV",
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        let token = localStorage.getItem("token");
        let companyId = localStorage.getItem("company_id");

        let res = await building_list(token);

        let buildingData = res.data.data.filter((item) => {
          return item.company_id === companyId;
        });

        let buildings = [];
        let floors = [];
        let rooms = [];

        // Filler Building
        buildingData.map((item) => {
          if (item.title !== undefined) {
            buildings.push({
              building: item.title,
              building_id: item._id,
            });
          }

          return item;
        });

        buildings.sort((a, b) => {
          let x = a.building_id.toLowerCase();
          let y = b.building_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        // Filler Floor
        buildingData.map((item) => {
          if (buildings[0].building !== undefined) {
            if (buildings[0].building === item.title) {
              if (item.floors !== undefined) {
                item.floors.map((data) => {
                  floors.push({
                    floor: data.title,
                    floor_image: data.plan_img,
                    floor_id: data.floorId,
                  });

                  return data;
                });
              }
            }
          }

          return item;
        });

        floors.sort((a, b) => {
          let x = a.floor_id.toLowerCase();
          let y = b.floor_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        // Filler Room
        buildingData.map((item) => {
          if (buildings[0].building !== undefined) {
            if (buildings[0].building === item.title) {
              if (item.floors) {
                item.floors.map((i) => {
                  if (floors[0].floor === i.title) {
                    if (i.rooms !== undefined) {
                      i.rooms.map((data) => {
                        // if (data.title !== "All") {
                        rooms.push({
                          room: data.title,
                          room_id: data.roomId,
                        });
                        // }

                        return data;
                      });
                    }
                  }

                  return i;
                });
              }
            }
          }

          return item;
        });

        rooms.sort((a, b) => {
          let x = a.room_id.toLowerCase();
          let y = b.room_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        setBuildingList(buildings);
        setFloorList(floors);
        setRoomListSearch(rooms);
        setBuilding(buildings.length ? buildings[0].building : "");
        setFloor(floors.length ? floors[0].floor : "");
        setBuildingFilter(buildings.length ? buildings[0].building : "");
        setFloorFilter(floors.length ? floors[0].floor : "");
        setRoomSearch("");

        let productArr = await products(
          token,
          buildings[0].building,
          floors.length ? floors[0].floor : ""
        );

        setAcList(productArr.data.productsList);
        setAcListSearchResult(productArr.data.productsList);

        setLoaded(false);
      } catch (error) {
        console.log(error);
        setLoaded(false);
      }
    })();
  }, [loaded]);

  const handleSubmit = async (type) => {
    if (!buildingSelect) {
      setBuildingValidate(false);
    }

    if (!floorSelect) {
      setFloorValidate(false);
    }

    if (!roomSelect) {
      setRoomValidate(false);
    }

    if (!acName) {
      setAcNameValidate(false);
    }

    if (!typeSelect) {
      setTypeValidate(false);
    }

    if (!modelSelect) {
      setModelSelect(false);
    }

    if (!serialNumber) {
      setSerialNumberValidate(false);
    }

    if (!gateway) {
      setGateWay(false);
    }

    if (!uid) {
      setUidValidate(false);
    }

    if (!cid) {
      setCidValidate(false);
    }

    if (!odu) {
      setOduValidate(false);
    }

    if (
      !buildingSelect ||
      !floorSelect ||
      !roomSelect ||
      !acName ||
      !typeSelect ||
      !modelSelect ||
      !serialNumber ||
      !gateway ||
      !uid ||
      !cid ||
      !odu
    ) {
      return;
    }

    console.log(
      acName,
      typeSelect,
      modelSelect,
      serialNumber,
      gateway,
      uid,
      cid,
      odu,
      buildingSelect,
      floorSelect,
      roomSelect,
      localStorage.getItem("company_id"),
      moment(new Date()).toISOString()
    );

    try {
      const token = localStorage.getItem("token");
      const companyID = localStorage.getItem("company_id");
      if (type === "add") {
        await add_ac(
          token,
          acName,
          typeSelect,
          modelSelect,
          serialNumber,
          gateway,
          uid,
          cid,
          odu,
          buildingSelect,
          floorSelect,
          roomSelect,
          companyID,
          true,
          moment(new Date()).toISOString(),
          moment(new Date()).toISOString()
        );

        alert("AC added successfully.");

        setAddForm(false);
        setLoaded(true);
      } else {
        // await update_ac(
        //   token,
        //   acName,
        //   typeSelect,
        //   modelSelect,
        //   serialNumber,
        //   gateway,
        //   uid,
        //   cid,
        //   odu,
        //   buildingSelect,
        //   floorSelect,
        //   roomSelect,
        //   companyID,
        //   true,
        //   moment(new Date()).toISOString(),
        //   moment(new Date()).toISOString()
        // );
        alert("AC updated successfully.");

        setEditForm(false);
        setLoaded(true);
      }
    } catch (error) {
      console.log(error);

      alert(type === "add" ? "AC added failed!" : "AC updated failed!");
    }
  };

  const search = () => {
    var result = acList.filter((item) => {
      if (
        item._id.indexOf(acNameSearch) >= 0 &&
        item.serail_no.indexOf(serialNumberSearch) >= 0 &&
        item.location.room.indexOf(roomSearch) >= 0
      ) {
        return true;
      } else {
        return false;
      }
    });

    setAcListSearchResult(result);
  };

  const clearSearch = () => {
    setAcNameSearch("");
    setSerialNumberSearch("");
    setRoomSearch("");
    setAcListSearchResult(acList);
  };

  const changeBuilding = async (val) => {
    setBuildingFilter(val);

    let floors = [];
    let rooms = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (val === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              floors.push({
                floor: i.title,
                floor_id: i.floorId,
              });

              return i;
            });
          }

          floors.sort((a, b) => {
            let x = a.floor_id.toLowerCase();
            let y = b.floor_id.toLowerCase();

            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          });

          if (item.floors) {
            item.floors.map((i) => {
              if (i.title === floors[0].floor) {
                if (i.rooms) {
                  i.rooms.map((j) => {
                    // if (j.title !== "All") {
                    rooms.push({
                      room: j.title,
                      room_id: j.roomId,
                    });
                    // }

                    return j;
                  });
                }
              }

              return i;
            });
          }
        }

        return item;
      });

      rooms.sort((a, b) => {
        let x = a.room_id.toLowerCase();
        let y = b.room_id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setFloorList(floors);
      setFloorFilter(floors.length ? floors[0].floor : "");
      setRoomListSearch(rooms);

      let productArr = await products(
        token,
        val,
        floors.length ? floors[0].floor : ""
      );

      setAcList(productArr.data.productsList);

      let acNames = [];
      let serials = [];

      productArr.data.productsList.map((item) => {
        acNames.push({
          title: item.title,
          id: item._id,
        });
        serials.push({
          sn: item.serail_no,
          id: item._id,
        });

        return item;
      });

      acNames = acNames.filter(
        (v, i, a) => a.findIndex((v2) => v2.title === v.title) === i
      );
      serials = serials.filter(
        (v, i, a) => a.findIndex((v2) => v2.sn === v.sn) === i
      );

      acNames.sort((a, b) => {
        let x = a.id.toLowerCase();
        let y = b.id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      serials.sort((a, b) => {
        let x = a.id.toLowerCase();
        let y = b.id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setAcNameListSearch(acNames);
      setSerialListSearch(serials);
    } catch (error) {
      console.log(error);
    }
  };

  const changeFloor = async (val) => {
    setFloorFilter(val);

    let rooms = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (buildingFilter === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              if (i.title === val) {
                if (i.rooms !== undefined) {
                  i.rooms.map((j) => {
                    // if (j.title !== "All") {
                    rooms.push({
                      room: j.title,
                      room_id: j.roomId,
                    });
                    // }

                    return i;
                  });
                }
              }

              return i;
            });
          }
        }

        return item;
      });

      rooms.sort((a, b) => {
        let x = a.room_id.toLowerCase();
        let y = b.room_id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setRoomListSearch(rooms);
      setRoomSearch("");

      let productArr = await products(token, buildingFilter, val);

      setAcList(productArr.data.productsList);

      let acNames = [];
      let serials = [];

      productArr.data.productsList.map((item) => {
        acNames.push({
          title: item.title,
          id: item._id,
        });
        serials.push({
          sn: item.serail_no,
          id: item._id,
        });

        return item;
      });

      acNames = acNames.filter(
        (v, i, a) => a.findIndex((v2) => v2.title === v.title) === i
      );
      serials = serials.filter(
        (v, i, a) => a.findIndex((v2) => v2.sn === v.sn) === i
      );

      acNames.sort((a, b) => {
        let x = a.id.toLowerCase();
        let y = b.id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      serials.sort((a, b) => {
        let x = a.id.toLowerCase();
        let y = b.id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setAcNameListSearch(acNames);
      setSerialListSearch(serials);
    } catch (error) {
      console.log(error);
    }
  };

  const changeRoom = async (val) => {
    try {
      let token = localStorage.getItem("token");
      let productArr = await products(token, building, floor);

      let acNames = [];
      let serials = [];

      productArr.data.productsList.map((item) => {
        if (item.location.room === val || val === "") {
          acNames.push({
            title: item.title,
            id: item._id,
          });

          serials.push({
            sn: item.serail_no,
            id: item._id,
          });
        }

        return item;
      });

      acNames = acNames.filter(
        (v, i, a) => a.findIndex((v2) => v2.title === v.title) === i
      );
      serials = serials.filter(
        (v, i, a) => a.findIndex((v2) => v2.sn === v.sn) === i
      );

      acNames.sort((a, b) => {
        let x = a.id.toLowerCase();
        let y = b.id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      serials.sort((a, b) => {
        let x = a.id.toLowerCase();
        let y = b.id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setAcNameListSearch(acNames);
      setSerialListSearch(serials);
      setAcNameSearch("");
      setSerialNumberSearch("");
      setRoomSearch(val);
    } catch (error) {}
  };

  const filterACRoom = async () => {
    if (buildingFilter && floorFilter) {
      setBuilding(buildingFilter);
      setFloor(floorFilter);
      setOpen(false);

      setAcNameSearch("");
      setSerialNumberSearch("");

      try {
        let token = localStorage.getItem("token");
        let res = await products(token, buildingFilter, floorFilter);

        setAcList(res.data.productsList);
        setAcListSearchResult(res.data.productsList);

        let acNames = [];
        let serials = [];

        res.data.productsList.map((item) => {
          acNames.push({
            title: item.title,
            id: item._id,
          });
          serials.push({
            sn: item.serail_no,
            id: item._id,
          });

          return item;
        });

        acNames = acNames.filter(
          (v, i, a) => a.findIndex((v2) => v2.title === v.title) === i
        );
        serials = serials.filter(
          (v, i, a) => a.findIndex((v2) => v2.sn === v.sn) === i
        );

        acNames.sort((a, b) => {
          let x = a.id.toLowerCase();
          let y = b.id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        serials.sort((a, b) => {
          let x = a.id.toLowerCase();
          let y = b.id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
      } catch (error) {
        console.log(error);
        setOpen(false);
      }
    } else {
      setOpen(false);
    }
  };

  const editModalHandle = async (
    ac_name,
    serail_no,
    cid_data,
    building_data,
    floor_data,
    room_data,
    deviceType,
    deviceModel,
    uidData,
    oduData,
    gatewayData
  ) => {
    setEditForm(true);
    setAcName(ac_name);
    setSerialNumber(serail_no);
    setBuildingSelect(building_data);
    setFloorSelect(floor_data);
    setRoomSelect(room_data);
    setCid(cid_data);
    setUID(uidData);
    setOdu(oduData);
    setTypeSelect(deviceType);
    setModelSelect(deviceModel);
    setGateWay(gatewayData);
    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      let buildings = [];
      let floors = [];
      let rooms = [];

      // Filler Building
      buildingData.map((item) => {
        if (item.title !== undefined) {
          buildings.push({
            building: item.title,
            building_id: item._id,
          });
        }

        return item;
      });

      buildings.sort((a, b) => {
        if (a.building_id.toLowerCase() < b.building_id.toLowerCase()) {
          return -1;
        }

        if (a.building_id.toLowerCase() > b.building_id.toLowerCase()) {
          return 1;
        }

        return 0;
      });

      // Filler Floor
      buildingData.map((item) => {
        if (building_data === item.title) {
          if (item.floors !== undefined) {
            item.floors.map((data) => {
              floors.push({
                floor: data.title,
                floor_id: data.floorId,
              });

              return data;
            });
          }
        }

        return item;
      });

      floors.sort((a, b) => {
        if (a.floor_id.toLowerCase() < b.floor_id.toLowerCase()) {
          return -1;
        }

        if (a.floor_id.toLowerCase() > b.floor_id.toLowerCase()) {
          return 1;
        }

        return 0;
      });

      // Filler Room
      buildingData.map((item) => {
        if (building_data === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              if (floor_data === i.title) {
                if (i.rooms) {
                  i.rooms.map((data) => {
                    // if (data.title !== "All") {
                    rooms.push({
                      room: data.title,
                      room_id: data.roomId,
                    });
                    // }

                    return data;
                  });
                }
              }

              return i;
            });
          }
        }

        return item;
      });

      rooms.sort((a, b) => {
        if (a.room_id.toLowerCase() < b.room_id.toLowerCase()) {
          return -1;
        }

        if (a.room_id.toLowerCase() > b.room_id.toLowerCase()) {
          return 1;
        }

        return 0;
      });

      setBuildingListModal(buildings);
      setFloorListModal(floors);
      setRoomListModal(rooms);
    } catch (error) {
      console.log(error);
    }
  };

  const addModalHandle = async () => {
    setSerialNumber("");
    setAcName("");
    setBuildingSelect("");
    setFloorSelect("");
    setRoomSelect("");
    setAddForm(true);

    setBuildingListModal(buildingList);
  };

  const changeBuildingSelect = async (val) => {
    setBuildingSelect(val);

    let floors = [];
    let rooms = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (val === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              floors.push({
                floor: i.title,
                floor_image: i.plan_img,
                floor_id: i.floorId,
              });

              return i;
            });
          }

          floors.sort((a, b) => {
            if (a.floor_id.toLowerCase() < b.floor_id.toLowerCase()) {
              return -1;
            }

            if (a.floor_id.toLowerCase() > b.floor_id.toLowerCase()) {
              return 1;
            }

            return 0;
          });

          if (item.floors) {
            item.floors.map((i) => {
              if (i.title === floors[0].floor) {
                if (i.rooms) {
                  i.rooms.map((j) => {
                    // if (j.title !== "All") {
                    rooms.push({
                      room: j.title,
                      room_id: j.roomId,
                    });
                    // }

                    return j;
                  });
                }
              }

              return i;
            });
          }
        }

        return item;
      });

      rooms.sort((a, b) => {
        if (a.room_id.toLowerCase() < b.room_id.toLowerCase()) {
          return -1;
        }

        if (a.room_id.toLowerCase() > b.room_id.toLowerCase()) {
          return 1;
        }

        return 0;
      });

      setFloorSelect(floors.length ? floors[0].floor : "");
      setRoomSelect(rooms.length ? rooms[0].room : "");
      setFloorListModal(floors);
      setRoomListModal(rooms);

      if (val.trim().length) {
        setBuildingValidate(true);
      } else {
        setBuildingValidate(false);
      }

      if (floors.length) {
        if (floors[0].floor) {
          setFloorValidate(true);
        } else {
          setFloorValidate(false);
        }
      } else {
        setFloorValidate(false);
      }

      if (rooms.length) {
        if (rooms[0].room) {
          setRoomValidate(true);
        } else {
          setRoomValidate(false);
        }
      } else {
        setRoomValidate(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeFloorSelect = async (val) => {
    setFloorSelect(val);

    let rooms = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (buildingSelect === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              if (i.title === val) {
                if (i.rooms !== undefined) {
                  i.rooms.map((j) => {
                    // if (j.title !== "All") {
                    rooms.push({
                      room: j.title,
                      room_id: j.roomId,
                    });
                    // }

                    return i;
                  });
                }
              }

              return i;
            });
          }
        }

        return item;
      });

      rooms.sort((a, b) => {
        if (a.room_id.toLowerCase() < b.room_id.toLowerCase()) {
          return -1;
        }

        if (a.room_id.toLowerCase() > b.room_id.toLowerCase()) {
          return 1;
        }

        return 0;
      });

      setRoomListModal(rooms);
      setRoomSelect(rooms.length ? rooms[0].room : "");

      if (val.trim().length) {
        setFloorValidate(true);
      } else {
        setFloorValidate(false);
      }

      if (rooms.length) {
        if (rooms[0].room) {
          setRoomValidate(true);
        } else {
          setRoomValidate(false);
        }
      } else {
        setRoomValidate(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeRoomSelect = async (val) => {
    if (val.trim().length) {
      setRoomSelect(val);
      setRoomValidate(true);
    } else {
      setRoomSelect(val);
      setRoomValidate(false);
    }
  };

  const acNameChangeHandle = (val) => {
    if (val.trim().length) {
      setAcName(val);
      setAcNameValidate(true);
    } else {
      setAcName(val);
      setAcNameValidate(false);
    }
  };

  const serialNumberChangeHandle = (val) => {
    if (val.trim().length) {
      setSerialNumber(val);
      setSerialNumberValidate(true);
    } else {
      setSerialNumber(val);
      setSerialNumberValidate(false);
    }
  };

  const uidChangeHandle = (val) => {
    if (val.trim().length) {
      setUID(val);
      setUidValidate(true);
    } else {
      setUID(val);
      setUidValidate(false);
    }
  };

  const changeTypeSelect = (val) => {
    setTypeSelect(val);
    setTypeValidate(val.length ? true : false);
  };

  const gatewayChangeHandle = (val) => {
    setGateWayValidate(val.trim().length ? true : false);
    setGateWay(val);
  };

  const cidChangeHandle = (val) => {
    setCid(val);
    setCidValidate(val.trim().length ? true : false);
  };

  const oduChangeHandle = (val) => {
    setOdu(val);
    setOduValidate(val.trim().length ? true : false);
  };

  const changeModelSelect = (val) => {
    setModelSelect(val);
    setModelValidate(val.length ? true : false);
  };

  const deleteHandle = async () => {
    try {
      let token = localStorage.getItem("token");
      // await delete_ac(token);
      setDeleteModal(false);
      setLoaded(true);
    } catch (error) {
      console.log(error);
      setDeleteModal(false);
      setLoaded(true);
    }
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-4 mt-4 pt-5 pt-xl-0">
        <Row className="mx-0 px-xl-4">
          <Col xs={12}>
            <div className="table-container px-2 px-sm-3 px-xl-4 py-3 py-sm-4">
              <div
                className="table-container-title px-2"
                style={{ justifyContent: "space-between" }}
              >
                <h5
                  className="mb-1 position-relative"
                  style={{ maxWidth: "70%" }}
                >
                  AC Management: {building} {floor}
                  <div
                    className="position-select-dropdown"
                    onClick={() => setOpen(!open)}
                  ></div>
                  <div
                    className="control-select-container p-2"
                    style={{
                      visibility: open ? "visible" : "hidden",
                      opacity: open ? 1 : 0,
                      right: "-110px",
                    }}
                    id="setting-filter-container"
                  >
                    <div className="control-select">
                      <img src={BuildingIcon} alt="building" className="pe-2" />
                      <p className="mb-0 pe-2">Building</p>
                      <Form.Select
                        size="sm"
                        onChange={(e) => changeBuilding(e.target.value)}
                        value={buildingFilter}
                      >
                        {buildingList.map((item, index) => (
                          <option value={item.building} key={index}>
                            {item.building}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                    <div className="control-select">
                      <img src={FloorIcon} alt="floor" className="pe-2" />
                      <p className="mb-0 pe-2">Floor</p>
                      <Form.Select
                        size="sm"
                        onChange={(e) => changeFloor(e.target.value)}
                        value={floorFilter}
                      >
                        {floorList.map((item, index) => (
                          <option value={item.floor} key={index}>
                            {item.floor}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                    <button
                      className="control-search-btn my-2 me-2"
                      onClick={() => filterACRoom()}
                    >
                      Confirm
                    </button>
                  </div>
                </h5>
                <button
                  className="add-modal-btn"
                  onClick={() => {
                    addModalHandle();
                  }}
                >
                  Add AC
                </button>
              </div>
              <Row>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-1 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">Room Name</Form.Label>
                    <Form.Select
                      onChange={(e) => changeRoom(e.target.value)}
                      required
                      value={roomSearch}
                    >
                      <option value="">Select Room</option>
                      {roomListSearch.map((item, index) => (
                        <option value={item.room} key={index}>
                          {item.room}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-3 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">AC Name</Form.Label>
                    <Form.Select
                      onChange={(e) => setAcNameSearch(e.target.value)}
                      required
                      value={acNameSearch}
                    >
                      <option value="">Select Ac Name</option>
                      {acNameListSearch.map((item, index) => (
                        <option value={item.id} key={index}>
                          {item.title}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-1 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">
                      Serial Number
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => setSerialNumberSearch(e.target.value)}
                      required
                      value={serialNumberSearch}
                    >
                      <option value="">Select Serial</option>
                      {serialListSearch.map((item, index) => (
                        <option value={item.sn} key={index}>
                          {item.sn}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={"auto"} className="pe-0 ms-2 ms-sm-0 mt-2 mt-sm-0">
                  <Form.Group className="mt-4 mb-2 px-2 d-none d-sm-block">
                    <Form.Label className="input-label mb-0">&nbsp;</Form.Label>
                  </Form.Group>
                  <button className="search-btn" onClick={() => search()}>
                    Search
                  </button>
                </Col>
                <Col xs={"auto"} className="mt-2 mt-sm-0">
                  <Form.Group className="mt-4 mb-2 px-2 d-none d-sm-block">
                    <Form.Label className="input-label mb-0">&nbsp;</Form.Label>
                  </Form.Group>
                  <button className="clear-btn" onClick={() => clearSearch()}>
                    Clear
                  </button>
                </Col>
              </Row>
              <div className="mx-2 mb-2 table-overflow">
                <Table hover className="mt-3 mb-2 setting-table align-middle">
                  <thead>
                    <tr>
                      <th className="px-4">No.</th>
                      <th className="text-center px-4">AC Name</th>
                      <th className="text-center px-4">Serial Number</th>
                      <th className="text-center px-4">UID</th>
                      <th className="text-center px-4">Room</th>
                      <th className="text-center px-4">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {acListSearchResult.length ? (
                      currentPosts.map((item, index) => (
                        <tr key={index}>
                          <td className="px-4">
                            {currentPage === 1
                              ? index + 1
                              : index + 1 + (currentPage - 1) * PerPage}
                            .
                          </td>
                          <td className="px-4 text-center">{item.title}</td>
                          <td className="px-4 text-center">{item.serail_no}</td>
                          <td className="px-4 text-center">{item.cid}</td>
                          <td className="px-4 text-center">
                            {item.location.room}
                          </td>
                          <td className="px-4 text-center">
                            <span
                              className="table-edit"
                              onClick={() => {
                                editModalHandle(
                                  item.title,
                                  item.serail_no,
                                  item.cid,
                                  item.location.building,
                                  item.location.floor,
                                  item.location.room,
                                  item.type,
                                  item.model,
                                  item.uid,
                                  item.odu,
                                  item.gateway_sn
                                );
                              }}
                            >
                              Edit
                            </span>{" "}
                            /{" "}
                            <span
                              className="table-delete"
                              onClick={() => setDeleteModal(true)}
                            >
                              Delete
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <Pagination
                postsPerPage={PerPage}
                totalPosts={acListSearchResult.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        size="lg"
        show={editForm}
        onHide={() => setEditForm(false)}
        centered
      >
        <Modal.Body>
          <h4 className="modal-title mb-2">Edit AC</h4>
          {/* <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">AC Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="AC Name"
              defaultValue={acName}
              required
              onChange={(e) => acNameChangeHandle(e.target.value)}
              style={{
                borderColor: acNameValidate ? "#cccccc" : "#b31117",
              }}
            />
            {!acNameValidate && (
              <Form.Label
                className="input-label mt-1"
                style={{ color: "#b31117" }}
              >
                Please provide a valid ac name.
              </Form.Label>
            )}
          </Form.Group>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Serial Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Serial Number"
              defaultValue={serialNumber}
              required
              onChange={(e) => serialNumberChangeHandle(e.target.value)}
              style={{
                borderColor: serialNumberValidate ? "#cccccc" : "#b31117",
              }}
            />
            {!serialNumberValidate && (
              <Form.Label
                className="input-label mt-1"
                style={{ color: "#b31117" }}
              >
                Please provide a valid serial number.
              </Form.Label>
            )}
          </Form.Group>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">UID</Form.Label>
            <Form.Control
              type="text"
              placeholder="UID"
              defaultValue={uid}
              required
              onChange={(e) => uidChangeHandle(e.target.value)}
              style={{
                borderColor: uidValidate ? "#cccccc" : "#b31117",
              }}
            />
            {!uidValidate && (
              <Form.Label
                className="input-label mt-1"
                style={{ color: "#b31117" }}
              >
                Please provide a valid uid.
              </Form.Label>
            )}
          </Form.Group>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Building Name</Form.Label>
            <Form.Select
              onChange={(e) => changeBuildingSelect(e.target.value)}
              required
              value={buildingSelect}
              style={{
                borderColor: buildingValidate ? "#cccccc" : "#b31117",
              }}
            >
              <option value="">Select Building</option>
              {buildingListModal.map((item, index) => (
                <option value={item.building} key={index}>
                  {item.building}
                </option>
              ))}
            </Form.Select>
            {!buildingValidate && (
              <Form.Label
                className="input-label mt-1"
                style={{ color: "#b31117" }}
              >
                Please select building.
              </Form.Label>
            )}
          </Form.Group>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Floor Name</Form.Label>
            <Form.Select
              onChange={(e) => changeFloorSelect(e.target.value)}
              required
              value={floorSelect}
              style={{
                borderColor: floorValidate ? "#cccccc" : "#b31117",
              }}
            >
              <option value="">Select Floor</option>
              {floorListModal.map((item, index) => (
                <option value={item.floor} key={index}>
                  {item.floor}
                </option>
              ))}
            </Form.Select>
            {!floorValidate && (
              <Form.Label
                className="input-label mt-1"
                style={{ color: "#b31117" }}
              >
                Please select floor.
              </Form.Label>
            )}
          </Form.Group>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Room Name</Form.Label>
            <Form.Select
              onChange={(e) => changeRoomSelect(e.target.value)}
              required
              value={roomSelect}
              style={{
                borderColor: roomValidate ? "#cccccc" : "#b31117",
              }}
            >
              <option value="">Select Room</option>
              {roomListModal.map((item, index) => (
                <option value={item.room} key={index}>
                  {item.room}
                </option>
              ))}
            </Form.Select>
            {!roomValidate && (
              <Form.Label
                className="input-label mt-1"
                style={{ color: "#b31117" }}
              >
                Please select room.
              </Form.Label>
            )}
          </Form.Group> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "50%" }}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">AC Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="AC Name"
                  onChange={(e) => acNameChangeHandle(e.target.value)}
                  required
                  style={{
                    borderColor: acNameValidate ? "#cccccc" : "#b31117",
                  }}
                  defaultValue={acName}
                />
                {!acNameValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please provide a valid ac name.
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Model</Form.Label>
                <Form.Select
                  onChange={(e) => changeModelSelect(e.target.value)}
                  required
                  value={modelSelect}
                  style={{
                    borderColor: modelValidate ? "#cccccc" : "#b31117",
                  }}
                  defaultValue={modelSelect}
                >
                  <option value="">Select Model</option>
                  {modelList.map((item, index) => (
                    <option value={item.value} key={index}>
                      {item.label}
                    </option>
                  ))}
                </Form.Select>
                {!modelValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please provide a model.
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Gateway</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Gateway"
                  onChange={(e) => gatewayChangeHandle(e.target.value)}
                  required
                  style={{
                    borderColor: gatewayValidate ? "#cccccc" : "#b31117",
                  }}
                  defaultValue={gateway}
                />
                {!gatewayValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please provide a valid gateway.
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">CID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="CID"
                  required
                  onChange={(e) => cidChangeHandle(e.target.value)}
                  style={{
                    borderColor: cidValidate ? "#cccccc" : "#b31117",
                  }}
                  defaultValue={cid}
                />
                {!cidValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please provide a valid cid.
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Building Name</Form.Label>
                <Form.Select
                  onChange={(e) => changeBuildingSelect(e.target.value)}
                  required
                  value={buildingSelect}
                  style={{
                    borderColor: buildingValidate ? "#cccccc" : "#b31117",
                  }}
                >
                  <option value="">Select Building</option>
                  {buildingListModal.map((item, index) => (
                    <option value={item.building} key={index}>
                      {item.building}
                    </option>
                  ))}
                </Form.Select>
                {!buildingValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please select building.
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Room Name</Form.Label>
                <Form.Select
                  onChange={(e) => changeRoomSelect(e.target.value)}
                  required
                  value={roomSelect}
                  style={{
                    borderColor: roomValidate ? "#cccccc" : "#b31117",
                  }}
                >
                  <option value="">Select Room</option>
                  {roomListModal.map((item, index) => (
                    <option value={item.room} key={index}>
                      {item.room}
                    </option>
                  ))}
                </Form.Select>
                {!roomValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please select room.
                  </Form.Label>
                )}
              </Form.Group>
            </div>
            <div style={{ width: "50%" }}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Type</Form.Label>
                <Form.Select
                  onChange={(e) => changeTypeSelect(e.target.value)}
                  required
                  value={typeSelect}
                  style={{
                    borderColor: typeValidate ? "#cccccc" : "#b31117",
                  }}
                  defaultValue={typeSelect}
                >
                  <option value="">Select Type</option>
                  {typeList.map((item, index) => (
                    <option value={item.value} key={index}>
                      {item.label}
                    </option>
                  ))}
                </Form.Select>
                {!typeValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please provide a type.
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Serial Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Serial Number"
                  onChange={(e) => serialNumberChangeHandle(e.target.value)}
                  required
                  style={{
                    borderColor: serialNumberValidate ? "#cccccc" : "#b31117",
                  }}
                  defaultValue={serialNumber}
                />
                {!serialNumberValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please provide a valid serial number.
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">UID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="UID"
                  required
                  onChange={(e) => uidChangeHandle(e.target.value)}
                  style={{
                    borderColor: uidValidate ? "#cccccc" : "#b31117",
                  }}
                  defaultValue={uid}
                />
                {!uidValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please provide a valid uid.
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">ODU</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="CID"
                  required
                  onChange={(e) => oduChangeHandle(e.target.value)}
                  style={{
                    borderColor: oduValidate ? "#cccccc" : "#b31117",
                  }}
                  defaultValue={odu}
                />
                {!oduValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please provide a valid odu.
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Floor Name</Form.Label>
                <Form.Select
                  onChange={(e) => changeFloorSelect(e.target.value)}
                  required
                  value={floorSelect}
                  style={{
                    borderColor: floorValidate ? "#cccccc" : "#b31117",
                  }}
                >
                  <option value="">Select Floor</option>
                  {floorListModal.map((item, index) => (
                    <option value={item.floor} key={index}>
                      {item.floor}
                    </option>
                  ))}
                </Form.Select>
                {!floorValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please select floor.
                  </Form.Label>
                )}
              </Form.Group>
            </div>
          </div>
          <div className="modal-btn-container mb-2">
            <Button
              type="button"
              onClick={() => {
                setEditForm(false);
                setBuildingValidate(true);
                setFloorValidate(true);
                setRoomValidate(true);
                setAcNameValidate(true);
                setSerialNumberValidate(true);
                setUidValidate(true);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" onClick={() => handleSubmit("edit")}>
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal size="lg" show={addForm} onHide={() => setAddForm(false)} centered>
        <Modal.Body>
          <h4 className="modal-title mb-2">Add AC</h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "50%" }}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">AC Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="AC Name"
                  onChange={(e) => acNameChangeHandle(e.target.value)}
                  required
                  style={{
                    borderColor: acNameValidate ? "#cccccc" : "#b31117",
                  }}
                />
                {!acNameValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please provide a valid ac name.
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Model</Form.Label>
                <Form.Select
                  onChange={(e) => changeModelSelect(e.target.value)}
                  required
                  value={modelSelect}
                  style={{
                    borderColor: modelValidate ? "#cccccc" : "#b31117",
                  }}
                >
                  <option value="">Select Model</option>
                  {modelList.map((item, index) => (
                    <option value={item.value} key={index}>
                      {item.label}
                    </option>
                  ))}
                </Form.Select>
                {!modelValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please provide a model.
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Gateway</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Gateway"
                  onChange={(e) => gatewayChangeHandle(e.target.value)}
                  required
                  style={{
                    borderColor: gatewayValidate ? "#cccccc" : "#b31117",
                  }}
                />
                {!gatewayValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please provide a valid gateway.
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">CID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="CID"
                  required
                  onChange={(e) => cidChangeHandle(e.target.value)}
                  style={{
                    borderColor: cidValidate ? "#cccccc" : "#b31117",
                  }}
                />
                {!cidValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please provide a valid cid.
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Building Name</Form.Label>
                <Form.Select
                  onChange={(e) => changeBuildingSelect(e.target.value)}
                  required
                  value={buildingSelect}
                  style={{
                    borderColor: buildingValidate ? "#cccccc" : "#b31117",
                  }}
                >
                  <option value="">Select Building</option>
                  {buildingListModal.map((item, index) => (
                    <option value={item.building} key={index}>
                      {item.building}
                    </option>
                  ))}
                </Form.Select>
                {!buildingValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please select building.
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Room Name</Form.Label>
                <Form.Select
                  onChange={(e) => changeRoomSelect(e.target.value)}
                  required
                  value={roomSelect}
                  style={{
                    borderColor: roomValidate ? "#cccccc" : "#b31117",
                  }}
                >
                  <option value="">Select Room</option>
                  {roomListModal.map((item, index) => (
                    <option value={item.room} key={index}>
                      {item.room}
                    </option>
                  ))}
                </Form.Select>
                {!roomValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please select room.
                  </Form.Label>
                )}
              </Form.Group>
            </div>
            <div style={{ width: "50%" }}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Type</Form.Label>
                <Form.Select
                  onChange={(e) => changeTypeSelect(e.target.value)}
                  required
                  value={typeSelect}
                  style={{
                    borderColor: typeValidate ? "#cccccc" : "#b31117",
                  }}
                >
                  <option value="">Select Type</option>
                  {typeList.map((item, index) => (
                    <option value={item.value} key={index}>
                      {item.label}
                    </option>
                  ))}
                </Form.Select>
                {!typeValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please provide a type.
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Serial Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Serial Number"
                  onChange={(e) => serialNumberChangeHandle(e.target.value)}
                  required
                  style={{
                    borderColor: serialNumberValidate ? "#cccccc" : "#b31117",
                  }}
                />
                {!serialNumberValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please provide a valid serial number.
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">UID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="UID"
                  required
                  onChange={(e) => uidChangeHandle(e.target.value)}
                  style={{
                    borderColor: uidValidate ? "#cccccc" : "#b31117",
                  }}
                />
                {!uidValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please provide a valid uid.
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">ODU</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="CID"
                  required
                  onChange={(e) => oduChangeHandle(e.target.value)}
                  style={{
                    borderColor: oduValidate ? "#cccccc" : "#b31117",
                  }}
                />
                {!oduValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please provide a valid odu.
                  </Form.Label>
                )}
              </Form.Group>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Floor Name</Form.Label>
                <Form.Select
                  onChange={(e) => changeFloorSelect(e.target.value)}
                  required
                  value={floorSelect}
                  style={{
                    borderColor: floorValidate ? "#cccccc" : "#b31117",
                  }}
                >
                  <option value="">Select Floor</option>
                  {floorListModal.map((item, index) => (
                    <option value={item.floor} key={index}>
                      {item.floor}
                    </option>
                  ))}
                </Form.Select>
                {!floorValidate && (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    Please select floor.
                  </Form.Label>
                )}
              </Form.Group>
            </div>
          </div>
          <div className="modal-btn-container mb-2">
            <Button
              type="button"
              onClick={() => {
                setAddForm(false);
                setBuildingValidate(true);
                setFloorValidate(true);
                setRoomValidate(true);
                setAcNameValidate(true);
                setSerialNumberValidate(true);
                setUidValidate(true);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" onClick={() => handleSubmit("add")}>
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={deleteModal} centered>
        <Modal.Body>
          <h4 className="modal-title mb-4">Do you want delete this ac ?</h4>
          <div className="modal-btn-container mb-2">
            <Button
              type="button"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={() => {
                deleteHandle();
              }}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={loaded}
        backdrop="static"
        keyboard="false"
        centered
        id="loading-modal"
      >
        <div className="loader"></div>
      </Modal>
    </div>
  );
};

export default SettingAC;
