import React, { useState, useEffect } from "react";
import { Row, Col, Table, Form, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import moment from "moment";

import Header from "../components/header";
import Nav from "../components/nav";
import Pagination from "../components/pagination";
import ExportExcel from "../components/export_excel";
import { odu_system_list } from "../api/report";
import { useRealtimeOduReport, useRealtimeClock } from "../hook/useHook";

const ReportOdu = (props) => {
  const { state } = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage] = useState(20);
  const [open, setOpen] = useState(false);
  const [cdu, setCdu] = useState("");
  const [cduFilter, setCduFilter] = useState("");
  const [systemList, setSystemList] = useState([]);

  const [clock] = useRealtimeClock();
  const [reportData, exportData, loading] = useRealtimeOduReport(cdu);

  const indexOfLastPost = currentPage * PerPage;
  const indexOfFirstPost = indexOfLastPost - PerPage;
  const currentPosts = reportData.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    (async () => {
      try {
        let token = localStorage.getItem("token");
        let res_system = await odu_system_list(token);

        setSystemList(res_system.data.systemlist);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const filterReport = () => {
    setOpen(false);
    setCdu(cduFilter);
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-4 pt-5 pt-xl-0">
        <Row className="mx-0 px-xl-4">
          <Col>
            <div className="table-container px-2 px-sm-3 px-xl-4 py-3 py-sm-4">
              <div className="table-container-title px-2">
                <Row className="w-100">
                  <Col xs={12} md={"auto"} className="position-relative">
                    <h5 className="mb-1">
                      {state?.report_title
                        ? state.report_title
                        : "ODU Realtime Report"}
                      {": "}
                      {cdu ? cdu : "All"}
                    </h5>
                    <div
                      id="report-open"
                      className="control-select-dropdown"
                      onClick={() => setOpen(!open)}
                    ></div>
                    <div
                      className="control-select-container p-2"
                      style={{
                        visibility: open ? "visible" : "hidden",
                        opacity: open ? 1 : 0,
                        top: "40px",
                        right: "-20px",
                      }}
                    >
                      <div className="control-select">
                        <p className="mb-0 pe-2">CDU No.</p>
                        <Form.Select
                          size="sm"
                          onChange={(e) => setCduFilter(e.target.value)}
                          defaultValue={cdu}
                        >
                          <option value={""}>All</option>
                          {systemList.map((item, index) =>
                            item.cdu_no ? (
                              <option value={item.cdu_no} key={index}>
                                {item.cdu_no}
                              </option>
                            ) : null
                          )}
                        </Form.Select>
                      </div>
                      <button
                        className="control-search-btn my-2 me-2"
                        onClick={() => filterReport()}
                      >
                        Confirm
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
              <p className="report-time px-2 mb-2 mb-lg-4">
                {clock ? clock : moment().format("DD/MM/YYYY [TIME] HH:mm:ss")}
              </p>
              <ExportExcel
                csvData={exportData}
                fileName={
                  "ODU Realtime Report_" +
                  moment().format("DD_MM_YYYY_HH_mm_ss") +
                  "_.csv"
                }
              />
              <div className="mx-2 mb-2 table-overflow">
                <Table hover className="mt-3 error-table mb-2">
                  <thead>
                    <tr>
                      <th className="sticky-col" style={{ left: 0 }}>
                        System Name
                      </th>
                      <th className="sticky-col" style={{ left: "100px" }}>
                        Gateway
                      </th>
                      <th
                        className="sticky-col"
                        style={{
                          left: "200px",
                          minWidth: "300px",
                        }}
                      >
                        Location
                      </th>
                      <th>UID</th>
                      <th>ODU ID</th>
                      <th>Mid Coil</th>
                      <th>Outlet Temp.</th>
                      <th>Discharge Pipe Temp. 1</th>
                      <th>Discharge Pipe Temp. 2</th>
                      <th>Ambient Temp.</th>
                      <th>Suction Pipe Temp.</th>
                      <th>Control Device Temp. 1</th>
                      <th>Control Device Temp. 2</th>
                      <th>Compressor Actual Speed 1</th>
                      <th>Compressor Actual Speed 2</th>
                      <th>Compressor Current 1</th>
                      <th>Compressor Current 2</th>
                      <th>Outdoor Fan</th>
                      <th>System Mode</th>
                      <th>Unit Capacity</th>
                      <th>Demand Running 1</th>
                      <th>Demand Running 2</th>
                      <th>System Status Comp. 1</th>
                      <th>System Status Comp. 2</th>
                      <th>Protect Status System 1</th>
                      <th>Protect Status System 2</th>
                      <th>System Power Input</th>
                      <th>Outdoor EXV</th>
                      <th>Outdoor LED</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.length ? (
                      currentPosts.map((item, key) =>
                        item.oduinfo.length ? (
                          item.oduinfo.map((i, index) => (
                            <tr key={key}>
                              <td className="sticky-col" style={{ left: 0 }}>
                                {item.system_name}
                              </td>
                              <td
                                className="sticky-col"
                                style={{ left: "100px" }}
                              >
                                {item.gateway_sn}
                              </td>
                              <td
                                className="sticky-col"
                                style={{
                                  left: "200px",
                                  minWidth: "300px",
                                }}
                              >
                                {item.location.length
                                  ? item.location.map((j, index) => (
                                      <React.Fragment key={index}>
                                        {j.building +
                                          " " +
                                          j.floor +
                                          " " +
                                          j.room +
                                          ", "}
                                      </React.Fragment>
                                    ))
                                  : "-"}
                              </td>
                              <td>{i.uid}</td>
                              <td>{i.oduid}</td>
                              <td>{i.mid_coil}</td>
                              <td
                                style={{
                                  color:
                                    i.outlet_temp === "No Sensor"
                                      ? "#b31117"
                                      : "#212529",
                                }}
                              >
                                {i.outlet_temp}
                              </td>
                              <td>{i.discharge_pipe_temp1}</td>
                              <td>{i.discharge_pipe_temp2}</td>
                              <td>{i.ambient_temp}</td>
                              <td>{i.suction_pipe_temp}</td>
                              <td>{i.control_device_temp1}</td>
                              <td>{i.control_device_temp2}</td>
                              <td>{i.compressor1_actual_speed}</td>
                              <td>{i.compressor2_actual_speed}</td>
                              <td>{i.compressor1_current}</td>
                              <td>{i.compressor2_current}</td>
                              <td>{i.outdoor_fan}</td>
                              <td>{i.system_mode}</td>
                              <td>{i.unit_capacity}</td>
                              <td>{i.demand_running1}</td>
                              <td>{i.demand_running2}</td>
                              <td
                                style={{
                                  color:
                                    i.system_status_comp1 === "Stop"
                                      ? "#b31117"
                                      : "#00A300",
                                }}
                              >
                                {i.system_status_comp1}
                              </td>
                              <td
                                style={{
                                  color:
                                    i.system_status_comp2 === "Stop"
                                      ? "#b31117"
                                      : "#00A300",
                                }}
                              >
                                {i.system_status_comp2}
                              </td>
                              <td
                                style={{
                                  color:
                                    i.protect_status_system1 === "Nomal"
                                      ? "#00A300"
                                      : "#b31117",
                                }}
                              >
                                {i.protect_status_system1}
                              </td>
                              <td
                                style={{
                                  color:
                                    i.protect_status_system2 === "Nomal"
                                      ? "#00A300"
                                      : "#b31117",
                                }}
                              >
                                {i.protect_status_system2}
                              </td>
                              <td>{i.system_power_input}</td>
                              <td>{i.outdoor_exv}</td>
                              <td>{i.outdoor_led}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="sticky-col" style={{ left: 0 }}>
                              {item.system_name}
                            </td>
                            <td
                              className="sticky-col"
                              style={{ left: "100px" }}
                            >
                              {item.gateway_sn}
                            </td>
                            <td
                              className="sticky-col"
                              style={{
                                left: "200px",
                                minWidth: "300px",
                              }}
                            >
                              {item.location.length
                                ? item.location.map((j, index) => (
                                    <React.Fragment key={index}>
                                      {j.building +
                                        " " +
                                        j.floor +
                                        " " +
                                        j.room +
                                        ", "}
                                    </React.Fragment>
                                  ))
                                : "-"}
                            </td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan={29}>No Data</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <Pagination
                postsPerPage={PerPage}
                totalPosts={reportData.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        show={loading}
        backdrop="static"
        keyboard="false"
        centered
        id="loading-modal"
      >
        <div className="loader"></div>
      </Modal>
    </div>
  );
};

export default ReportOdu;
