import React, { useState, useEffect, useContext, useMemo } from "react";
import { Row, Col, Form, Modal, Container } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";

import Header from "../components/header";
import Nav from "../components/nav";
import Marker from "../assets/image/header/blue_marker.png";
import BuildingIcon from "../assets/image/control/building-icon.png";
import FloorIcon from "../assets/image/control/floor-icon.png";
import RoomIcon from "../assets/image/control/room-icon.png";
import FloorIconWhite from "../assets/image/dashboard/floor.png";
import RoomIconWhite from "../assets/image/dashboard/room.png";
import { MqttContext } from "../components/mqtt_context";
import { ProfileContext } from "../components/profile_context";
import { products, building_list } from "../api/product";

import DoubleSkin from "../assets/image/dashboard/double_skin.png";
import UTF from "../assets/image/dashboard/ultrafine.png";
import PE from "../assets/image/dashboard/pe.png";
import FFU from "../assets/image/dashboard/fan_filter_inverter.png";
import IAQ from "../assets/image/dashboard/iaq.png";
import Cassette from "../assets/image/dashboard/cassette.png";
import Duct from "../assets/image/dashboard/duct.png";
import Temp from "../assets/image/control/temp.png";
import Humidity from "../assets/image/control/humidity.png";
import Cool from "../assets/image/control/cool.png";
import Fan from "../assets/image/control/fan.png";
import Dry from "../assets/image/control/dry.png";
import Heat from "../assets/image/control/heat.png";
import Auto from "../assets/image/control/auto.png";
import Manual from "../assets/image/control/manual.png";
import AutoProtective from "../assets/image/control/auto_protective.png";
import FanLow from "../assets/image/control/fan_low.png";
import FanMid from "../assets/image/control/fan_mid.png";
import FanHigh from "../assets/image/control/fan_high.png";
import FanHiHi from "../assets/image/control/fan_hi_hi.png";
import FanTurbo from "../assets/image/control/fan_turbo.png";
import FanAuto from "../assets/image/control/fan_auto.png";
import Swing_1 from "../assets/image/control/swing_1.png";
import Swing_2 from "../assets/image/control/swing_2.png";
import Swing_3 from "../assets/image/control/swing_3.png";
import Swing_4 from "../assets/image/control/swing_4.png";
import Swing_5 from "../assets/image/control/swing_5.png";
import SwingAuto from "../assets/image/control/swing_auto.png";
import AutoFunction from "../assets/image/control/function_auto.png";
import AutoProtection from "../assets/image/control/function_protection.png";
import OzoneFilter from "../assets/image/control/function_ozone.png";
import Power from "../assets/image/control/power.png";
import PowerIcon from "../assets/image/dashboard/power.png";
import faker from "faker";

const MarkerComponent = ({ text }) => (
  <div className="marker-container">
    <div className="marker-title-container">{text}</div>
    <img src={Marker} alt="marker" className="marker-img" />
  </div>
);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: "Air Quality",
      padding: {
        bottom: 20,
      },
      font: {
        size: 16,
        family: "SukhumvitSet-Medium",
      },
    },
  },
};

const labels = [
  "00 am",
  "01 am",
  "02 am",
  "03 am",
  "04 am",
  "05 am",
  "06 am",
  "07 am",
  "08 am",
  "09 am",
  "10 am",
  "11 am",
  "12 am",
  "01 pm",
  "02 pm",
  "03 pm",
  "04 pm",
  "05 pm",
  "06 pm",
  "07 pm",
  "08 pm",
  "09 pm",
  "10 pm",
  "11 pm",
  "12 pm",
];

const graphData = {
  labels,
  datasets: [
    {
      label: "Air Quality",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 300 })),
      borderColor: "#3fbfef",
      backgroundColor: "#66a6ff",
    },
  ],
};

const ControlPanel = ({
  deviceList,
  building,
  floor,
  room,
  uid,
  serialNumber,
  id,
  enableService,
  checkModel,
  modePeConvert,
  setHumidity,
}) => {
  const [acData, setAcData] = useState({
    ac_options: '{"power":true,"temp":true,"iaq":true}',
    acinfo: [],
    cdu_no: "",
    cid: "",
    enable: false,
    gateway_sn: "",
    location: {
      building: "",
      floor: "",
      room: "",
    },
    model: "",
    odu: "",
    position: '{"x":"0","y":"0"}',
    serail_no: "",
    tag: "[]",
    title: "",
    type: "",
    uid: "",
    _id: "",
  });

  const { pubCommand } = useContext(MqttContext);

  useMemo(() => {
    let acInfoObject = {
      ac_options: '{"power":true,"temp":true,"iaq":true}',
      acinfo: [],
      cdu_no: "",
      cid: "",
      enable: false,
      gateway_sn: "",
      location: {
        building: "",
        floor: "",
        room: "",
      },
      model: "",
      odu: "",
      position: '{"x":"0","y":"0"}',
      serail_no: "",
      tag: "[]",
      title: "",
      type: "",
      uid: "",
      _id: "",
    };

    deviceList.map((item) => {
      if (
        item.location.building === building &&
        item.location.floor === floor &&
        (room === "All" ? true : item.location.room === room) &&
        uid === item.cid
      ) {
        acInfoObject = {
          ac_options: item.ac_options,
          acinfo: item.acinfo,
          cdu_no: item.cdu_no,
          cid: item.cid,
          enable: item.enable,
          gateway_sn: item.gateway_sn,
          location: {
            building: item.location.building,
            floor: item.location.floor,
            room: item.location.room,
          },
          model: item.model,
          odu: item.odu,
          position: item.position,
          serail_no: item.serail_no,
          tag: item.tag,
          title: item.title,
          type: item.type,
          uid: item.uid,
          _id: item._id,
        };
      }

      return item;
    });

    setAcData(acInfoObject);
  }, [deviceList, building, floor, room, uid]);

  const tempHandle = (val, tempVal, enable) => {
    if (enable) {
      if (val === "inc") {
        pubCommand(
          uid,
          serialNumber,
          "temp",
          tempVal >= 30 ? 30 : tempVal + 0.5,
          id
        );
      } else {
        pubCommand(
          uid,
          serialNumber,
          "temp",
          tempVal <= 15 ? 15 : tempVal - 0.5,
          id
        );
      }
    }
  };

  const humidityHandle = (val, humidityVal, enable) => {
    if (enable) {
      if (val === "inc") {
        pubCommand(
          uid,
          serialNumber,
          "setHumidity",
          humidityVal >= 100 ? 100 : humidityVal + 1,
          id
        );
        setHumidity(humidityVal >= 100 ? 100 : humidityVal + 1);
      } else {
        pubCommand(
          uid,
          serialNumber,
          "setHumidity",
          humidityVal <= 0 ? 0 : humidityVal - 1,
          id
        );
        setHumidity(humidityVal <= 0 ? 0 : humidityVal - 1);
      }
    }
  };

  const powerConvert = (power) => {
    let power_bit = parseInt(power).toString(2).padStart(8, "0");
    let powerSplit = power_bit.split("");

    return powerSplit[7].toString();
  };

  return (
    <Row>
      <Col xs={9} className="ps-0 pe-2">
        <div
          className={
            checkModel(acData.odu, acData.model) === "IAQ"
              ? acData.acinfo[0]
                ? parseFloat(acData.acinfo[0].istst.RT2) >= 30
                  ? "control-room-temp-container hot h-100"
                  : "control-room-temp-container cool h-100"
                : "control-room-temp-container cool h-100"
              : acData.acinfo[0]
              ? parseFloat(acData.acinfo[0].istst.rt) >= 30
                ? "control-room-temp-container hot h-100"
                : "control-room-temp-container cool h-100"
              : "control-room-temp-container cool h-100"
          }
        >
          <p className="room-temp-label mx-0 mb-2 ps-2 ">
            Room Temp : {acData.title}
          </p>
          <div className="monitor-container ">
            <div className="monitor-value">
              <img src={Temp} alt="temp" />
              <p className="mb-0">
                {checkModel(acData.odu, acData.model) === "IAQ"
                  ? acData.acinfo[0]
                    ? acData.acinfo[0].istst.RT2
                    : 0
                  : acData.acinfo[0]
                  ? acData.acinfo[0].istst.rt
                  : 0}
                <span> °C</span>
              </p>
            </div>
            <div className="monitor-value">
              <img src={Humidity} alt="humidity" className="pe-2" />
              <p className="mb-0">
                {checkModel(acData.odu, acData.model) === "IAQ"
                  ? acData.acinfo[0]
                    ? acData.acinfo[0].istst.RH2
                    : 0
                  : acData.acinfo[0]
                  ? acData.acinfo[0].istst.RH
                  : 0}
                <span> %</span>
              </p>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={3} className="px-0 control-room-control-container">
        <p
          className={
            enableService(checkModel(acData.odu, acData.model), "power") &&
            acData.acinfo.online
              ? "control-label text-center mx-0 mb-2"
              : "control-label text-center mx-0 mb-2 disabled"
          }
        >
          POWER
        </p>
        <div className="control-vertical-container">
          <button
            className={
              acData.acinfo[0]
                ? powerConvert(acData.acinfo[0].istst.onoff) === "1"
                  ? "mode-container active"
                  : "mode-container"
                : "mode-container"
            }
            onClick={() =>
              pubCommand(
                uid,
                serialNumber,
                "power",
                acData.acinfo[0]
                  ? powerConvert(acData.acinfo[0].istst.onoff) === "1"
                    ? "off"
                    : "on"
                  : "off",
                id
              )
            }
            disabled={
              acData.acinfo.online
                ? !enableService(checkModel(acData.odu, acData.model), "power")
                : true
            }
          >
            <img src={Power} alt="power" className="mb-3" />
          </button>
        </div>
      </Col>
      <Col xs={6} className="ps-0 pe-1 mt-2">
        <div className="control-room-control-container px-0">
          <div className="control-container-panel">
            <p
              className={
                enableService(checkModel(acData.odu, acData.model), "temp") &&
                acData.acinfo.online
                  ? "control-label mb-0 ms-0"
                  : "control-label mb-0 ms-0 disabled"
              }
            >
              SET TEMP
            </p>
            <div
              className={
                enableService(checkModel(acData.odu, acData.model), "temp") &&
                acData.acinfo.online
                  ? "temp-panel"
                  : "temp-panel disabled"
              }
            >
              <div
                className="arrow-up me-3"
                onClick={() => {
                  tempHandle(
                    "inc",
                    acData.acinfo[0] ? acData.acinfo[0].istst.st : 0,
                    enableService(
                      checkModel(acData.odu, acData.model),
                      "temp"
                    ) && acData.acinfo.online
                  );
                }}
              ></div>
              <p className="mb-0">
                {acData.acinfo[0] ? acData.acinfo[0].istst.st : 15}
                <span> °C</span>
              </p>
              <div
                className="arrow-down ms-3"
                onClick={() =>
                  tempHandle(
                    "dec",
                    acData.acinfo[0] ? acData.acinfo[0].istst.st : 15,
                    enableService(
                      checkModel(acData.odu, acData.model),
                      "temp"
                    ) && acData.acinfo.online
                  )
                }
              ></div>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={6} className="pe-0 ps-1 mt-2">
        <div className="control-room-control-container px-0">
          <div className="control-container-panel">
            <p
              className={
                enableService(
                  checkModel(acData.odu, acData.model),
                  "humidity"
                ) && acData.acinfo.online
                  ? "control-label mb-0 ms-0"
                  : "control-label mb-0 ms-0 disabled"
              }
            >
              HUMIDITY
            </p>
            <div
              className={
                enableService(
                  checkModel(acData.odu, acData.model),
                  "humidity"
                ) && acData.acinfo.online
                  ? "temp-panel"
                  : "temp-panel disabled"
              }
            >
              <div
                className="arrow-up me-3"
                onClick={() => {
                  humidityHandle(
                    "inc",
                    acData.acinfo[0] ? acData.acinfo[0].istst.SetRH : 0,
                    enableService(
                      checkModel(acData.odu, acData.model),
                      "humidity"
                    ) && acData.acinfo.online
                  );
                }}
              ></div>
              <p className="mb-0">
                {acData.acinfo[0] ? acData.acinfo[0].istst.SetRH : 0}
                <span> %</span>
              </p>
              <div
                className="arrow-down ms-3"
                onClick={() => {
                  humidityHandle(
                    "dec",
                    acData.acinfo[0] ? acData.acinfo[0].istst.SetRH : 0,
                    enableService(
                      checkModel(acData.odu, acData.model),
                      "humidity"
                    ) && acData.acinfo.online
                  );
                }}
              ></div>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={12} className={"control-room-control-container mt-2"}>
        <p
          className={
            (enableService(checkModel(acData.odu, acData.model), "mode") ||
              enableService(checkModel(acData.odu, acData.model), "mode_pe")) &&
            acData.acinfo.online
              ? "control-label mx-0 mb-2"
              : "control-label mx-0 mb-2 disabled"
          }
        >
          MODE
        </p>
        <Row className="mx-0">
          <Col xs={8} className="pe-0 ps-0">
            <div className="control-mode-container">
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.mode === "0"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() =>
                  pubCommand(uid, serialNumber, "mode", "cool", id)
                }
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "mode"
                      )
                    : true
                }
              >
                <img src={Cool} alt="cool" className="mb-2" />
                Cool
              </button>
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.mode === "4"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "mode"
                      )
                    : true
                }
                onClick={() => pubCommand(uid, serialNumber, "mode", "fan", id)}
              >
                <img src={Fan} alt="fan" className="mb-2" />
                Fan Only
              </button>
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.mode === "1"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() => pubCommand(uid, serialNumber, "mode", "dry", id)}
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "mode"
                      )
                    : true
                }
              >
                <img src={Dry} alt="dry" className="mb-2" />
                Dry
              </button>
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.mode === "3"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() =>
                  pubCommand(uid, serialNumber, "mode", "heat", id)
                }
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "mode"
                      )
                    : true
                }
              >
                <img src={Heat} alt="heat" className="mb-2" />
                Heat
              </button>
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.mode === "2"
                      ? "mode-container active me-2"
                      : "mode-container me-2"
                    : "mode-container me-2"
                }
                onClick={() =>
                  pubCommand(uid, serialNumber, "mode", "auto", id)
                }
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "mode"
                      )
                    : true
                }
              >
                <img src={Auto} alt="auto" className="mb-2" />
                &nbsp;
              </button>
              <div className="control-right-border" />
            </div>
          </Col>
          <Col xs={4} className="ps-4">
            <div className="control-mode-container">
              <button
                className={
                  acData.acinfo[0]
                    ? modePeConvert(acData.acinfo[0].istst.mode) === "16"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() =>
                  pubCommand(uid, serialNumber, "modePe", "16", id)
                }
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "mode_pe"
                      )
                    : true
                }
              >
                <img src={Manual} alt="manual" className="mb-2" />
                Manual
              </button>
              <button
                className={
                  acData.acinfo[0]
                    ? modePeConvert(acData.acinfo[0].istst.mode) === "32"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() =>
                  pubCommand(uid, serialNumber, "modePe", "32", id)
                }
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "mode_pe"
                      )
                    : true
                }
              >
                <img
                  src={AutoProtective}
                  alt="auto-protective"
                  className="mb-2"
                />
                Auto Protective
              </button>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={12} className="control-room-control-container mt-2">
        <p
          className={
            enableService(checkModel(acData.odu, acData.model), "fan_speed") &&
            acData.acinfo.online
              ? "control-label mx-0 mb-2"
              : "control-label mx-0 mb-2 disabled"
          }
        >
          FAN SPEED
        </p>
        <Row className="mx-0 mb-3">
          <Col className="px-0">
            <div className="control-mode-container">
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.fspeed === "1"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() =>
                  pubCommand(uid, serialNumber, "fanspeed", "l", id)
                }
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "fan_speed"
                      )
                    : true
                }
              >
                <img src={FanLow} alt="fan-low" />
              </button>
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.fspeed === "2"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() =>
                  pubCommand(uid, serialNumber, "fanspeed", "m", id)
                }
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "fan_speed"
                      )
                    : true
                }
              >
                <img src={FanMid} alt="fan-mid" />
              </button>
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.fspeed === "3"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() =>
                  pubCommand(uid, serialNumber, "fanspeed", "h", id)
                }
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "fan_speed"
                      )
                    : true
                }
              >
                <img src={FanHigh} alt="fan-high" />
              </button>
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.fspeed === "4"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() =>
                  pubCommand(uid, serialNumber, "fanspeed", "t", id)
                }
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "fan_speed"
                      )
                    : true
                }
              >
                <img src={FanHiHi} alt="fan-hi-hi" />
              </button>
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.fspeed === "5"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() =>
                  pubCommand(uid, serialNumber, "fanspeed", "s", id)
                }
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "fan_speed"
                      )
                    : true
                }
              >
                <img src={FanTurbo} alt="fan-turbo" />
              </button>
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.fspeed === "0"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() =>
                  pubCommand(uid, serialNumber, "fanspeed", "a", id)
                }
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "fan_speed"
                      )
                    : true
                }
              >
                <img src={FanAuto} alt="fan-auto" />
              </button>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={12} className="control-room-control-container mt-2">
        <p
          className={
            enableService(checkModel(acData.odu, acData.model), "swing") &&
            acData.acinfo.online
              ? "control-label mx-0 mb-2"
              : "control-label mx-0 mb-2 disabled"
          }
        >
          SWING
        </p>
        <Row className="mx-0 mb-3">
          <Col>
            <div className="control-mode-container">
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.louver === "1"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() => pubCommand(uid, serialNumber, "swing", "h", id)}
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "swing"
                      )
                    : true
                }
              >
                <img src={Swing_1} alt="swing-h" />
              </button>
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.louver === "2"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() => pubCommand(uid, serialNumber, "swing", "3", id)}
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "swing"
                      )
                    : true
                }
              >
                <img src={Swing_2} alt="swing-3" />
              </button>
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.louver === "3"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() => pubCommand(uid, serialNumber, "swing", "4", id)}
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "swing"
                      )
                    : true
                }
              >
                <img src={Swing_3} alt="swing-4" />
              </button>
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.louver === "4"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() => pubCommand(uid, serialNumber, "swing", "6", id)}
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "swing"
                      )
                    : true
                }
              >
                <img src={Swing_4} alt="swing-6" />
              </button>
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.louver === "5"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() => pubCommand(uid, serialNumber, "swing", "v", id)}
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "swing"
                      )
                    : true
                }
              >
                <img src={Swing_5} alt="swing-v" />
              </button>
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.louver === "0"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() => pubCommand(uid, serialNumber, "swing", "a", id)}
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "swing"
                      )
                    : true
                }
              >
                <img src={SwingAuto} alt="swing-auto" />
              </button>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={12} className="control-room-control-container mt-2">
        <p
          className={
            enableService(checkModel(acData.odu, acData.model), "function") &&
            acData.acinfo.online
              ? "control-label mx-0 mb-2"
              : "control-label mx-0 mb-2 disabled"
          }
        >
          FUNCTION
        </p>
        <Row className="mx-0 mb-2">
          <Col>
            <div className="control-mode-container">
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.uPtRem === "1"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() =>
                  pubCommand(
                    uid,
                    serialNumber,
                    "uPtRem",
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.uPtRem === "1"
                        ? "0"
                        : "1"
                      : "0",
                    id
                  )
                }
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "function"
                      )
                    : true
                }
              >
                <img src={AutoFunction} alt="auto-function" className="mb-2" />
                Auto Function
              </button>
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.function === "auto-protective"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() =>
                  pubCommand(
                    uid,
                    serialNumber,
                    "function",
                    "auto-protective",
                    id
                  )
                }
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "function"
                      )
                    : true
                }
              >
                <img
                  src={AutoProtection}
                  alt="auto-protective"
                  className="mb-2"
                />
                Auto Protection 24 hrs
              </button>
              <button
                className={
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.TrbAPS === "1"
                      ? "mode-container active"
                      : "mode-container"
                    : "mode-container"
                }
                onClick={() =>
                  pubCommand(
                    uid,
                    serialNumber,
                    "trbAPS",
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.TrbAPS === "1"
                        ? "0"
                        : "1"
                      : "0",
                    id
                  )
                }
                disabled={
                  acData.acinfo.online
                    ? !enableService(
                        checkModel(acData.odu, acData.model),
                        "function"
                      )
                    : true
                }
              >
                <img src={OzoneFilter} alt="ozone-filter" className="mb-2" />
                Ozone Filter Disinfection
              </button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const Dashboard = (props) => {
  const [floorImage, setFloorImage] = useState(null);
  const [buildingList, setBuildingList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [roomTemp, setRoomTemp] = useState(0);
  const [temp, setTemp] = useState(0);
  const [co2, setCO2] = useState(0);
  const [pm25, setPM25] = useState(0);
  const [humidity, setHumidity] = useState(0);
  const [roomPressure, setRoomPressure] = useState(0);
  const [uid, setUid] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [model, setModel] = useState("");
  const [id, setID] = useState("");
  const [open, setOpen] = useState(false);
  const [buildingFilter, setBuildingFilter] = useState("");
  const [floorFilter, setFloorFilter] = useState("");
  const [roomFilter, setRoomFilter] = useState("");
  const [acList, setAcList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [load, setLoad] = useState(true);

  const navigate = useNavigate();

  const {
    productList,
    deviceList,
    setDeviceList,
    pubCommand,
    building,
    floor,
    room,
    loading,
  } = useContext(MqttContext);

  const { company, latitude, longitude } = useContext(ProfileContext);

  useEffect(() => {
    loading.current = true;
    setLoad(true);

    (async () => {
      try {
        let token = localStorage.getItem("token");
        let companyId = localStorage.getItem("company_id");

        let res = await building_list(token);

        let buildingData = res.data.data.filter((item) => {
          return item.company_id === companyId;
        });

        let buildings = [];
        let floors = [];
        let rooms = [];

        // Filler Building
        buildingData.map((item) => {
          if (item.title !== undefined) {
            buildings.push({
              building: item.title,
              building_id: item._id,
            });
          }

          return item;
        });

        buildings.sort((a, b) => {
          let x = a.building_id.toLowerCase();
          let y = b.building_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        // Filler Floor
        buildingData.map((item) => {
          if (buildings[0].building !== undefined) {
            if (buildings[0].building === item.title) {
              if (item.floors !== undefined) {
                item.floors.map((data) => {
                  floors.push({
                    floor: data.title,
                    floor_image: data.plan_img,
                    floor_id: data.floorId,
                  });

                  return data;
                });
              }
            }
          }

          return item;
        });

        floors.sort((a, b) => {
          let x = a.floor_id.toLowerCase();
          let y = b.floor_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        // Filler Room
        buildingData.map((item) => {
          if (buildings[0].building !== undefined) {
            if (buildings[0].building === item.title) {
              if (item.floors) {
                item.floors.map((i) => {
                  if (floors[0].floor === i.title) {
                    if (i.rooms !== undefined) {
                      i.rooms.map((data) => {
                        rooms.push({
                          room: data.title,
                          room_id: data.roomId,
                        });

                        return data;
                      });
                    }
                  }

                  return i;
                });
              }
            }
          }

          return item;
        });

        rooms.sort((a, b) => {
          let x = a.room_id.toLowerCase();
          let y = b.room_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        setBuildingList(buildings);
        setFloorList(floors);
        setRoomList(rooms);
        setBuildingFilter(buildings.length ? buildings[0].building : "");
        setFloorFilter(floors.length ? floors[0].floor : "");
        setRoomFilter(rooms.length ? rooms[0].room : "");

        building.current = buildings.length ? buildings[0].building : "";
        floor.current = floors.length ? floors[0].floor : "";
        room.current = rooms.length ? rooms[0].room : "";

        setFloorImage(
          floors.length ? encodeURIComponent(floors[0].floor_image) : null
        );

        let productArr = await products(token, building.current, floor.current);

        let productFilter = productArr.data.productsList.filter((item) => {
          if (room.current === "All") {
            return (
              item.location.building === building.current &&
              item.location.floor === floor.current
            );
          } else {
            return (
              item.location.building === building.current &&
              item.location.floor === floor.current &&
              item.location.room === room.current
            );
          }
        });

        productList.current = productFilter;
        setDeviceList(productFilter);
        setAcList(productFilter);

        let rt = 0;
        let set_temp = 0;
        let carbon = 0;
        let pm = 0;
        let rh = 0;
        let room_pressure = 0;

        productFilter.map((item, index) => {
          if (index === 0) {
            setUid(item.cid);
            setSerialNumber(item.gateway_sn);
            setID(item._id);
            setModel(checkModel(item.odu, item.model));

            if (checkModel(item.odu, item.model) === "IAQ") {
              rt = item.acinfo[0]?.istst.RT2
                ? parseFloat(item.acinfo[0].istst.RT2)
                : 0;
            } else {
              rt = item.acinfo[0]?.istst.rt
                ? parseFloat(item.acinfo[0].istst.rt)
                : 0;
            }

            set_temp = item.acinfo[0]?.istst.st
              ? parseFloat(item.acinfo[0].istst.st)
              : 0;

            if (checkModel(item.odu, item.model) === "IAQ") {
              carbon = item.acinfo[0]?.istst.CO2_H
                ? parseInt(item.acinfo[0].istst.CO2_H) * 256
                : 0;
              carbon =
                carbon +
                (item.acinfo[0]?.istst.CO2_L
                  ? parseInt(item.acinfo[0].istst.CO2_L)
                  : 0);
            }
            if (checkModel(item.odu, item.model) === "IAQ") {
              pm = item.acinfo[0]?.istst.PM2_2
                ? parseInt(item.acinfo[0].istst.PM2_2) * 65536
                : 0;

              pm =
                pm +
                (item.acinfo[0]?.istst.PM2_1
                  ? parseInt(item.acinfo[0].istst.PM2_1) * 256
                  : 0);

              pm =
                (pm +
                  (item.acinfo[0]?.istst.PM2_0
                    ? parseInt(item.acinfo[0].istst.PM2_0)
                    : 0)) /
                1000;

              if (item.acinfo[0] !== undefined) {
                if (
                  parseInt(item.acinfo[0].istst.PM2_2) === 255 &&
                  parseInt(item.acinfo[0].istst.PM2_1) === 255 &&
                  parseInt(item.acinfo[0].istst.PM2_0) === 255
                ) {
                  pm = 36;
                }
              }
            }

            if (checkModel(item.odu, item.model) === "IAQ") {
              rh = item.acinfo[0]?.istst.RH2
                ? parseFloat(item.acinfo[0].istst.RH2)
                : 0;
            } else {
              rh = item.acinfo[0]?.istst.RH
                ? parseFloat(item.acinfo[0].istst.RH)
                : 0;
            }

            if (checkModel(item.odu, item.model) === "IAQ") {
              room_pressure = item.acinfo[0]?.istst.IND2_8
                ? (parseInt(item.acinfo[0].istst.IND2_8) - 3000) / 10
                : 0;
            }
          }

          return item;
        });

        setRoomTemp(rt.toFixed(1));
        setTemp(set_temp.toFixed(1));
        setCO2(carbon);
        setPM25(pm.toFixed(1));
        setHumidity(rh.toFixed(1));
        setRoomPressure(room_pressure.toFixed(1));
      } catch (error) {
        console.log(error);
      }
    })();

    setTimeout(() => {
      loading.current = false;
      setLoad(false);
    }, 15000);
  }, [building, floor, room, loading, productList, setDeviceList]);

  useEffect(() => {
    let productFilter = productList.current.filter((item) => {
      if (room.current === "All") {
        return (
          item.location.building === building.current &&
          item.location.floor === floor.current
        );
      } else {
        return (
          item.location.building === building.current &&
          item.location.floor === floor.current &&
          item.location.room === room.current
        );
      }
    });

    let rt = 0;
    let set_temp = 0;
    let carbon = 0;
    let pm = 0;
    let rh = 0;
    let room_pressure = 0;

    productFilter.map((item, index) => {
      if (uid === item.cid) {
        if (checkModel(item.odu, item.model) === "IAQ") {
          rt = item.acinfo[0]?.istst.RT2
            ? parseFloat(item.acinfo[0].istst.RT2)
            : 0;
        } else {
          rt = item.acinfo[0]?.istst?.rt
            ? parseFloat(item.acinfo[0].istst.rt)
            : 0;
        }

        set_temp = item.acinfo[0]?.istst.st
          ? parseFloat(item.acinfo[0].istst.st)
          : 0;

        if (checkModel(item.odu, item.model) === "IAQ") {
          carbon = item.acinfo[0]?.istst.CO2_H
            ? parseInt(item.acinfo[0].istst.CO2_H) * 256
            : 0;
          carbon =
            carbon +
            (item.acinfo[0]?.istst.CO2_L
              ? parseInt(item.acinfo[0].istst.CO2_L)
              : 0);
        }

        if (checkModel(item.odu, item.model) === "IAQ") {
          pm = item.acinfo[0]?.istst.PM2_2
            ? parseInt(item.acinfo[0].istst.PM2_2) * 65536
            : 0;

          pm =
            pm +
            (item.acinfo[0]?.istst.PM2_1
              ? parseInt(item.acinfo[0].istst.PM2_1) * 256
              : 0);

          pm =
            (pm +
              (item.acinfo[0]?.istst.PM2_0
                ? parseInt(item.acinfo[0].istst.PM2_0)
                : 0)) /
            1000;

          if (item.acinfo[0] !== undefined) {
            if (
              parseInt(item.acinfo[0].istst.PM2_2) === 255 &&
              parseInt(item.acinfo[0].istst.PM2_1) === 255 &&
              parseInt(item.acinfo[0].istst.PM2_0) === 255
            ) {
              pm = 36;
            }
          }
        }

        if (checkModel(item.odu, item.model) === "IAQ") {
          rh = item.acinfo[0]?.istst.RH2
            ? parseFloat(item.acinfo[0].istst.RH2)
            : 0;
        } else {
          rh = item.acinfo[0]?.istst.RH
            ? parseFloat(item.acinfo[0].istst.RH)
            : 0;
        }

        if (checkModel(item.odu, item.model) === "IAQ") {
          room_pressure = item.acinfo[0]?.istst?.IND2_8
            ? (parseInt(item.acinfo[0].istst.IND2_8) - 3000) / 10
            : 0;
        }
      } else if (index === 0) {
        rt = item.acinfo[0]?.istst.RT2
          ? parseFloat(item.acinfo[0].istst.RT2)
          : 0;

        if (checkModel(item.odu, item.model) === "IAQ") {
          rh = item.acinfo[0]?.istst.RT2
            ? parseFloat(item.acinfo[0].istst.RT2)
            : 0;
        } else {
          rh = item.acinfo[0]?.istst.rt
            ? parseFloat(item.acinfo[0].istst.rt)
            : 0;
        }

        set_temp = item.acinfo[0]?.istst.st
          ? parseFloat(item.acinfo[0].istst.st)
          : 0;

        if (checkModel(item.odu, item.model) === "IAQ") {
          carbon = item.acinfo[0]?.istst.CO2_H
            ? parseInt(item.acinfo[0].istst.CO2_H) * 256
            : 0;
          carbon =
            carbon +
            (item.acinfo[0]?.istst.CO2_L
              ? parseInt(item.acinfo[0].istst.CO2_L)
              : 0);
        }

        if (checkModel(item.odu, item.model) === "IAQ") {
          pm = item.acinfo[0]?.istst.PM2_2
            ? parseInt(item.acinfo[0].istst.PM2_2) * 65536
            : 0;

          pm =
            pm +
            (item.acinfo[0]?.istst.PM2_1
              ? parseInt(item.acinfo[0].istst.PM2_1) * 256
              : 0);

          pm =
            (pm +
              (item.acinfo[0]?.istst.PM2_0
                ? parseInt(item.acinfo[0].istst.PM2_0)
                : 0)) /
            1000;

          if (item.acinfo[0] !== undefined) {
            if (
              parseInt(item.acinfo[0].istst.PM2_2) === 255 &&
              parseInt(item.acinfo[0].istst.PM2_1) === 255 &&
              parseInt(item.acinfo[0].istst.PM2_0) === 255
            ) {
              pm = 36;
            }
          }
        }

        if (checkModel(item.odu, item.model) === "IAQ") {
          rh = item.acinfo[0]?.istst.RH2
            ? parseFloat(item.acinfo[0].istst.RH2)
            : 0;
        } else {
          rh = item.acinfo[0]?.istst.RH
            ? parseFloat(item.acinfo[0].istst.RH)
            : 0;
        }

        if (checkModel(item.odu, item.model) === "IAQ") {
          room_pressure = item.acinfo[0]?.istst.IND2_8
            ? (parseInt(item.acinfo[0].istst.IND2_8) - 3000) / 10
            : 0;
        }
      }

      return item;
    });

    productList.current = productFilter;

    setRoomTemp(rt.toFixed(1));
    setTemp(set_temp.toFixed(1));
    setCO2(carbon);
    setPM25(pm.toFixed(1));
    setHumidity(rh.toFixed(1));
    setRoomPressure(room_pressure.toFixed(1));
  }, [uid, building, floor, room, productList]);

  const changeBuilding = async (val) => {
    setBuildingFilter(val);

    let floors = [];
    let rooms = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (val === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              floors.push({
                floor: i.title,
                floor_id: i.floorId,
              });

              return i;
            });
          }

          floors.sort((a, b) => {
            let x = a.floor_id.toLowerCase();
            let y = b.floor_id.toLowerCase();

            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          });

          if (item.floors) {
            item.floors.map((i) => {
              if (i.title === floors[0].floor) {
                if (i.rooms) {
                  i.rooms.map((j) => {
                    rooms.push({
                      room: j.title,
                      room_id: j.roomId,
                    });
                    return j;
                  });
                }
              }

              return i;
            });
          }
        }

        return item;
      });

      rooms.sort((a, b) => {
        let x = a.room_id.toLowerCase();
        let y = b.room_id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setFloorList(floors);
      setRoomList(rooms);

      if (floors.length) {
        setFloorFilter(floors[0].floor);
      } else {
        setFloorFilter("");
      }

      if (rooms.length) {
        setRoomFilter(rooms[0].room);
      } else {
        setRoomFilter("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeFloor = async (val) => {
    setFloorFilter(val);

    let rooms = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (buildingFilter === item.title) {
          item.floors.map((i) => {
            if (i.title === val) {
              if (i.rooms !== undefined) {
                i.rooms.map((j) => {
                  rooms.push({
                    room: j.title,
                    room_id: j.roomId,
                  });

                  return i;
                });
              }
            }

            return i;
          });
        }

        return item;
      });

      rooms.sort((a, b) => {
        let x = a.room_id.toLowerCase();
        let y = b.room_id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setRoomList(rooms);

      if (rooms.length) {
        setRoomFilter(rooms[0].room);
      } else {
        setRoomFilter("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeRoom = (val) => {
    setRoomFilter(val);
  };

  const filterACRoom = async () => {
    if (buildingFilter && floorFilter && roomFilter) {
      setOpen(false);
      loading.current = true;
      setLoad(true);

      building.current = buildingFilter;
      floor.current = floorFilter;
      room.current = roomFilter;

      try {
        let token = localStorage.getItem("token");
        let companyId = localStorage.getItem("company_id");

        let res = await building_list(token);

        let buildingData = res.data.data.filter((item) => {
          return item.company_id === companyId;
        });

        buildingData.map((i) => {
          if (i.title === buildingFilter) {
            i.floors.map((j) => {
              if (j.title === floorFilter) {
                setFloorImage(encodeURIComponent(j.plan_img));
              }

              return j;
            });
          }

          return i;
        });

        let productArr = await products(token, buildingFilter, floorFilter);

        let productFilter = productArr.data.productsList.filter((item) => {
          if (roomFilter === "All") {
            return (
              item.location.building === buildingFilter &&
              item.location.floor === floorFilter
            );
          } else {
            return (
              item.location.building === buildingFilter &&
              item.location.floor === floorFilter &&
              item.location.room === roomFilter
            );
          }
        });

        productList.current = productFilter;

        productFilter.map((item, index) => {
          if (index === 0) {
            setUid(item.cid);
            setSerialNumber(item.gateway_sn);
            setID(item._id);
            setModel(checkModel(item.odu, item.model));
          }

          return item;
        });

        setDeviceList([]);
        setAcList(productFilter);
      } catch (error) {
        console.log(error);
      }
    }

    setTimeout(() => {
      loading.current = false;
      setLoad(false);
    }, 15000);
  };

  const roomTempColor = (val) => {
    return val < 30 ? "#3FBFEF" : "#b31117";
  };

  const carbonColor = (val) => {
    switch (true) {
      case val < 400:
        return "#BFBEBE";
      case val < 800:
        return "#AEADAD";
      case val < 1200:
        return "#9E9D9D";
      case val < 1600:
        return "#8E8C8C";
      default:
        return "#7E7C7C";
    }
  };

  const pmColor = (val) => {
    switch (true) {
      case parseFloat(val) < 12.1:
        return "#94DB7B";
      case parseFloat(val) < 35.5:
        return "#FCE750";
      case parseFloat(val) < 55.5:
        return "#FE9657";
      case parseFloat(val) < 150.5:
        return "#D4424F";
      case parseFloat(val) < 250.5:
        return "#AF2787";
      default:
        return "#94003B";
    }
  };

  const humidityColor = (val) => {
    switch (true) {
      case val < 25:
        return "#CFEFFB";
      case val < 50:
        return "#9FDFF7";
      case val < 75:
        return "#6FCFF3";
      default:
        return "#3FBFEF";
    }
  };

  const roomPressureColor = (val) => {
    switch (true) {
      case val < -50:
        return "#CD78B4";
      case val < 50:
        return "#C35DA5";
      case val < 250:
        return "#B94296";
      default:
        return "#AF2787";
    }
  };

  const productImage = (val) => {
    switch (true) {
      case val === "FRESHAIR" || "MONOBLOCK" || "AHU":
        return DoubleSkin;
      case val === "PE":
        return PE;
      case val === "FFU" || "EX":
        return FFU;
      case val === "IAQ":
        return IAQ;
      case val === "CASSETTE":
        return Cassette;
      case val === "DUCT":
        return Duct;
      default:
        return UTF;
    }
  };

  const enableService = (type, feature) => {
    switch (true) {
      case type === "FFU":
        switch (true) {
          case feature === "power":
            return true;
          case feature === "temp":
            return false;
          case feature === "humidity":
            return false;
          case feature === "mode":
            return false;
          case feature === "mode_pe":
            return true;
          case feature === "fan_speed":
            return false;
          case feature === "swing":
            return false;
          case feature === "function":
            return true;
          default:
            return false;
        }
      case type === "PE":
        switch (true) {
          case feature === "power":
            return true;
          case feature === "temp":
            return false;
          case feature === "humidity":
            return false;
          case feature === "mode":
            return false;
          case feature === "mode_pe":
            return true;
          case feature === "fan_speed":
            return false;
          case feature === "swing":
            return false;
          case feature === "function":
            return true;
          default:
            return false;
        }
      case type === "IAQ":
        return false;
      case type === "FRESHAIR" || "MONOBLOCK" || "AHU":
        switch (true) {
          case feature === "power":
            return true;
          case feature === "temp":
            return true;
          case feature === "humidity":
            return true;
          case feature === "mode":
            return false;
          case feature === "mode_pe":
            return false;
          case feature === "fan_speed":
            return true;
          case feature === "swing":
            return false;
          case feature === "function":
            return false;
          default:
            return false;
        }
      default:
        switch (true) {
          case feature === "power":
            return true;
          case feature === "temp":
            return true;
          case feature === "humidity":
            return false;
          case feature === "mode":
            return true;
          case feature === "mode_pe":
            return false;
          case feature === "fan_speed":
            return true;
          case feature === "swing":
            return true;
          case feature === "function":
            return false;
          default:
            return false;
        }
    }
  };

  const enableStatus = (type, feature) => {
    switch (true) {
      case type === "FFU":
        switch (true) {
          case feature === "room_temp":
            return true;
          case feature === "set_temp":
            return true;
          case feature === "co2":
            return false;
          case feature === "pm":
            return false;
          case feature === "humidity":
            return true;
          case feature === "room_pressure":
            return false;
          default:
            return false;
        }
      case type === "PE":
        switch (true) {
          case feature === "room_temp":
            return true;
          case feature === "set_temp":
            return true;
          case feature === "co2":
            return false;
          case feature === "pm":
            return false;
          case feature === "humidity":
            return true;
          case feature === "room_pressure":
            return false;
          default:
            return false;
        }
      case type === "IAQ":
        switch (true) {
          case feature === "room_temp":
            return true;
          case feature === "set_temp":
            return false;
          case feature === "co2":
            return true;
          case feature === "pm":
            return true;
          case feature === "humidity":
            return true;
          case feature === "room_pressure":
            return true;
          default:
            return false;
        }
      case type === "FRESHAIR" || "MONOBLOCK" || "AHU":
        switch (true) {
          case feature === "room_temp":
            return true;
          case feature === "set_temp":
            return true;
          case feature === "co2":
            return false;
          case feature === "pm":
            return false;
          case feature === "humidity":
            return true;
          case feature === "room_pressure":
            return false;
          default:
            return false;
        }
      default:
        switch (true) {
          case feature === "room_temp":
            return true;
          case feature === "set_temp":
            return true;
          case feature === "co2":
            return false;
          case feature === "pm":
            return false;
          case feature === "humidity":
            return true;
          case feature === "room_pressure":
            return false;
          default:
            return false;
        }
    }
  };

  const modePeConvert = (mode) => {
    let mode_bit = parseInt(mode).toString(2).padStart(8, "0");
    let modePe = mode_bit.split("");

    switch (true) {
      case modePe[2] === "0" && modePe[3] === "1":
        return "16";
      case modePe[0] === "0" && modePe[1] === "1":
        return "32";
      default:
        return "0";
    }
  };

  const checkModel = (oud, model) => {
    // let val = oud.substr(4, 5);

    // switch (true) {
    //   case val * 1 >= 10 && val * 1 < 15:
    //     if (model.toUpperCase() === "FFU" || model.toUpperCase() === "EX") {
    //       return model.toUpperCase();
    //     }
    //     return "FFU";
    //   case val * 1 >= 15 && val * 1 < 19:
    //     return "IAQ";
    //   default:
    //     return model.toUpperCase();
    // }

    return model.toUpperCase();
  };

  const powerConvert = (power) => {
    let power_bit = parseInt(power).toString(2).padStart(8, "0");
    let powerSplit = power_bit.split("");

    return powerSplit[7].toString();
  };

  const twoDecimal = (val) => {
    return Math.floor(val * 10) / 10;
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="d-flex d-xl-none dashboard-xs-header-container px-0 mb-3">
        <div className="dashboard-xs-header-container">
          <h5 className="header-title px-3 px-sm-4 mt-3">{company}</h5>
          <div className="position-relative px-3 px-sm-4 ">
            <h5 className="dashboard-header py-1">
              {building.current + " " + floor.current + ", " + room.current}
            </h5>
            <div
              className="control-select-dropdown"
              onClick={() => setOpen(!open)}
            ></div>
            <div
              className="control-select-container p-2"
              style={{
                visibility: open ? "visible" : "hidden",
                opacity: open ? 1 : 0,
              }}
            >
              <div className="control-select">
                <img src={BuildingIcon} alt="building" className="pe-2" />
                <p className="mb-0 pe-2">Building</p>
                <Form.Select
                  size="sm"
                  onChange={(e) => changeBuilding(e.target.value)}
                  value={buildingFilter}
                >
                  {buildingList.map((item, index) => (
                    <option value={item.building} key={index}>
                      {item.building}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className="control-select">
                <img src={FloorIcon} alt="floor" className="pe-2" />
                <p className="mb-0 pe-2">Floor</p>
                <Form.Select
                  size="sm"
                  onChange={(e) => changeFloor(e.target.value)}
                  value={floorFilter}
                >
                  {floorList.map((item, index) => (
                    <option value={item.floor} key={index}>
                      {item.floor}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className="control-select">
                <img src={RoomIcon} alt="floor" className="pe-2" />
                <p className="mb-0 pe-2">Room</p>
                <Form.Select
                  size="sm"
                  onChange={(e) => changeRoom(e.target.value)}
                  value={roomFilter}
                >
                  {roomList.map((item, index) => (
                    <option value={item.room} key={index}>
                      {item.room}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <button
                className="control-search-btn my-2 me-2"
                onClick={() => filterACRoom()}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="body-container" id="dashboard">
        <Row className="mx-0 px-xl-4 px-sm-2">
          <Col xs={12} className="d-none d-xl-flex">
            <div
              className="position-relative pe-3"
              style={{ width: "fit-content" }}
            >
              <h3 className="dashboard-header mb-0">
                {building.current + " " + floor.current + ", " + room.current}
              </h3>
              <div
                className="control-select-dropdown"
                onClick={() => setOpen(!open)}
              ></div>
              <div
                className="control-select-container p-2"
                style={{
                  visibility: open ? "visible" : "hidden",
                  opacity: open ? 1 : 0,
                }}
              >
                <div className="control-select">
                  <img src={BuildingIcon} alt="building" className="pe-2" />
                  <p className="mb-0 pe-2">Building</p>
                  <Form.Select
                    size="sm"
                    onChange={(e) => changeBuilding(e.target.value)}
                    value={buildingFilter}
                  >
                    {buildingList.map((item, index) => (
                      <option value={item.building} key={index}>
                        {item.building}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="control-select">
                  <img src={FloorIcon} alt="floor" className="pe-2" />
                  <p className="mb-0 pe-2">Floor</p>
                  <Form.Select
                    size="sm"
                    onChange={(e) => changeFloor(e.target.value)}
                    value={floorFilter}
                  >
                    {floorList.map((item, index) => (
                      <option value={item.floor} key={index}>
                        {item.floor}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="control-select">
                  <img src={RoomIcon} alt="floor" className="pe-2" />
                  <p className="mb-0 pe-2">Room</p>
                  <Form.Select
                    size="sm"
                    onChange={(e) => changeRoom(e.target.value)}
                    value={roomFilter}
                  >
                    {roomList.map((item, index) => (
                      <option value={item.room} key={index}>
                        {item.room}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <button
                  className="control-search-btn my-2 me-2"
                  onClick={() => filterACRoom()}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} className="mb-2 mb-xl-4 pe-sm-2">
            <div className="map-container">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyDgBvW3DijKFJ63irJPP9CebaUg_yP5lXY",
                }}
                // defaultCenter={{ lat: latitude, lng: longitude }}
                center={{ lat: latitude, lng: longitude }}
                defaultZoom={12}
                disableDoubleClickZoom={true}
                zoomControl={false}
                draggable={false}
              >
                <MarkerComponent
                  lat={latitude}
                  lng={longitude}
                  text={company}
                />
              </GoogleMapReact>
            </div>
          </Col>
          <Col
            xs={12}
            sm={6}
            className="dashboard-right-container mb-2 mb-xl-4 ps-sm-2"
          >
            <div className="dashboard-card">
              <div className="dashboard-card-header">
                <div className="dashboard-card-icon-container">
                  <img src={FloorIconWhite} alt="floor" className="mx-2" />
                  Floor Plan
                </div>
              </div>
              <div
                className="dashboard-card-body"
                style={{
                  backgroundImage: floorImage
                    ? "url(http://node-env.eba-7xwegkmr.ap-southeast-1.elasticbeanstalk.com/api/v2.0/images/upload/" +
                      floorImage +
                      ")"
                    : null,
                }}
              ></div>
            </div>
          </Col>
          <Col
            xs={12}
            className="pe-0 pb-4"
            //style={{ display: iaq ? "flex" : "none" }}
            style={{ display: "none" }}
          >
            <div className="dashboard-card">
              <div className="dashboard-card-header">
                <div className="dashboard-card-icon-container">
                  <img src={RoomIconWhite} alt="room" className="mx-2" />
                  Indoor Air Quality
                </div>
              </div>
              <div className="dashboard-card-body">
                <div className="graph-container">
                  <Line options={options} data={graphData} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row
          style={{
            paddingTop: 0,
            display: "flex",
          }}
          className="mx-0 px-xl-4 px-sm-2"
        >
          <Col xs={4} sm={2} className="mb-xl-4 mb-2">
            <div
              className={
                enableStatus(model, "room_temp")
                  ? "dashboard-progress-card"
                  : "dashboard-progress-card disabled"
              }
            >
              <p className="mb-0">Room Temp.</p>
              <div className="dashboard-progress-container">
                <CircularProgressbarWithChildren
                  value={roomTemp}
                  maxValue={50}
                  strokeWidth={5}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    rotation: 0.5,
                    textColor: "#000000",
                    textSize: 20,
                    trailColor: "transparent",
                    pathColor: roomTempColor(roomTemp),
                  })}
                >
                  <div className="dashboard-value-container">
                    <span className="dashboard-value">
                      {roomTemp !== "NaN"
                        ? roomTemp >= 63.75
                          ? "Error"
                          : twoDecimal(roomTemp)
                        : 0}
                    </span>
                    <span className="dashboard-unit">°C</span>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </Col>
          <Col xs={4} sm={2} className="mb-xl-4 mb-2">
            <div
              className={
                enableStatus(model, "set_temp")
                  ? "dashboard-progress-card"
                  : "dashboard-progress-card disabled"
              }
            >
              <p className="mb-0">Set Temp.</p>
              <div className="dashboard-progress-container">
                <CircularProgressbarWithChildren
                  value={temp}
                  maxValue={50}
                  strokeWidth={5}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    rotation: 0.5,
                    textColor: "#000000",
                    textSize: 20,
                    trailColor: "transparent",
                    pathColor: roomTempColor(temp),
                  })}
                >
                  <div className="dashboard-value-container">
                    <span className="dashboard-value">
                      {temp !== "NaN"
                        ? temp >= 63.75
                          ? "Error"
                          : twoDecimal(temp)
                        : 0}
                    </span>
                    <span className="dashboard-unit">°C</span>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </Col>
          <Col xs={4} sm={2} className="mb-xl-4 mb-2">
            <div
              className={
                enableStatus(model, "co2")
                  ? "dashboard-progress-card"
                  : "dashboard-progress-card disabled"
              }
            >
              <p className="mb-0">Carbon Dioxide</p>
              <div className="dashboard-progress-container">
                <CircularProgressbarWithChildren
                  value={co2}
                  maxValue={2000}
                  strokeWidth={5}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    rotation: 0.5,
                    textColor: "#000000",
                    textSize: 20,
                    trailColor: "transparent",
                    pathColor: carbonColor(co2),
                  })}
                >
                  <div className="dashboard-value-container">
                    <span className="dashboard-value">
                      {co2 !== "NaN" ? twoDecimal(co2) : 0}
                    </span>
                    <span className="dashboard-unit">ppm</span>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </Col>
          <Col xs={4} sm={2} className="mb-xl-4 mb-2">
            <div
              className={
                enableStatus(model, "pm")
                  ? "dashboard-progress-card"
                  : "dashboard-progress-card disabled"
              }
            >
              <p className="mb-0">PM 2.5</p>
              <div className="dashboard-progress-container">
                <CircularProgressbarWithChildren
                  value={pm25}
                  maxValue={500}
                  strokeWidth={5}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    rotation: 0.5,
                    textColor: "#000000",
                    textSize: 20,
                    trailColor: "transparent",
                    pathColor: pmColor(pm25),
                  })}
                >
                  <div className="dashboard-value-container">
                    <span className="dashboard-value">
                      {pm25 !== "NaN" ? twoDecimal(pm25) : 0}
                    </span>
                    <span className="dashboard-unit">
                      ug/m<sup>3</sup>
                    </span>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </Col>
          <Col xs={4} sm={2} className="mb-xl-4 mb-2">
            <div
              className={
                enableStatus(model, "humidity")
                  ? "dashboard-progress-card"
                  : "dashboard-progress-card disabled"
              }
            >
              <p className="mb-0">Humidity</p>
              <div className="dashboard-progress-container">
                <CircularProgressbarWithChildren
                  value={humidity}
                  maxValue={100}
                  strokeWidth={5}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    rotation: 0.5,
                    textColor: "#000000",
                    textSize: 20,
                    trailColor: "transparent",
                    pathColor: humidityColor(humidity),
                  })}
                >
                  <div className="dashboard-value-container">
                    <span className="dashboard-value">
                      {humidity !== "NaN"
                        ? humidity > 100
                          ? "Error"
                          : twoDecimal(humidity)
                        : 0}
                    </span>
                    <span className="dashboard-unit">%rh</span>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </Col>
          <Col xs={4} sm={2} className="mb-xl-4 mb-2">
            <div
              className={
                enableStatus(model, "room_pressure")
                  ? "dashboard-progress-card"
                  : "dashboard-progress-card disabled"
              }
            >
              <p className="mb-0">Room Pressure</p>
              <div className="dashboard-progress-container">
                <CircularProgressbarWithChildren
                  value={roomPressure}
                  maxValue={300}
                  minValue={-300}
                  strokeWidth={5}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    rotation: 0.5,
                    textColor: "#000000",
                    textSize: 20,
                    trailColor: "transparent",
                    pathColor: roomPressureColor(roomPressure),
                  })}
                >
                  <div className="dashboard-value-container">
                    <span className="dashboard-value">
                      {roomPressure !== "NaN" ? twoDecimal(roomPressure) : 0}
                    </span>
                    <span className="dashboard-unit">Pa</span>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </Col>
        </Row>
        {deviceList.length
          ? deviceList.map((item, index) => (
              <Row
                className="mx-0 px-xl-4 px-sm-2"
                style={{
                  display: item.enable ? "d-block" : "none",
                }}
                key={index}
              >
                <Col
                  className="pointer"
                  onClick={() => {
                    setUid(item.cid);
                    setSerialNumber(item.gateway_sn);
                    setID(item._id);
                    setModel(checkModel(item.odu, item.model));
                  }}
                >
                  <Row className="dashboard-product-container mx-0 mb-2">
                    <Col
                      sm={2}
                      className="dashboard-image-container h-100 d-none d-sm-flex"
                    >
                      <img
                        src={productImage(checkModel(item.odu, item.model))}
                        alt={
                          checkModel(item.odu, item.model)
                            ? checkModel(item.odu, item.model)
                            : "AC"
                        }
                      />
                    </Col>
                    <Col
                      xs={4}
                      sm={3}
                      xl={4}
                      className="dashboard-product-name-container px-0"
                    >
                      <div className="dashboard-name-label px-3 px-sm-4 py-1 mt-2 mt-sm-3 mt-xl-4">
                        {item.title}
                      </div>
                      <p className="dashboard-model px-3 px-sm-4 mt-1 mb-0 mb-xl-1">
                        {checkModel(item.odu, item.model)
                          ? checkModel(item.odu, item.model)
                          : "SRV"}
                      </p>
                      <p className="dashboard-serial px-3 px-sm-4 mb-0 mb-xl-1">
                        {item.gateway_sn ? item.gateway_sn : ""}
                      </p>
                      <p className="dashboard-serial px-3 px-sm-4 mb-0">
                        Location:{" "}
                        {item.location.building ? item.location.building : ""}{" "}
                        {item.location.floor ? item.location.floor : ""}
                        {", "}
                        {item.location.room ? item.location.room : ""}
                      </p>
                    </Col>
                    <Col
                      xs={4}
                      sm={2}
                      md={2}
                      className="dashboard-other-container"
                    >
                      <div className="dashboard-other-label-container">
                        <div
                          className={
                            item.acinfo.online
                              ? "dashboard-product-status"
                              : "dashboard-product-status service-status"
                          }
                        />
                        <span className="dashboard-other-label">Status</span>
                      </div>
                      <div
                        className={
                          item.acinfo.online
                            ? "dashboard-status-container"
                            : "dashboard-status-container service-status"
                        }
                      >
                        {item.acinfo.online ? "Online" : "Offline"}
                      </div>
                    </Col>
                    <Col
                      sm={3}
                      xl={2}
                      className="dashboard-other-container d-none d-sm-flex"
                    >
                      <div className="dashboard-other-label-container">
                        <img
                          src={PowerIcon}
                          className="dashboard-other-icon"
                          alt="power"
                        />
                        <span className="dashboard-other-label">Power</span>
                      </div>
                      <div className="dashboard-switch-container">
                        <span className="me-2">OFF</span>
                        {item.acinfo.online ? (
                          item.acinfo.map((data, index) => (
                            <div key={index}>
                              <Switch
                                onChange={() => {
                                  pubCommand(
                                    item.cid ? item.cid : "",
                                    item.gateway_sn,
                                    "power",
                                    data.istst?.onoff
                                      ? data.istst.onoff === "1"
                                        ? "off"
                                        : "on"
                                      : "off",
                                    item._id
                                  );
                                }}
                                checked={
                                  data.istst?.onoff
                                    ? powerConvert(data.istst.onoff) === "1"
                                      ? true
                                      : false
                                    : false
                                }
                                onColor="#70a5f9"
                                onHandleColor="#1d2860"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 8px rgba(0, 0, 0, 0.2)"
                              />
                            </div>
                          ))
                        ) : (
                          <Switch
                            onChange={() => false}
                            checked={false}
                            onColor="#70a5f9"
                            onHandleColor="#1d2860"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 8px rgba(0, 0, 0, 0.2)"
                          />
                        )}
                        <span className="ms-2">ON</span>
                      </div>
                    </Col>
                    <Col xs={4} sm={2} className="dashboard-other-container">
                      <div className="dashboard-other-btn-container">
                        {item.acinfo.online ? (
                          item.acinfo.map((data, index) => (
                            <React.Fragment key={index}>
                              <button
                                type="button"
                                className="dashboard-control-btn d-none d-lg-block"
                                onClick={() =>
                                  navigate("/control", {
                                    state: {
                                      building: item.location.building,
                                      floor: item.location.floor,
                                      room: item.location.room,
                                      title: item.title,
                                      serialNumber: item.gateway_sn
                                        ? item.gateway_sn
                                        : "",
                                      uid: item.cid,
                                      power:
                                        data.istst.onoff === "1" ? true : false,
                                      roomTemp: data.istst.rt
                                        ? data.istst.rt
                                        : 0,
                                      roomHumidity: data.istst.RH
                                        ? data.istst.RH
                                        : 0,
                                      setTemp: data.istst ? data.st : 0,
                                      setHumidity: data.istst.SetRH
                                        ? data.istst.SetRH
                                        : 0,
                                      mode: item.mode,
                                      modePe: data.istst.modePe
                                        ? modePeConvert(data.istst.modePe)
                                        : 0,
                                      fanSpeed: data.istst.fspeed
                                        ? data.istst.fspeed
                                        : 0,
                                      swing: data.istst.louver
                                        ? data.istst.louver
                                        : 0,
                                      uPtRem: data.istst.uPtRem
                                        ? data.istst.uPtRem
                                        : 0,
                                      function: data.istst.function
                                        ? data.istst.function
                                        : 0,
                                      trbAPS: data.istst.TrbAPS
                                        ? data.istst.TrbAPS
                                        : 0,
                                      id: item._id ? item._id : "",
                                    },
                                  })
                                }
                              >
                                Control
                              </button>
                              <button
                                type="button"
                                className="dashboard-control-btn d-block d-lg-none"
                                onClick={() => {
                                  setModalShow(true);
                                  setUid(item.cid);
                                  setSerialNumber(item.gateway_sn);
                                  setID(item._id);
                                  setModel(checkModel(item.odu, item.model));
                                }}
                              >
                                Control
                              </button>
                            </React.Fragment>
                          ))
                        ) : (
                          <button
                            type="button"
                            className="dashboard-control-btn"
                            disabled={true}
                          >
                            Control
                          </button>
                        )}
                        <button
                          type="button"
                          className="dashboard-notify-btn"
                          disabled={item.enable ? true : false}
                          onClick={() => navigate("/notify")}
                        >
                          Notify
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))
          : acList.map((item, index) => (
              <Row
                className="mx-0 px-xl-4 px-sm-2"
                style={{
                  display: item.enable ? "d-block" : "none",
                }}
                key={index * -1 - 1}
              >
                <Col
                  className="pointer"
                  onClick={() => {
                    setUid(item.cid);
                    setSerialNumber(item.gateway_sn);
                    setID(item._id);
                    setModel(checkModel(item.odu, item.model));
                  }}
                >
                  <Row className="dashboard-product-container mx-0 mb-2">
                    <Col
                      sm={2}
                      className="dashboard-image-container d-none d-sm-flex"
                    >
                      <img
                        src={productImage(checkModel(item.odu, item.model))}
                        alt={
                          checkModel(item.odu, item.model)
                            ? checkModel(item.odu, item.model)
                            : "AC"
                        }
                      />
                    </Col>
                    <Col
                      xs={4}
                      sm={3}
                      xl={4}
                      className="dashboard-product-name-container px-0"
                    >
                      <div className="dashboard-name-label px-3 px-sm-4 py-1 mt-xl-4 mt-2">
                        {item.title}
                      </div>
                      <p className="dashboard-model px-3 px-sm-4 mt-1 mb-0">
                        {checkModel(item.odu, item.model)
                          ? checkModel(item.odu, item.model)
                          : "-"}
                      </p>
                      <p className="dashboard-serial px-3 px-sm-4 mb-0">
                        {item.gateway_sn ? item.gateway_sn : ""}
                      </p>
                      <p className="dashboard-serial px-3 px-sm-4 mb-0">
                        Location:{" "}
                        {item.location.building ? item.location.building : ""}{" "}
                        {item.location.floor ? item.location.floor : ""}
                        {", "}
                        {item.location.room ? item.location.room : ""}
                      </p>
                    </Col>
                    <Col
                      xs={4}
                      sm={2}
                      md={2}
                      className="dashboard-other-container"
                    >
                      <div className="dashboard-other-label-container">
                        <div
                          className={
                            item.acinfo.online
                              ? "dashboard-product-status"
                              : "dashboard-product-status service-status"
                          }
                        />
                        <span className="dashboard-other-label">Status</span>
                      </div>
                      <div
                        className={
                          item.acinfo.online
                            ? "dashboard-status-container"
                            : "dashboard-status-container service-status"
                        }
                      >
                        {item.acinfo.online ? "Online" : "Offline"}
                      </div>
                    </Col>
                    <Col
                      sm={3}
                      xl={2}
                      className="dashboard-other-container d-none d-sm-flex"
                    >
                      <div className="dashboard-other-label-container">
                        <img
                          src={PowerIcon}
                          className="dashboard-other-icon"
                          alt="power"
                        />
                        <span className="dashboard-other-label">Power</span>
                      </div>
                      <div className="dashboard-switch-container">
                        <span className="me-2">OFF</span>
                        {item.acinfo.online ? (
                          item.acinfo.map((data, index) => (
                            <div key={index}>
                              <Switch
                                onChange={() =>
                                  pubCommand(
                                    item.cid ? item.cid : "",
                                    item.gateway_sn,
                                    "power",
                                    data.istst?.onoff
                                      ? data.istst.onoff === "1"
                                        ? "off"
                                        : "on"
                                      : "off",
                                    item._id
                                  )
                                }
                                checked={
                                  data.istst?.onoff
                                    ? data.istst.onoff === "1"
                                      ? true
                                      : false
                                    : false
                                }
                                onColor="#70a5f9"
                                onHandleColor="#1d2860"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 8px rgba(0, 0, 0, 0.2)"
                              />
                            </div>
                          ))
                        ) : (
                          <Switch
                            onChange={() => false}
                            checked={false}
                            onColor="#70a5f9"
                            onHandleColor="#1d2860"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 8px rgba(0, 0, 0, 0.2)"
                          />
                        )}
                        <span className="ms-2">ON</span>
                      </div>
                    </Col>
                    <Col xs={4} sm={2} className="dashboard-other-container">
                      <div className="dashboard-other-btn-container">
                        {item.acinfo.online ? (
                          item.acinfo.map((data, index) => (
                            <>
                              <button
                                type="button"
                                className="dashboard-control-btn d-none d-lg-block"
                                onClick={() =>
                                  navigate("/control", {
                                    state: {
                                      title: item.title,
                                      serialNumber: item.gateway_sn
                                        ? item.gateway_sn
                                        : "",
                                      uid: item.cid,
                                      power:
                                        data.istst.onoff === "1" ? true : false,
                                      roomTemp: data.istst.rt
                                        ? data.istst.rt
                                        : 0,
                                      roomHumidity: data.istst.RH
                                        ? data.istst.RH
                                        : 0,
                                      setTemp: data.istst ? data.st : 0,
                                      setHumidity: data.istst.SetRH
                                        ? data.istst.SetRH
                                        : 0,
                                      mode: item.mode,
                                      modePe: data.istst.modePe
                                        ? modePeConvert(data.istst.modePe)
                                        : 0,
                                      fanSpeed: data.istst.fspeed
                                        ? data.istst.fspeed
                                        : 0,
                                      swing: data.istst.louver
                                        ? data.istst.louver
                                        : 0,
                                      function: data.istst.function
                                        ? data.istst.function
                                        : 0,
                                    },
                                  })
                                }
                              >
                                Control
                              </button>
                              <button
                                type="button"
                                className="dashboard-control-btn d-block d-lg-none"
                                onClick={() => {
                                  setModalShow(true);
                                  console.log(data);
                                }}
                              >
                                Control
                              </button>
                            </>
                          ))
                        ) : (
                          <button
                            type="button"
                            className="dashboard-control-btn"
                            disabled={true}
                          >
                            Control
                          </button>
                        )}
                        <button
                          type="button"
                          className="dashboard-notify-btn"
                          disabled={item.enable ? true : false}
                          onClick={() => navigate("/notify")}
                        >
                          Notify
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
      </div>
      <div className="loading_bg"></div>
      <Modal show={modalShow} onHide={() => setModalShow(false)} centered>
        <Modal.Header closeButton className="py-2">
          <Modal.Title id="contained-modal-title-vcenter">Control</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <ControlPanel
              deviceList={deviceList}
              building={building.current}
              floor={floor.current}
              room={room.current}
              uid={uid}
              serialNumber={serialNumber}
              id={id}
              enableService={enableService}
              checkModel={checkModel}
              modePeConvert={modePeConvert}
              setHumidity={setHumidity}
            />
          </Container>
        </Modal.Body>
      </Modal>
      <Modal
        show={loading.current ? load : false}
        backdrop="static"
        keyboard="false"
        centered
        id="loading-modal"
      >
        <div className="loader"></div>
      </Modal>
    </div>
  );
};

export default Dashboard;
