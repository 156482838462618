import axios from "axios";
import { SERV_API } from "../constant_config";

const gateway = async (token) => {
  return await axios({
    url: SERV_API + "gateway",
    method: "GET",
    // timeout: 5000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      "x-access-token": token,
    },
  });
};

const add_gateway = async (token, device_sn, ip, name, customer_id) => {
  return await axios({
    url: SERV_API + "gateway",
    method: "POST",
    // timeout: 5000,
    data: JSON.stringify({
      device_sn: device_sn,
      ip: ip,
      name: name,
      customer_id: customer_id,
    }),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
};

const update_gateway = async (token, device_sn, ip, name) => {
  return await axios({
    url: SERV_API + "gateway",
    method: "POST",
    // timeout: 5000,
    data: JSON.stringify({
      device_sn: device_sn,
      ip: ip,
      name: name,
    }),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
};

const delete_gateway = async (token, id) => {
  return await axios({
    url: SERV_API + "gateway",
    method: "DELETE",
    // timeout: 5000,
    data: JSON.stringify({
      delId: id,
    }),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
};

const add_building = async (token, building) => {
  const formData = new FormData();
  formData.append("title", building);
  formData.append("tags", building);

  console.log(building);

  return await axios({
    url: SERV_API + "building",
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
      Accept: "multipart/form-data",
    },
  });
};

const update_building = async (token, id, building) => {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("title", building);
  formData.append("tags", building);

  return await axios({
    url: SERV_API + "building",
    method: "PUT",
    data: formData,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
      Accept: "multipart/form-data",
    },
  });
};

const delete_building = async (token, id) => {
  return await axios({
    url: SERV_API + "building",
    method: "DELETE",
    data: JSON.stringify({
      id: id,
    }),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
};

const add_floor = async (token, building_id, floor, image) => {
  const formData = new FormData();
  formData.append("title", floor);
  formData.append("building_id", building_id);
  formData.append("tags", floor);
  formData.append("plan_img", image ? image : null);

  return await axios({
    url: SERV_API + "building/floor",
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
      Accept: "multipart/form-data",
    },
  });
};

const update_floor = async (token, building_id, floor_id, floor, image) => {
  const formData = new FormData();
  formData.append("building_id", building_id);
  formData.append("floor_id", floor_id);

  if (image) {
    formData.append("plan_img", image);
  } else {
    formData.append("title", floor);
    formData.append("tags", floor);
  }

  return await axios({
    url: SERV_API + "building/floor",
    method: "PUT",
    data: formData,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
      Accept: "multipart/form-data",
    },
  });
};

const delete_floor = async (token, building_id, floor) => {
  const formData = new FormData();
  formData.append("building_id", building_id);
  formData.append("title", floor);

  return await axios({
    url: SERV_API + "building/floor",
    method: "DELETE",
    data: formData,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
      Accept: "multipart/form-data",
    },
  });
};

const add_room = async (token, building_id, floor, room, image) => {
  const formData = new FormData();
  formData.append("id", building_id);
  formData.append("floor_title", floor);
  formData.append("title", room);
  formData.append("tags", room);
  formData.append("plan_img", image ? image : null);

  return await axios({
    url: SERV_API + "building/floor/room",
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
      Accept: "multipart/form-data",
    },
  });
};

const update_room = async (token, building_id, floor, room, image) => {
  const formData = new FormData();
  formData.append("id", building_id);
  formData.append("floor_title", floor);
  formData.append("title", room);
  formData.append("tags", room);
  if (image) {
    formData.append("plan_img", image);
  }

  return await axios({
    url: SERV_API + "building/floor/room",
    method: "PUT",
    data: formData,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
      Accept: "multipart/form-data",
    },
  });
};

const delete_room = async (token, building_id, floor, room) => {
  const formData = new FormData();
  formData.append("id", building_id);
  formData.append("floor_title", floor);
  formData.append("title", room);

  return await axios({
    url: SERV_API + "building/floor/room",
    method: "DELETE",
    data: formData,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
      Accept: "multipart/form-data",
    },
  });
};

const add_ac = async (
  token,
  acName,
  type,
  model,
  serialNumber,
  gateway,
  uid,
  cid,
  odu,
  building,
  floor,
  room,
  companyID,
  enable,
  registerDate,
  updatedDate
) => {
  return await axios({
    url: SERV_API + "product",
    method: "POST",
    data: {
      title: acName,
      type: type,
      model: model,
      serail_no: serialNumber,
      gateway_sn: gateway,
      uid: uid,
      cid: cid,
      odu: odu,
      location: {
        building: building,
        floor: floor,
        room: room,
      },
      company_id: companyID,
      enable: enable,
      register_date: registerDate,
      updated_dated: updatedDate,
    },
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
};

const update_ac = async (
  token,
  acName,
  type,
  model,
  serialNumber,
  gateway,
  uid,
  cid,
  odu,
  building,
  floor,
  room,
  companyID,
  enable,
  registerDate,
  updatedDate
) => {
  return await axios({
    url: SERV_API + "product",
    method: "POST",
    data: {
      title: acName,
      type: type,
      model: model,
      serail_no: serialNumber,
      gateway_sn: gateway,
      uid: uid,
      cid: cid,
      odu: odu,
      location: {
        building: building,
        floor: floor,
        room: room,
      },
      company_id: companyID,
      enable: enable,
      register_date: registerDate,
      updated_dated: updatedDate,
    },
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
};

const delete_ac = async (
  token,
  acName,
  type,
  model,
  serialNumber,
  gateway,
  uid,
  cid,
  odu,
  building,
  floor,
  room,
  companyID,
  enable,
  registerDate,
  updatedDate
) => {
  return await axios({
    url: SERV_API + "product",
    method: "POST",
    data: {
      title: acName,
      type: type,
      model: model,
      serail_no: serialNumber,
      gateway_sn: gateway,
      uid: uid,
      cid: cid,
      odu: odu,
      location: {
        building: building,
        floor: floor,
        room: room,
      },
      company_id: companyID,
      enable: enable,
      register_date: registerDate,
      updated_dated: updatedDate,
    },
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
};

export {
  gateway,
  add_gateway,
  update_gateway,
  delete_gateway,
  add_building,
  update_building,
  delete_building,
  add_floor,
  update_floor,
  delete_floor,
  add_room,
  update_room,
  delete_room,
  add_ac,
  update_ac,
  delete_ac,
};
