const roomTemp = (item) => {
  let rt = 0;

  if (item.model === "IAQ") {
    rt = item.acinfo[0]?.istst.RT2
      ? parseFloat(item.acinfo[0].istst.RT2) === 255
        ? "Error"
        : item.acinfo[0].istst.RT2
      : 0;
  } else {
    rt = item.acinfo[0]?.istst.rt ? parseFloat(item.acinfo[0].istst.rt) : 0;
  }

  if (rt === 255) {
    return "Error";
  }

  return rt;
};

const roomHumidity = (item) => {
  let rh = 0;

  if (item.model === "IAQ") {
    rh = item.acinfo[0]?.istst.RH2 ? parseFloat(item.acinfo[0].istst.RH2) : 0;
  } else {
    rh = item.acinfo[0]?.istst.RH ? parseFloat(item.acinfo[0].istst.RH) : 0;
  }

  return rh;
};

const carbon = (item) => {
  let carbon = 0;

  carbon = item.acinfo[0]?.istst.CO2_H
    ? parseInt(item.acinfo[0].istst.CO2_H) * 256
    : 0;
  carbon =
    carbon +
    (item.acinfo[0]?.istst.CO2_L ? parseInt(item.acinfo[0].istst.CO2_L) : 0);

  return carbon;
};

const pm = (item) => {
  let pm = 0;

  if (item.model === "PE" || item.model === "FFU") {
    pm = item.acinfo[0]?.istst.IND2_8
      ? parseInt(item.acinfo[0].istst.IND2_8) * 256
      : 0;
    pm =
      (pm +
        (item.acinfo[0]?.istst.IND2_7
          ? parseInt(item.acinfo[0].istst.IND2_7)
          : 0)) /
      1000;
  } else {
    pm = item.acinfo[0]?.istst.PM2_2
      ? parseInt(item.acinfo[0].istst.PM2_2) * 256
      : 0;
    pm =
      (pm +
        (item.acinfo[0]?.istst.PM2_1
          ? parseInt(item.acinfo[0].istst.PM2_1)
          : 0)) /
      1000;
  }

  return pm;
};

const roomPressure = (item) => {
  let room_pressure = 0;

  if (item.model === "IAQ") {
    room_pressure = item.acinfo[0]?.istst.IND2_8
      ? (parseInt(item.acinfo[0].istst.IND2_8) - 3000) / 10
      : 0;
  }

  return room_pressure;
};

const power = (val) => {
  if (val === "0") {
    return "OFF";
  }

  return "ON";
};

const mode = (val) => {
  switch (true) {
    case val === "0":
      return "Cool";
    case val === "4":
      return "Fan Only";
    case val === "1":
      return "Dry";
    case val === "3":
      return "Heat";
    default:
      return "Auto";
  }
};

const fanSpeed = (val) => {
  switch (true) {
    case val === "1":
      return "Low";
    case val === "2":
      return "Mid";
    case val === "3":
      return "High";
    case val === "4":
      return "Hi-Hi";
    case val === "5":
      return "Turbo";
    default:
      return "Auto";
  }
};

const swing = (val) => {
  switch (true) {
    case val === "1":
      return "H";
    case val === "2":
      return "30°";
    case val === "3":
      return "45°";
    case val === "4":
      return "60°";
    case val === "5":
      return "V";
    default:
      return "Auto";
  }
};

const functionPe = (item) => {
  switch (true) {
    case parseInt(item.acinfo[0].istst.uPtRem) === 1:
      return "Auto Function";
    // case item.acinfo[0].istst.uPtRem === "1":
    //   return "Auto Protection 24 hrs";
    case parseInt(item.acinfo[0].istst.TrbAPS) === 1:
      return "Ozone Filter Disinfection";
    default:
      return "-";
  }
};

const indoorProtection = (val) => {
  let indoor_bit = parseInt(val).toString(2).padStart(6, "0");

  let option = "";

  if (indoor_bit[5] === "1") {
    option += "Indoor defrost protection: Protection,\n";
  } else if (indoor_bit[5] === "0") {
    option += "Indoor defrost protection: Normal,\n";
  }

  if (indoor_bit[4] === "1") {
    option += "Indoor defrost shutdown: Shutdown,\n";
  } else if (indoor_bit[4] === "0") {
    option += "Indoor defrost protection: Normal,\n";
  }

  if (indoor_bit[3] === "1") {
    option += "Indoor fan overload: Trip,\n";
  } else if (indoor_bit[3] === "0") {
    option += "Indoor fan overload: Normal,\n";
  }

  if (indoor_bit[2] === "1") {
    option += "Indoor flood protection: Trip,\n";
  } else if (indoor_bit[2] === "0") {
    option += "Indoor defrost protection: Normal,\n";
  }

  if (indoor_bit[1] === "1") {
    option += "Indoor Mid-coil Over Heat Protection: Protection,\n";
  } else if (indoor_bit[1] === "0") {
    option += "Indoor Mid-coil Over Heat Protection: Normal,\n";
  }

  if (indoor_bit[0] === "1") {
    option += "Indoor Mid-coil Over Heat Shutdown: Protection,\n";
  } else if (indoor_bit[3] === "0") {
    option += "Indoor Mid-coil Over Heat Shutdown: Normal,\n";
  }

  return option.slice(0, -1);
};

const indoorType = (val) => {
  val = parseInt(val);
  switch (true) {
    case val === 1:
      return "Floor/Ceiling Type";
    case val === 2:
      return "Cassette Type";
    case val === 3:
      return "Floor Standing Type";
    case val === 4:
      return "Duct Type";
    case val === 5:
      return "Rooftop Package";
    default:
      return "Wall Type";
  }
};

const indoorModel = (val) => {
  val = parseInt(val);
  switch (true) {
    case val === 1:
      return "Intelligent Inverter";
    case val === 2:
      return "Hygiene Inverter";
    case val === 3:
      return "ATW Heat Pump Monoblock";
    case val === 4:
      return "ATW Heat Pump (Temperzone)";
    case val === 5:
      return "Rooftop Package Unit";
    case val === 6:
      return "Air Purifier";
    case val === 7:
      return "Hot Water A/C";
    case val === 8:
      return "Package Water Cool";
    case val === 9:
      return "RAC Inverter";
    case val === 10:
      return "UFT Inverter";
    case val === 11:
      return "Mini VRF (SRV)";
    case val === 12:
      return "Chiller";
    case val === 13:
      return "Air Change";
    case val === 14:
      return "UTF Extreme Ozone";
    case val === 15:
      return "Protective Environment";
    case val === 16:
      return "Heat Recovery PE";
    case val === 17:
      return "FFU";
    case val === 18:
      return "Micro PE, IC";
    case val === 19:
      return "PE/IC Mono Block";
    case val === 20:
      return "Duct Type 35";
    case val === 21:
      return "IAQ";
    case val === 22:
      return "Duct Re-Heat";
    default:
      return "GPS Inverter";
  }
};

const indoorOption = (val) => {
  let indoor_bit = parseInt(val).toString(2).padStart(4, "0");

  let option = "";

  if (indoor_bit[3] === "1") {
    option = "Mode กำจัดไรฝุ่น: Active,\n";
  } else if (indoor_bit[3] === "0") {
    option = "Mode กำจัดไรฝุ่น: Inactive,\n";
  }

  if (indoor_bit[2] === "1") {
    option = option + ", Heat Mode: Active,\n";
  } else if (indoor_bit[2] === "0") {
    option = option + ", Heat Mode: Inactive,\n";
  }

  if (indoor_bit[1] === "1") {
    option = option + ", Water Temp Mode: Active,\n";
  } else if (indoor_bit[1] === "0") {
    option = option + ", Water Temp Mode: Inactive,\n";
  }

  if (indoor_bit[0] === "1") {
    option = option + ", Hot Water Heating: Enabled,\n";
  } else if (indoor_bit[0] === "0") {
    option = option + ", Hot Water Heating: Disabled,\n";
  }

  return option.slice(0, -1);
};

const errorCodeDetailEN = (errorCode) => {
  let value = parseInt(errorCode);
  switch (true) {
    case value === 2:
      return "Water overflow (CASSETTE TYPE Only)";
    case value === 3:
      return "The room temperature sensor is broken or missing.";
    case value === 4:
      return "The Inlet temperature sensor is loose or missing.";
    case value === 5:
      return "The Outlet temperature sensor is loose or missing.";
    case value === 6:
      return "The Indoor Motor has a problem.";
    case value === 11:
      return "There is a problem in the Outdoor Control Unit. The control board does not communicate with the driver.";
    case value === 12:
      return "Problems caused by cutting from High or Low-Pressure Switch.";
    case value === 13:
      return "Outdoor ambient sensor error.";
    case value === 14:
      return "Outdoor suction pipe sensor error.";
    case value === 15:
      return "Outdoor outlet pipe sensor error.";
    case value === 16:
      return "Outdoor condenser sensor error.";
    case value === 17:
      return "Outdoor discharge pipe sensor error.";
    case value === 18:
      return "Outdoor Compressor shell sensor error.";
    case value === 19:
      return "Compressor 1 current sensor failure.";
    case value === 20:
      return "Compressor 2 current sensor failure.";
    case value === 30:
      return "Compressor 1 discharge over temperature.";
    case value === 31:
      return "Compressor 2 discharge over temperature.";
    case value === 32:
      return "Compressor 1 driver over temperature.";
    case value === 33:
      return "Compressor 2 driver over temperature.";
    case value === 34:
      return "Outdoor condenser over temperature.";
    case value === 35:
      return "Outdoor high pressure trip.";
    case value === 36:
      return "Outdoor low pressure trip.";
    case value === 37:
      return "Outdoor electrical phase protect.";
    case value === 38:
      return "Compressor drive over voltage.";
    case value === 39:
      return "Compressor drive under voltage.";
    case value === 40:
      return "Compressor 1 over current.";
    case value === 41:
      return "Compressor 2 over current.";
    case value === 42:
      return "Compressor 1 driver over current.";
    case value === 43:
      return "Compressor 2 driver over current.";
    case value === 44:
      return "Compressor 1 driver speed error.";
    case value === 45:
      return "Compressor 2 driver speed error.";
    case value === 46:
      return "Compressor 1 driver DC bus error.";
    case value === 47:
      return "Compressor 2 driver DC bus error.";
    case value === 48:
      return "Compressor driver communication loss.";
    case value === 49:
      return "Compressor 1 driver message error.";
    case value === 50:
      return "Compressor 2 driver message error.";
    case value === 54:
      return "Compressor 1 start failure.";
    case value === 55:
      return "Compressor 1 no load running.";
    case value === 56:
      return "Compressor 2 no load running.";
    case value === 57:
      return "Compressor 1 low refrigerant flow.";
    case value === 58:
      return "Compressor 2 low refrigerant flow.";
    case value === 59:
      return "Compressor 1 no refrigerant flow.";
    case value === 60:
      return "Compressor 2 no refrigerant flow.";
    case value === 61:
      return "Compressor 1 shell over temperature.";
    case value === 62:
      return "Compressor 2 shell over temperature.";
    case value === 63:
      return "Compressor 1 driver start failure.";
    case value === 64:
      return "Compressor 2 driver start failure.";
    case value === 65:
      return "Compressor 1 accident stop.";
    default:
      return "Unknown error code. (" + value + ")";
  }
};

const errorCodeDetailTH = (errorCode) => {
  let value = parseInt(errorCode);
  switch (true) {
    case value === 2:
      return "น้ำล้น (เฉพาะรุ่น CASSETTE TYPE)";
    case value === 3:
      return "เซนเซอร์วัดอุณหภูมิห้องหลุดหรือขาด";
    case value === 4:
      return "เซนเซอร์วัดอุณหภูมิเข้าคอยล์เย็น (Inlet)หลุดหรือขาด";
    case value === 5:
      return "เซนเซอร์วัดอุณหภูมิออกคอยล์เย็น (Outlet)หลุดหรือขาด";
    case value === 6:
      return "มอเตอร์พัดลมคอยล์เย็นมีปัญหา";
    case value === 11:
      return "มีปัญหาในชุดคอนโทรลตัวร้อน บอร์ดคอนโทรลไม่สื่อสารกับ Driver";
    case value === 12:
      return "เกิดการตัดจาก High หรือ Low-Pressure Switch ";
    case value === 13:
      return "เซนเซอร์วัดอุณหภูมิอากาศเข้า outdoor unit เสียหาย";
    case value === 14:
      return "เซนเซอร์วัดอุณหภูมิท่อ suction outdoor unit เสียหาย";
    case value === 15:
      return "เซนเซอร์วัดอุณหภูมิท่อทางออก outdoor unit เสียหาย";
    case value === 16:
      return "เซนเซอร์วัดอุณหภูมิ Condenser outdoor unit เสียหาย";
    case value === 17:
      return "เซนเซอร์วัดอุณหภูมิ ท่อ Discharge compressor เสียหาย";
    case value === 18:
      return "เซนเซอร์วัดอุณหภูมิ เปลือก compressor เสียหาย";
    case value === 19:
      return "เซนเซอร์วัดกระแส compressor 1 เสียหาย";
    case value === 20:
      return "เซนเซอร์วัดกระแส compressor 2 เสียหาย";
    case value === 30:
      return "อุณหภูมิท่อ Discharge compressor 1 สูงเกินกำหนด (90 °C)";
    case value === 31:
      return "อุณหภูมิท่อ Discharge compressor 2 สูงเกินกำหนด (90 °C)";
    case value === 32:
      return "อุณหภูมิ Heatzing ระบายความร้อนของ Driver 1 สูงเกินกำหนด (90 °C)";
    case value === 33:
      return "อุณหภูมิ Heatzing ระบายความร้อนของ Driver 2 สูงเกินกำหนด (90 °C)";
    case value === 34:
      return "อุณหภูมิ Condenser สูงเกินกำหนด (63 °C)";
    case value === 35:
      return "High pressure switch trip";
    case value === 36:
      return "Low pressure switch trip";
    case value === 37:
      return "แรงดันไฟฟ้าสลับเฟส หรือผิดปกติ";
    case value === 38:
      return "แรงดันไฟฟ้าในตัว Driver สูงเกินกำหนด";
    case value === 39:
      return "แรงดันไฟฟ้าในตัว Driver ต่ำกว่ากำหนด";
    case value === 40:
      return "กระแสไฟฟ้าของ compressor 1 สูงเกินกำหนด";
    case value === 41:
      return "กระแสไฟฟ้าของ compressor 2 สูงเกินกำหนด";
    case value === 42:
      return "กระแสไฟฟ้าของ Driver 1 สูงเกินกำหนด";
    case value === 43:
      return "กระแสไฟฟ้าของ Driver 2 สูงเกินกำหนด";
    case value === 44:
      return "Driver พบความผิดปกติในการสั่งงาน Compressor 1";
    case value === 45:
      return "Driver พบความผิดปกติในการสั่งงาน Compressor 2";
    case value === 46:
      return "แรงดันไฟฟ้าในตัว Driver 1 ผิดปกติ";
    case value === 47:
      return "แรงดันไฟฟ้าในตัว Driver 2 ผิดปกติ";
    case value === 48:
      return "ไม่สามารถสื่อสารกับ Driver ได้";
    case value === 49:
      return "มีค่า Error จาก Inverter Driver 1";
    case value === 50:
      return "มีค่า Error จาก Inverter Driver 2";
    case value === 54:
      return "คอมเพรสเซอร์ Start ไม่สำเร็จ";
    case value === 55:
      return "ค่ากระแสไฟฟ้า compressor 1 ต่ำเกินกำหนด";
    case value === 56:
      return "ค่ากระแสไฟฟ้า compressor 2 ต่ำเกินกำหนด";
    case value === 57:
      return "อุณหภูมิท่อ suction สูงเกินกำหนด(25 °C)เมื่อ compressor 1 ทำงาน";
    case value === 58:
      return "อุณหภูมิท่อ suction สูงเกินกำหนด(25 °C)เมื่อ compressor 2 ทำงาน";
    case value === 59:
      return "ตรวจไม่พบความแตกต่างระหว่างอุณหภูมิของท่อ discharge และ suction เมื่อ compressor 1 ทำงาน (5 °C)";
    case value === 60:
      return "ตรวจไม่พบความแตกต่างระหว่างอุณหภูมิของท่อ discharge และ suction เมื่อ compressor 2 ทำงาน (5 °C)";
    case value === 61:
      return "อุณหภูมิเปลือก compressor 1 สูงเกินกำหนด (90 °C)";
    case value === 62:
      return "อุณหภูมิเปลือก compressor 1 สูงเกินกำหนด (90 °C)";
    case value === 63:
      return "เกิดความผิดพลาดในการ start compressor 1";
    case value === 64:
      return "เกิดความผิดพลาดในการ start compressor 2";
    case value === 65:
      return "คอมเพรสเซอร์ 1 หยุดทำงาน";
    default:
      return "ไม่ทราบรหัสข้อผิดพลาด (" + value + ")";
  }
};

const validatePhoneNumber = (val) => {
  let re = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{3,4}$/;
  return re.test(val);
};

const dateTimeFormat = (dateTime) => {
  let date = dateTime.split(" ")[0];
  let time = dateTime.split(" ")[1];

  date = date.split("-");

  return date[2] + "-" + date[1] + "-" + date[0] + " " + time;
};

export {
  roomTemp,
  roomHumidity,
  carbon,
  pm,
  roomPressure,
  power,
  mode,
  fanSpeed,
  swing,
  functionPe,
  indoorProtection,
  indoorType,
  indoorModel,
  indoorOption,
  errorCodeDetailEN,
  errorCodeDetailTH,
  validatePhoneNumber,
  dateTimeFormat,
};
