import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Draggable from "react-draggable";
import { useLocation } from "react-router-dom";
import Header from "../components/header";
import Nav from "../components/nav";
import { products, update_position } from "../api/product";
import Type_1 from "../assets/image/control/CoolAC.gif";
import Type_2 from "../assets/image/control/Air.gif";
import Type_3 from "../assets/image/control/Fresh-Air.gif";
import Type_4 from "../assets/image/control/Ex.gif";
import Duct from "../assets/image/control/Duct.gif";
import Cassette from "../assets/image/control/Cassette.gif";
import BuildingIcon from "../assets/image/control/building-icon.png";
import FloorIcon from "../assets/image/control/floor-icon.png";
import RoomIcon from "../assets/image/control/room-icon.png";
import { building_list } from "../api/product";

const SettingPosition = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [activeList, setActiveList] = useState([]);
  const [inactiveList, setInactiveList] = useState([]);
  const [open, setOpen] = useState(false);
  const [building, setBuilding] = useState(
    state?.building ? state.building : ""
  );
  const [floor, setFloor] = useState(state?.floor ? state.floor : "");
  const [room, setRoom] = useState(state?.room ? state.room : "");
  const [buildingFilter, setBuildingFilter] = useState(
    state?.building ? state.building : ""
  );
  const [floorFilter, setFloorFilter] = useState(
    state?.floor ? state.floor : ""
  );
  const [roomFilter, setRoomFilter] = useState(state?.room ? state.room : "");
  const [buildingList, setBuildingList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [roomImage, setRoomImage] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [showIndex, setShowIndex] = useState(-1);

  const ref = useRef(null);

  useEffect(() => {
    let building_state = state?.building ? state.building : "";
    let floor_state = state?.floor ? state.floor : "";
    let room_state = state?.room ? state.room : "";
    let buildingFilter_state = state?.building ? state.building : "";
    let floorFilter_state = state?.floor ? state.floor : "";

    if (building_state === "" && floor_state === "" && room_state === "") {
      navigate("/setting_room");
    }

    (async () => {
      try {
        let token = localStorage.getItem("token");
        let productArr = await products(token, building_state, floor_state);

        let productFilter = productArr.data.productsList.filter((item) => {
          if (room_state === "All") {
            return (
              item.location.building === building_state &&
              item.location.floor === floor_state
            );
          } else {
            return (
              item.location.building === building_state &&
              item.location.floor === floor_state &&
              item.location.room === room_state
            );
          }
        });

        let active_list = productFilter.filter(
          (data) => data.enable === "true" || data.enable === true
        );
        let inactive_list = productFilter.filter(
          (data) => data.enable === "false" || data.enable === false
        );

        setActiveList(active_list);
        setInactiveList(inactive_list);

        let buildings = [];
        let floors = [];
        let rooms = [];

        let companyId = localStorage.getItem("company_id");

        let res = await building_list(token);

        let buildingData = res.data.data.filter((item) => {
          return item.company_id === companyId;
        });

        // Filler Building
        buildingData.map((item) => {
          if (item.title !== undefined) {
            buildings.push({
              building: item.title,
              building_id: item._id,
            });
          }

          return item;
        });

        buildings.sort((a, b) => {
          let x = a.building_id.toLowerCase();
          let y = b.building_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        // Filler Floor
        buildingData.map((item) => {
          if (buildingFilter_state === item.title) {
            if (item.floors !== undefined) {
              item.floors.map((data) => {
                floors.push({
                  floor: data.title,
                  floor_id: data.floorId,
                });

                return data;
              });
            }
          }

          return item;
        });

        floors.sort((a, b) => {
          let x = a.floor_id.toLowerCase();
          let y = b.floor_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        // Filler Room
        buildingData.map((i) => {
          if (buildingFilter_state === i.title) {
            if (i.floors) {
              i.floors.map((j) => {
                if (j.rooms.length && floorFilter_state === j.title) {
                  if (j.rooms) {
                    j.rooms.map((k) => {
                      rooms.push({
                        room: k.title,
                        room_image: k.plan_img,
                        room_id: k.roomId,
                      });

                      if (k.title === room_state) {
                        setRoomImage(k.plan_img);
                      }

                      return k;
                    });
                  }
                }

                return j;
              });
            }
          }

          return i;
        });

        rooms.sort((a, b) => {
          let x = a.room_id.toLowerCase();
          let y = b.room_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        setBuildingList(buildings);
        setFloorList(floors);
        setRoomList(rooms);
        setLoaded(false);
      } catch (error) {
        console.log(error);
        setLoaded(false);
      }
    })();
  }, [navigate, state.building, state.floor, state.room]);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
  }, []);

  const handleDrag = (e, ui, item) => {
    let active_list = activeList.map((data) => {
      if (item.title === data.title) {
        data.position = JSON.stringify({
          x: JSON.parse(data.position).x
            ? (((JSON.parse(data.position).x / 100) * width + ui.deltaX) /
                width) *
              100
            : 0,
          y: JSON.parse(data.position).y
            ? (((JSON.parse(data.position).y / 100) * height + ui.deltaY) /
                height) *
              100
            : 0,
        });
      }

      return data;
    });

    setActiveList(active_list);
  };

  const acImage = (type) => {
    switch (true) {
      case type === "PE":
        return Type_3;
      case type === "FRESHAIR" || "MONOBLOCK" || "AHU":
        return Type_1;
      case type === "FFU" || "EX":
        return Type_4;
      case type === "IAQ":
        return Type_1;
      case type === "DUCT":
        return Duct;
      case type === "CASSETTE":
        return Cassette;
      default:
        return Type_2;
    }
  };

  const active = (acData) => {
    let inactive_list = inactiveList.filter((a) => a !== acData);

    acData.enable = "true";

    activeList.push(acData);

    setActiveList(activeList);
    setInactiveList(inactive_list);
  };

  const remove = (acData) => {
    let active_list = activeList.filter((a) => a !== acData);

    acData.enable = "false";
    acData.position = JSON.stringify({
      x: 0,
      y: 0,
    });

    inactiveList.push(acData);

    setActiveList(active_list);
    setInactiveList(inactiveList);
  };

  const changeBuilding = async (val) => {
    setBuildingFilter(val);
    setLoaded(true);

    let floors = [];
    let rooms = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (val === item.title) {
          if (item.floors.length) {
            if (item.floors) {
              item.floors.map((i) => {
                floors.push({
                  floor: i.title,
                  floor_id: i.floorId,
                });

                return i;
              });
            }
          }

          floors.sort((a, b) => {
            let x = a.floor_id.toLowerCase();
            let y = b.floor_id.toLowerCase();

            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          });

          if (item.floors) {
            item.floors.map((i) => {
              if (i.title === floors[0].floor) {
                if (i.rooms) {
                  i.rooms.map((j) => {
                    rooms.push({
                      room: j.title,
                      room_id: j.roomId,
                    });

                    return j;
                  });
                }
              }

              return i;
            });
          }
        }

        return item;
      });

      rooms.sort((a, b) => {
        let x = a.room_id.toLowerCase();
        let y = b.room_id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setFloorList(floors);
      setRoomList(rooms);

      if (floors.length) {
        setFloorFilter(floors[0].floor);
      } else {
        setFloorFilter("");
      }

      if (rooms.length) {
        setRoomFilter(rooms[0].room);
      } else {
        setRoomFilter("");
      }

      setLoaded(false);
    } catch (error) {
      console.log(error);
      setLoaded(false);
    }
  };

  const changeFloor = async (val) => {
    setFloorFilter(val);
    setLoaded(true);

    let rooms = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (buildingFilter === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              if (i.title === val) {
                if (i.rooms !== undefined) {
                  i.rooms.map((j) => {
                    rooms.push({
                      room: j.title,
                      room_id: j.roomId,
                    });

                    return i;
                  });
                }
              }

              return i;
            });
          }
        }

        return item;
      });

      rooms.sort((a, b) => {
        let x = a.room_id.toLowerCase();
        let y = b.room_id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setRoomList(rooms);

      if (rooms.length) {
        setRoomFilter(rooms[0].room);
      } else {
        setFloorFilter("");
      }

      setLoaded(false);
    } catch (error) {
      console.log(error);
      setLoaded(false);
    }
  };

  const changeRoom = (val) => {
    setRoomFilter(val);
  };

  const filterACRoom = async () => {
    if (buildingFilter && floorFilter && roomFilter) {
      setOpen(false);

      setBuilding(buildingFilter);
      setFloor(floorFilter);
      setRoom(roomFilter);
      setLoaded(true);

      try {
        let token = localStorage.getItem("token");
        let companyId = localStorage.getItem("company_id");

        let res = await building_list(token);

        let buildingData = res.data.data.filter((item) => {
          return item.company_id === companyId;
        });

        buildingData.map((i) => {
          if (i.title === buildingFilter) {
            i.floors.map((j) => {
              if (j.title === floorFilter) {
                j.rooms.map((k) => {
                  if (k.title === roomFilter) {
                    setRoomImage(k.plan_img);
                  }

                  return k;
                });
              }

              return j;
            });
          }

          return i;
        });
      } catch (error) {
        console.log(error);
      }

      try {
        let token = localStorage.getItem("token");
        let res = await products(token, buildingFilter, floorFilter);

        let productFilter = res.data.productsList.filter((item) => {
          if (roomFilter === "All") {
            return (
              item.location.building === buildingFilter &&
              item.location.floor === floorFilter
            );
          } else {
            return (
              item.location.building === buildingFilter &&
              item.location.floor === floorFilter &&
              item.location.room === roomFilter
            );
          }
        });

        let active_list = productFilter.filter(
          (data) => data.enable === true || data.enable === "true"
        );
        let inactive_list = productFilter.filter(
          (data) => data.enable === false || data.enable === "false"
        );

        setActiveList(active_list);
        setInactiveList(inactive_list);
        setLoaded(false);
      } catch (error) {
        console.log(error);
        setLoaded(false);
      }
    }
  };

  const savePosition = () => {
    let token = localStorage.getItem("token");
    let success_count = 0;

    inactiveList.map(async (item) => {
      try {
        await update_position(token, item._id, 0, 0);
      } catch (error) {
        console.log(error);
        alert("Update item position fail.");
      }
    });

    activeList.map(async (item) => {
      try {
        await update_position(
          token,
          item._id,
          JSON.parse(item.position).x,
          JSON.parse(item.position).y
        );

        success_count++;

        if (activeList.length === success_count) {
          alert("Update item position successful");
        }
      } catch (error) {
        console.log(error);
        alert("Update item position fail.");
      }
    });
  };

  const checkModel = (oud, model) => {
    let val = oud.substr(4, 5);

    switch (true) {
      case val * 1 >= 10 && val * 1 < 15:
        if (model.toUpperCase() === "FFU" || model.toUpperCase() === "EX") {
          return model.toUpperCase();
        }
        return "FFU";
      case val * 1 >= 15 && val * 1 < 19:
        return "IAQ";
      default:
        return model.toUpperCase();
    }
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-4">
        <Row className="mx-0 ps-4">
          <Col xs={12}>
            <div
              className="table-container-title px-2 mb-3"
              style={{ justifyContent: "space-between" }}
            >
              <h5 className="setting-title mb-0 position-relative">
                AC Position Management: {building} {floor} {room}
                <div
                  className="position-select-dropdown"
                  onClick={() => setOpen(!open)}
                ></div>
                <div
                  className="control-select-container p-2"
                  style={{
                    visibility: open ? "visible" : "hidden",
                    opacity: open ? 1 : 0,
                    top: "40px",
                    right: "-38px",
                  }}
                >
                  <div className="control-select">
                    <img src={BuildingIcon} alt="building" className="pe-2" />
                    <p className="mb-0 pe-2">Building</p>
                    <Form.Select
                      size="sm"
                      onChange={(e) => changeBuilding(e.target.value)}
                      value={buildingFilter}
                    >
                      {buildingList.map((item, index) => (
                        <option value={item.building} key={index}>
                          {item.building}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className="control-select">
                    <img src={FloorIcon} alt="floor" className="pe-2" />
                    <p className="mb-0 pe-2">Floor</p>
                    <Form.Select
                      size="sm"
                      onChange={(e) => changeFloor(e.target.value)}
                      value={floorFilter}
                    >
                      {floorList.map((item, index) => (
                        <option value={item.floor} key={index}>
                          {item.floor}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className="control-select">
                    <img src={RoomIcon} alt="floor" className="pe-2" />
                    <p className="mb-0 pe-2">Room</p>
                    <Form.Select
                      size="sm"
                      onChange={(e) => changeRoom(e.target.value)}
                      value={roomFilter}
                    >
                      {roomList.map((item, index) => (
                        <option value={item.room} key={index}>
                          {item.room}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <button
                    className="control-search-btn my-2 me-2"
                    onClick={() => filterACRoom()}
                  >
                    Confirm
                  </button>
                </div>
              </h5>
              <button
                className="save-position-btn"
                style={{ bottom: "0px" }}
                onClick={() => savePosition()}
              >
                Save
              </button>
            </div>
          </Col>
          <Col xs={12}>
            <div className="ac-list-card-container">
              {inactiveList.map((item, index) => (
                <div className="ac-list-card" key={index}>
                  <p className="ac-title mb-1">{item.title}</p>
                  <Row className="mx-0">
                    <Col xs={9} className="px-0">
                      <p className="ac-description mb-0">
                        AC Model: {checkModel(item.odu, item.model)}
                      </p>
                      <p className="ac-description mb-0">
                        AC Item.: {item.gateway_sn}
                      </p>
                    </Col>
                    <Col className="add-ac-btn-container px-0">
                      <button
                        className="add-ac-btn"
                        onClick={() => active(item)}
                      >
                        +
                      </button>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </Col>
          <Col xs={12} ref={ref}>
            <div
              className="control-plan-container"
              style={{
                backgroundImage: `url('${
                  roomImage
                    ? "http://node-env.eba-7xwegkmr.ap-southeast-1.elasticbeanstalk.com/api/v2.0/images/upload/" +
                      roomImage
                    : null
                }')`,
              }}
            >
              <div className="room-img-front">
                {activeList.map((item, index) => (
                  <Draggable
                    axis="both"
                    handle={".drag-ac"}
                    position={{
                      x: JSON.parse(item.position).x
                        ? (JSON.parse(item.position).x / 100) * width
                        : 0,
                      y: JSON.parse(item.position).y
                        ? (JSON.parse(item.position).y / 100) * height
                        : 0,
                    }}
                    scale={1}
                    onDrag={(e, ui) => handleDrag(e, ui, item)}
                    bounds="parent"
                    key={index}
                  >
                    <div
                      className="ac-container"
                      onMouseOver={() => {
                        setShowIndex(index);
                      }}
                      // onMouseOut={() => setShowIndex(-1)}
                      onClick={() => setShowIndex(index)}
                    >
                      <img
                        src={acImage(checkModel(item.odu, item.model))}
                        alt="AC"
                      />
                      <div className="drag-ac"></div>
                      <div
                        className={
                          (((JSON.parse(item.position).y / 100) * height) /
                            height) *
                            100 >
                          70
                            ? "setting-ac-info-top"
                            : "setting-ac-info-bottom"
                        }
                        style={
                          (((JSON.parse(item.position).x / 100) * width) /
                            width) *
                            100 >
                          55
                            ? {
                                right: "0",
                                visibility:
                                  showIndex === index ? "visible" : "hidden",
                              }
                            : {
                                left: "0",
                                visibility:
                                  showIndex === index ? "visible" : "hidden",
                              }
                        }
                      >
                        <p className="ac-name mb-0">{item.title}</p>
                        <p className="ac-title">
                          AC Model:{" "}
                          <span className="ac-status">
                            {checkModel(item.odu, item.model)}
                          </span>
                        </p>
                        {/* <p className="ac-title">
                            AC Item.:{" "}
                            <span className="ac-status">{item.gateway_sn}</span>
                          </p> */}
                      </div>
                      <div className="delete-ac" onClick={() => remove(item)}>
                        x
                      </div>
                    </div>
                  </Draggable>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        show={loaded}
        backdrop="static"
        keyboard="false"
        centered
        id="loading-modal"
      >
        <div className="loader"></div>
      </Modal>
    </div>
  );
};

export default SettingPosition;
