import React from "react";
import { CSVLink } from "react-csv";

import ExportIcon from "../assets/image/report/export.png";

const ExportExcel = ({ csvData, fileName }) => {
  return (
    <CSVLink data={csvData} filename={fileName}>
      <button className="export-btn d-none d-sm-block">
        <img src={ExportIcon} alt="export" className="me-2" />
        Export CSV
      </button>
    </CSVLink>
  );
};

export default ExportExcel;
