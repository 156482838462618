import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import { useNavigate } from "react-router-dom";

import Header from "../components/header";
import Nav from "../components/nav";
import Marker from "../assets/image/header/blue_marker.png";
import Current from "../assets/image/setting/current-location.png";

const MarkerComponent = () => (
  <div className="marker-container">
    <img src={Marker} alt="marker" className="marker-img" />
  </div>
);

const SettingLocation = () => {
  const navigate = useNavigate();

  const [latitude, setLatitude] = useState(13.7811116);
  const [longitude, setLongitude] = useState(100.5072081);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (!latitude && !longitude) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    }
  }, [latitude, longitude]);

  const addMarker = (val) => {
    setLatitude(val.lat);
    setLongitude(val.lng);
  };

  const currentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav />
      <div className="body-container mt-4 mt-4 pt-5 pt-xl-0">
        <Row className="mx-0 px-xl-4">
          <Col xs={12}>
            <div className="table-container px-2 px-sm-3 px-xl-4 py-3 py-sm-4">
              <div
                className="table-container-title px-2"
                style={{ justifyContent: "space-between" }}
              >
                <h5 className="mb-1">Location Management</h5>
                <button
                  className="add-modal-btn"
                  onClick={() => setModalShow(true)}
                >
                  Save
                </button>
              </div>
              <div className="location-container px-2 pt-3 pt-sm-4">
                <button
                  className="current-location-btn"
                  onClick={() => currentLocation()}
                >
                  <img
                    src={Current}
                    alt="current-location"
                    className="current-location-icon"
                  />
                  Mark Current Location
                </button>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyDgBvW3DijKFJ63irJPP9CebaUg_yP5lXY",
                  }}
                  center={{ lat: latitude, lng: longitude }}
                  defaultZoom={16}
                  disableDoubleClickZoom={true}
                  zoomControl={false}
                  draggable={true}
                  onClick={addMarker}
                >
                  <MarkerComponent lat={latitude} lng={longitude} />
                </GoogleMapReact>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4 className="modal-title mb-2">Success!</h4>
          <p className="modal-des text-center">Update location successfully.</p>
          <button
            onClick={() => {
              setModalShow(false);
              navigate("/");
            }}
            className="modal-btn float-end"
          >
            Close
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SettingLocation;
