import axios from "axios";
import { SERV_API } from "../constant_config";

const products = async (token, building, floor) => {
  let url = "product";

  if (building || floor) {
    url = url + "?";
  }

  if (building) {
    url = url + "building=" + building;
  }

  if (floor) {
    url = url + "&floor=" + floor;
  }

  return await axios({
    url: SERV_API + url,
    method: "GET",
    // timeout: 5000,
    headers: {
      Accept: "application/json",
      // "Content-Type": "application/json; charset=utf-8",
      "x-access-token": token,
    },
  });
};

const pub_command = async (token, device, cmd, value, uid) => {
  return await axios({
    url: SERV_API + "device/action",
    method: "POST",
    // timeout: 5000,
    data: JSON.stringify({
      device: device,
      cmd: cmd,
      value: value.toString(),
      uid: uid,
    }),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
};

const building_list = async (token) => {
  return await axios({
    url: SERV_API + "building",
    method: "GET",
    // timeout: 5000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      "x-access-token": token,
    },
  });
};

const update_position = async (token, id, x_axis, y_axis) => {
  return await axios({
    url: SERV_API + "product/position",
    method: "POST",
    data: JSON.stringify({
      id: id,
      position: {
        x: x_axis.toString(),
        y: y_axis.toString(),
      },
    }),
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  });
};

export { products, pub_command, building_list, update_position };
