import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./routes/dashboard";
import Control from "./routes/control";
import Product from "./routes/product";
import ErrorOutdoor from "./routes/error_outdoor";
import ReportOdu from "./routes/report_odu";
import ReportIndoor from "./routes/report_indoor";
import ReportWeeklyEnergy from "./routes/report_weekly_energy";
import ReportMonthlyEnergy from "./routes/report_monthly_energy";
import ReportOduHistory from "./routes/report_odu_history";
import Login from "./routes/login";
import Register from "./routes/register";
import ForgetPassword from "./routes/forget_password";
import Profile from "./routes/profile";
import ChangePassword from "./routes/change_password";
import SettingBuilding from "./routes/setting_building";
import SettingFloor from "./routes/setting_floor";
import SettingRoom from "./routes/setting_room";
import SettingPosition from "./routes/setting_position";
import SettingAC from "./routes/setting_ac";
import SettingLocation from "./routes/setting_location";
import SettingGateWay from "./routes/setting_gateway";
import CompanyList from "./routes/company_list";
import NotFound from "./routes/not_found";
import Maintenance from "./routes/maintenance";
import MaintenanceSchedule from "./routes/maintenance_schedule";
import Monitor from "./routes/monitor";
import LoginMonitor from "./routes/login_monitor";

import { MqttContext } from "./components/mqtt_context";
import { ProfileContext } from "./components/profile_context";
import { io } from "socket.io-client";
import { SERV_WWS } from "./constant_config";

import ProfileImage from "./assets/image/user_128.png";

import { pub_command } from "./api/product";
import { users, profile } from "./api/user";
import ErrorIndoor from "./routes/error_indoor";

const App = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [company, setCompany] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [image, setImage] = useState(ProfileImage);
  const [deviceList, setDeviceList] = useState([]);
  const [timerState, setTimerState] = useState(0);

  const connect = useRef(false);
  const updateData = useRef(true);
  const building = useRef("");
  const floor = useRef("");
  const room = useRef("");
  const productList = useRef([]);
  const socket = useRef();
  const loading = useRef(true);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      (async () => {
        try {
          let token = localStorage.getItem("token");
          let user_res = await users(token);
          let profile_res = await profile(token);

          let company_id = localStorage.getItem("company_id");

          let userArr = user_res.data.users.filter((item) => {
            return item.company_id === company_id;
          });

          if (userArr[0].full_name !== undefined) {
            let full_name = userArr[0].full_name.split(" ");

            setName(full_name[0] ? full_name[0] : "-");
            setSurname(full_name[1] ? full_name[1] : null);
          }
          setCompany(
            profile_res.data.user_Info[0].company_info[0].company_name
              ? profile_res.data.user_Info[0].company_info[0].company_name
              : "-"
          );
          setLatitude(
            profile_res.data.user_Info[0].company_info[0].coords
              ? profile_res.data.user_Info[0].company_info[0].coords[0]
              : 0
          );
          setLongitude(
            profile_res.data.user_Info[0].company_info[0].coords
              ? profile_res.data.user_Info[0].company_info[0].coords[1]
              : 0
          );
        } catch (error) {
          console.log(error);
        } finally {
          connect_socket();
        }
      })();
    }

    return () => {
      if (connect.current && socket.current) {
        socket.current.off("connect");
        socket.current.off("disconnect");
      }

      disconnect_socket();
    };
  }, []);

  const update_profile = (name, surname, company, image) => {
    setName(name);
    setSurname(surname);
    setCompany(company);
    setImage(image);
  };

  const pubCommand = (uid, serialNumber, type, value, id) => {
    let result = productList.current.map((item) => {
      if (item.acinfo.length) {
        switch (true) {
          case type === "power":
            if (item.cid === uid && item._id === id) {
              item.acinfo[0].istst.onoff = value === "on" ? "1" : "0";
              updateData.current = false;
              (async () => {
                try {
                  let token = await localStorage.getItem("token");

                  const res = await pub_command(
                    token,
                    serialNumber,
                    type,
                    value,
                    item.cid
                  );

                  console.log(serialNumber, type, value, item.cid, res.data);
                  setTimeout(() => {
                    updateData.current = true;
                  }, 20000);
                } catch (error) {
                  console.log(error);
                  updateData.current = true;
                }
              })();
            }
            break;
          case type === "mode":
            if (item.cid === uid && item._id === id) {
              switch (true) {
                case value === "cool":
                  item.acinfo[0].istst.mode = "0";
                  break;
                case value === "fan":
                  item.acinfo[0].istst.mode = "4";
                  break;
                case value === "dry":
                  item.acinfo[0].istst.mode = "1";
                  break;
                case value === "heat":
                  item.acinfo[0].istst.mode = "3";
                  break;
                default:
                  item.acinfo[0].istst.mode = "2";
                  break;
              }
              updateData.current = false;
              (async () => {
                try {
                  let token = await localStorage.getItem("token");
                  await pub_command(token, serialNumber, type, value, item.cid);
                  setTimeout(() => {
                    updateData.current = true;
                  }, 20000);
                } catch (error) {
                  console.log(error);
                  updateData.current = true;
                }
              })();
            }
            break;
          case type === "modePe":
            if (item.cid === uid && item._id === id) {
              item.acinfo[0].istst.mode = value;

              updateData.current = false;

              (async () => {
                try {
                  let token = await localStorage.getItem("token");
                  await pub_command(
                    token,
                    serialNumber,
                    "mode",
                    value,
                    item.cid
                  );
                  setTimeout(() => {
                    updateData.current = true;
                  }, 20000);
                } catch (error) {
                  console.log(error);
                  updateData.current = true;
                }
              })();
            }
            break;
          case type === "fanspeed":
            if (item.cid === uid && item._id === id) {
              switch (true) {
                case value === "l":
                  item.acinfo[0].istst.fspeed = "1";
                  break;
                case value === "m":
                  item.acinfo[0].istst.fspeed = "2";
                  break;
                case value === "h":
                  item.acinfo[0].istst.fspeed = "3";
                  break;
                case value === "t":
                  item.acinfo[0].istst.fspeed = "4";
                  break;
                case value === "s":
                  item.acinfo[0].istst.fspeed = "5";
                  break;
                default:
                  item.acinfo[0].istst.fspeed = "0";
                  break;
              }
              updateData.current = false;
              (async () => {
                try {
                  let token = await localStorage.getItem("token");
                  await pub_command(token, serialNumber, type, value, item.cid);
                  setTimeout(() => {
                    updateData.current = true;
                  }, 20000);
                } catch (error) {
                  console.log(error);
                  updateData.current = true;
                }
              })();
            }
            break;
          case type === "swing":
            if (item.cid === uid && item._id === id) {
              switch (true) {
                case value === "h":
                  item.acinfo[0].istst.louver = "1";
                  break;
                case value === "3":
                  item.acinfo[0].istst.louver = "3";
                  break;
                case value === "4":
                  item.acinfo[0].istst.louver = "4";
                  break;
                case value === "6":
                  item.acinfo[0].istst.louver = "5";
                  break;
                case value === "v":
                  item.acinfo[0].istst.louver = "2";
                  break;
                default:
                  item.acinfo[0].istst.louver = "0";
                  break;
              }
              updateData.current = false;
              (async () => {
                try {
                  let token = await localStorage.getItem("token");
                  await pub_command(token, serialNumber, type, value, item.cid);
                  setTimeout(() => {
                    updateData.current = true;
                  }, 20000);
                } catch (error) {
                  console.log(error);
                  updateData.current = true;
                }
              })();
            }
            break;
          case type === "uPtRem":
            if (item.cid === uid && item._id === id) {
              item.acinfo[0].istst.uPtRem = value;
              item.acinfo[0].istst.function = "0";
              item.acinfo[0].istst.TrbAPS = "0";
            }
            break;
          case type === "function":
            if (item.cid === uid && item._id === id) {
              item.acinfo[0].istst.uPtRem = "0";
              item.acinfo[0].istst.function = value;
              item.acinfo[0].istst.TrbAPS = "0";
            }
            break;
          case type === "trbAPS":
            if (item.cid === uid && item._id === id) {
              item.acinfo[0].istst.uPtRem = "0";
              item.acinfo[0].istst.function = "0";
              item.acinfo[0].istst.TrbAPS = value;
            }
            break;
          case type === "temp":
            if (item.cid === uid && item._id === id) {
              item.acinfo[0].istst.st = value;
              updateData.current = false;
              clearTimeout(timerState);
              let timer = setTimeout(() => {
                (async () => {
                  try {
                    let token = await localStorage.getItem("token");
                    await pub_command(
                      token,
                      serialNumber,
                      type,
                      value,
                      item.cid
                    );
                    setTimeout(() => {
                      updateData.current = true;
                    }, 20000);
                  } catch (error) {
                    console.log(error);
                    updateData.current = true;
                  }
                })();
              }, 300);
              setTimerState(timer);
            }
            break;
          case type === "setHumidity":
            if (item.cid === uid && item._id === id) {
              item.acinfo[0].istst.SetRH = value;
            }
            break;
          default:
            break;
        }
      }
      return item;
    });

    setDeviceList(result);
  };

  const connect_socket = () => {
    try {
      if (!connect.current) {
        connect.current = true;
        console.log("Connecting...");
        let socket_io = io(SERV_WWS, {
          query: {
            token: localStorage.getItem("token"),
          },
          reconnectionDelay: 5000,
          reconnectionAttempts: 10,
          reconnection: true,
          transports: ["websocket"],
        });
        socket.current = socket_io;
        socket_io.on("connect", () => {
          console.log("Connected");
        });
        socket_io.on(
          "products/" + localStorage.getItem("company_id"),
          (data) => {
            let subscribe_data = JSON.parse(data);
            if (subscribe_data) {
              loading.current = false;
            }
            let result = productList.current.map((item) => {
              subscribe_data.result.map((data) => {
                if (data.acinfo[0] !== undefined) {
                  if (data.acinfo[0].connected === "OK") {
                    if (data.acinfo[0].datainfo.length) {
                      data.acinfo[0].datainfo.map((i) => {
                        if (
                          i.unit_id === item.uid &&
                          data.device_sn === item.gateway_sn &&
                          updateData.current &&
                          i.istat !== undefined &&
                          item.acinfo[0] !== undefined
                        ) {
                          item.acinfo[0].istst = i.istat;
                          item.acinfo.online = true;
                        }
                        return i;
                      });
                    } else {
                      if (data.device_sn === item.gateway_sn) {
                        item.acinfo.online = false;
                      }
                    }
                  } else {
                    if (data.acinfo[0].datainfo !== undefined) {
                      if (data.acinfo[0].datainfo.length) {
                        data.acinfo[0].datainfo.map((i) => {
                          if (
                            i.unit_id === item.uid &&
                            data.device_sn === item.gateway_sn &&
                            updateData.current &&
                            i.istat !== undefined &&
                            item.acinfo[0] !== undefined
                          ) {
                            item.acinfo[0].istst = i.istat;
                            item.acinfo.online = false;
                          }
                          return i;
                        });
                      }
                    }
                  }
                } else {
                  item.acinfo.online = false;
                }
                return data;
              });
              return item;
            });
            setDeviceList(result);
          }
        );
        socket_io.on("connect_error", () => {
          let result = productList.current.map((item) => {
            item.acinfo.online = false;
            return item;
          });
          setDeviceList(result);
        });
      }
    } catch (e) {
      console.log(e);
      disconnect_socket();
      let result = productList.current.map((item) => {
        item.acinfo.online = false;
        return item;
      });
      setDeviceList(result);
    }
  };

  const disconnect_socket = () => {
    if (typeof socket.current !== "undefined") {
      if (socket.current.connected) {
        socket.current.disconnect();
        socket.current = undefined;
        console.log("disconnect socket!");
      }
    }
  };

  return (
    <ProfileContext.Provider
      value={{
        update_profile,
        name,
        surname,
        company,
        image,
        setName,
        setSurname,
        setCompany,
        latitude,
        longitude,
        setLatitude,
        setLongitude,
      }}
    >
      <MqttContext.Provider
        value={{
          productList,
          deviceList,
          setDeviceList,
          pubCommand,
          connect_socket,
          disconnect_socket,
          building,
          floor,
          room,
          updateData,
          loading,
          connect,
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard page={"dashboard"} />} />
            <Route path="/control" element={<Control page={"control"} />} />
            <Route path="/product" element={<Product page={"product"} />} />
            <Route
              path="/error_indoor"
              element={<ErrorIndoor page={"error"} />}
            />
            <Route
              path="/error_outdoor"
              element={<ErrorOutdoor page={"error"} />}
            />
            <Route path="/report_odu" element={<ReportOdu page={"report"} />} />
            <Route
              path="/report_indoor"
              element={<ReportIndoor page={"report"} />}
            />
            <Route
              path="/report_odu_history"
              element={<ReportOduHistory page={"report"} />}
            />
            <Route
              path="/report_weekly_energy"
              element={<ReportWeeklyEnergy page={"report"} />}
            />
            <Route
              path="/report_monthly_energy"
              element={<ReportMonthlyEnergy page={"report"} />}
            />
            <Route path="/profile" element={<Profile />} />
            <Route path="/change_password" element={<ChangePassword />} />
            <Route path="/register" element={<Register page={"register"} />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/forget_password"
              element={<ForgetPassword page={"forget_password"} />}
            />
            <Route
              path="/setting_position"
              element={<SettingPosition page={"setting"} />}
            />
            <Route
              path="/setting_building"
              element={<SettingBuilding page={"setting"} />}
            />
            <Route
              path="/setting_floor"
              element={<SettingFloor page={"setting"} />}
            />
            <Route
              path="/setting_room"
              element={<SettingRoom page={"setting"} />}
            />
            <Route
              path="/setting_ac"
              element={<SettingAC page={"setting"} />}
            />
            <Route
              path="/setting_location"
              element={<SettingLocation page={"setting"} />}
            />
            <Route
              path="/setting_gateway"
              element={<SettingGateWay page={"setting"} />}
            />
            <Route
              path="/company_list"
              element={<CompanyList page={"company_list"} />}
            />
            <Route
              path="/maintenance"
              element={<Maintenance page={"maintenance"} />}
            />
            <Route
              path="/maintenance_schedule"
              element={<MaintenanceSchedule page={"maintenance"} />}
            />
            <Route path="/monitor" element={<Monitor page={"monitor"} />} />
            <Route
              path="/login_monitor"
              element={<LoginMonitor page={"login_monitor"} />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </MqttContext.Provider>
    </ProfileContext.Provider>
  );
};

export default App;
