import React, { useState, useRef } from "react";
import { Row, Col, Table, Modal, Form, Button } from "react-bootstrap";
import Header from "../components/header";
import Nav from "../components/nav";
import Pagination from "../components/pagination";
import { profile } from "../api/user";
import ErrorIcon from "../assets/image/error/error.png";
import { useRealtimeIndoorError } from "../hook/useHook";
import {
  errorCodeDetailEN,
  errorCodeDetailTH,
  validatePhoneNumber,
} from "../utils/utils";

const ErrorIndoor = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage] = useState(20);
  const [addForm, setAddForm] = useState(false);
  const [data, setData] = useState({
    name: "",
    phoneNumber: "",
    building: "",
    floor: "",
    room: "",
    address: "",
    appointmentDay: "",
    model: "",
    problem: "",
    serialNumber: "",
    systemName: "",
    isValidName: false,
    isValidPhoneNumber: false,
    isValidAddress: false,
    isValidAppointmentDay: false,
    isValidProblem: false,
    errorName: "",
    errorPhoneNumber: "",
    errorAddress: "",
    errorAppointmentDay: "",
    errorProblem: "",
  });

  const [errorData, loading] = useRealtimeIndoorError();

  const indexOfLastPost = currentPage * PerPage;
  const indexOfFirstPost = indexOfLastPost - PerPage;
  const currentPosts = errorData.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const appointmentOption1 = useRef(null);
  const appointmentOption2 = useRef(null);
  const appointmentOption3 = useRef(null);
  const appointmentOption4 = useRef(null);
  const appointmentOption5 = useRef(null);
  const appointmentOption6 = useRef(null);
  const appointmentOption7 = useRef(null);
  const appointmentOption8 = useRef(null);

  const appointmentDayHandle = (e) => {
    setData({
      ...data,
      appointmentDay: e.target.value,
      isValidAppointmentDay: true,
      errorAppointmentDay: "",
    });

    switch (true) {
      case e.target.value === "1":
        appointmentOption1.current.checked = e.target.checked;
        appointmentOption2.current.checked = e.target.unchecked;
        appointmentOption3.current.checked = e.target.unchecked;
        appointmentOption4.current.checked = e.target.unchecked;
        appointmentOption5.current.checked = e.target.unchecked;
        appointmentOption6.current.checked = e.target.unchecked;
        appointmentOption7.current.checked = e.target.unchecked;
        appointmentOption8.current.checked = e.target.unchecked;

        break;
      case e.target.value === "2":
        appointmentOption1.current.checked = e.target.unchecked;
        appointmentOption2.current.checked = e.target.checked;
        appointmentOption3.current.checked = e.target.unchecked;
        appointmentOption4.current.checked = e.target.unchecked;
        appointmentOption5.current.checked = e.target.unchecked;
        appointmentOption6.current.checked = e.target.unchecked;
        appointmentOption7.current.checked = e.target.unchecked;
        appointmentOption8.current.checked = e.target.unchecked;

        break;
      default:
        appointmentOption1.current.checked = e.target.unchecked;
        appointmentOption2.current.checked = e.target.unchecked;
        appointmentOption3.current.checked =
          e.target.value === "3" ? e.target.checked : e.target.unchecked;
        appointmentOption4.current.checked =
          e.target.value === "4" ? e.target.checked : e.target.unchecked;
        appointmentOption5.current.checked =
          e.target.value === "5" ? e.target.checked : e.target.unchecked;
        appointmentOption6.current.checked =
          e.target.value === "6" ? e.target.checked : e.target.unchecked;
        appointmentOption7.current.checked =
          e.target.value === "7" ? e.target.checked : e.target.unchecked;
        appointmentOption8.current.checked =
          e.target.value === "8" ? e.target.checked : e.target.unchecked;
        break;
    }
  };

  const nameChangeHandle = (val) => {
    if (val.trim().length) {
      setData({ ...data, name: val, isValidName: true, errorName: "" });
    } else {
      setData({
        ...data,
        name: val,
        isValidName: false,
        errorName: "Name can't be empty!",
      });
    }
  };

  const phoneNumberHandle = (val) => {
    if (val.trim().length) {
      let validate = validatePhoneNumber(val);

      if (validate) {
        setData({
          ...data,
          phoneNumber: val,
          isValidPhoneNumber: true,
          errorPhoneNumber: "",
        });
      } else {
        setData({
          ...data,
          phoneNumber: val,
          isValidPhoneNumber: false,
          errorPhoneNumber: "Invalid format!",
        });
      }
    } else {
      setData({
        ...data,
        phoneNumber: val,
        isValidPhoneNumber: false,
        errorPhoneNumber: "Phone number can't be empty!",
      });
    }
  };

  const addressHandle = (val) => {
    if (val.trim().length) {
      setData({
        ...data,
        address: val,
        isValidAddress: true,
        errorAddress: "",
      });
    } else {
      setData({
        ...data,
        address: val,
        isValidAddress: false,
        errorAddress: "Address can't be empty!",
      });
    }
  };

  const problemHandle = (val) => {
    if (val.trim().length) {
      setData({
        ...data,
        problem: val,
        isValidProblem: true,
        errorProblem: "",
      });
    } else {
      setData({
        ...data,
        problem: val,
        isValidProblem: true,
        errorProblem: "Problem can't be empty!",
      });
    }
  };

  const handleSubmit = () => {
    if (
      data.isValidName &&
      data.isValidPhoneNumber &&
      data.isValidAddress &&
      data.isValidProblem &&
      data.isValidAppointmentDay &&
      data.appointmentDay
    ) {
      setAddForm(false);
      setData({
        name: "",
        phoneNumber: "",
        building: "",
        floor: "",
        room: "",
        address: "",
        appointmentDay: "",
        model: "",
        problem: "",
        serialNumber: "",
        systemName: "",
        isValidName: false,
        isValidPhoneNumber: false,
        isValidAddress: false,
        isValidAppointmentDay: false,
        isValidProblem: false,
        errorName: "",
        errorPhoneNumber: "",
        errorAddress: "",
        errorAppointmentDay: "",
        errorProblem: "",
      });
    } else {
      setData({
        ...data,
        errorName: data.name === "" ? "Name can't be empty!" : data.errorName,
        errorPhoneNumber:
          data.phoneNumber === ""
            ? "Phone number can't be empty!"
            : data.errorPhoneNumber,
        errorAddress:
          data.address === "" ? "Address can't be empty!" : data.errorAddress,
        errorBuilding:
          data.building === ""
            ? "Building must be select!"
            : data.errorBuilding,
        errorFloor:
          data.floor === "" ? "Floor must be select!" : data.errorFloor,
        errorRoom: data.room === "" ? "Room must be select!" : data.errorRoom,
        errorAcName:
          data.systemName === "" ? "AC Name must be select!" : data.errorAcName,
        errorProblem:
          data.problem === "" ? "Problem can't be empty!" : data.errorProblem,
        errorAppointmentDay:
          data.appointmentDay === ""
            ? "Convenient date must be select!"
            : data.errorAppointmentDay,
      });
    }
  };

  const editModalHandle = async (
    building,
    floor,
    room,
    system_name,
    serial_number,
    problem
  ) => {
    setAddForm(true);

    try {
      let token = await localStorage.getItem("token");
      let res = await profile(token);

      let name = res.data.user_Info[0].company_info[0].company_name
        ? res.data.user_Info[0].company_info[0].company_name
        : "";
      let address = res.data.user_Info[0].company_info[0].address
        ? res.data.user_Info[0].company_info[0].address
        : "";
      let addr = "";
      let amphoe = "";
      let province = "";
      let zipcode = "";
      let address_result = "";

      if (address) {
        addr = address[0] ? address[0].addr : "";
        amphoe = address[0] ? address[0].amphoe : "";
        province = address[0] ? address[0].province : "";
        zipcode = address[0] ? address[0].zipcode : "";
        address_result = addr + " " + amphoe + " " + province + " " + zipcode;
      }

      setData({
        ...data,
        building: building,
        floor: floor,
        room: room,
        systemName: system_name,
        problem: problem,
        name: name,
        address: address_result,
        isValidName: name ? true : false,
        isValidAddress: address_result ? true : false,
        isValidProblem: problem ? true : false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-4 mt-4 pt-5 pt-xl-0">
        <Row className="mx-0 px-xl-4">
          <Col>
            <div className="table-container px-2 px-sm-3 px-xl-4 py-3 py-sm-4">
              <div
                className="table-container-title px-2"
                style={{ alignItems: "center" }}
              >
                <img src={ErrorIcon} alt="error" className="error-icon" />
                <h5 className="ms-3 mb-0">
                  Indoor - Error Code {"&"} Service Guidance
                </h5>
              </div>
              <div className="mx-2 mb-2 table-overflow">
                <Table hover className="mt-3 error-table">
                  <thead>
                    <tr>
                      <th>System Name {loading}</th>
                      <th>Gateway</th>
                      <th>Location</th>
                      <th>UID</th>
                      <th>Error EN</th>
                      <th>Error TH</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {errorData.length ? (
                      currentPosts.map((item, k) => (
                        <tr key={k}>
                          <td>{item.system_name}</td>
                          <td>{item.gateway_sn}</td>
                          <td>
                            {item.location
                              ? item.location.building +
                                " " +
                                item.location.floor +
                                ", " +
                                item.location.room
                              : "-"}
                          </td>
                          <td>{item.uid}</td>
                          <td>{errorCodeDetailEN(item.error_code)}</td>
                          <td>{errorCodeDetailTH(item.error_code)}</td>
                          <td className="manage-ac">
                            <span
                              style={{
                                color: "#b31117",
                                fontWeight: "bold",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                editModalHandle(
                                  item.location?.building
                                    ? item.location.building
                                    : "-",
                                  item.location?.floor
                                    ? item.location.floor
                                    : "-",
                                  item.location?.room
                                    ? item.location.room
                                    : "-",
                                  item.system_name,
                                  item.gateway_sn,
                                  errorCodeDetailTH(item.error_code)
                                );
                              }}
                            >
                              Request Service
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={7}>No Data</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <Pagination
                postsPerPage={PerPage}
                totalPosts={errorData.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        show={loading}
        backdrop="static"
        keyboard="false"
        centered
        id="loading-modal"
      >
        <div className="loader"></div>
      </Modal>
      <Modal
        size="lg"
        show={addForm}
        onHide={() => {
          setAddForm(false);
          setData({
            name: "",
            phoneNumber: "",
            building: "",
            floor: "",
            room: "",
            address: "",
            appointmentDay: "",
            model: "",
            problem: "",
            serialNumber: "",
            systemName: "",
            isValidName: false,
            isValidPhoneNumber: false,
            isValidAddress: false,
            isValidAppointmentDay: false,
            isValidProblem: false,
            errorName: "",
            errorPhoneNumber: "",
            errorAddress: "",
            errorAppointmentDay: "",
            errorProblem: "",
          });
        }}
        centered
      >
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <h4 className="modal-title mb-2">Request Form</h4>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  onChange={(e) => nameChangeHandle(e.target.value)}
                  style={{
                    borderColor: !data.errorName.length ? "#cccccc" : "#b31117",
                  }}
                  defaultValue={data.name}
                />
                {!data.errorName.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorName}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone Number"
                  onChange={(e) => phoneNumberHandle(e.target.value)}
                  style={{
                    borderColor: !data.errorPhoneNumber.length
                      ? "#cccccc"
                      : "#b31117",
                  }}
                  defaultValue={data.phoneNumber}
                />
                {!data.errorPhoneNumber.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorPhoneNumber}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Address</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  placeholder="Address"
                  onChange={(e) => addressHandle(e.target.value)}
                  style={{
                    borderColor: !data.errorAddress.length
                      ? "#cccccc"
                      : "#b31117",
                  }}
                  defaultValue={data.address}
                />
                {!data.errorAddress.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorAddress}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Building</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Building"
                  style={{
                    borderColor: "#cccccc",
                  }}
                  defaultValue={data.building}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Floor</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Floor"
                  style={{
                    borderColor: "#cccccc",
                  }}
                  defaultValue={data.building}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Room</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Room"
                  style={{
                    borderColor: "#cccccc",
                  }}
                  defaultValue={data.building}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">System Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="System Name"
                  style={{
                    borderColor: "#cccccc",
                  }}
                  defaultValue={data.systemName}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Problem</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  placeholder="Problem"
                  onChange={(e) => problemHandle(e.target.value)}
                  style={{
                    borderColor: !data.errorProblem.length
                      ? "#cccccc"
                      : "#b31117",
                  }}
                  defaultValue={data.problem}
                />
                {!data.errorProblem.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorProblem}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">
                  Convenient date for service
                </Form.Label>
                <div>
                  <Form.Check
                    inline
                    label="Monday-Friday"
                    name="appointment day"
                    onClick={(e) => appointmentDayHandle(e)}
                    value={"1"}
                    ref={appointmentOption1}
                  />
                  <Form.Check
                    inline
                    label="Monday-Saturday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"2"}
                    ref={appointmentOption2}
                  />
                  <Form.Check
                    inline
                    label="Monday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"3"}
                    ref={appointmentOption3}
                  />
                  <Form.Check
                    inline
                    label="Tuesday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"4"}
                    ref={appointmentOption4}
                  />
                  <Form.Check
                    inline
                    label="Wednesday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"5"}
                    ref={appointmentOption5}
                  />
                  <Form.Check
                    inline
                    label="Thursday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"6"}
                    ref={appointmentOption6}
                  />
                  <Form.Check
                    inline
                    label="Friday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"7"}
                    ref={appointmentOption7}
                  />
                  <Form.Check
                    inline
                    label="Saturday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"8"}
                    ref={appointmentOption8}
                  />
                </div>
                {!data.errorAppointmentDay.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorAppointmentDay}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12}>
              <div className="modal-btn-container mb-2">
                <Button
                  type="button"
                  onClick={() => {
                    setAddForm(false);
                    setData({
                      name: "",
                      phoneNumber: "",
                      building: "",
                      floor: "",
                      room: "",
                      address: "",
                      appointmentDay: "",
                      model: "",
                      problem: "",
                      serialNumber: "",
                      systemName: "",
                      isValidName: false,
                      isValidPhoneNumber: false,
                      isValidAddress: false,
                      isValidAppointmentDay: false,
                      isValidProblem: false,

                      errorName: "",
                      errorPhoneNumber: "",
                      errorAddress: "",
                      errorAppointmentDay: "",
                      errorProblem: "",
                    });
                  }}
                >
                  Cancel
                </Button>
                <Button type="button" onClick={() => handleSubmit("add")}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ErrorIndoor;
