import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Table, Form, Modal, Button } from "react-bootstrap";
import moment from "moment";

import Header from "../components/header";
import Nav from "../components/nav";
import Pagination from "../components/pagination";
import ToolIcon from "../assets/image/maintenance/tool.png";
import Upload from "../assets/image/setting/upload.png";
import CloseIcon from "../assets/image/maintenance/close.png";
import { products, building_list } from "../api/product";
import { profile } from "../api/user";

const Maintenance = (props) => {
  const [maintenanceList, setMaintenanceList] = useState([]);
  const [maintenanceSearchResult, setMaintenanceSearchResult] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage] = useState(20);
  const [buildingList, setBuildingList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [buildingListSearch, setBuildingListSearch] = useState([]);
  const [floorListSearch, setFloorListSearch] = useState([]);
  const [roomListSearch, setRoomListSearch] = useState([]);
  const [buildingSearch, setBuildingSearch] = useState("");
  const [floorSearch, setFloorSearch] = useState("");
  const [roomSearch, setRoomSearch] = useState("");
  const [acNameList, setAcNameList] = useState([]);
  const [acNameListSearch, setAcNameListSearch] = useState([]);
  const [acNameSearch, setAcNameSearch] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [status, setStatus] = useState("");
  const [loaded, setLoaded] = useState(true);
  const [addForm, setAddForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [viewForm, setViewForm] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState({
    name: "",
    phoneNumber: "",
    building: "",
    floor: "",
    room: "",
    address: "",
    appointmentDay: "",
    model: "",
    problem: "",
    serialNumber: "",
    acName: "",
    status: "",
    image_1: null,
    image_2: null,
    image_3: null,
    isValidName: false,
    isValidPhoneNumber: false,
    isValidBuilding: false,
    isValidFloor: false,
    isValidRoom: false,
    isValidAddress: false,
    isValidAppointmentDay: false,
    isValidProblem: false,
    isValidAcName: false,
    isValidImage: false,
    errorName: "",
    errorPhoneNumber: "",
    errorBuilding: "",
    errorFloor: "",
    errorRoom: "",
    errorAddress: "",
    errorAppointmentDay: "",
    errorProblem: "",
    errorAcName: "",
    errorImage: "",
  });

  const indexOfLastPost = currentPage * PerPage;
  const indexOfFirstPost = indexOfLastPost - PerPage;
  const currentPosts = maintenanceSearchResult.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const uploadedImage1 = useRef(null);
  const uploadedImage2 = useRef(null);
  const uploadedImage3 = useRef(null);
  const imageUploader1 = useRef(null);
  const imageUploader2 = useRef(null);
  const imageUploader3 = useRef(null);
  const appointmentOption1 = useRef(null);
  const appointmentOption2 = useRef(null);
  const appointmentOption3 = useRef(null);
  const appointmentOption4 = useRef(null);
  const appointmentOption5 = useRef(null);
  const appointmentOption6 = useRef(null);
  const appointmentOption7 = useRef(null);
  const appointmentOption8 = useRef(null);

  useEffect(() => {
    setMaintenanceList([]);
    // setMaintenanceSearchResult([
    //   {
    //     id: 1,
    //     name: "Mr.Saijo Denki",
    //     phoneNumber: "0123456789",
    //     acName: "FCU-01-1",
    //     serialNumber: "SSU-25C-A-DTMP1",
    //     model: "Spilt",
    //     type: "SRV",
    //     location: {
    //       building: "อาคารศูนย์คอมพิวเตอร์",
    //       floor: "FL G",
    //       room: "ห้องโอเปอร์เรเตอร์",
    //     },
    //     address: "Saijo",
    //     problem: "น้ำหยด",
    //     appointment: "2022-09-15 10:00:00",
    //     appointmentDay: "",
    //     status: "ดำเนินการเสร็จสิ้น",
    //     image_1: null,
    //     image_2: null,
    //     image_3: null,
    //     createDate: "2022-09-11 10:00:00",
    //   },
    //   {
    //     id: 2,
    //     name: "Mr.Saijo Denki",
    //     phoneNumber: "0123456789",
    //     acName: "FCU-01-1",
    //     serialNumber: "SSU-25C-A-DTMP1",
    //     model: "Spilt",
    //     type: "SRV",
    //     location: {
    //       building: "อาคารศูนย์คอมพิวเตอร์",
    //       floor: "FL G",
    //       room: "ห้องโอเปอร์เรเตอร์",
    //     },
    //     address: "Saijo",
    //     problem: "น้ำหยด",
    //     appointment: "",
    //     appointmentDay: "",
    //     status: "รอดำเนินการติดต่อกลับ",
    //     image_1: null,
    //     image_2: null,
    //     image_3: null,
    //     createDate: "2022-10-11 10:00:00",
    //   },
    // ]);
    // setMaintenanceList([
    //   {
    //     id: 1,
    //     name: "Mr.Saijo Denki",
    //     phoneNumber: "0123456789",
    //     acName: "FCU-01-1",
    //     serialNumber: "SSU-25C-A-DTMP1",
    //     model: "Spilt",
    //     type: "SRV",
    //     location: {
    //       building: "อาคารศูนย์คอมพิวเตอร์",
    //       floor: "FL G",
    //       room: "ห้องโอเปอร์เรเตอร์",
    //     },
    //     address: "Saijo",
    //     problem: "น้ำหยด",
    //     appointment: "2022-09-15 10:00:00",
    //     appointmentDay: "",
    //     status: "ดำเนินการเสร็จสิ้น",
    //     image_1: null,
    //     image_2: null,
    //     image_3: null,
    //     createDate: "2022-09-11 10:00:00",
    //   },
    //   {
    //     id: 2,
    //     name: "Mr.Saijo Denki",
    //     phoneNumber: "0123456789",
    //     acName: "FCU-01-1",
    //     serialNumber: "SSU-25C-A-DTMP1",
    //     model: "Spilt",
    //     type: "SRV",
    //     location: {
    //       building: "อาคารศูนย์คอมพิวเตอร์",
    //       floor: "FL G",
    //       room: "ห้องโอเปอร์เรเตอร์",
    //     },
    //     address: "Saijo",
    //     problem: "น้ำหยด",
    //     appointment: "",
    //     appointmentDay: "",
    //     status: "รอดำเนินการติดต่อกลับ",
    //     image_1: null,
    //     image_2: null,
    //     image_3: null,
    //     createDate: "2022-10-11 10:00:00",
    //   },
    // ]);

    setStatusList([]);

    (async () => {
      try {
        let token = localStorage.getItem("token");
        let companyId = localStorage.getItem("company_id");

        let res = await building_list(token);

        let buildingData = res.data.data.filter((item) => {
          return item.company_id === companyId;
        });

        let buildings = [];
        let floors = [];
        let rooms = [];

        // Filler Building
        buildingData.map((item) => {
          if (item.title !== undefined) {
            buildings.push({
              building: item.title,
              building_id: item._id,
            });
          }

          return item;
        });

        buildings.sort((a, b) => {
          let x = a.building_id.toLowerCase();
          let y = b.building_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        setBuildingList(buildings);
        setFloorList(floors);
        setRoomList(rooms);

        setBuildingListSearch(buildings);

        setLoaded(false);
      } catch (error) {
        setLoaded(false);
      }
    })();
  }, [loaded]);

  useEffect(() => {
    (async () => {
      try {
        let token = await localStorage.getItem("token");
        let res = await profile(token);

        let name = res.data.user_Info[0].company_info[0].company_name
          ? res.data.user_Info[0].company_info[0].company_name
          : "";
        let address = res.data.user_Info[0].company_info[0].address
          ? res.data.user_Info[0].company_info[0].address
          : "";
        let addr = "";
        let amphoe = "";
        let province = "";
        let zipcode = "";
        let address_result = "";

        if (address) {
          addr = address[0] ? address[0].addr : "";
          amphoe = address[0] ? address[0].amphoe : "";
          province = address[0] ? address[0].province : "";
          zipcode = address[0] ? address[0].zipcode : "";
          address_result = addr + " " + amphoe + " " + province + " " + zipcode;
        }

        setData({
          ...data,
          name: name,
          address: address_result,
          isValidName: name ? true : false,
          isValidAddress: address_result ? true : false,
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [data]);

  const changeBuildingSearch = async (val) => {
    setBuildingSearch(val);

    let floors = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (val === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              floors.push({
                floor: i.title,
                floor_id: i.floorId,
              });

              return i;
            });
          }

          floors.sort((a, b) => {
            if (a.floor_id.toLowerCase() < b.floor_id.toLowerCase()) {
              return -1;
            }

            if (a.floor_id.toLowerCase() > b.floor_id.toLowerCase()) {
              return 1;
            }

            return 0;
          });
        }

        return item;
      });

      setFloorListSearch(floors);
      setRoomListSearch([]);

      let productArr = await products(token, val, null);

      let acNames = [];

      productArr.data.productsList.map((item) => {
        acNames.push({
          title: item.title,
          id: item._id,
        });

        return item;
      });

      acNames.sort((a, b) => {
        let x = a.id.toLowerCase();
        let y = b.id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setAcNameListSearch(acNames);
    } catch (error) {
      console.log(error);
    }
  };

  const changeFloorSearch = async (val) => {
    setFloorSearch(val);

    let rooms = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (buildingSearch === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              if (i.title === val) {
                if (i.rooms !== undefined) {
                  i.rooms.map((j) => {
                    if (j.title !== "All") {
                      rooms.push({
                        room: j.title,
                        room_id: j.roomId,
                      });
                    }

                    return i;
                  });
                }
              }

              return i;
            });
          }
        }

        return item;
      });

      let productArr = await products(token, buildingSearch, val);

      let acNames = [];

      productArr.data.productsList.map((item) => {
        acNames.push({
          title: item.title,
          id: item._id,
        });

        return item;
      });

      acNames.sort((a, b) => {
        let x = a.id.toLowerCase();
        let y = b.id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setAcNameListSearch(acNames);
      setRoomListSearch(rooms);
    } catch (error) {
      console.log(error);
    }
  };

  const changeRoomSearch = async (val) => {
    setRoomSearch(val);

    try {
      let token = localStorage.getItem("token");
      let productArr = await products(token, buildingSearch, floorSearch);

      let acNames = [];

      productArr.data.productsList.map((item) => {
        if (item.location.room === val || val === "") {
          acNames.push({
            title: item.title,
            id: item._id,
          });
        }

        return item;
      });

      acNames.sort((a, b) => {
        let x = a.id.toLowerCase();
        let y = b.id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setAcNameListSearch(acNames);
    } catch (error) {
      console.log(val);
    }
  };

  const validatePhoneNumber = (val) => {
    var re = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{3,4}$/;
    return re.test(val);
  };

  const handleImageUpload1 = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage1;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);

      setData({
        ...data,
        image_1: e.target.files[0],
        isValidImage: true,
        errorImage: "",
      });
    }
  };

  const handleImageUpload2 = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage2;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);

      setData({
        ...data,
        image_2: e.target.files[0],
      });
    }
  };

  const handleImageUpload3 = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage3;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);

      setData({
        ...data,
        image_3: e.target.files[0],
      });
    }
  };

  const appointmentDayHandle = (e) => {
    setData({
      ...data,
      appointmentDay: e.target.value,
      isValidAppointmentDay: true,
      errorAppointmentDay: "",
    });

    switch (true) {
      case e.target.value === "1":
        appointmentOption1.current.checked = e.target.checked;
        appointmentOption2.current.checked = e.target.unchecked;
        appointmentOption3.current.checked = e.target.unchecked;
        appointmentOption4.current.checked = e.target.unchecked;
        appointmentOption5.current.checked = e.target.unchecked;
        appointmentOption6.current.checked = e.target.unchecked;
        appointmentOption7.current.checked = e.target.unchecked;
        appointmentOption8.current.checked = e.target.unchecked;

        break;
      case e.target.value === "2":
        appointmentOption1.current.checked = e.target.unchecked;
        appointmentOption2.current.checked = e.target.checked;
        appointmentOption3.current.checked = e.target.unchecked;
        appointmentOption4.current.checked = e.target.unchecked;
        appointmentOption5.current.checked = e.target.unchecked;
        appointmentOption6.current.checked = e.target.unchecked;
        appointmentOption7.current.checked = e.target.unchecked;
        appointmentOption8.current.checked = e.target.unchecked;

        break;
      default:
        appointmentOption1.current.checked = e.target.unchecked;
        appointmentOption2.current.checked = e.target.unchecked;
        appointmentOption3.current.checked =
          e.target.value === "3" ? e.target.checked : e.target.unchecked;
        appointmentOption4.current.checked =
          e.target.value === "4" ? e.target.checked : e.target.unchecked;
        appointmentOption5.current.checked =
          e.target.value === "5" ? e.target.checked : e.target.unchecked;
        appointmentOption6.current.checked =
          e.target.value === "6" ? e.target.checked : e.target.unchecked;
        appointmentOption7.current.checked =
          e.target.value === "7" ? e.target.checked : e.target.unchecked;
        appointmentOption8.current.checked =
          e.target.value === "8" ? e.target.checked : e.target.unchecked;
        break;
    }
  };

  const removeImage = (index) => {
    switch (true) {
      case index === 1:
        if (data.image_2) {
          uploadedImage1.current.src = uploadedImage2.current.src;
          uploadedImage2.current.src = data.image_3
            ? uploadedImage3.current.src
            : Upload;
          uploadedImage3.current.src = Upload;

          uploadedImage1.current.file = uploadedImage2.current.file;
          uploadedImage2.current.file = data.image_3
            ? uploadedImage3.current.file
            : null;
          uploadedImage3.current.file = null;
        } else {
          if (uploadedImage1.current) {
            uploadedImage1.current.src = Upload;
            uploadedImage1.current.file = null;
          }

          if (uploadedImage2.current) {
            uploadedImage2.current.src = Upload;
            uploadedImage2.current.file = null;
          }

          if (uploadedImage3.current) {
            uploadedImage3.current.src = Upload;
            uploadedImage3.current.file = null;
          }
        }

        setData({
          ...data,
          image_1: uploadedImage1.current.file ? data.image_2 : null,
          image_2: uploadedImage2.current.file ? data.image_3 : null,
          image_3: null,
          isValidImage: uploadedImage1.current.file ? true : false,
          errorImage: uploadedImage1.current.file
            ? ""
            : "Please upload the problem image.",
        });

        break;
      case index === 2:
        if (data.image_3) {
          uploadedImage2.current.src = data.image_3
            ? uploadedImage3.current.src
            : Upload;
          uploadedImage3.current.src = Upload;

          uploadedImage2.current.file = data.image_3
            ? uploadedImage3.current.file
            : null;
          uploadedImage3.current.file = null;
        } else {
          uploadedImage2.current.src = Upload;
          uploadedImage2.current.file = null;
        }

        setData({
          ...data,
          image_2: uploadedImage2.current.file ? data.image_3 : null,
          image_3: null,
        });

        break;
      default:
        uploadedImage3.current.src = Upload;
        uploadedImage3.current.file = null;

        setData({
          ...data,
          image_3: null,
        });
        break;
    }
  };

  const nameChangeHandle = (val) => {
    if (val.trim().length) {
      setData({ ...data, name: val, isValidName: true, errorName: "" });
    } else {
      setData({
        ...data,
        name: val,
        isValidName: false,
        errorName: "Name can't be empty!",
      });
    }
  };

  const phoneNumberHandle = (val) => {
    if (val.trim().length) {
      let validate = validatePhoneNumber(val);

      if (validate) {
        setData({
          ...data,
          phoneNumber: val,
          isValidPhoneNumber: true,
          errorPhoneNumber: "",
        });
      } else {
        setData({
          ...data,
          phoneNumber: val,
          isValidPhoneNumber: false,
          errorPhoneNumber: "Invalid format!",
        });
      }
    } else {
      setData({
        ...data,
        phoneNumber: val,
        isValidPhoneNumber: false,
        errorPhoneNumber: "Phone number can't be empty!",
      });
    }
  };

  const addressHandle = (val) => {
    if (val.trim().length) {
      setData({
        ...data,
        address: val,
        isValidAddress: true,
        errorAddress: "",
      });
    } else {
      setData({
        ...data,
        address: val,
        isValidAddress: false,
        errorAddress: "Address can't be empty!",
      });
    }
  };

  const changeBuildingHandle = async (val) => {
    let floors = [];
    let rooms = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (val === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              floors.push({
                floor: i.title,
                floor_id: i.floorId,
              });

              return i;
            });
          }

          floors.sort((a, b) => {
            if (a.floor_id.toLowerCase() < b.floor_id.toLowerCase()) {
              return -1;
            }

            if (a.floor_id.toLowerCase() > b.floor_id.toLowerCase()) {
              return 1;
            }

            return 0;
          });

          if (item.floors) {
            item.floors.map((i) => {
              if (i.title === floors[0].floor) {
                if (i.rooms) {
                  i.rooms.map((j) => {
                    if (j.title !== "All") {
                      rooms.push({
                        room: j.title,
                        room_id: j.roomId,
                      });
                    }

                    return j;
                  });
                }
              }

              return i;
            });
          }
        }

        return item;
      });

      rooms.sort((a, b) => {
        if (a.room_id.toLowerCase() < b.room_id.toLowerCase()) {
          return -1;
        }

        if (a.room_id.toLowerCase() > b.room_id.toLowerCase()) {
          return 1;
        }

        return 0;
      });

      let productArr = await products(
        token,
        val,
        floors.length ? floors[0].floor : ""
      );

      let acNames = [];

      productArr.data.productsList.map((item) => {
        if (item.location.room === (rooms.length ? rooms[0].room : "")) {
          acNames.push({
            title: item.title,
            id: item._id,
          });
        }

        return item;
      });

      acNames.sort((a, b) => {
        let x = a.id.toLowerCase();
        let y = b.id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setFloorList(floors);
      setRoomList(rooms);
      setAcNameList(acNames);

      setData({
        ...data,
        building: val,
        isValidBuilding: true,
        errorBuilding: "",
        floor: floors.length ? floors[0].floor : "",
        isValidFloor: true,
        errorFloor: "",
        room: rooms.length ? rooms[0].room : "",
        isValidRoom: true,
        errorRoom: "",
        acName: acNames.length ? acNames[0].title : "",
        isValidAcName: true,
        errorAcName: "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const changeFloorHandle = async (val) => {
    let rooms = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (data.building === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              if (i.title === val) {
                if (i.rooms !== undefined) {
                  i.rooms.map((j) => {
                    if (j.title !== "All") {
                      rooms.push({
                        room: j.title,
                        room_id: j.roomId,
                      });
                    }

                    return i;
                  });
                }
              }

              return i;
            });
          }
        }

        return item;
      });

      rooms.sort((a, b) => {
        if (a.room_id.toLowerCase() < b.room_id.toLowerCase()) {
          return -1;
        }

        if (a.room_id.toLowerCase() > b.room_id.toLowerCase()) {
          return 1;
        }

        return 0;
      });

      let productArr = await products(token, data.building, val);

      let acNames = [];

      productArr.data.productsList.map((item) => {
        if (item.location.room === (rooms.length ? rooms[0].room : "")) {
          acNames.push({
            title: item.title,
            id: item._id,
          });
        }

        return item;
      });

      acNames.sort((a, b) => {
        let x = a.id.toLowerCase();
        let y = b.id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setRoomList(rooms);
      setAcNameList(acNames);
      setData({
        ...data,
        floor: val,
        isValidFloor: true,
        errorFloor: "",
        room: rooms.length ? rooms[0].room : "",
        isValidRoom: true,
        errorRoom: "",
        acName: acNames.length ? acNames[0].title : "",
        isValidAcName: true,
        errorAcName: "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const changeRoomHandle = async (val) => {
    try {
      let token = localStorage.getItem("token");
      let productArr = await products(token, data.building, data.floor);

      let acNames = [];

      productArr.data.productsList.map((item) => {
        if (item.location.room === val) {
          acNames.push({
            title: item.title,
            id: item._id,
          });
        }

        return item;
      });

      acNames.sort((a, b) => {
        let x = a.id.toLowerCase();
        let y = b.id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setAcNameList(acNames);
      setData({
        ...data,
        room: val,
        isValidRoom: true,
        errorRoom: "",
        acName: acNames.length ? acNames[0].title : "",
        isValidAcName: true,
        errorAcName: "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const problemHandle = (val) => {
    if (val.trim().length) {
      setData({
        ...data,
        problem: val,
        isValidProblem: true,
        errorProblem: "",
      });
    } else {
      setData({
        ...data,
        problem: val,
        isValidProblem: true,
        errorProblem: "Problem can't be empty!",
      });
    }
  };

  const handleSubmit = (type) => {
    if (
      data.isValidName &&
      data.isValidPhoneNumber &&
      data.isValidAddress &&
      data.isValidBuilding &&
      data.isValidFloor &&
      data.isValidRoom &&
      data.isValidAcName &&
      data.isValidProblem &&
      data.isValidAppointmentDay &&
      data.appointmentDay &&
      data.isValidImage
    ) {
      setAddForm(false);
      setEditForm(false);
      setViewForm(false);
      setData({
        name: "",
        phoneNumber: "",
        building: "",
        floor: "",
        room: "",
        address: "",
        appointmentDay: "",
        model: "",
        problem: "",
        serialNumber: "",
        acName: "",
        status: "",
        image_1: null,
        image_2: null,
        image_3: null,
        isValidName: false,
        isValidPhoneNumber: false,
        isValidBuilding: false,
        isValidFloor: false,
        isValidRoom: false,
        isValidAddress: false,
        isValidAppointmentDay: false,
        isValidProblem: false,
        isValidAcName: false,
        isValidImage: false,
        errorName: "",
        errorPhoneNumber: "",
        errorBuilding: "",
        errorFloor: "",
        errorRoom: "",
        errorAddress: "",
        errorAppointmentDay: "",
        errorProblem: "",
        errorAcName: "",
        errorImage: "",
      });
    } else {
      setData({
        ...data,
        errorName: data.name === "" ? "Name can't be empty!" : data.errorName,
        errorPhoneNumber:
          data.phoneNumber === ""
            ? "Phone number can't be empty!"
            : data.errorPhoneNumber,
        errorAddress:
          data.address === "" ? "Address can't be empty!" : data.errorAddress,
        errorBuilding:
          data.building === ""
            ? "Building must be select!"
            : data.errorBuilding,
        errorFloor:
          data.floor === "" ? "Floor must be select!" : data.errorFloor,
        errorRoom: data.room === "" ? "Room must be select!" : data.errorRoom,
        errorAcName:
          data.acName === "" ? "AC Name must be select!" : data.errorAcName,
        errorProblem:
          data.problem === "" ? "Problem can't be empty!" : data.errorProblem,
        errorAppointmentDay:
          data.appointmentDay === ""
            ? "Convenient date must be select!"
            : data.errorAppointmentDay,
        errorImage:
          data.image_1 === null
            ? "Please upload the problem image."
            : data.errorImage,
      });
    }
  };

  const editModalHandle = async (
    type,
    name,
    phoneNumber,
    address,
    acName,
    serialNumber,
    model,
    building,
    floor,
    room,
    problem,
    appointmentDay,
    image_1,
    image_2,
    image_3
  ) => {
    if (type === "edit") {
      setEditForm(true);
    } else {
      setViewForm(true);
    }

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      let buildings = [];
      let floors = [];
      let rooms = [];

      // Filler Building
      buildingData.map((item) => {
        if (item.title !== undefined) {
          buildings.push({
            building: item.title,
            building_id: item._id,
          });
        }

        return item;
      });

      buildings.sort((a, b) => {
        if (a.building_id.toLowerCase() < b.building_id.toLowerCase()) {
          return -1;
        }

        if (a.building_id.toLowerCase() > b.building_id.toLowerCase()) {
          return 1;
        }

        return 0;
      });

      // Filler Floor
      buildingData.map((item) => {
        if (building === item.title) {
          if (item.floors !== undefined) {
            item.floors.map((data) => {
              floors.push({
                floor: data.title,
                floor_id: data.floorId,
              });

              return data;
            });
          }
        }

        return item;
      });

      floors.sort((a, b) => {
        if (a.floor_id.toLowerCase() < b.floor_id.toLowerCase()) {
          return -1;
        }

        if (a.floor_id.toLowerCase() > b.floor_id.toLowerCase()) {
          return 1;
        }

        return 0;
      });

      // Filler Room
      buildingData.map((item) => {
        if (building === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              if (floor === i.title) {
                if (i.rooms) {
                  i.rooms.map((data) => {
                    if (data.title !== "All") {
                      rooms.push({
                        room: data.title,
                        room_id: data.roomId,
                      });
                    }

                    return data;
                  });
                }
              }

              return i;
            });
          }
        }

        return item;
      });

      rooms.sort((a, b) => {
        if (a.room_id.toLowerCase() < b.room_id.toLowerCase()) {
          return -1;
        }

        if (a.room_id.toLowerCase() > b.room_id.toLowerCase()) {
          return 1;
        }

        return 0;
      });

      let acNames = [];

      let productArr = await products(token, building, floor);

      productArr.data.productsList.map((item) => {
        if (item.location.room === room) {
          acNames.push({
            title: item.title,
            id: item._id,
          });
        }

        return item;
      });

      acNames.sort((a, b) => {
        let x = a.id.toLowerCase();
        let y = b.id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setBuildingList(buildings);
      setFloorList(floors);
      setRoomList(rooms);
      setAcNameList(acNames);

      setData({
        ...data,
        name: name,
        phoneNumber: phoneNumber,
        building: building,
        floor: floor,
        room: room,
        address: address,
        appointmentDay: appointmentDay,
        model: model,
        problem: problem,
        serialNumber: serialNumber,
        acName: acName,
        image_1: image_1,
        image_2: image_2,
        image_3: image_3,
        isValidName: true,
        isValidPhoneNumber: true,
        isValidBuilding: true,
        isValidFloor: true,
        isValidRoom: true,
        isValidAddress: true,
        isValidAppointmentDay: true,
        isValidProblem: true,
        isValidAcName: true,
        isValidImage: true,
        errorName: "",
        errorPhoneNumber: "",
        errorBuilding: "",
        errorFloor: "",
        errorRoom: "",
        errorAddress: "",
        errorAppointmentDay: "",
        errorProblem: "",
        errorAcName: "",
        errorImage: "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const search = () => {
    var result = maintenanceList.filter((item) => {
      if (
        item.location.building.indexOf(buildingSearch) >= 0 &&
        item.location.floor.indexOf(floorSearch) >= 0 &&
        item.location.room.indexOf(roomSearch) >= 0 &&
        item.acName.indexOf(acNameSearch) >= 0 &&
        item.status.indexOf(status) >= 0
      ) {
        return true;
      } else {
        return false;
      }
    });

    setMaintenanceSearchResult(result);
  };

  const clearSearch = () => {
    setBuildingSearch("");
    setFloorSearch("");
    setRoomSearch("");
    setAcNameSearch("");
    setStatus("");

    setFloorListSearch([]);
    setRoomListSearch([]);
    setAcNameSearch([]);
    setMaintenanceSearchResult(maintenanceList);
  };

  // const checkModel = (oud, model) => {
  //   let val = oud.substr(4, 5);

  //   switch (true) {
  //     case val * 1 >= 10 && val * 1 < 15:
  //       if (model.toUppercase() === "FFU" || model.toUppercase() === "EX") {
  //         return model.toUppercase();
  //       }
  //       return "FFU";
  //     case val * 1 >= 15 && val * 1 < 19:
  //       return "IAQ";
  //     default:
  //       return model.toUppercase();
  //   }
  // };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-4 mt-4 pt-5 pt-xl-0">
        <Row className="mx-0 px-xl-4">
          <Col xs={12}>
            <div className="table-container px-2 px-sm-3 px-xl-4 py-3 py-sm-4">
              <div
                className="table-container-title px-2"
                style={{ justifyContent: "space-between" }}
              >
                <div
                  style={{
                    alignItems: "center",
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  <img src={ToolIcon} alt="tool" className="error-icon" />
                  <h5 className="ms-3 mb-0">Maintenance List </h5>
                </div>
                <button
                  className="add-modal-btn"
                  onClick={() => {
                    setAddForm(true);
                  }}
                >
                  New Request
                </button>
              </div>
              <Row>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-3 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">Building</Form.Label>
                    <Form.Select
                      onChange={(e) => changeBuildingSearch(e.target.value)}
                      value={buildingSearch}
                    >
                      <option value="">Select Building</option>
                      {buildingListSearch.map((item, index) => (
                        <option value={item.building} key={index}>
                          {item.building}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-1 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">Floor</Form.Label>
                    <Form.Select
                      onChange={(e) => changeFloorSearch(e.target.value)}
                      value={floorSearch}
                    >
                      <option value="">Select Floor</option>
                      {floorListSearch.map((item, index) => (
                        <option value={item.floor} key={index}>
                          {item.floor}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-1 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">Room Name</Form.Label>
                    <Form.Select
                      onChange={(e) => changeRoomSearch(e.target.value)}
                      value={roomSearch}
                    >
                      <option value="">Select Room</option>
                      {roomListSearch.map((item, index) => (
                        <option value={item.room} key={index}>
                          {item.room}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-1 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">AC Name</Form.Label>
                    <Form.Select
                      onChange={(e) => setAcNameSearch(e.target.value)}
                      value={acNameSearch}
                    >
                      <option value="">Select AC Name</option>
                      {acNameListSearch.map((item, index) => (
                        <option value={item.title} key={index}>
                          {item.title}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-1 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">Status</Form.Label>
                    <Form.Select
                    // onChange={(e) => setRoomSearch(e.target.value)}
                    // value={status}
                    >
                      <option value="">Select Status</option>
                      {statusList.map((item, index) => (
                        <option value={item.title} key={index}>
                          {item.title}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={"auto"} className="pe-0 ms-2 ms-sm-0 mt-2 mt-sm-0">
                  <Form.Group className="mt-4 mb-2 px-2 d-none d-sm-block">
                    <Form.Label className="input-label mb-0">&nbsp;</Form.Label>
                  </Form.Group>
                  <button className="search-btn" onClick={() => search()}>
                    Search
                  </button>
                </Col>
                <Col xs={"auto"} className="mt-2 mt-sm-0">
                  <Form.Group className="mt-4 mb-2 px-2 d-none d-sm-block">
                    <Form.Label className="input-label mb-0">&nbsp;</Form.Label>
                  </Form.Group>
                  <button className="clear-btn" onClick={() => clearSearch()}>
                    Clear
                  </button>
                </Col>
              </Row>
              <div className="mx-2 mb-2 table-overflow">
                <Table hover className="mt-3 mb-2 setting-table align-middle">
                  <thead>
                    <tr>
                      <th className="px-4">No.</th>
                      <th className="text-center px-4">AC Name</th>
                      <th className="text-center px-4">Serial Number</th>
                      <th className="text-center px-4">Model</th>
                      <th className="text-center px-4">Location</th>
                      <th className="text-center px-4">Problem</th>
                      <th className="text-center px-4">
                        Appointment Date - Time
                      </th>
                      <th className="text-center px-4">Status</th>
                      <th className="text-center px-4">Request Date</th>
                      <th className="text-center px-4">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {maintenanceSearchResult.length ? (
                      currentPosts.map((item, index) => (
                        <tr key={index}>
                          <td className="px-4">
                            {index + 1 + (currentPage - 1) * PerPage}.
                          </td>
                          <td className="px-4 text-center">{item.acName}</td>
                          <td className="px-4 text-center">
                            {item.serialNumber}
                          </td>
                          <td className="px-4 text-center">
                            {/* {checkModel(item.odu, item.model)} */}
                            {item.model}
                          </td>
                          <td className="px-4 text-center">
                            {item.location.building} {item.location.floor},{" "}
                            {item.location.room}
                          </td>
                          <td className="px-4 text-center">{item.problem}</td>
                          <td className="px-4 text-center">
                            {item.appointment
                              ? moment(item.appointment).format(
                                  "DD/MM/YYYY - HH:mm"
                                )
                              : "-"}
                          </td>
                          <td className="px-4 text-center">{item.status}</td>
                          <td className="px-4 text-center">
                            {moment(item.createDate).format(
                              "DD/MM/YYYY - HH:mm"
                            )}
                          </td>
                          {item.status === "รอดำเนินการติดต่อกลับ" ? (
                            <td className="px-4 text-center">
                              <span
                                className="table-edit"
                                onClick={() => {
                                  editModalHandle(
                                    "edit",
                                    item.name,
                                    item.phoneNumber,
                                    item.address,
                                    item.acName,
                                    item.serialNumber,
                                    // checkModel(item.odu, item.model),
                                    item.model,
                                    item.location.building,
                                    item.location.floor,
                                    item.location.room,
                                    item.problem,
                                    item.appointmentDay,
                                    item.image_1,
                                    item.image_2,
                                    item.image_3
                                  );
                                }}
                              >
                                Edit
                              </span>{" "}
                              /{" "}
                              <span
                                className="table-delete"
                                onClick={() => setDeleteModal(true)}
                              >
                                Cancel
                              </span>
                            </td>
                          ) : (
                            <td className="px-4 text-center">
                              <span
                                className="table-edit"
                                onClick={() => {
                                  editModalHandle(
                                    "view",
                                    item.name,
                                    item.phoneNumber,
                                    item.address,
                                    item.acName,
                                    item.serialNumber,
                                    // checkModel(item.odu, item.model),
                                    item.model,
                                    item.location.building,
                                    item.location.floor,
                                    item.location.room,
                                    item.problem,
                                    item.appointmentDay,
                                    item.image_1,
                                    item.image_2,
                                    item.image_3
                                  );
                                }}
                              >
                                View
                              </span>
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={10} className="text-center">
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <Pagination
                postsPerPage={PerPage}
                totalPosts={maintenanceSearchResult.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        show={loaded}
        backdrop="static"
        keyboard="false"
        centered
        id="loading-modal"
      >
        <div className="loader"></div>
      </Modal>
      <Modal
        size="lg"
        show={addForm}
        onHide={() => {
          setAddForm(false);
          setData({
            name: "",
            phoneNumber: "",
            building: "",
            floor: "",
            room: "",
            address: "",
            appointmentDay: "",
            model: "",
            problem: "",
            serialNumber: "",
            acName: "",
            image_1: null,
            image_2: null,
            image_3: null,
            isValidName: false,
            isValidPhoneNumber: false,
            isValidBuilding: false,
            isValidFloor: false,
            isValidRoom: false,
            isValidAddress: false,
            isValidAppointmentDay: false,
            isValidProblem: false,
            isValidAcName: false,
            isValidImage: false,
            errorName: "",
            errorPhoneNumber: "",
            errorBuilding: "",
            errorFloor: "",
            errorRoom: "",
            errorAddress: "",
            errorAppointmentDay: "",
            errorProblem: "",
            errorAcName: "",
            errorImage: "",
          });
          setFloorList([]);
          setRoomList([]);
          setAcNameList([]);
        }}
        centered
      >
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <h4 className="modal-title mb-2">Request Form</h4>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  onChange={(e) => nameChangeHandle(e.target.value)}
                  style={{
                    borderColor: !data.errorAcName.length
                      ? "#cccccc"
                      : "#b31117",
                  }}
                  defaultValue={data.name}
                />
                {!data.errorAcName.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorName}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone Number"
                  onChange={(e) => phoneNumberHandle(e.target.value)}
                  style={{
                    borderColor: !data.errorPhoneNumber.length
                      ? "#cccccc"
                      : "#b31117",
                  }}
                />
                {!data.errorPhoneNumber.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorPhoneNumber}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Address</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  placeholder="Address"
                  onChange={(e) => addressHandle(e.target.value)}
                  style={{
                    borderColor: !data.errorAddress.length
                      ? "#cccccc"
                      : "#b31117",
                  }}
                  defaultValue={data.address}
                />
                {!data.errorAddress.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorAddress}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Building</Form.Label>
                <Form.Select
                  onChange={(e) => changeBuildingHandle(e.target.value)}
                  value={data.building}
                  style={{
                    borderColor: !data.errorBuilding.length
                      ? "#cccccc"
                      : "#b31117",
                  }}
                >
                  <option value="">Select Building</option>
                  {buildingList.map((item, index) => (
                    <option value={item.building} key={index}>
                      {item.building}
                    </option>
                  ))}
                </Form.Select>
                {!data.errorBuilding.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorBuilding}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Floor</Form.Label>
                <Form.Select
                  onChange={(e) => changeFloorHandle(e.target.value)}
                  value={data.floor}
                  style={{
                    borderColor: !data.errorFloor.length
                      ? "#cccccc"
                      : "#b31117",
                  }}
                >
                  <option value="">Select Floor</option>
                  {floorList.map((item, index) => (
                    <option value={item.floor} key={index}>
                      {item.floor}
                    </option>
                  ))}
                </Form.Select>
                {!data.errorFloor.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorFloor}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Room</Form.Label>
                <Form.Select
                  onChange={(e) => changeRoomHandle(e.target.value)}
                  value={data.room}
                  style={{
                    borderColor: !data.errorRoom.length ? "#cccccc" : "#b31117",
                  }}
                >
                  <option value="">Select Room</option>
                  {roomList.map((item, index) => (
                    <option value={item.room} key={index}>
                      {item.room}
                    </option>
                  ))}
                </Form.Select>
                {!data.errorRoom.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorRoom}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">AC Name</Form.Label>
                <Form.Select
                  onChange={(e) =>
                    setData({
                      ...data,
                      acName: e.target.value,
                      isValidAcName: true,
                      errorAcName: "",
                    })
                  }
                  value={data.acName}
                  style={{
                    borderColor: !data.errorAcName.length
                      ? "#cccccc"
                      : "#b31117",
                  }}
                >
                  <option value="">Select AC Name</option>
                  {acNameList.map((item, index) => (
                    <option value={item.title} key={index}>
                      {item.title}
                    </option>
                  ))}
                </Form.Select>
                {!data.errorAcName.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorAcName}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Problem</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  placeholder="Problem"
                  onChange={(e) => problemHandle(e.target.value)}
                  style={{
                    borderColor: !data.errorProblem.length
                      ? "#cccccc"
                      : "#b31117",
                  }}
                />
                {!data.errorProblem.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorProblem}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">
                  Convenient date for service
                </Form.Label>
                <div>
                  <Form.Check
                    inline
                    label="Monday-Friday"
                    name="appointment day"
                    onClick={(e) => appointmentDayHandle(e)}
                    value={"1"}
                    ref={appointmentOption1}
                  />
                  <Form.Check
                    inline
                    label="Monday-Saturday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"2"}
                    ref={appointmentOption2}
                  />
                  <Form.Check
                    inline
                    label="Monday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"3"}
                    ref={appointmentOption3}
                  />
                  <Form.Check
                    inline
                    label="Tuesday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"4"}
                    ref={appointmentOption4}
                  />
                  <Form.Check
                    inline
                    label="Wednesday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"5"}
                    ref={appointmentOption5}
                  />
                  <Form.Check
                    inline
                    label="Thursday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"6"}
                    ref={appointmentOption6}
                  />
                  <Form.Check
                    inline
                    label="Friday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"7"}
                    ref={appointmentOption7}
                  />
                  <Form.Check
                    inline
                    label="Saturday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"8"}
                    ref={appointmentOption8}
                  />
                </div>
                {!data.errorAppointmentDay.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorAppointmentDay}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Problem Image</Form.Label>
                <Row>
                  <Col xs={12} lg={4} style={{ position: "relative" }}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload1}
                      ref={imageUploader1}
                      style={{
                        display: "none",
                      }}
                    />
                    <div
                      className="form-image-container mb-2"
                      onClick={() => imageUploader1.current.click()}
                    >
                      <img
                        ref={uploadedImage1}
                        src={data.image_1 ? uploadedImage1.current.src : Upload}
                        className="form-image-input"
                        style={{ maxWidth: "100%", height: "auto" }}
                        alt="problem"
                      />
                    </div>
                    {data.image_1 && (
                      <img
                        src={CloseIcon}
                        className="remove-image"
                        alt="close"
                        onClick={() => removeImage(1)}
                      />
                    )}
                  </Col>
                  {data.image_1 && (
                    <Col xs={12} lg={4} style={{ position: "relative" }}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload2}
                        ref={imageUploader2}
                        style={{
                          display: "none",
                        }}
                      />
                      <div
                        className="form-image-container mb-2"
                        onClick={() => imageUploader2.current.click()}
                      >
                        <img
                          ref={uploadedImage2}
                          src={
                            data.image_2 ? uploadedImage2.current.src : Upload
                          }
                          className="form-image-input"
                          style={{ maxWidth: "100%", height: "auto" }}
                          alt="problem"
                        />
                      </div>
                      {data.image_2 && (
                        <img
                          src={CloseIcon}
                          className="remove-image"
                          alt="close"
                          onClick={() => removeImage(2)}
                        />
                      )}
                    </Col>
                  )}
                  {data.image_2 && (
                    <Col xs={12} lg={4} style={{ position: "relative" }}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload3}
                        ref={imageUploader3}
                        style={{
                          display: "none",
                        }}
                      />
                      <div
                        className="form-image-container mb-2"
                        onClick={() => imageUploader3.current.click()}
                      >
                        <img
                          ref={uploadedImage3}
                          src={
                            data.image_3 ? uploadedImage3.current.src : Upload
                          }
                          className="form-image-input"
                          style={{ maxWidth: "100%", height: "auto" }}
                          alt="problem"
                        />
                      </div>
                      {data.image_3 && (
                        <img
                          src={CloseIcon}
                          className="remove-image"
                          alt="close"
                          onClick={() => removeImage(3)}
                        />
                      )}
                    </Col>
                  )}
                  {!data.errorImage.length ? null : (
                    <Col xs={12}>
                      <Form.Label
                        className="input-label mt-1"
                        style={{ color: "#b31117" }}
                      >
                        {data.errorImage}
                      </Form.Label>
                    </Col>
                  )}
                </Row>
              </Form.Group>
              <div className="modal-btn-container mb-2">
                <Button
                  type="button"
                  onClick={() => {
                    setAddForm(false);
                    setData({
                      name: "",
                      phoneNumber: "",
                      building: "",
                      floor: "",
                      room: "",
                      address: "",
                      appointmentDay: "",
                      model: "",
                      problem: "",
                      serialNumber: "",
                      acName: "",
                      image_1: null,
                      image_2: null,
                      image_3: null,
                      isValidName: false,
                      isValidPhoneNumber: false,
                      isValidBuilding: false,
                      isValidFloor: false,
                      isValidRoom: false,
                      isValidAddress: false,
                      isValidAppointmentDay: false,
                      isValidProblem: false,
                      isValidAcName: false,
                      isValidImage: false,
                      errorName: "",
                      errorPhoneNumber: "",
                      errorBuilding: "",
                      errorFloor: "",
                      errorRoom: "",
                      errorAddress: "",
                      errorAppointmentDay: "",
                      errorProblem: "",
                      errorAcName: "",
                      errorImage: "",
                    });
                  }}
                >
                  Cancel
                </Button>
                <Button type="button" onClick={() => handleSubmit("add")}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={editForm}
        onHide={() => {
          setEditForm(false);
          setData({
            name: "",
            phoneNumber: "",
            building: "",
            floor: "",
            room: "",
            address: "",
            appointmentDay: "",
            model: "",
            problem: "",
            serialNumber: "",
            acName: "",
            image_1: null,
            image_2: null,
            image_3: null,
            isValidName: false,
            isValidPhoneNumber: false,
            isValidBuilding: false,
            isValidFloor: false,
            isValidRoom: false,
            isValidAddress: false,
            isValidAppointmentDay: false,
            isValidProblem: false,
            isValidAcName: false,
            isValidImage: false,
            errorName: "",
            errorPhoneNumber: "",
            errorBuilding: "",
            errorFloor: "",
            errorRoom: "",
            errorAddress: "",
            errorAppointmentDay: "",
            errorProblem: "",
            errorAcName: "",
            errorImage: "",
          });
        }}
        centered
      >
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <h4 className="modal-title mb-2">Request Form</h4>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  onChange={(e) => nameChangeHandle(e.target.value)}
                  style={{
                    borderColor: !data.errorName.length ? "#cccccc" : "#b31117",
                  }}
                  defaultValue={data.name}
                />
                {!data.errorAcName.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorName}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone Number"
                  onChange={(e) => phoneNumberHandle(e.target.value)}
                  style={{
                    borderColor: !data.errorPhoneNumber.length
                      ? "#cccccc"
                      : "#b31117",
                  }}
                  defaultValue={data.phoneNumber}
                />
                {!data.errorPhoneNumber.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorPhoneNumber}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Address</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  placeholder="Address"
                  onChange={(e) => addressHandle(e.target.value)}
                  style={{
                    borderColor: !data.errorAddress.length
                      ? "#cccccc"
                      : "#b31117",
                  }}
                  defaultValue={data.address}
                />
                {!data.errorAddress.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorAddress}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Building</Form.Label>
                <Form.Select
                  onChange={(e) => changeBuildingHandle(e.target.value)}
                  value={data.building}
                  style={{
                    borderColor: !data.errorBuilding.length
                      ? "#cccccc"
                      : "#b31117",
                  }}
                >
                  <option value="">Select Building</option>
                  {buildingList.map((item, index) => (
                    <option value={item.building} key={index}>
                      {item.building}
                    </option>
                  ))}
                </Form.Select>
                {!data.errorBuilding.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorBuilding}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Floor</Form.Label>
                <Form.Select
                  onChange={(e) => changeFloorHandle(e.target.value)}
                  value={data.floor}
                  style={{
                    borderColor: !data.errorFloor.length
                      ? "#cccccc"
                      : "#b31117",
                  }}
                >
                  <option value="">Select Floor</option>
                  {floorList.map((item, index) => (
                    <option value={item.floor} key={index}>
                      {item.floor}
                    </option>
                  ))}
                </Form.Select>
                {!data.errorFloor.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorFloor}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Room</Form.Label>
                <Form.Select
                  onChange={(e) => changeRoomHandle(e.target.value)}
                  value={data.room}
                  style={{
                    borderColor: !data.errorRoom.length ? "#cccccc" : "#b31117",
                  }}
                >
                  <option value="">Select Room</option>
                  {roomList.map((item, index) => (
                    <option value={item.room} key={index}>
                      {item.room}
                    </option>
                  ))}
                </Form.Select>
                {!data.errorRoom.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorRoom}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">AC Name</Form.Label>
                <Form.Select
                  onChange={(e) =>
                    setData({
                      ...data,
                      acName: e.target.value,
                      isValidAcName: true,
                      errorAcName: "",
                    })
                  }
                  value={data.acName}
                  style={{
                    borderColor: !data.errorAcName.length
                      ? "#cccccc"
                      : "#b31117",
                  }}
                >
                  <option value="">Select AC Name</option>
                  {acNameList.map((item, index) => (
                    <option value={item.title} key={index}>
                      {item.title}
                    </option>
                  ))}
                </Form.Select>
                {!data.errorAcName.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorAcName}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Problem</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  placeholder="Problem"
                  onChange={(e) => problemHandle(e.target.value)}
                  style={{
                    borderColor: !data.errorProblem.length
                      ? "#cccccc"
                      : "#b31117",
                  }}
                  defaultValue={data.problem}
                />
                {!data.errorProblem.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorProblem}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">
                  Convenient date for service
                </Form.Label>
                <div>
                  <Form.Check
                    inline
                    label="Monday-Friday"
                    name="appointment day"
                    onClick={(e) => appointmentDayHandle(e)}
                    value={"1"}
                    ref={appointmentOption1}
                  />
                  <Form.Check
                    inline
                    label="Monday-Saturday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"2"}
                    ref={appointmentOption2}
                  />
                  <Form.Check
                    inline
                    label="Monday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"3"}
                    ref={appointmentOption3}
                  />
                  <Form.Check
                    inline
                    label="Tuesday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"4"}
                    ref={appointmentOption4}
                  />
                  <Form.Check
                    inline
                    label="Wednesday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"5"}
                    ref={appointmentOption5}
                  />
                  <Form.Check
                    inline
                    label="Thursday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"6"}
                    ref={appointmentOption6}
                  />
                  <Form.Check
                    inline
                    label="Friday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"7"}
                    ref={appointmentOption7}
                  />
                  <Form.Check
                    inline
                    label="Saturday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"8"}
                    ref={appointmentOption8}
                  />
                </div>
                {!data.errorAppointmentDay.length ? null : (
                  <Form.Label
                    className="input-label mt-1"
                    style={{ color: "#b31117" }}
                  >
                    {data.errorAppointmentDay}
                  </Form.Label>
                )}
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Problem Image</Form.Label>
                <Row>
                  <Col xs={12} lg={4} style={{ position: "relative" }}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload1}
                      ref={imageUploader1}
                      style={{
                        display: "none",
                      }}
                    />
                    <div
                      className="form-image-container mb-2"
                      onClick={() => imageUploader1.current.click()}
                    >
                      <img
                        ref={uploadedImage1}
                        src={data.image_1 ? uploadedImage1.current.src : Upload}
                        className="form-image-input"
                        style={{ maxWidth: "100%", height: "auto" }}
                        alt="problem"
                      />
                    </div>
                    {data.image_1 && (
                      <img
                        src={CloseIcon}
                        className="remove-image"
                        alt="close"
                        onClick={() => removeImage(1)}
                      />
                    )}
                  </Col>
                  {data.image_1 && (
                    <Col xs={12} lg={4} style={{ position: "relative" }}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload2}
                        ref={imageUploader2}
                        style={{
                          display: "none",
                        }}
                      />
                      <div
                        className="form-image-container mb-2"
                        onClick={() => imageUploader2.current.click()}
                      >
                        <img
                          ref={uploadedImage2}
                          src={
                            data.image_2 ? uploadedImage2.current.src : Upload
                          }
                          className="form-image-input"
                          style={{ maxWidth: "100%", height: "auto" }}
                          alt="problem"
                        />
                      </div>
                      {data.image_2 && (
                        <img
                          src={CloseIcon}
                          className="remove-image"
                          alt="close"
                          onClick={() => removeImage(2)}
                        />
                      )}
                    </Col>
                  )}
                  {data.image_2 && (
                    <Col xs={12} lg={4} style={{ position: "relative" }}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload3}
                        ref={imageUploader3}
                        style={{
                          display: "none",
                        }}
                      />
                      <div
                        className="form-image-container mb-2"
                        onClick={() => imageUploader3.current.click()}
                      >
                        <img
                          ref={uploadedImage3}
                          src={
                            data.image_3 ? uploadedImage3.current.src : Upload
                          }
                          className="form-image-input"
                          style={{ maxWidth: "100%", height: "auto" }}
                          alt="problem"
                        />
                      </div>
                      {data.image_3 && (
                        <img
                          src={CloseIcon}
                          className="remove-image"
                          alt="close"
                          onClick={() => removeImage(3)}
                        />
                      )}
                    </Col>
                  )}
                  {!data.errorImage.length ? null : (
                    <Col xs={12}>
                      <Form.Label
                        className="input-label mt-1"
                        style={{ color: "#b31117" }}
                      >
                        {data.errorImage}
                      </Form.Label>
                    </Col>
                  )}
                </Row>
              </Form.Group>
              <div className="modal-btn-container mb-2">
                <Button
                  type="button"
                  onClick={() => {
                    setEditForm(false);
                    setData({
                      name: "",
                      phoneNumber: "",
                      building: "",
                      floor: "",
                      room: "",
                      address: "",
                      appointmentDay: "",
                      model: "",
                      problem: "",
                      serialNumber: "",
                      acName: "",
                      image_1: null,
                      image_2: null,
                      image_3: null,
                      isValidName: false,
                      isValidPhoneNumber: false,
                      isValidBuilding: false,
                      isValidFloor: false,
                      isValidRoom: false,
                      isValidAddress: false,
                      isValidAppointmentDay: false,
                      isValidProblem: false,
                      isValidAcName: false,
                      isValidImage: false,
                      errorName: "",
                      errorPhoneNumber: "",
                      errorBuilding: "",
                      errorFloor: "",
                      errorRoom: "",
                      errorAddress: "",
                      errorAppointmentDay: "",
                      errorProblem: "",
                      errorAcName: "",
                      errorImage: "",
                    });
                  }}
                >
                  Cancel
                </Button>
                <Button type="button" onClick={() => handleSubmit("add")}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={viewForm}
        onHide={() => {
          setViewForm(false);
          setData({
            name: "",
            phoneNumber: "",
            building: "",
            floor: "",
            room: "",
            address: "",
            appointmentDay: "",
            model: "",
            problem: "",
            serialNumber: "",
            acName: "",
            image_1: null,
            image_2: null,
            image_3: null,
            isValidName: false,
            isValidPhoneNumber: false,
            isValidBuilding: false,
            isValidFloor: false,
            isValidRoom: false,
            isValidAddress: false,
            isValidAppointmentDay: false,
            isValidProblem: false,
            isValidAcName: false,
            isValidImage: false,
            errorName: "",
            errorPhoneNumber: "",
            errorBuilding: "",
            errorFloor: "",
            errorRoom: "",
            errorAddress: "",
            errorAppointmentDay: "",
            errorProblem: "",
            errorAcName: "",
            errorImage: "",
          });
        }}
        centered
      >
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <h4 className="modal-title mb-2">Request Form</h4>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  onChange={(e) => nameChangeHandle(e.target.value)}
                  style={{
                    borderColor: "#cccccc",
                  }}
                  defaultValue={data.name}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone Number"
                  onChange={(e) => phoneNumberHandle(e.target.value)}
                  style={{
                    borderColor: "#cccccc",
                  }}
                  defaultValue={data.phoneNumber}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Address</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  placeholder="Address"
                  onChange={(e) => addressHandle(e.target.value)}
                  style={{
                    borderColor: "#cccccc",
                  }}
                  defaultValue={data.address}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Building</Form.Label>
                <Form.Select
                  onChange={(e) => changeBuildingHandle(e.target.value)}
                  value={data.building}
                  style={{
                    borderColor: "#cccccc",
                  }}
                  disabled
                >
                  <option value="">Select Building</option>
                  {buildingList.map((item, index) => (
                    <option value={item.building} key={index}>
                      {item.building}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Floor</Form.Label>
                <Form.Select
                  onChange={(e) => changeFloorHandle(e.target.value)}
                  value={data.floor}
                  style={{
                    borderColor: "#cccccc",
                  }}
                  disabled
                >
                  <option value="">Select Floor</option>
                  {floorList.map((item, index) => (
                    <option value={item.floor} key={index}>
                      {item.floor}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Room</Form.Label>
                <Form.Select
                  onChange={(e) => changeRoomHandle(e.target.value)}
                  value={data.room}
                  style={{
                    borderColor: "#cccccc",
                  }}
                  disabled
                >
                  <option value="">Select Room</option>
                  {roomList.map((item, index) => (
                    <option value={item.room} key={index}>
                      {item.room}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">AC Name</Form.Label>
                <Form.Select
                  onChange={(e) =>
                    setData({
                      ...data,
                      acName: e.target.value,
                      isValidAcName: true,
                      errorAcName: "",
                    })
                  }
                  value={data.acName}
                  style={{
                    borderColor: "#cccccc",
                  }}
                  disabled
                >
                  <option value="">Select AC Name</option>
                  {acNameList.map((item, index) => (
                    <option value={item.title} key={index}>
                      {item.title}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Problem</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  placeholder="Problem"
                  onChange={(e) => problemHandle(e.target.value)}
                  style={{
                    borderColor: "#cccccc",
                  }}
                  defaultValue={data.problem}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">
                  Convenient date for service
                </Form.Label>
                <div>
                  <Form.Check
                    inline
                    label="Monday-Friday"
                    name="appointment day"
                    onClick={(e) => appointmentDayHandle(e)}
                    value={"1"}
                    disabled
                  />
                  <Form.Check
                    inline
                    label="Monday-Saturday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"2"}
                    disabled
                  />
                  <Form.Check
                    inline
                    label="Monday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"3"}
                    disabled
                  />
                  <Form.Check
                    inline
                    label="Tuesday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"4"}
                    ref={appointmentOption4}
                    disabled
                  />
                  <Form.Check
                    inline
                    label="Wednesday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"5"}
                    disabled
                  />
                  <Form.Check
                    inline
                    label="Thursday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"6"}
                    disabled
                  />
                  <Form.Check
                    inline
                    label="Friday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"7"}
                    disabled
                  />
                  <Form.Check
                    inline
                    label="Saturday"
                    name="appointment day"
                    onChange={(e) => appointmentDayHandle(e)}
                    value={"8"}
                    disabled
                  />
                </div>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-4 px-2">
                <Form.Label className="input-label">Problem Image</Form.Label>
                <Row>
                  <Col xs={12} lg={4} style={{ position: "relative" }}>
                    <input
                      type="file"
                      accept="image/*"
                      style={{
                        display: "none",
                      }}
                      disabled
                    />
                    <div className="form-image-container mb-2">
                      <img
                        ref={uploadedImage1}
                        src={data.image_1 ? uploadedImage1.current.src : Upload}
                        className="form-image-input"
                        style={{ maxWidth: "100%", height: "auto" }}
                        alt="problem"
                      />
                    </div>
                  </Col>
                  {data.image_2 && (
                    <Col xs={12} lg={4} style={{ position: "relative" }}>
                      <input
                        type="file"
                        accept="image/*"
                        style={{
                          display: "none",
                        }}
                        disabled
                      />
                      <div className="form-image-container mb-2">
                        <img
                          ref={uploadedImage2}
                          src={
                            data.image_2 ? uploadedImage2.current.src : Upload
                          }
                          className="form-image-input"
                          style={{ maxWidth: "100%", height: "auto" }}
                          alt="problem"
                        />
                      </div>
                    </Col>
                  )}
                  {data.image_3 && (
                    <Col xs={12} lg={4} style={{ position: "relative" }}>
                      <input
                        type="file"
                        accept="image/*"
                        style={{
                          display: "none",
                        }}
                        disabled
                      />
                      <div className="form-image-container mb-2">
                        <img
                          ref={uploadedImage3}
                          src={
                            data.image_3 ? uploadedImage3.current.src : Upload
                          }
                          className="form-image-input"
                          style={{ maxWidth: "100%", height: "auto" }}
                          alt="problem"
                        />
                      </div>
                    </Col>
                  )}
                </Row>
              </Form.Group>
              <div className="modal-btn-container mb-2">
                <Button
                  type="button"
                  onClick={() => {
                    setViewForm(false);
                    setData({
                      name: "",
                      phoneNumber: "",
                      building: "",
                      floor: "",
                      room: "",
                      address: "",
                      appointmentDay: "",
                      model: "",
                      problem: "",
                      serialNumber: "",
                      acName: "",
                      image_1: null,
                      image_2: null,
                      image_3: null,
                      isValidName: false,
                      isValidPhoneNumber: false,
                      isValidBuilding: false,
                      isValidFloor: false,
                      isValidRoom: false,
                      isValidAddress: false,
                      isValidAppointmentDay: false,
                      isValidProblem: false,
                      isValidAcName: false,
                      isValidImage: false,
                      errorName: "",
                      errorPhoneNumber: "",
                      errorBuilding: "",
                      errorFloor: "",
                      errorRoom: "",
                      errorAddress: "",
                      errorAppointmentDay: "",
                      errorProblem: "",
                      errorAcName: "",
                      errorImage: "",
                    });
                  }}
                >
                  Cancel
                </Button>
                <Button type="button" onClick={() => handleSubmit("add")}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal show={deleteModal} centered>
        <Modal.Body>
          <h4 className="modal-title mb-4">
            Do you want cancel this request ?
          </h4>
          <div className="modal-btn-container mb-2">
            <Button
              type="button"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Maintenance;
