import React, { useState, useEffect } from "react";
import { Row, Col, Table, Modal, Form, Button } from "react-bootstrap";

import Header from "../components/header";
import Nav from "../components/nav";
import Pagination from "../components/pagination";
import { building_list } from "../api/product";
import { add_building, update_building, delete_building } from "../api/setting";

const SettingBuilding = (props) => {
  const [buildingList, setBuildingList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage] = useState(10);
  const [addForm, setAddForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [building, setBuilding] = useState("");
  const [buildingID, setBuildingID] = useState("");
  const [buildingSearch, setBuildingSearch] = useState("");
  const [buildingListSearchResult, setBuildingListSearchResult] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [buildingValidate, setBuildingValidate] = useState(true);
  const [buildingListSearch, setBuildingListSearch] = useState([]);

  const indexOfLastPost = currentPage * PerPage;
  const indexOfFirstPost = indexOfLastPost - PerPage;
  const currentPosts = buildingListSearchResult.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    (async () => {
      try {
        let token = localStorage.getItem("token");
        let companyId = localStorage.getItem("company_id");

        let res = await building_list(token);

        let buildingData = res.data.data.filter((item) => {
          return item.company_id === companyId;
        });

        setBuildingList(buildingData);
        setBuildingListSearchResult(buildingData);
        setBuildingListSearch(buildingData);
        setLoaded(false);
      } catch (error) {
        console.log(error);
        setLoaded(false);
      }
    })();
  }, [loaded]);

  const handleSubmit = async (type) => {
    if (!building) {
      setBuildingValidate(false);
      return;
    }

    try {
      let token = localStorage.getItem("token");

      if (type === "add") {
        await add_building(token, building);
        alert("Building added successfully.");

        setAddForm(false);
        setLoaded(true);
      } else {
        await update_building(token, buildingID, building);
        alert("Building updated successfully.");

        setEditForm(false);
        setLoaded(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const search = () => {
    var result = buildingList.filter((item) => {
      if (item._id.toLowerCase().indexOf(buildingSearch.toLowerCase()) >= 0) {
        return true;
      } else {
        return false;
      }
    });

    setBuildingListSearchResult(result);
  };

  const clearSearch = () => {
    setBuildingSearch("");
    setBuildingListSearchResult(buildingList);
  };

  const deleteHandle = async () => {
    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      await delete_building(token, buildingID);
      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      setBuildingList(buildingData);
      setBuildingListSearchResult(buildingData);

      setDeleteModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const buildingChangeHandle = (val) => {
    if (val.trim().length) {
      setBuilding(val);
      setBuildingValidate(true);
    } else {
      setBuilding(val);
      setBuildingValidate(false);
    }
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-4 mt-4 pt-5 pt-xl-0">
        <Row className="mx-0 px-xl-4">
          <Col xs={12}>
            <div className="table-container px-2 px-sm-3 px-xl-4 py-3 py-sm-4">
              <div
                className="table-container-title px-2"
                style={{ justifyContent: "space-between" }}
              >
                <h5 className="mb-1">Building Management</h5>
                <button
                  className="add-modal-btn"
                  onClick={() => {
                    setBuilding("");
                    setAddForm(true);
                  }}
                >
                  Add Building
                </button>
              </div>
              <Row>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-3 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">
                      Building Name
                    </Form.Label>
                    {/* <Form.Control
                      type="text"
                      placeholder="Building"
                      required
                      onChange={(e) => setBuildingSearch(e.target.value)}
                      value={buildingSearch}
                    /> */}
                    <Form.Select
                      onChange={(e) => setBuildingSearch(e.target.value)}
                      required
                      value={buildingSearch}
                    >
                      <option value="">Select Building</option>
                      {buildingListSearch.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.title}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={"auto"} className="pe-0 ms-2 ms-sm-0 mt-2 mt-sm-0">
                  <Form.Group className="mt-4 mb-2 px-2 d-none d-sm-block">
                    <Form.Label className="input-label mb-0">&nbsp;</Form.Label>
                  </Form.Group>
                  <button className="search-btn" onClick={() => search()}>
                    Search
                  </button>
                </Col>
                <Col xs={"auto"} className="mt-2 mt-sm-0">
                  <Form.Group className="mt-4 mb-2 px-2 d-none d-sm-block">
                    <Form.Label className="input-label mb-0">&nbsp;</Form.Label>
                  </Form.Group>
                  <button className="clear-btn" onClick={() => clearSearch()}>
                    Clear
                  </button>
                </Col>
              </Row>
              <div className="mx-2 table-overflow">
                <Table hover className="mt-3 mb-2 setting-table align-middle">
                  <thead>
                    <tr>
                      <th className="px-4">No.</th>
                      {/* <th className="text-center px-4">Image</th> */}
                      <th className="text-center px-4">Building Name</th>
                      <th className="text-center px-4">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {buildingListSearchResult.length ? (
                      currentPosts.map((item, index) => (
                        <tr key={index}>
                          <td className="px-4">
                            {currentPage === 1
                              ? index + 1
                              : index + 1 + (currentPage - 1) * PerPage}
                            .
                          </td>
                          <td className="px-4 text-center" width={"40%"}>
                            {item.title}
                          </td>
                          <td className="px-4 text-center">
                            <span
                              className="table-edit"
                              onClick={() => {
                                setBuilding(item.title);
                                setEditForm(true);
                                setBuildingID(item._id);
                              }}
                            >
                              Edit
                            </span>{" "}
                            /{" "}
                            <span
                              className="table-delete"
                              onClick={() => {
                                setBuildingID(item._id);
                                setDeleteModal(true);
                              }}
                            >
                              Delete
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4} className="text-center">
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <Pagination
                postsPerPage={PerPage}
                totalPosts={buildingListSearchResult.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Modal show={editForm} onHide={() => setEditForm(false)} centered>
        <Modal.Body>
          <h4 className="modal-title mb-2">Edit Building</h4>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Building Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Building"
              required
              onChange={(e) => buildingChangeHandle(e.target.value)}
              style={{
                borderColor: buildingValidate ? "#cccccc" : "#b31117",
              }}
              defaultValue={building}
            />
            {!buildingValidate && (
              <Form.Label
                className="input-label mt-1"
                style={{ color: "#b31117" }}
              >
                Please provide a valid building name.
              </Form.Label>
            )}
          </Form.Group>
          <div className="modal-btn-container mb-2">
            <Button
              type="button"
              onClick={() => {
                setEditForm(false);
                setBuildingValidate(true);
              }}
            >
              Cancel
            </Button>
            <Button type="button" onClick={() => handleSubmit("edit")}>
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={addForm} onHide={() => setAddForm(false)} centered>
        <Modal.Body>
          <h4 className="modal-title mb-2">Add Building</h4>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Building Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Building"
              required
              onChange={(e) => buildingChangeHandle(e.target.value)}
              style={{
                borderColor: buildingValidate ? "#cccccc" : "#b31117",
              }}
            />
            {!buildingValidate && (
              <Form.Label
                className="input-label mt-1"
                style={{ color: "#b31117" }}
              >
                Please provide a valid building name.
              </Form.Label>
            )}
          </Form.Group>
          <div className="modal-btn-container mb-2">
            <Button
              type="button"
              onClick={() => {
                setAddForm(false);
                setBuildingValidate(true);
              }}
            >
              Cancel
            </Button>
            <Button type="button" onClick={() => handleSubmit("add")}>
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} centered>
        <Modal.Body>
          <h4 className="modal-title mb-4">
            Do you want delete this building ?
          </h4>
          <div className="modal-btn-container mb-2">
            <Button
              type="button"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={() => {
                deleteHandle();
              }}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={loaded}
        backdrop="static"
        keyboard="false"
        centered
        id="loading-modal"
      >
        <div className="loader"></div>
      </Modal>
    </div>
  );
};

export default SettingBuilding;
