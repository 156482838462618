import axios from "axios";
import { SERV_API } from "../constant_config";

const users = async (token) => {
  return await axios({
    url: SERV_API + "users",
    method: "GET",
    // timeout: 5000,
    headers: {
      Accept: "application/json",
      "x-access-token": token,
    },
  });
};

const company_info = async (token) => {
  return await axios({
    url: SERV_API + "users/company",
    method: "GET",
    // timeout: 5000,
    headers: {
      Accept: "application/json",
      "x-access-token": token,
    },
  });
};

const profile = async (token) => {
  return await axios({
    url: SERV_API + "users/profile",
    method: "GET",
    // timeout: 5000,
    headers: {
      Accept: "application/json",
      "x-access-token": token,
    },
  });
};

export { users, company_info, profile };
