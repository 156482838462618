import React, { useEffect, useState } from "react";
import { Row, Col, Form, Modal } from "react-bootstrap";

import Header from "../components/header";
import Nav from "../components/nav";

import DoubleSkin from "../assets/image/dashboard/double_skin.png";
import UTF from "../assets/image/dashboard/ultrafine.png";
import PE from "../assets/image/dashboard/pe.png";
import FFU from "../assets/image/dashboard/fan_filter_inverter.png";
import IAQ from "../assets/image/dashboard/iaq.png";
import Cassette from "../assets/image/dashboard/cassette.png";
import Duct from "../assets/image/dashboard/duct.png";
import BuildingIcon from "../assets/image/control/building-icon.png";
import FloorIcon from "../assets/image/control/floor-icon.png";

import { products, building_list } from "../api/product";

const Product = (props) => {
  const [productList, setProductList] = useState([]);
  const [building, setBuilding] = useState("");
  const [floor, setFloor] = useState("");
  const [buildingList, setBuildingList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [open, setOpen] = useState(false);
  const [buildingFilter, setBuildingFilter] = useState("");
  const [floorFilter, setFloorFilter] = useState("");
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        let token = localStorage.getItem("token");
        let companyId = localStorage.getItem("company_id");

        let res = await building_list(token);

        let buildingData = res.data.data.filter((item) => {
          return item.company_id === companyId;
        });

        let buildings = [];
        let floors = [];

        // Filler Building
        buildingData.map((item) => {
          buildings.push({
            building: item.title,
            building_id: item._id,
          });

          return item;
        });

        buildings.sort((a, b) => {
          let x = a.building_id.toLowerCase();
          let y = b.building_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        // Filler Floor
        buildingData.map((item) => {
          if (buildingData[0].title === item.title) {
            if (item.floors) {
              item.floors.map((data) => {
                floors.push({
                  floor: data.title,
                  floor_id: data.floorId,
                });

                return data;
              });
            }
          }

          return item;
        });

        floors.sort((a, b) => {
          let x = a.floor_id.toLowerCase();
          let y = b.floor_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        setBuildingList(buildings);
        setFloorList(floors);
        setBuilding(buildings.length ? buildings[0].building : "");
        setFloor(floors.length ? floors[0].floor : "");
        setBuildingFilter(buildings.length ? buildings[0].building : "");
        setFloorFilter(floors.length ? floors[0].floor : "");

        let product = await products(
          token,
          buildings.length ? buildings[0].building : "",
          floors.length ? floors[0].floor : ""
        );

        setProductList(product.data.productsList);
        setLoaded(false);
      } catch (error) {
        console.log(error);
        setLoaded(false);
      }
    })();
  }, []);

  const productImage = (val) => {
    switch (true) {
      case val === "FRESHAIR" || "MONOBLOCK" || "AHU":
        return DoubleSkin;
      case val === "PE":
        return PE;
      case val === "FFU" || "EX":
        return FFU;
      case val === "IAQ":
        return IAQ;
      case val === "CASSETTE":
        return Cassette;
      case val === "DUCT":
        return Duct;
      default:
        return UTF;
    }
  };

  const changeBuilding = async (val) => {
    setBuildingFilter(val);

    let floors = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (val === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              floors.push({
                floor: i.title,
                floor_id: i.floorId,
              });

              return i;
            });
          }
        }

        return item;
      });

      floors.sort((a, b) => {
        let x = a.floor_id.toLowerCase();
        let y = b.floor_id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setFloorList(floors);
      setFloorFilter(floors.length ? floors[0].floor : "");
    } catch (error) {
      console.log(error);
    }
  };

  const changeFloor = (val) => {
    setFloorFilter(val);
  };

  const filterProductList = async () => {
    setBuilding(buildingFilter);
    setFloor(floorFilter);
    setOpen(false);
    setLoaded(true);

    try {
      let token = localStorage.getItem("token");
      let res = await products(token, buildingFilter, floorFilter);

      setProductList(res.data.productsList);
      setLoaded(false);
    } catch (error) {
      console.log(error);
      setLoaded(false);
    }
  };

  const checkModel = (odu, model) => {
    // let val = odu.substr(4, 5);

    // switch (true) {
    //   case val * 1 >= 10 && val * 1 < 15:
    //     if (model.toUpperCase() === "FFU" || model.toUpperCase() === "EX") {
    //       return model.toUpperCase();
    //     }
    //     return "FFU";
    //   case val * 1 >= 15 && val * 1 < 19:
    //     return "IAQ";
    //   default:
    //     return model.toUpperCase();
    // }

    return model.toUpperCase();
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-4 pt-4 pt-xl-0">
        <Row className="mx-0 px-xl-4">
          <Col xs={"auto"} className="position-relative w-100">
            <h3 className="dashboard-header mb-0">
              {building} {floor}
            </h3>
            <div
              className="control-select-dropdown"
              onClick={() => setOpen(!open)}
              style={{
                top: "53%",
                right: 0,
                transform: "translate(-50%, -50%)",
              }}
            ></div>
            <div
              className="control-select-container p-2"
              style={{
                visibility: open ? "visible" : "hidden",
                opacity: open ? 1 : 0,
                top: "60px",
              }}
            >
              <div className="control-select">
                <img src={BuildingIcon} alt="building" className="pe-2" />
                <p className="mb-0 pe-2">Building</p>
                <Form.Select
                  size="sm"
                  onChange={(e) => changeBuilding(e.target.value)}
                  value={buildingFilter}
                >
                  {buildingList.map((item, index) => (
                    <option value={item.building} key={index}>
                      {item.building}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className="control-select">
                <img src={FloorIcon} alt="floor" className="pe-2" />
                <p className="mb-0 pe-2">Floor</p>
                <Form.Select
                  size="sm"
                  onChange={(e) => changeFloor(e.target.value)}
                  value={floorFilter}
                >
                  {floorList.map((item, index) => (
                    <option value={item.floor} key={index}>
                      {item.floor}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <button
                className="control-search-btn my-2 me-2"
                onClick={() => filterProductList()}
              >
                Confirm
              </button>
            </div>
          </Col>
        </Row>
        <Row className="mx-0 px-xl-4">
          <Col className="">
            {productList.length
              ? productList.map((item, index) => (
                  <Row className="product-container mx-0 mb-2" key={index}>
                    <Col
                      xs={4}
                      sm={2}
                      className="dashboard-image-container product"
                    >
                      <img
                        src={productImage(checkModel(item.odu, item.model))}
                        alt={checkModel(item.odu, item.model)}
                      />
                    </Col>
                    <Col
                      xs={8}
                      sm={10}
                      className="dashboard-product-name-container product px-0"
                    >
                      <div className="dashboard-name-label px-3 px-sm-4 py-1 mt-2 mt-sm-3 mt-xl-4">
                        {item.title ? item.title : "-"}
                      </div>
                      <p className="dashboard-model px-3 px-sm-4 mt-1 mb-0 mb-xl-1">
                        {checkModel(item.odu, item.model)}
                      </p>
                      <p className="dashboard-serial px-3 px-sm-4 mb-0 mb-xl-1">
                        {item.gateway_sn ? item.gateway_sn : ""}
                      </p>
                      <p className="dashboard-serial px-3 px-sm-4">
                        Location:{" "}
                        {item.location.building ? item.location.building : ""}{" "}
                        {item.location.floor ? item.location.floor : ""}{" "}
                        {item.location.room ? item.location.room : ""}
                      </p>
                    </Col>
                    <Col xs={2} className="dashboard-other-container d-none">
                      <div className="product-date-container">
                        <span className="product-date-label">
                          วันที่ติดตั้ง
                        </span>
                        <span className="product-date">DD / MM / YY</span>
                      </div>
                    </Col>
                    <Col xs={2} className="dashboard-other-container d-none">
                      <div className="product-date-container">
                        <span className="product-date-label">
                          วันหมดอายุรับประกัน
                        </span>
                        <span className="product-date">DD / MM / YY</span>
                      </div>
                    </Col>
                    <Col className="dashboard-other-container d-none">
                      <div className="warranty-container">
                        <div className="warranty-add-btn">+</div>
                      </div>
                    </Col>
                  </Row>
                ))
              : null}
          </Col>
        </Row>
      </div>
      <Modal
        show={loaded}
        backdrop="static"
        keyboard="false"
        centered
        id="loading-modal"
      >
        <div className="loader"></div>
      </Modal>
    </div>
  );
};

export default Product;
