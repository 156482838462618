import React, { useState, useEffect, useContext, useMemo } from "react";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import Header from "../components/header";
import Nav from "../components/nav";
import { MqttContext } from "../components/mqtt_context";

import Temp from "../assets/image/control/temp.png";
import Humidity from "../assets/image/control/humidity.png";
import Cool from "../assets/image/control/cool.png";
import Fan from "../assets/image/control/fan.png";
import Dry from "../assets/image/control/dry.png";
import Heat from "../assets/image/control/heat.png";
import Auto from "../assets/image/control/auto.png";
import Manual from "../assets/image/control/manual.png";
import AutoProtective from "../assets/image/control/auto_protective.png";
import FanLow from "../assets/image/control/fan_low.png";
import FanMid from "../assets/image/control/fan_mid.png";
import FanHigh from "../assets/image/control/fan_high.png";
import FanHiHi from "../assets/image/control/fan_hi_hi.png";
import FanTurbo from "../assets/image/control/fan_turbo.png";
import FanAuto from "../assets/image/control/fan_auto.png";
import Swing_1 from "../assets/image/control/swing_1.png";
import Swing_2 from "../assets/image/control/swing_2.png";
import Swing_3 from "../assets/image/control/swing_3.png";
import Swing_4 from "../assets/image/control/swing_4.png";
import Swing_5 from "../assets/image/control/swing_5.png";
import SwingAuto from "../assets/image/control/swing_auto.png";
import AutoFunction from "../assets/image/control/function_auto.png";
import AutoProtection from "../assets/image/control/function_protection.png";
import OzoneFilter from "../assets/image/control/function_ozone.png";
import Power from "../assets/image/control/power.png";
import Plan from "../assets/image/control/plan.png";
import Icon from "../assets/image/control/icon.png";
import Info from "../assets/image/control/info.png";
import Ex from "../assets/image/control/Ex.gif";
import ExPng from "../assets/image/control/Ex.png";
import Air from "../assets/image/control/Air.gif";
import AirPng from "../assets/image/control/Air.png";
import CoolAC from "../assets/image/control/CoolAC.gif";
import CoolACPng from "../assets/image/control/CoolAC.png";
import Duct from "../assets/image/control/Duct.gif";
import DuctPng from "../assets/image/control/Duct.png";
import Cassette from "../assets/image/control/Cassette.gif";
import CassettePng from "../assets/image/control/Cassette.png";
import FreshAir from "../assets/image/control/Fresh-Air.gif";
import FreshAirPng from "../assets/image/control/Fresh-Air.png";
import BuildingIcon from "../assets/image/control/building-icon.png";
import FloorIcon from "../assets/image/control/floor-icon.png";
import RoomIcon from "../assets/image/control/room-icon.png";
import { products, building_list } from "../api/product";

const ControlPanel = ({
  deviceList,
  building,
  floor,
  room,
  uid,
  title,
  serialNumber,
  id,
  viewModeHandle,
  viewMode,
}) => {
  const [acData, setAcData] = useState({
    ac_options: '{"power":true,"temp":true,"iaq":true}',
    acinfo: [],
    cdu_no: "",
    cid: "",
    enable: false,
    gateway_sn: "",
    location: {
      building: "",
      floor: "",
      room: "",
    },
    model: "",
    odu: "",
    position: '{"x":"0","y":"0"}',
    serail_no: "",
    tag: "[]",
    title: "",
    type: "",
    uid: "",
    _id: "",
  });

  const { pubCommand } = useContext(MqttContext);

  useMemo(() => {
    let acDataArr = [];

    deviceList.map((item) => {
      if (
        item.location.building === building &&
        item.location.floor === floor &&
        (room === "All" ? true : item.location.room === room) &&
        uid === item.cid &&
        title === item.title
      ) {
        acDataArr.push(item);
      }

      return item;
    });

    let acInfoObject = {
      ac_options: '{"power":true,"temp":true,"iaq":true}',
      acinfo: [],
      cdu_no: "",
      cid: "",
      enable: false,
      gateway_sn: "",
      location: {
        building: "",
        floor: "",
        room: "",
      },
      model: "",
      odu: "",
      position: '{"x":"0","y":"0"}',
      serail_no: "",
      tag: "[]",
      title: "",
      type: "",
      uid: "",
      _id: "",
    };

    acDataArr.map((item) => {
      acInfoObject = {
        ac_options: item.ac_options,
        acinfo: item.acinfo,
        cdu_no: item.cdu_no,
        cid: item.cid,
        enable: item.enable,
        gateway_sn: item.gateway_sn,
        location: {
          building: item.location.building,
          floor: item.location.floor,
          room: item.location.room,
        },
        model: item.model,
        odu: item.odu,
        position: item.position,
        serail_no: item.serail_no,
        tag: item.tag,
        title: item.title,
        type: item.type,
        uid: item.uid,
        _id: item._id,
      };

      return item;
    });

    setAcData(acInfoObject);
  }, [deviceList, building, floor, room, title, uid]);

  const checkModel = (oud, model) => {
    // let val = oud.substr(4, 5);

    // switch (true) {
    //   case val * 1 >= 10 && val * 1 < 15:
    //     if (model.toUpperCase() === "FFU" || model.toUpperCase() === "EX") {
    //       return model.toUpperCase();
    //     }
    //     return "FFU";
    //   case val * 1 >= 15 && val * 1 < 19:
    //     return "IAQ";
    //   default:
    //     return model.toUpperCase();
    // }

    return model.toUpperCase();
  };

  const tempHandle = (val, tempVal, enable) => {
    if (enable) {
      if (val === "inc") {
        pubCommand(
          uid,
          serialNumber,
          "temp",
          tempVal >= 30 ? 30 : tempVal + 0.5,
          id
        );
      } else {
        pubCommand(
          uid,
          serialNumber,
          "temp",
          tempVal <= 15 ? 15 : tempVal - 0.5,
          id
        );
      }
    }
  };

  const humidityHandle = (val, humidityVal, enable) => {
    if (enable) {
      if (val === "inc") {
        pubCommand(
          uid,
          serialNumber,
          "setHumidity",
          humidityVal >= 100 ? 100 : humidityVal + 1,
          id
        );
      } else {
        pubCommand(
          uid,
          serialNumber,
          "setHumidity",
          humidityVal <= 0 ? 0 : humidityVal - 1,
          id
        );
      }
    }
  };

  const enableService = (type, feature) => {
    switch (true) {
      case type === "PE":
        switch (true) {
          case feature === "power":
            return true;
          case feature === "temp":
            return false;
          case feature === "humidity":
            return false;
          case feature === "mode":
            return false;
          case feature === "mode_pe":
            return true;
          case feature === "fan_speed":
            return false;
          case feature === "swing":
            return false;
          case feature === "function":
            return true;
          default:
            return false;
        }
      case type === "IAQ":
        return false;
      case type === "FFU":
        switch (true) {
          case feature === "power":
            return true;
          case feature === "temp":
            return false;
          case feature === "humidity":
            return false;
          case feature === "mode":
            return false;
          case feature === "mode_pe":
            return true;
          case feature === "fan_speed":
            return false;
          case feature === "swing":
            return false;
          case feature === "function":
            return true;
          default:
            return false;
        }
      case type === "FRESHAIR" || "MONOBLOCK" || "AHU":
        switch (true) {
          case feature === "power":
            return true;
          case feature === "temp":
            return true;
          case feature === "humidity":
            return true;
          case feature === "mode":
            return false;
          case feature === "mode_pe":
            return false;
          case feature === "fan_speed":
            return true;
          case feature === "swing":
            return false;
          case feature === "function":
            return false;
          default:
            return false;
        }
      default:
        switch (true) {
          case feature === "power":
            return true;
          case feature === "temp":
            return true;
          case feature === "humidity":
            return false;
          case feature === "mode":
            return true;
          case feature === "mode_pe":
            return false;
          case feature === "fan_speed":
            return true;
          case feature === "swing":
            return true;
          case feature === "function":
            return false;
          default:
            return false;
        }
    }
  };

  const modePeConvert = (mode) => {
    let mode_bit = parseInt(mode).toString(2).padStart(8, "0");
    let modePe = mode_bit.split("");

    switch (true) {
      case modePe[2] === "0" && modePe[3] === "1":
        return "16";
      case modePe[0] === "0" && modePe[1] === "1":
        return "32";
      default:
        return "0";
    }
  };

  const powerConvert = (power) => {
    let power_bit = parseInt(power).toString(2).padStart(8, "0");
    let powerSplit = power_bit.split("");

    return powerSplit[7].toString();
  };

  return (
    <Row className="mx-0 ps-0 ps-xl-4 control-container">
      <Col xs={3} className="control-left-colum pe-0">
        <div
          className={
            checkModel(acData.odu, acData.model) === "IAQ"
              ? acData.acinfo[0]
                ? parseFloat(acData.acinfo[0].istst.RT2) >= 30
                  ? "control-room-temp-container hot mb-2"
                  : "control-room-temp-container cool mb-2"
                : "control-room-temp-container cool mb-2"
              : acData.acinfo[0]
              ? parseFloat(acData.acinfo[0].istst.rt) >= 30
                ? "control-room-temp-container hot mb-2"
                : "control-room-temp-container cool mb-2"
              : "control-room-temp-container cool mb-2"
          }
        >
          <p className="room-temp-label mb-2">Room Temp : {acData.title}</p>
          <div className="monitor-container">
            <div className="monitor-value">
              <img src={Temp} alt="temp" />
              <p className="mb-0">
                {checkModel(acData.odu, acData.model) === "IAQ"
                  ? acData.acinfo[0]
                    ? acData.acinfo[0].istst.RT2 * 1 >= 63.75
                      ? "Error"
                      : acData.acinfo[0].istst.RT2 * 1
                    : 0
                  : acData.acinfo[0]
                  ? acData.acinfo[0].istst.rt * 1 >= 63.75
                    ? "Error"
                    : acData.acinfo[0].istst.rt * 1
                  : 0}
                <span> °C</span>
              </p>
            </div>
            <div className="monitor-value">
              <img src={Humidity} alt="humidity" className="pe-2" />
              <p className="mb-0">
                {checkModel(acData.odu, acData.model) === "IAQ"
                  ? acData.acinfo[0]
                    ? acData.acinfo[0].istst.RH2 * 1 > 100
                      ? "Error"
                      : acData.acinfo[0].istst.RH2 * 1
                    : 0
                  : acData.acinfo[0]
                  ? acData.acinfo[0].istst.RH * 1 > 100
                    ? "Error"
                    : acData.acinfo[0].istst.RH * 1
                  : 0}
                <span> %</span>
              </p>
            </div>
          </div>
        </div>
        <Row className="mx-0 h-100 mb-2">
          <Col className="ps-0 pe-1">
            <div className="control-room-control-container mb-2">
              <div className="control-container-panel">
                <p
                  className={
                    enableService(
                      checkModel(acData.odu, acData.model),
                      "temp"
                    ) && acData.acinfo.online
                      ? "control-label mb-0 mx-0 text-center"
                      : "control-label mb-0 mx-0 text-center disabled"
                  }
                >
                  SET TEMP
                </p>
                <div
                  className={
                    enableService(
                      checkModel(acData.odu, acData.model),
                      "temp"
                    ) && acData.acinfo.online
                      ? "temp-panel control"
                      : "temp-panel control disabled"
                  }
                >
                  <div
                    className="arrow-up mb-2"
                    onClick={() => {
                      tempHandle(
                        "inc",
                        acData.acinfo[0] ? acData.acinfo[0].istst.st : 0,
                        enableService(
                          checkModel(acData.odu, acData.model),
                          "temp"
                        ) && acData.acinfo.online
                      );
                    }}
                  ></div>
                  {acData.acinfo[0] ? acData.acinfo[0].istst.st * 1 : 15}
                  <br />
                  <span>°C</span>
                  <div
                    className="arrow-down mt-2"
                    onClick={() =>
                      tempHandle(
                        "dec",
                        acData.acinfo[0] ? acData.acinfo[0].istst.st : 15,
                        enableService(
                          checkModel(acData.odu, acData.model),
                          "temp"
                        ) && acData.acinfo.online
                      )
                    }
                  ></div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="pe-0 ps-1">
            <div className="control-room-control-container mb-2">
              <div className="control-container-panel">
                <p
                  className={
                    enableService(
                      checkModel(acData.odu, acData.model),
                      "humidity"
                    ) && acData.acinfo.online
                      ? "control-label mb-0 mx-0 text-center"
                      : "control-label mb-0 mx-0 text-center disabled"
                  }
                >
                  HUMIDITY
                </p>
                <div
                  className={
                    enableService(
                      checkModel(acData.odu, acData.model),
                      "humidity"
                    ) && acData.acinfo.online
                      ? "temp-panel control"
                      : "temp-panel control disabled"
                  }
                >
                  <div
                    className="arrow-up mb-2"
                    onClick={() =>
                      humidityHandle(
                        "inc",
                        acData.acinfo[0]
                          ? parseInt(acData.acinfo[0].istst.SetRH)
                          : 0,
                        enableService(
                          checkModel(acData.odu, acData.model),
                          "humidity"
                        ) && acData.acinfo.online
                      )
                    }
                  ></div>
                  {acData.acinfo[0]
                    ? acData.acinfo[0].istst.SetRH * 1 > 100
                      ? "Error"
                      : acData.acinfo[0].istst.SetRH * 1
                    : 0}
                  <br />
                  <span>%</span>
                  <div
                    className="arrow-down mt-2"
                    onClick={() =>
                      humidityHandle(
                        "dec",
                        acData.acinfo[0] && acData.acinfo.online
                          ? parseInt(acData.acinfo[0].istst.SetRH)
                          : 0,
                        enableService(
                          checkModel(acData.odu, acData.model),
                          "humidity"
                        ) && acData.acinfo.online
                      )
                    }
                  ></div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={5} className="control-center-colum pe-0 ps-2">
        <div className="control-room-control-container mb-2">
          <p
            className={
              (enableService(checkModel(acData.odu, acData.model), "mode") ||
                enableService(
                  checkModel(acData.odu, acData.model),
                  "mode_pe"
                )) &&
              acData.acinfo.online
                ? "control-label mb-1"
                : "control-label mb-1 disabled"
            }
          >
            MODE
          </p>
          <Row className="mx-0">
            <Col xs={8} className="pe-1">
              <div className="control-mode-container">
                <button
                  className={
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.mode === "0"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "mode", "cool", id)
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "mode"
                        )
                      : true
                  }
                >
                  <img src={Cool} alt="cool" className="mb-2" />
                  Cool
                </button>
                <button
                  className={
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.mode === "4"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "mode"
                        )
                      : true
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "mode", "fan", id)
                  }
                >
                  <img src={Fan} alt="fan" className="mb-2" />
                  Fan Only
                </button>
                <button
                  className={
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.mode === "1"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "mode", "dry", id)
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "mode"
                        )
                      : true
                  }
                >
                  <img src={Dry} alt="dry" className="mb-2" />
                  Dry
                </button>
                <button
                  className={
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.mode === "3"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "mode", "heat", id)
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "mode"
                        )
                      : true
                  }
                >
                  <img src={Heat} alt="heat" className="mb-2" />
                  Heat
                </button>
                <button
                  className={
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.mode === "2"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "mode", "auto", id)
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "mode"
                        )
                      : true
                  }
                >
                  <img src={Auto} alt="auto" className="mb-2" />
                  &nbsp;
                </button>
                <div className="control-right-border" />
              </div>
            </Col>
            <Col className="ps-1">
              <div className="control-mode-container">
                <button
                  className={
                    acData.acinfo[0]
                      ? modePeConvert(acData.acinfo[0].istst.mode) === "16"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "modePe", "16", id)
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "mode_pe"
                        )
                      : true
                  }
                >
                  <img src={Manual} alt="manual" className="mb-2" />
                  Manual
                </button>
                <button
                  className={
                    acData.acinfo[0]
                      ? modePeConvert(acData.acinfo[0].istst.mode) === "32"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "modePe", "32", id)
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "mode_pe"
                        )
                      : true
                  }
                >
                  <img
                    src={AutoProtective}
                    alt="auto-protective"
                    className="mb-2"
                  />
                  Auto Protective
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="control-room-control-container mb-2">
          <p
            className={
              enableService(
                checkModel(acData.odu, acData.model),
                "fan_speed"
              ) && acData.acinfo.online
                ? "control-label mb-0"
                : "control-label mb-0 disabled"
            }
          >
            FAN SPEED
          </p>
          <Row className="mx-0">
            <Col>
              <div className="control-mode-container">
                <button
                  className={
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.fspeed === "1"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "fanspeed", "l", id)
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "fan_speed"
                        )
                      : true
                  }
                >
                  <img src={FanLow} alt="fan-low" />
                </button>
                <button
                  className={
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.fspeed === "2"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "fanspeed", "m", id)
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "fan_speed"
                        )
                      : true
                  }
                >
                  <img src={FanMid} alt="fan-mid" />
                </button>
                <button
                  className={
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.fspeed === "3"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "fanspeed", "h", id)
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "fan_speed"
                        )
                      : true
                  }
                >
                  <img src={FanHigh} alt="fan-high" />
                </button>
                <button
                  className={
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.fspeed === "4"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "fanspeed", "t", id)
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "fan_speed"
                        )
                      : true
                  }
                >
                  <img src={FanHiHi} alt="fan-hi-hi" />
                </button>
                <button
                  className={
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.fspeed === "5"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "fanspeed", "s", id)
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "fan_speed"
                        )
                      : true
                  }
                >
                  <img src={FanTurbo} alt="fan-turbo" />
                </button>
                <button
                  className={
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.fspeed === "0"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "fanspeed", "a", id)
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "fan_speed"
                        )
                      : true
                  }
                >
                  <img src={FanAuto} alt="fan-auto" />
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="control-room-control-container mb-2">
          <p
            className={
              enableService(checkModel(acData.odu, acData.model), "swing") &&
              acData.acinfo.online
                ? "control-label mb-0"
                : "control-label mb-0 disabled"
            }
          >
            SWING
          </p>
          <Row className="mx-0 pt-2">
            <Col>
              <div className="control-mode-container">
                <button
                  className={
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.louver === "1"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "swing", "h", id)
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "swing"
                        )
                      : true
                  }
                >
                  <img src={Swing_1} alt="swing-h" />
                </button>
                <button
                  className={
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.louver === "3"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "swing", "3", id)
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "swing"
                        )
                      : true
                  }
                >
                  <img src={Swing_2} alt="swing-3" />
                </button>
                <button
                  className={
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.louver === "4"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "swing", "4", id)
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "swing"
                        )
                      : true
                  }
                >
                  <img src={Swing_3} alt="swing-4" />
                </button>
                <button
                  className={
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.louver === "5"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "swing", "6", id)
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "swing"
                        )
                      : true
                  }
                >
                  <img src={Swing_4} alt="swing-6" />
                </button>
                <button
                  className={
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.louver === "2"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "swing", "v", id)
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "swing"
                        )
                      : true
                  }
                >
                  <img src={Swing_5} alt="swing-v" />
                </button>
                <button
                  className={
                    acData.acinfo[0]
                      ? acData.acinfo[0].istst.louver === "0"
                        ? "mode-container active"
                        : "mode-container"
                      : "mode-container"
                  }
                  onClick={() =>
                    pubCommand(uid, serialNumber, "swing", "a", id)
                  }
                  disabled={
                    acData.acinfo.online
                      ? !enableService(
                          checkModel(acData.odu, acData.model),
                          "swing"
                        )
                      : true
                  }
                >
                  <img src={SwingAuto} alt="swing-auto" />
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
      <Col className="control-right-colum pe-0 ps-2">
        <div className="control-room-control-container mb-2">
          <p
            className={
              enableService(checkModel(acData.odu, acData.model), "function") &&
              acData.acinfo.online
                ? "control-label my-2 text-center mx-0"
                : "control-label my-2 text-center mx-0 disabled"
            }
          >
            FUNCTION
          </p>
          <div className="control-vertical-container">
            <button
              className={
                acData.acinfo[0]
                  ? acData.acinfo[0].istst.uPtRem === "1"
                    ? "mode-container active"
                    : "mode-container"
                  : "mode-container"
              }
              onClick={() =>
                pubCommand(
                  uid,
                  serialNumber,
                  "uPtRem",
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.uPtRem === "1"
                      ? "0"
                      : "1"
                    : "0",
                  id
                )
              }
              disabled={
                acData.acinfo.online
                  ? !enableService(
                      checkModel(acData.odu, acData.model),
                      "function"
                    )
                  : true
              }
            >
              <img src={AutoFunction} alt="auto-function" className="mb-2" />
              Auto Function
            </button>
            <button
              className={
                acData.acinfo[0]
                  ? acData.acinfo[0].istst.function === "auto-protective"
                    ? "mode-container active"
                    : "mode-container"
                  : "mode-container"
              }
              onClick={() =>
                pubCommand(uid, serialNumber, "function", "auto-protective", id)
              }
              disabled={
                acData.acinfo.online
                  ? !enableService(
                      checkModel(acData.odu, acData.model),
                      "function"
                    )
                  : true
              }
            >
              <img
                src={AutoProtection}
                alt="auto-protective"
                className="mb-2"
              />
              Auto Protection 24 hrs
            </button>
            <button
              className={
                acData.acinfo[0]
                  ? acData.acinfo[0].istst.TrbAPS === "1"
                    ? "mode-container active"
                    : "mode-container"
                  : "mode-container"
              }
              onClick={() =>
                pubCommand(
                  uid,
                  serialNumber,
                  "trbAPS",
                  acData.acinfo[0]
                    ? acData.acinfo[0].istst.TrbAPS === "1"
                      ? "0"
                      : "1"
                    : "0",
                  id
                )
              }
              disabled={
                acData.acinfo.online
                  ? !enableService(
                      checkModel(acData.odu, acData.model),
                      "function"
                    )
                  : true
              }
            >
              <img src={OzoneFilter} alt="ozone-filter" className="mb-2" />
              Ozone Filter Disinfection
            </button>
          </div>
        </div>
      </Col>
      <Col className="control-right-colum pe-0 ps-2">
        <div className="control-room-control-container mb-2">
          <p
            className={
              enableService(checkModel(acData.odu, acData.model), "power") &&
              acData.acinfo.online
                ? "control-label my-2 text-center mx-0"
                : "control-label my-2 text-center mx-0 disabled"
            }
          >
            POWER
          </p>
          <div className="control-vertical-container">
            <button
              className={
                acData.acinfo[0]
                  ? powerConvert(acData.acinfo[0].istst.onoff) === "1"
                    ? "mode-container active"
                    : "mode-container"
                  : "mode-container"
              }
              onClick={() =>
                pubCommand(
                  uid,
                  serialNumber,
                  "power",
                  acData.acinfo[0]
                    ? powerConvert(acData.acinfo[0].istst.onoff) === "1"
                      ? "off"
                      : "on"
                    : "off",
                  id
                )
              }
              disabled={
                acData.acinfo.online
                  ? !enableService(
                      checkModel(acData.odu, acData.model),
                      "power"
                    )
                  : true
              }
            >
              <img src={Power} alt="power" className="mb-3 power-control-btn" />
              <span className="power-status">
                {acData.acinfo[0]
                  ? powerConvert(acData.acinfo[0].istst.onoff) === "1"
                    ? "ON"
                    : "OFF"
                  : "OFF"}
              </span>
            </button>
          </div>
        </div>
      </Col>
      <Col className="control-right-colum pe-0 ps-2">
        <div className="plan-view-container mb-2">
          <div
            className={
              viewMode === "plan"
                ? "plan-view-btn-container active"
                : "plan-view-btn-container"
            }
            onClick={() => viewModeHandle("plan")}
          >
            <div className="plan-view-btn mb-1">
              <img src={Plan} alt="plan" />
            </div>
            Plan
          </div>
          <div
            className={
              viewMode === "icon"
                ? "plan-view-btn-container active"
                : "plan-view-btn-container"
            }
            onClick={() => viewModeHandle("icon")}
          >
            <div className="plan-view-btn mb-1">
              <img src={Icon} alt="icon" />
            </div>
            Icon
          </div>
          <div
            className={
              viewMode === "info"
                ? "plan-view-btn-container active"
                : "plan-view-btn-container"
            }
            onClick={() => viewModeHandle("info")}
          >
            <div className="plan-view-btn mb-1">
              <img src={Info} alt="info" />
            </div>
            Info
          </div>
        </div>
      </Col>
    </Row>
  );
};

const ControlPlan = ({
  deviceList,
  building,
  floor,
  room,
  showIcon,
  showInfo,
  showIndex,
  setShowIndex,
  showIndexClick,
  setShowIndexClick,
  setUid,
  setID,
  setSerialNumber,
  setTitle,
}) => {
  const [acList, setAcList] = useState([]);

  useMemo(() => {
    let acDataArr = [];

    deviceList.map((item) => {
      if (
        item.location.building.toString() === building &&
        item.location.floor.toString() === floor &&
        (room === "All" ? true : item.location.room === room)
      ) {
        acDataArr.push(item);
      }

      return item;
    });

    setAcList(acDataArr);
  }, [deviceList, building, floor, room]);

  const acIcon = (val, ac_power) => {
    switch (true) {
      case val === "PE":
        return ac_power === "1" ? CoolAC : CoolACPng;
      case val === "FFU" || val === "EX":
        return ac_power === "1" ? Ex : ExPng;
      case val === "IAQ":
        return ac_power === "1" ? FreshAir : FreshAirPng;
      case val === "FRESHAIR" || val === "MONOBLOCK" || val === "AHU":
        return ac_power === "1" ? FreshAir : FreshAirPng;
      case val === "DUCT":
        return ac_power === "1" ? Duct : DuctPng;
      case val === "CASSETTE":
        return ac_power === "1" ? Cassette : CassettePng;
      default:
        return ac_power === "1" ? Air : AirPng;
    }
  };

  const checkModel = (oud, model) => {
    let val = oud.substr(4, 5);

    switch (true) {
      case val * 1 >= 10 && val * 1 < 15:
        if (model.toUpperCase() === "FFU" || model.toUpperCase() === "EX") {
          return model.toUpperCase();
        }
        return "FFU";
      case val * 1 >= 15 && val * 1 < 19:
        return "IAQ";
      default:
        return model.toUpperCase();
    }
  };

  const powerConvert = (power) => {
    let power_bit = parseInt(power).toString(2).padStart(8, "0");
    let powerSplit = power_bit.split("");

    return powerSplit[7].toString();
  };

  return acList.length
    ? acList.map((item, key) => (
        <div key={key} style={{ display: item.enable ? "block" : "none" }}>
          <div
            className="ac-container"
            style={{
              top: item.position ? JSON.parse(item.position).y + "%" : "0%",
              left: item.position ? JSON.parse(item.position).x + "%" : "0%",
              visibility: showIcon ? "visible" : "hidden",
              zIndex:
                showIndex === key ? 999 : showIndexClick === key ? 998 : 1,
            }}
            onClick={() => {
              setUid(item.cid);
              setTitle(item.title);
              setID(item._id);
              setSerialNumber(item.gateway_sn);
              setShowIndexClick(showIndexClick === key ? -1 : key);
            }}
            onMouseOver={() => {
              setShowIndex(key);
            }}
            onMouseOut={() => setShowIndex(-1)}
          >
            {item.acinfo.online ? (
              item.acinfo.map((data, index) => (
                <img
                  src={acIcon(
                    checkModel(item.odu, item.model),
                    data.istst?.onoff ? powerConvert(data.istst.onoff) : 0
                  )}
                  alt={checkModel(item.odu, item.model)}
                  key={index}
                />
              ))
            ) : (
              <img
                src={acIcon(checkModel(item.odu, item.model), null)}
                alt={checkModel(item.odu, item.model)}
              />
            )}
            <div
              className={
                item.position
                  ? parseFloat(JSON.parse(item.position).y) > 70
                    ? checkModel(item.odu, item.model) === "IAQ"
                      ? "ac-info-top-iaq"
                      : "ac-info-top"
                    : checkModel(item.odu, item.model) === "IAQ"
                    ? "ac-info-bottom-iaq"
                    : "ac-info-bottom"
                  : "ac-info-top"
              }
              style={{
                visibility: showInfo
                  ? "visible"
                  : showIndex === key
                  ? "visible"
                  : showIndexClick === key
                  ? "visible"
                  : "hidden",
                right: item.position
                  ? parseFloat(JSON.parse(item.position).x) > 55
                    ? "0"
                    : "auto"
                  : 0,
                left: item.position
                  ? parseFloat(JSON.parse(item.position).x) > 55
                    ? "auto"
                    : "0"
                  : "auto",
              }}
            >
              <p className="ac-name mb-0">{item.title ? item.title : "-"} </p>
              <p className="ac-title">Room.: {item.location.room}</p>
              {item.acinfo.online ? (
                item.acinfo.map((data, index) => (
                  <p className="ac-title" key={index}>
                    Status:{" "}
                    <span
                      className={
                        data.istst?.onoff
                          ? powerConvert(data.istst.onoff) === "1"
                            ? "ac-status power-on"
                            : "ac-status power-off"
                          : "ac-status power-off"
                      }
                    >
                      {data.istst?.onoff
                        ? powerConvert(data.istst.onoff) === "1"
                          ? "ON"
                          : "OFF"
                        : "OFF"}
                    </span>
                  </p>
                ))
              ) : (
                <p className="ac-title">
                  Status:{" "}
                  <span className={"ac-status offline"}>{" OFFLINE"}</span>
                </p>
              )}
              {checkModel(item.odu, item.model) !== "IAQ" ? (
                item.acinfo.online ? (
                  item.acinfo.map((data, index) => (
                    <p className="ac-title" key={index}>
                      Set Temp.:{" "}
                      <span className="ac-status">
                        {data.istst?.st ? data.istst.st : 0}°C
                      </span>
                    </p>
                  ))
                ) : (
                  <p className="ac-title">
                    Set Temp.: <span className="ac-status">0°C</span>
                  </p>
                )
              ) : null}
              {item.acinfo.online ? (
                item.acinfo.map((data, index) => (
                  <p className="ac-title" key={index}>
                    Room Temp.:{" "}
                    <span className="ac-status">
                      {checkModel(item.odu, item.model) === "IAQ"
                        ? data.istst?.RT2
                          ? data.istst.RT2
                          : 0
                        : data.istst?.rt
                        ? data.istst.rt
                        : 0}{" "}
                      °C
                    </span>
                  </p>
                ))
              ) : (
                <p className="ac-title">
                  Room Temp.: <span className="ac-status">0°C</span>
                </p>
              )}
              {checkModel(item.odu, item.model) === "IAQ" ? (
                item.acinfo.online ? (
                  item.acinfo.map((data, index) => (
                    <p className="ac-title" key={index}>
                      CO2.:{" "}
                      <span className="ac-status">
                        {data.istst?.CO2_H && data.istst?.CO2_L
                          ? data.istst.CO2_H * 256 + data.istst.CO2_L
                          : 0}{" "}
                        ppm
                      </span>
                    </p>
                  ))
                ) : (
                  <p className="ac-title">
                    CO2.: <span className="ac-status">0 ppm</span>
                  </p>
                )
              ) : null}
              {checkModel(item.odu, item.model) === "IAQ" ? (
                item.acinfo.online ? (
                  item.acinfo.map((data, index) => (
                    <p className="ac-title" key={index}>
                      PM 2.5.:{" "}
                      <span className="ac-status">
                        {data.istst?.PM2_2 && data.istst?.PM2_1
                          ? (
                              (data.istst.PM2_2 * 256 + data.istst.PM2_1) /
                              1000
                            ).toFixed(1)
                          : 0}{" "}
                        ug/m3
                      </span>
                    </p>
                  ))
                ) : (
                  <p className="ac-title">
                    PM 2.5.: <span className="ac-status">0 ug/m3</span>
                  </p>
                )
              ) : null}
              {checkModel(item.odu, item.model) === "IAQ" ? (
                item.acinfo.online ? (
                  item.acinfo.map((data, index) => (
                    <p className="ac-title" key={index}>
                      Humidity.:{" "}
                      <span className="ac-status">
                        {data.istst?.RH2 ? data.istst.RH2 : 0} %
                      </span>
                    </p>
                  ))
                ) : (
                  <p className="ac-title">
                    Humidity.: <span className="ac-status">0 %</span>
                  </p>
                )
              ) : null}
              {checkModel(item.odu, item.model) === "IAQ" ? (
                item.acinfo.online ? (
                  item.acinfo.map((data, index) => (
                    <p className="ac-title" key={index}>
                      Room Pressure.:{" "}
                      <span className="ac-status">
                        {data.istst?.IND2_8
                          ? (parseInt(data.istst.IND2_8) - 3000) / 10
                          : 0}{" "}
                        Pa
                      </span>
                    </p>
                  ))
                ) : (
                  <p className="ac-title">
                    Room Pressure.: <span className="ac-status">0 Pa</span>
                  </p>
                )
              ) : null}
            </div>
          </div>
        </div>
      ))
    : null;
};

const Control = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState("icon");
  const [showIcon, setShowIcon] = useState(true);
  const [showInfo, setShowInfo] = useState(false);
  const [open, setOpen] = useState(false);
  const [buildingFilter, setBuildingFilter] = useState(
    state?.building ? state.building : ""
  );
  const [floorFilter, setFloorFilter] = useState(
    state?.floor ? state.floor : ""
  );
  const [roomFilter, setRoomFilter] = useState(state?.room ? state.room : "");
  const [buildingList, setBuildingList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [uid, setUid] = useState(state?.uid ? state.uid : "");
  const [serialNumber, setSerialNumber] = useState(
    state?.serialNumber ? state.serialNumber : ""
  );
  const [title, setTitle] = useState(state?.title ? state.title : "");
  const [id, setID] = useState(state?.id ? state.id : "");
  const [roomImage, setRoomImage] = useState(null);
  const [load, setLoad] = useState(true);
  const [showIndex, setShowIndex] = useState(-1);
  const [showIndexClick, setShowIndexClick] = useState(-1);

  const {
    productList,
    deviceList,
    setDeviceList,
    building,
    floor,
    room,
    loading,
  } = useContext(MqttContext);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      navigate("/");
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 768) {
        navigate("/");
      }
    });

    return () => {
      window.removeEventListener("resize", () => {
        if (window.innerWidth <= 768) {
          navigate("/");
        }
      });
    };
  }, [navigate]);

  useEffect(() => {
    if (
      !building.current.length &&
      !floor.current.length &&
      !room.current.length
    ) {
      navigate("/");
    }

    loading.current = true;
    setLoad(true);

    setRoomImage(null);

    (async () => {
      try {
        let token = localStorage.getItem("token");
        let companyId = localStorage.getItem("company_id");

        let res = await building_list(token);

        let buildingData = res.data.data.filter((item) => {
          return item.company_id === companyId;
        });

        let buildings = [];
        let floors = [];
        let rooms = [];

        // Filler Building
        buildingData.map((item) => {
          if (item.title !== undefined) {
            buildings.push({
              building: item.title,
              building_id: item._id,
            });
          }

          return item;
        });

        // Filler Floor
        buildingData.map((item) => {
          if (building.current === item.title) {
            if (item.floors !== undefined) {
              item.floors.map((data) => {
                floors.push({
                  floor: data.title,
                  floor_id: data.floorId,
                });

                return data;
              });
            }
          }

          return item;
        });

        // Filler Room
        buildingData.map((i) => {
          if (building.current === i.title) {
            if (i.floors) {
              i.floors.map((j) => {
                if (j.rooms !== undefined && floor.current === j.title) {
                  j.rooms.map((k) => {
                    rooms.push({
                      room: k.title,
                      room_image: k.plan_img,
                      room_id: k.roomId,
                    });

                    if (k.title === room.current) {
                      setRoomImage(k.plan_img);
                    }

                    return k;
                  });
                }
                return j;
              });
            }
          }

          return i;
        });

        buildings.sort((a, b) => {
          let x = a.building_id.toLowerCase();
          let y = b.building_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        floors.sort((a, b) => {
          let x = a.floor_id.toLowerCase();
          let y = b.floor_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        rooms.sort((a, b) => {
          let x = a.room_id.toLowerCase();
          let y = b.room_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        setBuildingList(buildings);
        setFloorList(floors);
        setRoomList(rooms);

        setBuildingFilter(building.current);
        setFloorFilter(floor.current);
        setRoomFilter(room.current);
        setOpen(false);

        let productArr = await products(token, building.current, floor.current);

        let productFilter = productArr.data.productsList.filter((item) => {
          if (room.current === "All") {
            return (
              item.location.building === building.current &&
              item.location.floor === floor.current
            );
          } else {
            return (
              item.location.building === building.current &&
              item.location.floor === floor.current &&
              item.location.room === room.current
            );
          }
        });

        if (productFilter.length && !state?.uid) {
          setUid(productFilter[0].cid);
          setTitle(productFilter[0].title);
          setID(productFilter[0]._id);
        }

        productList.current = productFilter;

        setDeviceList([]);
      } catch (error) {
        console.log(error);
      }
    })();

    setShowIndex(-1);
    setShowIndexClick(-1);

    setTimeout(() => {
      loading.current = false;
      setLoad(false);
    }, 15000);
  }, [
    state,
    building,
    floor,
    loading,
    navigate,
    productList,
    room,
    setDeviceList,
  ]);

  const viewModeHandle = (mode) => {
    setViewMode(mode);
    setShowIndex(-1);
    setShowIndexClick(-1);

    switch (true) {
      case mode === "icon":
        setShowIcon(true);
        setShowInfo(false);
        break;
      case mode === "info":
        setShowIcon(true);
        setShowInfo(true);
        break;
      default:
        setShowIcon(false);
        setShowInfo(false);
        break;
    }
  };

  const filterACRoom = async () => {
    if (buildingFilter && floorFilter && roomFilter) {
      setOpen(false);
      loading.current = true;
      setLoad(true);

      setShowIndex(-1);
      setShowIndexClick(-1);

      building.current = buildingFilter;
      floor.current = floorFilter;
      room.current = roomFilter;

      try {
        let token = localStorage.getItem("token");
        let companyId = localStorage.getItem("company_id");

        let res = await building_list(token);

        let buildingData = res.data.data.filter((item) => {
          return item.company_id === companyId;
        });

        buildingData.map((i) => {
          if (i.title === buildingFilter) {
            if (i.floors) {
              i.floors.map((j) => {
                if (j.title === floorFilter) {
                  if (j.rooms) {
                    j.rooms.map((k) => {
                      if (k.title === roomFilter) {
                        setRoomImage(k.plan_img);
                      }

                      return k;
                    });
                  }
                }

                return j;
              });
            }
          }

          return i;
        });

        let productArr = await products(token, building.current, floor.current);

        let productFilter = productArr.data.productsList.filter((item) => {
          if (room.current === "All") {
            return (
              item.location.building === building.current &&
              item.location.floor === floor.current
            );
          } else {
            return (
              item.location.building === building.current &&
              item.location.floor === floor.current &&
              item.location.room === room.current
            );
          }
        });

        if (productFilter.length) {
          setUid(productFilter[0].cid);
          setTitle(productFilter[0].title);
          setID(productFilter[0]._id);
        }

        productList.current = productFilter;

        setDeviceList([]);
      } catch (error) {
        console.log(error);
      }

      setTimeout(() => {
        loading.current = false;
        setLoad(false);
      }, 15000);
    }
  };

  const changeBuilding = async (val) => {
    setBuildingFilter(val);

    let floors = [];
    let rooms = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (val === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              floors.push({
                floor: i.title,
                floor_id: i.floorId,
              });

              return i;
            });
          }

          floors.sort((a, b) => {
            let x = a.floor_id.toLowerCase();
            let y = b.floor_id.toLowerCase();

            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          });

          item.floors.map((i) => {
            if (i.title === floors[0].floor) {
              if (i.rooms) {
                i.rooms.map((j) => {
                  rooms.push({
                    room: j.title,
                    room_id: j.roomId,
                  });
                  return j;
                });
              }
            }

            return i;
          });
        }

        return item;
      });

      rooms.sort((a, b) => {
        let x = a.room_id.toLowerCase();
        let y = b.room_id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setFloorList(floors);
      setRoomList(rooms);

      if (floors.length) {
        setFloorFilter(floors[0].floor);
      } else {
        setFloorFilter("");
      }

      if (rooms.length) {
        setRoomFilter(rooms[0].room);
      } else {
        setRoomFilter("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeFloor = async (val) => {
    setFloorFilter(val);

    let rooms = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (buildingFilter === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              if (i.title === val) {
                if (i.rooms !== undefined) {
                  i.rooms.map((j) => {
                    rooms.push({
                      room: j.title,
                      room_id: j.roomId,
                    });

                    return i;
                  });
                }
              }

              return i;
            });
          }
        }

        return item;
      });

      setRoomList(rooms);

      if (rooms.length) {
        setRoomFilter(rooms[0].room);
      } else {
        setFloorFilter("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeRoom = (val) => {
    setRoomFilter(val);
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container">
        <Row className="mx-0 ps-0 ps-xl-4">
          <Col xs={"auto"} className="position-relative">
            <h3 className="dashboard-header mb-0">
              {building.current + " " + floor.current + " " + room.current}
            </h3>
            <div
              className="control-select-dropdown"
              onClick={() => setOpen(!open)}
            ></div>
            <div
              className="control-select-container p-2"
              style={{
                visibility: open ? "visible" : "hidden",
                opacity: open ? 1 : 0,
              }}
            >
              <div className="control-select">
                <img src={BuildingIcon} alt="building" className="pe-2" />
                <p className="mb-0 pe-2">Building</p>
                <Form.Select
                  size="sm"
                  onChange={(e) => changeBuilding(e.target.value)}
                  value={buildingFilter}
                >
                  {buildingList.map((item, index) => (
                    <option value={item.building} key={index}>
                      {item.building}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className="control-select">
                <img src={FloorIcon} alt="floor" className="pe-2" />
                <p className="mb-0 pe-2">Floor</p>
                <Form.Select
                  size="sm"
                  onChange={(e) => changeFloor(e.target.value)}
                  value={floorFilter}
                >
                  {floorList.map((item, index) => (
                    <option value={item.floor} key={index}>
                      {item.floor}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className="control-select">
                <img src={RoomIcon} alt="floor" className="pe-2" />
                <p className="mb-0 pe-2">Room</p>
                <Form.Select
                  size="sm"
                  onChange={(e) => changeRoom(e.target.value)}
                  value={roomFilter}
                >
                  {roomList.map((item, index) => (
                    <option value={item.room} key={index}>
                      {item.room}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <button
                className="control-search-btn my-2 me-2"
                onClick={() => filterACRoom()}
              >
                Confirm
              </button>
            </div>
          </Col>
        </Row>
        <ControlPanel
          deviceList={deviceList}
          building={building.current}
          floor={floor.current}
          room={room.current}
          uid={uid}
          title={title}
          serialNumber={serialNumber}
          id={id}
          viewModeHandle={viewModeHandle}
          viewMode={viewMode}
        />

        <Row className="mx-0 ps-0 ps-xl-4 mt-2 mt-xl-4">
          <Col>
            <div
              className="control-plan-container"
              style={{
                backgroundImage: `url('${
                  roomImage
                    ? "http://node-env.eba-7xwegkmr.ap-southeast-1.elasticbeanstalk.com/api/v2.0/images/upload/" +
                      roomImage
                    : null
                }')`,
              }}
            >
              <ControlPlan
                deviceList={productList.current}
                building={building.current}
                floor={floor.current}
                room={room.current}
                showIcon={showIcon}
                showInfo={showInfo}
                showIndex={showIndex}
                setShowIndex={setShowIndex}
                showIndexClick={showIndexClick}
                setShowIndexClick={setShowIndexClick}
                setUid={setUid}
                setID={setID}
                setSerialNumber={setSerialNumber}
                setTitle={setTitle}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="loading_bg"></div>
      <Modal
        show={loading.current ? load : false}
        backdrop="static"
        keyboard="false"
        centered
        id="loading-modal"
      >
        <div className="loader"></div>
      </Modal>
    </div>
  );
};

export default Control;
