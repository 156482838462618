import React, { useState, useEffect } from "react";
import { Row, Col, Table, Form, Modal, Button } from "react-bootstrap";

import Header from "../components/header";
import Nav from "../components/nav";
import Pagination from "../components/pagination";
import {
  gateway,
  add_gateway,
  update_gateway,
  delete_gateway,
} from "../api/setting";

const SettingGateway = (props) => {
  const [gatewayList, setGatewayList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage] = useState(20);
  const [gatewayListSearch, setGatewayListSearch] = useState([]);
  const [serialNumberSearch, setSerialNumberSearch] = useState("");
  const [nameSearch, setNameSearch] = useState("");
  const [ipSearch, setIpSearch] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [addModalShow, setAddModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [serialNumber, setSerialNumber] = useState("");
  const [ip, setIp] = useState("");
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [loaded, setLoaded] = useState(true);

  const indexOfLastPost = currentPage * PerPage;
  const indexOfFirstPost = indexOfLastPost - PerPage;
  const currentPosts = gatewayListSearch.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    (async () => {
      try {
        let token = localStorage.getItem("token");
        let res = await gateway(token);

        if (res.data.gateways !== undefined) {
          setGatewayList(res.data.gateways);
          setGatewayListSearch(res.data.gateways);
        }

        setLoaded(false);
      } catch (error) {
        console.log(error);
        setLoaded(false);
      }
    })();
  }, []);

  const search = () => {
    let result = gatewayList.filter((item) => {
      if (
        item.device_sn
          .toLowerCase()
          .indexOf(serialNumberSearch.toLowerCase()) >= 0 &&
        item.name.toLowerCase().indexOf(nameSearch.toLowerCase()) >= 0 &&
        item.ip.toLowerCase().indexOf(ipSearch.toLowerCase()) >= 0
      ) {
        return true;
      } else {
        return false;
      }
    });

    setGatewayListSearch(result);
  };

  const clearSearch = () => {
    setSerialNumberSearch("");
    setNameSearch("");
    setIpSearch("");
    setGatewayListSearch(gatewayList);
  };

  const editGateway = (gateway_sn, gateway_ip, gateway_name) => {
    setEditModalShow(true);
    setSerialNumber(gateway_sn);
    setIp(gateway_ip);
    setName(gateway_name);
  };

  const deleteGateWay = async () => {
    try {
      let token = localStorage.getItem("token");

      await delete_gateway(token, id);
      let res = await gateway(token);

      setGatewayList(res.data.gateways);
      setGatewayListSearch(res.data.gateways);
      setDeleteModalShow(false);
    } catch (error) {
      console.log(error);
      alert("Delete failed!");
      setDeleteModalShow(false);
    }
  };

  const handleSubmit = async (event, action) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (action === "add") {
      try {
        let token = localStorage.getItem("token");
        let user_id = localStorage.getItem("user_id");
        await add_gateway(token, serialNumber, ip, name, user_id);

        setValidated(true);
      } catch (error) {
        console.log(error);
        alert("Edit failed!");
      }
    } else {
      try {
        let token = localStorage.getItem("token");
        await update_gateway(token, serialNumber, ip, name);

        setValidated(true);
      } catch (error) {
        console.log(error);
        alert("Edit failed!");
      }
    }
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-4 mt-4 pt-5 pt-xl-0">
        <Row className="mx-0 px-xl-4">
          <Col xs={12}>
            <div className="table-container px-2 px-sm-3 px-xl-4 py-3 py-sm-4">
              <div
                className="table-container-title px-2"
                style={{ justifyContent: "space-between" }}
              >
                <h5 className="mb-1">Gateway Management</h5>
                <button
                  className="add-modal-btn"
                  onClick={() => {
                    setSerialNumber("");
                    setIp("");
                    setName("");
                    setAddModalShow(true);
                  }}
                >
                  Add Gateway
                </button>
              </div>
              <Row>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-3 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">
                      Serial Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Serial Number"
                      required
                      onChange={(e) => setSerialNumberSearch(e.target.value)}
                      value={serialNumberSearch}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-1 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      required
                      onChange={(e) => setNameSearch(e.target.value)}
                      value={nameSearch}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-1 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">IP</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="IP"
                      required
                      onChange={(e) => setIpSearch(e.target.value)}
                      value={ipSearch}
                    />
                  </Form.Group>
                </Col>
                <Col xs={"auto"} className="pe-0 ms-2 ms-sm-0 mt-2 mt-sm-0">
                  <Form.Group className="mt-4 mb-2 px-2 d-none d-sm-block">
                    <Form.Label className="input-label mb-0">&nbsp;</Form.Label>
                  </Form.Group>
                  <button className="search-btn" onClick={() => search()}>
                    Search
                  </button>
                </Col>
                <Col xs={"auto"} className="mt-2 mt-sm-0">
                  <Form.Group className="mt-4 mb-2 px-2 d-none d-sm-block">
                    <Form.Label className="input-label mb-0">&nbsp;</Form.Label>
                  </Form.Group>
                  <button className="clear-btn" onClick={() => clearSearch()}>
                    Clear
                  </button>
                </Col>
              </Row>
              <div className="mx-2 mb-2 table-overflow">
                <Table hover className="mt-3 mb-2 setting-table align-middle">
                  <thead>
                    <tr>
                      <th className="px-4">No.</th>
                      <th className="text-center px-4">Serial Number</th>
                      <th className="text-center px-4">Name</th>
                      <th className="text-center px-4">IP</th>
                      <th className="text-center px-4">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {gatewayListSearch.length ? (
                      currentPosts.map((item, index) => (
                        <tr key={index}>
                          <td className="px-4">
                            {currentPage === 1
                              ? index + 1
                              : index + 1 + (currentPage - 1) * PerPage}
                            .
                          </td>
                          <td className="px-4 text-center">{item.device_sn}</td>
                          <td className="px-4 text-center">{item.name}</td>
                          <td className="px-4 text-center">{item.ip}</td>
                          <td className="px-4 text-center">
                            <span
                              className="table-edit"
                              onClick={() =>
                                editGateway(item.device_sn, item.ip, item.name)
                              }
                            >
                              Edit
                            </span>{" "}
                            /{" "}
                            <span
                              className="table-delete"
                              onClick={() => {
                                setDeleteModalShow(true);
                                setId(item._id);
                              }}
                            >
                              Delete
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <Pagination
                postsPerPage={PerPage}
                totalPosts={gatewayListSearch.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h4 className="modal-title mb-2">Success!</h4>
          <p className="modal-des text-center">Update successfully.</p>
          <button
            onClick={() => {
              setModalShow(false);
            }}
            className="modal-btn float-end"
          >
            Done
          </button>
        </Modal.Body>
      </Modal>
      <Modal show={addModalShow} onHide={() => setAddModalShow(false)} centered>
        <Modal.Body>
          <h4 className="modal-title mb-2">Add Gateway</h4>
          <Form
            noValidate
            validated={validated}
            onSubmit={(e) => handleSubmit(e, "add")}
          >
            <Form.Group className="mb-4 px-2">
              <Form.Label className="input-label">Serial Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Serial Number"
                defaultValue={serialNumber}
                required
                onChange={(e) => setSerialNumber(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid serial number.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4 px-2">
              <Form.Label className="input-label">Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                defaultValue={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4 px-2">
              <Form.Label className="input-label">IP</Form.Label>
              <Form.Control
                type="text"
                placeholder="IP"
                defaultValue={ip}
                required
                onChange={(e) => setIp(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid ip.
              </Form.Control.Feedback>
            </Form.Group>
            <div className="modal-btn-container mb-2">
              <Button
                type="reset"
                onClick={() => {
                  setAddModalShow(false);
                  setValidated(false);
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        centered
      >
        <Modal.Body>
          <h4 className="modal-title mb-2">Edit Gateway</h4>
          <Form
            noValidate
            validated={validated}
            onSubmit={(e) => handleSubmit(e, "edit")}
          >
            <Form.Group className="mb-4 px-2">
              <Form.Label className="input-label">Serial Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Serial Number"
                defaultValue={serialNumber}
                required
                readOnly={true}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid serial number.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4 px-2">
              <Form.Label className="input-label">Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                defaultValue={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-4 px-2">
              <Form.Label className="input-label">IP</Form.Label>
              <Form.Control
                type="text"
                placeholder="IP"
                defaultValue={ip}
                required
                onChange={(e) => setIp(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid ip.
              </Form.Control.Feedback>
            </Form.Group>
            <div className="modal-btn-container mb-2">
              <Button
                type="reset"
                onClick={() => {
                  setEditModalShow(false);
                  setValidated(false);
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        centered
      >
        <Modal.Body>
          <h4 className="modal-title mb-4">
            Do you want delete this gateway ?
          </h4>
          <div className="modal-btn-container mb-2">
            <Button
              type="button"
              onClick={() => {
                setDeleteModalShow(false);
              }}
            >
              Cancel
            </Button>
            <Button type="button" onClick={() => deleteGateWay()}>
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={loaded}
        backdrop="static"
        keyboard="false"
        centered
        id="loading-modal"
      >
        <div className="loader"></div>
      </Modal>
    </div>
  );
};

export default SettingGateway;
