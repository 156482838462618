import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import loginLogo from "../assets/image/login_logo.png";
import User from "../assets/image/user.png";
import Password from "../assets/image/password.png";
import shadow from "../assets/image/shadow.png";
import moment from "moment";
import { sign_in } from "../api/auth";
import jwt_decode from "jwt-decode";
import { users, profile } from "../api/user";

import { MqttContext } from "../components/mqtt_context";
import { ProfileContext } from "../components/profile_context";

const Login = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [errorUser, setErrorUser] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  const { connect_socket, connect } = useContext(MqttContext);
  const { setName, setSurname, setCompany, setLatitude, setLongitude } =
    useContext(ProfileContext);

  const userHandle = (e) => {
    setUser(e.target.value);
    setErrorUser("");
  };

  const passwordHandle = (e) => {
    setPassword(e.target.value);
    setErrorPassword("");
  };

  const login = async () => {
    if (user.length !== 0) {
      setErrorUser("");
    } else {
      setErrorUser("User can't be empty!");
    }

    if (password.length !== 0) {
      setErrorPassword("");
    } else {
      setErrorPassword("Password can't be empty!");
    }

    try {
      let res = await sign_in(user, password);

      if (typeof res.data.error !== "undefined") {
        setErrorPassword("Wrong Password!!!");
      } else {
        let decoded = jwt_decode(res.data.token);

        localStorage.setItem("token", res.data.token);
        localStorage.setItem("email", res.data.email);
        localStorage.setItem("user_id", decoded.user_id);
        localStorage.setItem("company_id", decoded.companyId);
        localStorage.setItem("user_role", decoded.role);

        if (decoded.role !== "1") {
          try {
            let token = localStorage.getItem("token");
            let user_res = await users(token);
            let profile_res = await profile(token);

            let userArr = user_res.data.users.filter((item) => {
              return item.company_id === decoded.companyId;
            });

            if (userArr[0].full_name !== undefined) {
              let full_name = userArr[0].full_name.split(" ");

              setName(full_name[0] ? full_name[0] : "-");
              setSurname(full_name[1] ? full_name[1] : null);
            }

            setCompany(
              profile_res.data.user_Info[0].company_info[0].company_name
                ? profile_res.data.user_Info[0].company_info[0].company_name
                : "-"
            );
            setLatitude(
              profile_res.data.user_Info[0].company_info[0].coords
                ? profile_res.data.user_Info[0].company_info[0].coords[0]
                : 0
            );
            setLongitude(
              profile_res.data.user_Info[0].company_info[0].coords
                ? profile_res.data.user_Info[0].company_info[0].coords[1]
                : 0
            );

            connect.current = false;
            connect_socket();

            navigate("/");
          } catch (error) {
            console.log(error);
            navigate("/login");
          }
        } else {
          navigate("/company_list");
        }
      }
    } catch (error) {
      console.log(error);
      setErrorPassword("Wrong Password!!!");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      let decoded = jwt_decode(localStorage.getItem("token"));
      let exp = parseInt(decoded.exp);
      let now = parseInt(moment().format("X"));

      if (decoded.role === "1" && exp > now) {
        navigate("/company_list");
      } else {
        if (exp > now) {
          navigate("/");
        }
      }
    }
  }, [navigate]);

  return (
    <div className="login-bg py-5">
      <div className="login-container py-5">
        <img src={loginLogo} className="login-logo mb-5" alt="saijo-denki" />
        <Form className="login-form">
          <Form.Group className="mb-4">
            <div
              className={
                errorUser.length
                  ? "login-input-container error-input"
                  : "login-input-container"
              }
            >
              <div className="login-input-icon-container">
                <img src={User} className="login-icon" alt="user" />
              </div>
              <div className="login-input-box">
                <input
                  placeholder="Username"
                  className="login-input"
                  onChange={(e) => userHandle(e)}
                />
              </div>
            </div>
            {errorUser.trim().length ? (
              <p className="error-input">{errorUser}</p>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <div
              className={
                errorPassword.length
                  ? "login-input-container error-input"
                  : "login-input-container"
              }
            >
              <div className="login-input-icon-container">
                <img src={Password} className="login-icon" alt="password" />
              </div>
              <div className="login-input-box">
                <input
                  placeholder="Password"
                  className="login-input"
                  type="password"
                  onChange={(e) => passwordHandle(e)}
                />
              </div>
            </div>
            {errorPassword.trim().length ? (
              <p className="error-input">{errorPassword}</p>
            ) : null}
          </Form.Group>
        </Form>
        {/* <div className="login-other-container">
          <p
            className="create-account mx-2 px-1"
            onClick={() => navigate("/register")}
          >
            Create Account
          </p>
          <div className="middle-bar"></div>
          <p
            className="forget-password mx-2 px-1"
            onClick={() => navigate("/forget_password")}
          >
            Forget password ?
          </p>
        </div> */}
        <button type="button" className="login-btn" onClick={() => login()}>
          Login
        </button>
      </div>
      <img src={shadow} className="login-shadow" alt="shadow" />
    </div>
  );
};

export default Login;
