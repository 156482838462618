import React, { useState, useEffect } from "react";
import { Row, Col, Table, Form, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import moment from "moment";

import Header from "../components/header";
import Nav from "../components/nav";
import Pagination from "../components/pagination";
import ExportExcel from "../components/export_excel";
import { building_list } from "../api/product";
import BuildingIcon from "../assets/image/control/building-icon.png";
import FloorIcon from "../assets/image/control/floor-icon.png";
import RoomIcon from "../assets/image/control/room-icon.png";
import { useRealtimeClock, useRealtimeIndoorReport } from "../hook/useHook";
import {
  roomTemp,
  roomHumidity,
  carbon,
  pm,
  roomPressure,
  power,
  mode,
  swing,
  fanSpeed,
  functionPe,
  indoorModel,
  indoorOption,
  indoorType,
  indoorProtection,
} from "../utils/utils";

const ReportIndoor = (props) => {
  const { state } = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage] = useState(20);
  const [open, setOpen] = useState(false);
  const [building, setBuilding] = useState("");
  const [buildingFilter, setBuildingFilter] = useState("");
  const [floor, setFloor] = useState("");
  const [floorFilter, setFloorFilter] = useState("");
  const [room, setRoom] = useState("");
  const [roomFilter, setRoomFilter] = useState("");
  const [buildingList, setBuildingList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [roomList, setRoomList] = useState([]);

  const [clock] = useRealtimeClock();
  const [reportData, exportData, loading] = useRealtimeIndoorReport(
    building,
    floor,
    room
  );

  const indexOfLastPost = currentPage * PerPage;
  const indexOfFirstPost = indexOfLastPost - PerPage;
  const currentPosts = reportData.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    (async () => {
      try {
        let token = localStorage.getItem("token");
        let companyId = localStorage.getItem("company_id");

        let res = await building_list(token);

        let buildingData = res.data.data.filter((item) => {
          return item.company_id === companyId;
        });

        let buildings = [];
        let floors = [];
        let rooms = [];

        // Filler Building
        buildingData.map((item) => {
          if (item.title !== undefined) {
            buildings.push({
              building: item.title,
              building_id: item._id,
            });
          }

          return item;
        });

        buildings.sort((a, b) => {
          let x = a.building_id.toLowerCase();
          let y = b.building_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        // Filler Floor
        buildingData.map((item) => {
          if (buildings[0].building !== undefined) {
            if (buildings[0].building === item.title) {
              if (item.floors !== undefined) {
                item.floors.map((data) => {
                  floors.push({
                    floor: data.title,
                    floor_image: data.plan_img,
                    floor_id: data.floorId,
                  });

                  return data;
                });
              }
            }
          }

          return item;
        });

        floors.sort((a, b) => {
          let x = a.floor_id.toLowerCase();
          let y = b.floor_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        // Filler Room
        buildingData.map((item) => {
          if (buildings[0].building !== undefined) {
            if (buildings[0].building === item.title) {
              if (item.floors) {
                item.floors.map((i) => {
                  if (floors[0].floor === i.title) {
                    if (i.rooms) {
                      i.rooms.map((data) => {
                        rooms.push({
                          room: data.title,
                          room_id: data.roomId,
                        });

                        return data;
                      });
                    }
                  }

                  return i;
                });
              }
            }
          }

          return item;
        });

        rooms.sort((a, b) => {
          let x = a.room_id.toLowerCase();
          let y = b.room_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        setBuildingList(buildings);
        setFloorList(floors);
        setRoomList(rooms);
        setBuilding(buildings.length ? buildings[0].building : "");
        setFloor(floors.length ? floors[0].floor : "");
        setRoom(rooms.length ? rooms[0].room : "");
        setBuildingFilter(buildings.length ? buildings[0].building : "");
        setFloorFilter(floors.length ? floors[0].floor : "");
        setRoomFilter(rooms.length ? rooms[0].room : "");
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const changeBuilding = async (val) => {
    setBuildingFilter(val);

    let floors = [];
    let rooms = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (val === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              floors.push({
                floor: i.title,
                floor_id: i.floorId,
              });

              return i;
            });
          }

          floors.sort((a, b) => {
            let x = a.floor_id.toLowerCase();
            let y = b.floor_id.toLowerCase();

            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          });

          if (item.floors) {
            item.floors.map((i) => {
              if (i.title === floors[0].floor) {
                if (i.rooms) {
                  i.rooms.map((j) => {
                    rooms.push({
                      room: j.title,
                      room_id: j.roomId,
                    });
                    return j;
                  });
                }
              }

              return i;
            });
          }

          rooms.sort((a, b) => {
            let x = a.room_id.toLowerCase();
            let y = b.room_id.toLowerCase();

            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          });
        }

        return item;
      });

      setFloorList(floors);
      setRoomList(rooms);

      if (floors.length) {
        setFloorFilter(floors[0].floor);
      } else {
        setFloorFilter("");
      }

      if (rooms.length) {
        setRoomFilter(rooms[0].room);
      } else {
        setRoomFilter("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeFloor = async (val) => {
    setFloorFilter(val);

    let rooms = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (building === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              if (i.title === val) {
                if (i.rooms !== undefined) {
                  i.rooms.map((j) => {
                    rooms.push({
                      room: j.title,
                      room_id: j.roomId,
                    });

                    return i;
                  });
                }
              }

              return i;
            });
          }
        }

        return item;
      });

      rooms.sort((a, b) => {
        let x = a.room_id.toLowerCase();
        let y = b.room_id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setRoomList(rooms);

      if (rooms.length) {
        setRoomFilter(rooms[0].room);
      } else {
        setRoomFilter("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeRoom = (val) => {
    setRoomFilter(val);
  };

  const filterACRoom = () => {
    if (buildingFilter && floorFilter && roomFilter) {
      setOpen(false);
      setBuilding(buildingFilter);
      setFloor(floorFilter);
      setRoom(roomFilter);
    }
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-4 pt-5 pt-xl-0">
        <Row className="mx-0 px-xl-4">
          <Col>
            <div className="table-container px-2 px-sm-3 px-xl-4 py-3 py-sm-4">
              <div className="table-container-title px-2">
                <Row>
                  <Col xs={"auto"} className="position-relative">
                    <h5 className="mb-1">
                      {state?.report_title
                        ? state.report_title
                        : "ODU Realtime Report"}
                      {": "}
                      {building + " " + floor + " " + room}
                    </h5>
                    <div
                      id="report-open-indoor"
                      className="control-select-dropdown"
                      onClick={() => setOpen(!open)}
                    ></div>
                    <div
                      className="control-select-container p-2"
                      style={{
                        visibility: open ? "visible" : "hidden",
                        opacity: open ? 1 : 0,
                        top: "40px",
                      }}
                    >
                      <div className="control-select">
                        <img
                          src={BuildingIcon}
                          alt="building"
                          className="pe-2"
                        />
                        <p className="mb-0 pe-2">Building</p>
                        <Form.Select
                          size="sm"
                          onChange={(e) => changeBuilding(e.target.value)}
                        >
                          {buildingList.map((item, index) => (
                            <option value={item.building} key={index}>
                              {item.building}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                      <div className="control-select">
                        <img src={FloorIcon} alt="floor" className="pe-2" />
                        <p className="mb-0 pe-2">Floor</p>
                        <Form.Select
                          size="sm"
                          onChange={(e) => changeFloor(e.target.value)}
                        >
                          {floorList.map((item, index) => (
                            <option value={item.floor} key={index}>
                              {item.floor}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                      <div className="control-select">
                        <img src={RoomIcon} alt="floor" className="pe-2" />
                        <p className="mb-0 pe-2">Room</p>
                        <Form.Select
                          size="sm"
                          onChange={(e) => changeRoom(e.target.value)}
                        >
                          {roomList.map((item, index) => (
                            <option value={item.room} key={index}>
                              {item.room}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                      <button
                        className="control-search-btn my-2 me-2"
                        onClick={() => filterACRoom()}
                      >
                        Confirm
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
              <p className="report-time px-2 mb-2 mb-lg-4">
                {clock ? clock : moment().format("DD/MM/YYYY [TIME] HH:MM:ss")}
              </p>
              <ExportExcel
                csvData={exportData}
                fileName={
                  state?.report_title
                    ? state.report_title +
                      "_" +
                      moment().format("DD_MM_YYYY_HH_mm_ss") +
                      "_.csv"
                    : "ODU Report_" +
                      moment().format("DD_MM_YYYY_HH_mm_ss") +
                      "_.csv"
                }
              />
              <div className="mx-2 mb-2 table-overflow">
                <Table hover className="mt-3 mb-2 error-table mb-2">
                  <thead>
                    <tr>
                      <th className="sticky-col" style={{ left: 0 }}>
                        System Name
                      </th>
                      <th className="sticky-col" style={{ left: "100px" }}>
                        Gateway
                      </th>
                      <th className="sticky-col" style={{ left: "200px" }}>
                        Location
                      </th>
                      <th>Title</th>
                      <th>Model</th>
                      <th>ODU</th>
                      <th>CID</th>
                      <th>UID</th>
                      <th>Set Temp. (°C)</th>
                      <th>Room Temp. (°C)</th>
                      <th>Set Humidity (%)</th>
                      <th>Room Humidity (%)</th>
                      <th>CO2 (ppm)</th>
                      <th>PM 2.5 (ug/m3)</th>
                      <th>Room Pressure (Pa)</th>
                      <th>Power</th>
                      <th>Mode</th>
                      <th>Fan Speed</th>
                      <th>Swing</th>
                      <th>Function</th>
                      <th>uPtRem</th>
                      <th>APS</th>
                      <th>TrbAPS</th>
                      <th>RH2 (%)</th>
                      <th>RT2 (°C)</th>
                      <th>Indoor Option</th>
                      <th>Type of Indoor</th>
                      <th>Model of Indoor</th>
                      <th>CO2 (Byte High)</th>
                      <th>CO2 (Byte Low)</th>
                      <th>PM 2.5 (Byte 2)</th>
                      <th>PM 2.5 (Byte 1)</th>
                      <th>PM 2.5 (Byte 0)</th>
                      <th>Indoor unit Coil Inlet Temp.(°C)</th>
                      <th>Indoor unit Coil Outlet Temp.(°C)</th>
                      <th>Indoor Unit EXV Position</th>
                      <th>Indoor %demand</th>
                      <th>Indoor Unit Capacity</th>
                      <th>Status (LED Code)</th>
                      <th>Indoor Unit Protection</th>
                      <th>Mid-coil Temp. (°C)</th>
                      <th>Outlet Temp. (°C)</th>
                      <th>Indoor Hepa Filter</th>
                      <th>Medium Filter</th>
                      <th>Pre Filter</th>
                      <th>UV Timing Running</th>
                      <th>PM 2.5 Sensor (Byte Low)</th>
                      <th>PM 2.5 Sensor (Byte High)</th>
                      <th>Extreme Ozone</th>
                      <th>Set Room Pa</th>
                      <th>Set Anti Room</th>
                      <th>Diff Pressure Room</th>
                      <th>Anti Room</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.length ? (
                      currentPosts.map((item, index) => (
                        <tr key={index}>
                          <td className="sticky-col" style={{ left: "0" }}>
                            {item.cdu_no}
                          </td>
                          <td className="sticky-col" style={{ left: "100px" }}>
                            {item.gateway_sn}
                          </td>
                          <td className="sticky-col" style={{ left: "200px" }}>
                            {item.location.building +
                              " " +
                              item.location.floor +
                              " , " +
                              item.location.room}
                          </td>
                          <td>{item.title}</td>
                          <td>{item.model ? item.model : "SRV"}</td>
                          <td>{item.odu}</td>
                          <td>{item.cid}</td>
                          <td>{item.uid}</td>
                          <td>
                            {item.acinfo[0] ? item.acinfo[0].istst.st : "-"}
                          </td>
                          <td>{roomTemp(item)}</td>
                          <td>
                            {item.acinfo[0] ? item.acinfo[0].istst.SetRH : "-"}
                          </td>
                          <td>{roomHumidity(item)}</td>
                          <td>{carbon(item)}</td>
                          <td>{pm(item)}</td>
                          <td>{roomPressure(item)}</td>
                          <td>
                            {item.acinfo[0]
                              ? power(item.acinfo[0].istst.onoff)
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? mode(item.acinfo[0].istst.mode)
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? fanSpeed(item.acinfo[0].istst.fspeed)
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? swing(item.acinfo[0].istst.louver)
                              : "-"}
                          </td>
                          <td>{item.acinfo[0] ? functionPe(item) : "-"}</td>
                          <td>
                            {item.acinfo[0]
                              ? parseInt(item.acinfo[0].istst.uPtRem) === 1
                                ? "Auto Function: Active"
                                : "Auto Function: Inactive"
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0] ? item.acinfo[0].istst.APS : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? parseInt(item.acinfo[0].istst.TrbAPS) === 1
                                ? "Ozone Filter Disinfection: Active"
                                : "Ozone Filter Disinfection: Inactive"
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0] ? item.acinfo[0].istst.RH2 : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? parseFloat(item.acinfo[0].istst.RT2) === 255
                                ? "Error"
                                : item.acinfo[0].istst.RT2
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? indoorOption(item.acinfo[0].istst.IND4_1)
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? indoorType(item.acinfo[0].istst.IND4_3)
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? indoorModel(item.acinfo[0].istst.IND4_4)
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0] ? item.acinfo[0].istst.CO2_H : "-"}
                          </td>
                          <td>
                            {item.acinfo[0] ? item.acinfo[0].istst.CO2_L : "-"}
                          </td>
                          <td>
                            {item.acinfo[0] ? item.acinfo[0].istst.PM2_2 : "-"}
                          </td>
                          <td>
                            {item.acinfo[0] ? item.acinfo[0].istst.PM2_1 : "-"}
                          </td>
                          <td>
                            {item.acinfo[0] ? item.acinfo[0].istst.PM2_0 : "-"}
                          </td>
                          <td>
                            {item.type === "SRV"
                              ? item.acinfo[0]
                                ? parseInt(item.acinfo[0].istst.IND2_0) === 255
                                  ? "Error"
                                  : parseInt(item.acinfo[0].istst.IND2_0) - 40
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {item.type === "SRV"
                              ? item.acinfo[0]
                                ? parseInt(item.acinfo[0].istst.IND2_1) === 255
                                  ? "Error"
                                  : parseInt(item.acinfo[0].istst.IND2_1) - 40
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? item.type === "SRV"
                                ? item.acinfo[0].istst.IND2_2
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? item.type === "SRV"
                                ? item.acinfo[0].istst.IND2_3
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {item.type === "SRV"
                              ? item.acinfo[0]
                                ? item.acinfo[0].istst.IND2_4
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0] ? item.acinfo[0].istst.IND2_5 : "-"}
                          </td>
                          <td>
                            {item.type === "SRV"
                              ? item.acinfo[0]
                                ? indoorProtection(item.acinfo[0].istst.IND2_6)
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {item.model === "PE" ||
                            item.model === "IC" ||
                            item.model === "FFU"
                              ? item.acinfo[0]
                                ? parseInt(item.acinfo[0].istst.IND2_0) === 255
                                  ? "Error"
                                  : parseInt(item.acinfo[0].istst.IND2_0) - 40
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {item.model === "PE" ||
                            item.model === "IC" ||
                            item.model === "FFU"
                              ? item.acinfo[0]
                                ? parseInt(item.acinfo[0].istst.IND2_1) === 255
                                  ? "Error"
                                  : parseInt(item.acinfo[0].istst.IND2_1) - 40
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? item.model === "PE" ||
                                item.model === "IC" ||
                                item.model === "FFU"
                                ? item.acinfo[0].istst.IND2_2
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? item.model === "PE" ||
                                item.model === "IC" ||
                                item.model === "FFU"
                                ? item.acinfo[0].istst.IND2_3
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? item.model === "PE" ||
                                item.model === "IC" ||
                                item.model === "FFU"
                                ? item.acinfo[0].istst.IND2_4
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? item.model === "PE" ||
                                item.model === "IC" ||
                                item.model === "FFU"
                                ? item.acinfo[0].istst.IND2_6
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? item.model === "PE" ||
                                item.model === "IC" ||
                                item.model === "FFU"
                                ? item.acinfo[0].istst.IND2_7
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? item.model === "PE" ||
                                item.model === "IC" ||
                                item.model === "FFU"
                                ? item.acinfo[0].istst.IND2_8
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? item.model === "PE" ||
                                item.model === "IC" ||
                                item.model === "FFU"
                                ? item.acinfo[0].istst.IND2_9
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? item.model === "IAQ"
                                ? (item.acinfo[0].istst.IND2_2 - 3000) / 10
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? item.model === "IAQ"
                                ? (item.acinfo[0].istst.IND2_3 - 3000) / 10
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? item.model === "IAQ"
                                ? (item.acinfo[0].istst.IND2_8 - 3000) / 10
                                : "-"
                              : "-"}
                          </td>
                          <td>
                            {item.acinfo[0]
                              ? item.model === "IAQ"
                                ? (item.acinfo[0].istst.IND2_9 - 3000) / 10
                                : "-"
                              : "-"}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={55}>No Data</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <Pagination
                postsPerPage={PerPage}
                totalPosts={reportData.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        show={loading}
        backdrop="static"
        keyboard="false"
        centered
        id="loading-modal"
      >
        <div className="loader"></div>
      </Modal>
    </div>
  );
};

export default ReportIndoor;
