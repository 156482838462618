import React, { useState, useEffect } from "react";
import { Row, Col, Table, Form, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import moment from "moment";

import Header from "../components/header";
import Nav from "../components/nav";
import Pagination from "../components/pagination";
import ExportExcel from "../components/export_excel";
import { odu_report_history, odu_system_list } from "../api/report";
import DatePicker from "react-datepicker";
import { addDays, subDays } from "date-fns";
import { useRealtimeClock } from "../hook/useHook";

import "react-datepicker/dist/react-datepicker.css";

const ReportOduHistory = (props) => {
  const { state } = useLocation();

  const [reportList, setReportList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage] = useState(20);
  const [open, setOpen] = useState(false);
  const [gateway, setGateway] = useState("");
  const [gatewayFilter, setGatewayFilter] = useState("");
  const [cdu, setCdu] = useState("");
  const [cduFilter, setCduFilter] = useState("");
  const [startDate, setStartDate] = useState(subDays(new Date(), 6));
  const [endDate, setEndDate] = useState(new Date());
  const [exportData, setExportData] = useState([]);
  const [systemList, setSystemList] = useState([]);
  const [loaded, setLoaded] = useState(true);

  const [clock] = useRealtimeClock();

  const indexOfLastPost = currentPage * PerPage;
  const indexOfFirstPost = indexOfLastPost - PerPage;
  const currentPosts = reportList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    (async () => {
      try {
        let token = localStorage.getItem("token");
        let res_system = await odu_system_list(token);

        let res = await odu_report_history(
          token,
          res_system.data.systemlist[0].gateway_sn,
          moment().subtract(6, "days").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD")
        );

        setSystemList(res_system.data.systemlist);
        setReportList(res.data.data);

        setGateway(
          res_system.data.systemlist.length
            ? res_system.data.systemlist[0].gateway_sn
            : ""
        );
        setGatewayFilter(
          res_system.data.systemlist.length
            ? res_system.data.systemlist[0].gateway_sn
            : ""
        );
        setCdu(
          res_system.data.systemlist.length
            ? res_system.data.systemlist[0].cdu_no
            : ""
        );
        setCduFilter(
          res_system.data.systemlist.length
            ? res_system.data.systemlist[0].cdu_no
            : ""
        );

        setLoaded(false);

        let data = [];
        res.data.data.map((i) => {
          data.push({
            Gateway: i.gateway_sn,
            Location: i.location.length
              ? i.location.map((k) => {
                  return k.building + " " + k.floor + " " + k.room + ", ";
                })
              : "-",

            "ODU ID": i.oduid,
            "Mid Coil": i.mid_coil,
            "Outlet Temp.": i.outlet_temp,
            "Discharge Pipe Temp. 1": i.discharge_pipe_temp1,
            "Discharge Pipe Temp. 2": i.discharge_pipe_temp2,
            "Ambient Temp.": i.ambient_temp,
            "Suction Pipe Temp.": i.suction_pipe_temp,
            "Control Device Temp. 1": i.control_device_temp1,
            "Control Device Temp. 2": i.control_device_temp2,
            "Compressor Actual Speed 1": i.compressor1_actual_speed,
            "Compressor Actual Speed 2": i.compressor2_actual_speed,
            "Compressor Current 1": i.compressor1_current,
            "Compressor Current 2": i.compressor2_current,
            "Outdoor Fan": i.outdoor_fan,
            "System Mode": i.system_mode,
            "Unit Capacity": i.unit_capacity,
            "Demand Running 1": i.demand_running1,
            "Demand Running 2": i.demand_running2,
            "System Status Comp. 1": i.system_status_comp1,
            "System Status Comp. 2": i.system_status_comp2,
            "Protect Status System 1": i.protect_status_system1,
            "Protect Status System 2": i.protect_status_system2,
            "System Power Input": i.system_power_input,
            "Outdoor EXV": i.outdoor_exv,
            "Outdoor LED": i.outdoor_led,
          });

          return i;
        });

        setExportData(data);
      } catch (error) {
        console.log(error);
        setLoaded(false);
      }
    })();
  }, []);

  const filterReport = async () => {
    setOpen(false);
    setLoaded(true);
    setGateway(gatewayFilter);
    setCdu(cduFilter);

    let start = moment(startDate).utc().format("YYYY-MM-DD");
    let end = moment(endDate).utc().format("YYYY-MM-DD");

    try {
      let token = localStorage.getItem("token");
      let res = await odu_report_history(token, gatewayFilter, start, end);
      let res_system = await odu_system_list(token);

      setSystemList(res_system.data.systemlist);

      setReportList(res.data.data);
      setLoaded(false);

      let data = [];
      res.data.data.map((i) => {
        data.push({
          Gateway: i.gateway_sn,
          Location: i.location.length
            ? i.location.map((k) => {
                return k.building + " " + k.floor + " " + k.room + ", ";
              })
            : "-",
          "ODU ID": i.oduid,
          "Mid Coil": i.mid_coil,
          "Outlet Temp.": i.outlet_temp,
          "Discharge Pipe Temp. 1": i.discharge_pipe_temp1,
          "Discharge Pipe Temp. 2": i.discharge_pipe_temp2,
          "Ambient Temp.": i.ambient_temp,
          "Suction Pipe Temp.": i.suction_pipe_temp,
          "Control Device Temp. 1": i.control_device_temp1,
          "Control Device Temp. 2": i.control_device_temp2,
          "Compressor Actual Speed 1": i.compressor1_actual_speed,
          "Compressor Actual Speed 2": i.compressor2_actual_speed,
          "Compressor Current 1": i.compressor1_current,
          "Compressor Current 2": i.compressor2_current,
          "Outdoor Fan": i.outdoor_fan,
          "System Mode": i.system_mode,
          "Unit Capacity": i.unit_capacity,
          "Demand Running 1": i.demand_running1,
          "Demand Running 2": i.demand_running2,
          "System Status Comp. 1": i.system_status_comp1,
          "System Status Comp. 2": i.system_status_comp2,
          "Protect Status System 1": i.protect_status_system1,
          "Protect Status System 2": i.protect_status_system2,
          "System Power Input": i.system_power_input,
          "Outdoor EXV": i.outdoor_exv,
          "Outdoor LED": i.outdoor_led,
        });

        return i;
      });

      setExportData(data);
    } catch (error) {
      console.log(error);
      setLoaded(false);
    }
  };

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-4 pt-5 pt-xl-0">
        <Row className="mx-0 px-xl-4">
          <Col xs={12}>
            <div className="table-container px-2 px-sm-3 px-xl-4 py-3 py-sm-4">
              <div className="table-container-title px-2 w-100">
                <Row className="w-100">
                  <Col xs={12} md={"auto"} className="position-relative">
                    <h5 className="mb-1">
                      {state?.report_title
                        ? state.report_title
                        : "ODU History Report"}
                      {": "}
                      {cdu ? cdu : "-"}
                    </h5>
                    <div
                      id="report-open"
                      className="control-select-dropdown"
                      onClick={() => setOpen(!open)}
                    ></div>
                    <div
                      className="control-select-container p-2"
                      style={{
                        visibility: open ? "visible" : "hidden",
                        opacity: open ? 1 : 0,
                        top: "40px",
                      }}
                    >
                      <div className="control-select">
                        <p className="mb-0 pe-2">CDU No.</p>
                        <Form.Select
                          size="sm"
                          onChange={(e) => {
                            setGatewayFilter(e.target.value);
                            setCduFilter(
                              e.nativeEvent.target[
                                e.nativeEvent.target.selectedIndex
                              ].text
                            );
                          }}
                          defaultValue={gateway}
                        >
                          {systemList.map((item, index) =>
                            item.gateway_sn ? (
                              <option value={item.gateway_sn} key={index}>
                                {item.cdu_no}
                              </option>
                            ) : null
                          )}
                        </Form.Select>
                      </div>
                      <div className="control-select">
                        <p className="mb-0 pe-2">Date Range</p>
                        <DatePicker
                          selected={startDate}
                          onChange={onDateChange}
                          dateFormat="dd/MM/yyyy"
                          className="form-control form-control-sm"
                          startDate={startDate}
                          endDate={endDate}
                          selectsRange
                          maxDate={addDays(startDate, 6)}
                        />
                      </div>
                      <button
                        className="control-search-btn my-2 me-2"
                        onClick={() => filterReport()}
                      >
                        Confirm
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
              <p className="report-time px-2 mb-2 mb-lg-4">
                {clock ? clock : moment().format("DD/MM/YYYY [TIME] HH:mm:ss")}
              </p>
              <ExportExcel
                csvData={exportData}
                fileName={
                  "ODU History Report_" +
                  moment().format("DD_MM_YYYY_HH_mm_ss") +
                  "_.csv"
                }
              />
              <div className="mx-2 mb-2 table-overflow">
                <Table hover className="mt-3 error-table mb-2">
                  <thead>
                    <tr>
                      <th className="sticky-col" style={{ left: 0 }}>
                        Gateway
                      </th>
                      <th
                        className="sticky-col"
                        style={{
                          left: "100px",
                          minWidth: "300px",
                        }}
                      >
                        Location
                      </th>
                      <th>ODU ID</th>
                      <th>Mid Coil</th>
                      <th>Outlet Temp.</th>
                      <th>Discharge Pipe Temp. 1</th>
                      <th>Discharge Pipe Temp. 2</th>
                      <th>Ambient Temp.</th>
                      <th>Suction Pipe Temp.</th>
                      <th>Control Device Temp. 1</th>
                      <th>Control Device Temp. 2</th>
                      <th>Compressor Actual Speed 1</th>
                      <th>Compressor Actual Speed 2</th>
                      <th>Compressor Current 1</th>
                      <th>Compressor Current 2</th>
                      <th>Outdoor Fan</th>
                      <th>System Mode</th>
                      <th>Unit Capacity</th>
                      <th>Demand Running 1</th>
                      <th>Demand Running 2</th>
                      <th>System Status Comp. 1</th>
                      <th>System Status Comp. 2</th>
                      <th>Protect Status System 1</th>
                      <th>Protect Status System 2</th>
                      <th>System Power Input</th>
                      <th>Outdoor EXV</th>
                      <th>Outdoor LED</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportList.length ? (
                      currentPosts.map((i, key) => (
                        <tr key={key}>
                          <td className="sticky-col" style={{ left: 0 }}>
                            {i.gateway_sn}
                          </td>
                          <td
                            className="sticky-col"
                            style={{
                              left: "100px",
                              minWidth: "300px",
                            }}
                          >
                            {i.location.length
                              ? i.location.map((j, index) => (
                                  <React.Fragment key={index}>
                                    {j.building +
                                      " " +
                                      j.floor +
                                      " " +
                                      j.room +
                                      ", "}
                                  </React.Fragment>
                                ))
                              : "-"}
                          </td>

                          <td>{i.oduid}</td>
                          <td>{i.mid_coil}</td>
                          <td
                            style={{
                              color:
                                i.outlet_temp === "No Sensor"
                                  ? "#b31117"
                                  : "#212529",
                            }}
                          >
                            {i.outlet_temp}
                          </td>
                          <td>{i.discharge_pipe_temp1}</td>
                          <td>{i.discharge_pipe_temp2}</td>
                          <td>{i.ambient_temp}</td>
                          <td>{i.suction_pipe_temp}</td>
                          <td>{i.control_device_temp1}</td>
                          <td>{i.control_device_temp2}</td>
                          <td>{i.compressor1_actual_speed}</td>
                          <td>{i.compressor2_actual_speed}</td>
                          <td>{i.compressor1_current}</td>
                          <td>{i.compressor2_current}</td>
                          <td>{i.outdoor_fan}</td>
                          <td>{i.system_mode}</td>
                          <td>{i.unit_capacity}</td>
                          <td>{i.demand_running1}</td>
                          <td>{i.demand_running2}</td>
                          <td
                            style={{
                              color:
                                i.system_status_comp1 === "Stop"
                                  ? "#b31117"
                                  : "#00A300",
                            }}
                          >
                            {i.system_status_comp1}
                          </td>
                          <td
                            style={{
                              color:
                                i.system_status_comp2 === "Stop"
                                  ? "#b31117"
                                  : "#00A300",
                            }}
                          >
                            {i.system_status_comp2}
                          </td>
                          <td
                            style={{
                              color:
                                i.protect_status_system1 === "Nomal"
                                  ? "#00A300"
                                  : "#b31117",
                            }}
                          >
                            {i.protect_status_system1}
                          </td>
                          <td
                            style={{
                              color:
                                i.protect_status_system2 === "Nomal"
                                  ? "#00A300"
                                  : "#b31117",
                            }}
                          >
                            {i.protect_status_system2}
                          </td>
                          <td>{i.system_power_input}</td>
                          <td>{i.outdoor_exv}</td>
                          <td>{i.outdoor_led}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={28}>No Data</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <Pagination
                postsPerPage={PerPage}
                totalPosts={reportList.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        show={loaded}
        backdrop="static"
        keyboard="false"
        centered
        id="loading-modal"
      >
        <div className="loader"></div>
      </Modal>
    </div>
  );
};

export default ReportOduHistory;
