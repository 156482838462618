import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Table, Modal, Form, Button } from "react-bootstrap";

import Header from "../components/header";
import Nav from "../components/nav";
import Pagination from "../components/pagination";
import Upload from "../assets/image/setting/upload.png";
import { building_list } from "../api/product";
import { add_floor, update_floor, delete_floor } from "../api/setting";

const SettingFloor = (props) => {
  const [buildingList, setBuildingList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage] = useState(20);
  const [show, setShow] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [floor, setFloor] = useState("");
  const [buildingID, setBuildingID] = useState("");
  const [floorID, setFloorID] = useState("");
  const [floorImage, setFloorImage] = useState(null);
  const [validateImage, setValidateImage] = useState(true);
  const [buildingSearch, setBuildingSearch] = useState("");
  const [floorSearch, setFloorSearch] = useState("");
  const [floorListSearchResult, setFloorListSearchResult] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [buildingValidate, setBuildingValidate] = useState(true);
  const [floorValidate, setFloorValidate] = useState(true);
  const [buildingListSearch, setBuildingListSearch] = useState([]);
  const [floorListSearch, setFloorListSearch] = useState([]);

  const indexOfLastPost = currentPage * PerPage;
  const indexOfFirstPost = indexOfLastPost - PerPage;
  const currentPosts = floorListSearchResult.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);

  useEffect(() => {
    (async () => {
      let buildings = [];
      let floors = [];

      try {
        let token = localStorage.getItem("token");
        let companyId = localStorage.getItem("company_id");

        let res = await building_list(token);

        let buildingData = res.data.data.filter((item) => {
          return item.company_id === companyId;
        });

        buildingData.map((item) => {
          buildings.push({
            building: item.title,
            building_id: item._id,
          });

          return item;
        });

        buildings.sort((a, b) => {
          let x = a.building_id.toLowerCase();
          let y = b.building_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        buildingData.map((item) => {
          if (item.floors !== undefined) {
            item.floors.map((data) => {
              floors.push({
                building: item.title,
                building_id: item._id,
                floor_id: data.floorId,
                floor: data.title,
                floor_image: data.plan_img ? data.plan_img : null,
              });

              return data;
            });
          }

          return item;
        });

        floors.sort((a, b) => {
          let x = a.floor_id.toLowerCase();
          let y = b.floor_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        setBuildingList(buildings);
        setFloorList(floors);
        setFloorListSearchResult(floors);
        setLoaded(false);

        setBuildingListSearch(buildings);
        setFloorListSearch([]);

        setBuildingSearch("");
        setFloorSearch("");
      } catch (error) {
        console.log(error);
        setLoaded(false);
      }
    })();
  }, [loaded]);

  const handleSubmit = async (type) => {
    if (!buildingID) {
      setBuildingValidate(false);
    }

    if (!floor) {
      setFloorValidate(false);
    }

    if (!floorImage) {
      setValidateImage(false);
    }

    if (!buildingID || !floor) {
      return;
    }

    try {
      let token = localStorage.getItem("token");

      if (type === "add") {
        await add_floor(token, buildingID, floor, floorImage);
        alert("Floor added successfully.");

        setAddForm(false);
        setLoaded(true);
      } else if (type === "edit") {
        await update_floor(token, buildingID, floorID, floor, null);

        if (floorImage) {
          await update_floor(token, buildingID, floorID, floor, floorImage);
        }

        alert("Floor updated successfully.");

        setEditForm(false);
        setLoaded(true);
      }
    } catch (error) {
      console.log(error.message);
      alert(type === "add" ? "Floor added failed!" : "Floor updated failed!");
    }
  };

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);

      setValidateImage(true);
      setFloorImage(e.target.files[0]);
    }
  };

  const search = () => {
    var result = floorList.filter((item) => {
      if (
        item.building_id.toLowerCase().indexOf(buildingSearch.toLowerCase()) >=
          0 &&
        item.floor_id.toLowerCase().indexOf(floorSearch.toLowerCase()) >= 0
      ) {
        return true;
      } else {
        return false;
      }
    });

    setFloorListSearchResult(result);
  };

  const clearSearch = () => {
    setBuildingSearch("");
    setFloorSearch("");
    setFloorListSearchResult(floorList);
  };

  const deleteHandle = async () => {
    try {
      let token = localStorage.getItem("token");
      await delete_floor(token, buildingID, floor);
      setDeleteModal(false);
      setLoaded(true);
    } catch (error) {
      console.log(error);
      setDeleteModal(false);
      setLoaded(true);
    }
  };

  const buildingChangeHandle = (val) => {
    if (val.trim().length) {
      setBuildingID(val);
      setBuildingValidate(true);
    } else {
      setBuildingID(val);
      setBuildingValidate(false);
    }
  };

  const floorChangeHandle = (val) => {
    if (val.trim().length) {
      setFloor(val);
      setFloorValidate(true);
    } else {
      setFloor(val);
      setFloorValidate(false);
    }
  };

  const changeBuildingSearchHandle = async (val) => {
    setBuildingSearch(val);

    let floors = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (val === item._id) {
          if (item.floors) {
            item.floors.map((i) => {
              floors.push({
                floor: i.title,
                floor_id: i.floorId,
                floor_image: i.plan_img,
              });

              return i;
            });
          }
        }

        return item;
      });

      floors.sort((a, b) => {
        let x = a.floor_id.toLowerCase();
        let y = b.floor_id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setFloorListSearch(floors);
      setFloorSearch("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-4 mt-4 pt-5 pt-xl-0">
        <Row className="mx-0 px-xl-4">
          <Col xs={12}>
            <div className="table-container px-2 px-sm-3 px-xl-4 py-3 py-sm-4">
              <div
                className="table-container-title px-2"
                style={{ justifyContent: "space-between" }}
              >
                <h5 className="mb-1">Floor Management</h5>
                <button
                  className="add-modal-btn"
                  onClick={() => {
                    setFloor("");
                    setBuildingID("");
                    setFloorImage(null);
                    setAddForm(true);
                  }}
                >
                  Add Floor
                </button>
              </div>
              <Row>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-3 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">
                      Building Name
                    </Form.Label>
                    {/* <Form.Control
                      type="text"
                      placeholder="Building"
                      required
                      onChange={(e) => setBuildingSearch(e.target.value)}
                      value={buildingSearch}
                    /> */}
                    <Form.Select
                      onChange={(e) =>
                        changeBuildingSearchHandle(e.target.value)
                      }
                      required
                      value={buildingSearch}
                    >
                      <option value="">Select Building</option>
                      {buildingListSearch.map((item, index) => (
                        <option value={item.building_id} key={index}>
                          {item.building}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-1 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">Floor Name</Form.Label>
                    <Form.Select
                      onChange={(e) => setFloorSearch(e.target.value)}
                      required
                      value={floorSearch}
                    >
                      <option value="">Select Floor</option>
                      {floorListSearch.map((item, index) => (
                        <option value={item.floor_id} key={index}>
                          {item.floor}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={"auto"} className="pe-0 ms-2 ms-sm-0 mt-2 mt-sm-0">
                  <Form.Group className="mt-4 mb-2 px-2 d-none d-sm-block">
                    <Form.Label className="input-label mb-0">&nbsp;</Form.Label>
                  </Form.Group>
                  <button className="search-btn" onClick={() => search()}>
                    Search
                  </button>
                </Col>
                <Col xs={"auto"} className="mt-2 mt-sm-0">
                  <Form.Group className="mt-4 mb-2 px-2 d-none d-sm-block">
                    <Form.Label className="input-label mb-0">&nbsp;</Form.Label>
                  </Form.Group>
                  <button className="clear-btn" onClick={() => clearSearch()}>
                    Clear
                  </button>
                </Col>
              </Row>
              <div className="mx-2 mb-2 table-overflow">
                <Table hover className="mt-3 mb-2 setting-table align-middle">
                  <thead>
                    <tr>
                      <th className="px-4">No.</th>
                      <th className="text-center px-4">Image</th>
                      <th className="text-center px-4">Building Name</th>
                      <th className="text-center px-4">Floor Name</th>
                      <th className="text-center px-4">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {floorListSearchResult.length ? (
                      currentPosts.map((item, index) => (
                        <tr key={index}>
                          <td className="px-4">
                            {currentPage === 1
                              ? index + 1
                              : index + 1 + (currentPage - 1) * PerPage}
                            .
                          </td>
                          <td className="px-4 text-center" width={"10%"}>
                            {item.floor_image ? (
                              <img
                                src={
                                  item.floor_image
                                    ? "http://node-env.eba-7xwegkmr.ap-southeast-1.elasticbeanstalk.com/api/v2.0/images/upload/" +
                                      item.floor_image
                                    : null
                                }
                                alt="floor"
                                className="table-image py-2"
                                onClick={() => {
                                  setModalImage(
                                    item.floor_image
                                      ? "http://node-env.eba-7xwegkmr.ap-southeast-1.elasticbeanstalk.com/api/v2.0/images/upload/" +
                                          item.floor_image
                                      : null
                                  );
                                  setShow(true);
                                }}
                              />
                            ) : null}
                          </td>
                          <td className="px-4 text-center" width={"20%"}>
                            {item.building}
                          </td>
                          <td className="px-4 text-center" width={"20%"}>
                            {item.floor}
                          </td>
                          <td className="px-4 text-center">
                            <span
                              className="table-edit"
                              onClick={() => {
                                setFloor(item.floor);
                                setBuildingID(item.building_id);
                                setFloorID(item.floor_id);
                                setModalImage(
                                  item.floor_image
                                    ? "http://node-env.eba-7xwegkmr.ap-southeast-1.elasticbeanstalk.com/api/v2.0/images/upload/" +
                                        item.floor_image
                                    : null
                                );
                                setEditForm(true);
                              }}
                            >
                              Edit
                            </span>{" "}
                            /{" "}
                            <span
                              className="table-delete"
                              onClick={() => {
                                setFloor(item.floor);
                                setBuildingID(item.building_id);
                                setDeleteModal(true);
                              }}
                            >
                              Delete
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-center">
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <Pagination
                postsPerPage={PerPage}
                totalPosts={floorListSearchResult.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Modal size="lg" show={show} onHide={() => setShow(false)} centered>
        <div className="position-relative">
          <img src={modalImage} alt="modal" className="w-100 h-auto" />
          <button
            type="button"
            className="btn-close modal-close-btn"
            aria-label="Close"
            onClick={() => setShow(false)}
          />
        </div>
      </Modal>
      <Modal show={editForm} onHide={() => setEditForm(false)} centered>
        <Modal.Body>
          <h4 className="modal-title mb-2">Edit Floor</h4>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Building Name</Form.Label>
            <Form.Select
              onChange={(e) => buildingChangeHandle(e.target.value)}
              required
              value={buildingID}
              style={{
                borderColor: buildingValidate ? "#cccccc" : "#b31117",
              }}
            >
              <option value="">Select Building</option>
              {buildingList.map((item, index) => (
                <option value={item.building_id} key={index}>
                  {item.building}
                </option>
              ))}
            </Form.Select>
            {!buildingValidate && (
              <Form.Label
                className="input-label mt-1"
                style={{ color: "#b31117" }}
              >
                Please select building.
              </Form.Label>
            )}
          </Form.Group>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Floor Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Building"
              defaultValue={floor}
              required
              onChange={(e) => floorChangeHandle(e.target.value)}
              style={{
                borderColor: floorValidate ? "#cccccc" : "#b31117",
              }}
            />
            {!floorValidate && (
              <Form.Label
                className="input-label mt-1"
                style={{ color: "#b31117" }}
              >
                Please provide a valid floor name.
              </Form.Label>
            )}
          </Form.Group>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Floor Image</Form.Label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                  display: "none",
                }}
              />
              <div
                className="form-image-container mb-2"
                onClick={() => imageUploader.current.click()}
              >
                <img
                  ref={uploadedImage}
                  src={modalImage ? modalImage : Upload}
                  className="form-image-input"
                  alt="floor"
                />
              </div>
            </div>
          </Form.Group>
          <div className="modal-btn-container mb-2">
            <Button
              type="button"
              onClick={() => {
                setEditForm(false);
                setValidateImage(true);
                setBuildingValidate(true);
                setFloorValidate(true);
              }}
            >
              Cancel
            </Button>
            <Button type="button" onClick={() => handleSubmit("edit")}>
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={addForm} onHide={() => setAddForm(false)} centered>
        <Modal.Body>
          <h4 className="modal-title mb-2">Add Floor</h4>

          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Building Name</Form.Label>
            <Form.Select
              onChange={(e) => buildingChangeHandle(e.target.value)}
              required
              style={{
                borderColor: buildingValidate ? "#cccccc" : "#b31117",
              }}
            >
              <option value="">Select Building</option>
              {buildingList.map((item, index) => (
                <option value={item.building_id} key={index}>
                  {item.building}
                </option>
              ))}
            </Form.Select>
            {!buildingValidate && (
              <Form.Label
                className="input-label mt-1"
                style={{ color: "#b31117" }}
              >
                Please select building.
              </Form.Label>
            )}
          </Form.Group>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Floor Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Floor"
              onChange={(e) => floorChangeHandle(e.target.value)}
              required
              style={{
                borderColor: floorValidate ? "#cccccc" : "#b31117",
              }}
            />
            {!floorValidate && (
              <Form.Label
                className="input-label mt-1"
                style={{ color: "#b31117" }}
              >
                Please provide a valid floor name.
              </Form.Label>
            )}
          </Form.Group>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Building Image</Form.Label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                  display: "none",
                }}
                required
              />
              <div
                className={
                  validateImage
                    ? "form-image-container"
                    : "form-image-container error-image-upload"
                }
                onClick={() => imageUploader.current.click()}
              >
                <img
                  ref={uploadedImage}
                  src={Upload}
                  className="form-image-input"
                  alt="profile"
                />
              </div>
              <p
                className="form-error mb-0"
                style={{ display: validateImage ? "none" : "block" }}
              >
                Please provide a building image.
              </p>
            </div>
          </Form.Group>
          <div className="modal-btn-container mb-2">
            <Button
              type="button"
              onClick={() => {
                setAddForm(false);
                setBuildingValidate(true);
                setFloorImage(true);
                setValidateImage(true);
              }}
            >
              Cancel
            </Button>
            <Button type="button" onClick={() => handleSubmit("add")}>
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={deleteModal} centered>
        <Modal.Body>
          <h4 className="modal-title mb-4">Do you want delete this floor ?</h4>
          <div className="modal-btn-container mb-2">
            <Button
              type="button"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={() => {
                deleteHandle();
              }}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={loaded}
        backdrop="static"
        keyboard="false"
        centered
        id="loading-modal"
      >
        <div className="loader"></div>
      </Modal>
    </div>
  );
};

export default SettingFloor;
