import React from "react";

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="px-2">
      <ul className="pagination mb-0">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={
              currentPage === number
                ? "page-item pointer active"
                : "page-item pointer"
            }
          >
            <p onClick={() => paginate(number)} className="page-link">
              {number}
            </p>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
