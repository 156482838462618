import axios from "axios";
import { SERV_API } from "../constant_config";

const sign_in = async (username, password) => {
  return await axios({
    url: SERV_API + "auth/signin",
    method: "POST",
    data: {
      email: username,
      password: password,
    },
    // timeout: 5000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    },
  });
};

export { sign_in };
