import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import "../assets/styles/monitor.css";
import Clock from "react-live-clock";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

import { MqttContext } from "../components/mqtt_context";

import tempIcon from "../assets/image/monitor/temp-icon.png";
import carbonIcon from "../assets/image/monitor/carbon-icon.png";
import pmIcon from "../assets/image/monitor/pm-icon.png";
import humidityIcon from "../assets/image/monitor/humidity-icon.png";
import pressureIcon from "../assets/image/monitor/pressure-icon.png";
import Logo from "../assets/image/monitor/logo.png";
import IC from "../assets/image/monitor/iaq.png";
import Duct from "../assets/image/monitor/Duct.png";
import FFU from "../assets/image/monitor/FFU.png";

import { building_list, products } from "../api/product";

const checkModel = (odu, model) => {
  // let val = odu.substr(4, 5);

  // switch (true) {
  //   case val * 1 >= 10 && val * 1 < 15:
  //     if (model.toUpperCase() === "FFU" || model.toUpperCase() === "EX") {
  //       return model.toUpperCase();
  //     }
  //     return "FFU";
  //   case val * 1 >= 15 && val * 1 < 19:
  //     return "IAQ";
  //   default:
  //     return model.toUpperCase();
  // }

  return model.toUpperCase();
};

const LazyBackground = ({ src }) => {
  const [source, setSource] = useState(src);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setSource(src);
  }, [src]);

  return (
    <div
      className="plan-image-container"
      style={{ backgroundImage: `url('${source}')` }}
    ></div>
  );
};

const Devices = ({ building, floor, room }) => {
  const { deviceList } = useContext(MqttContext);
  const [acList, setAcList] = useState([]);

  const productImage = (model) => {
    switch (true) {
      case model === "IAQ":
        return IC;
      case model === "FFU":
        return FFU;
      default:
        return Duct;
    }
  };

  const powerConvert = (power) => {
    let power_bit = parseInt(power).toString(2).padStart(8, "0");
    let powerSplit = power_bit.split("");

    return powerSplit[7].toString();
  };

  useMemo(() => {
    let deviceArr = [];

    deviceList.map((item) => {
      if (
        item.location.building === building &&
        item.location.floor === floor &&
        item.location.room === room
      ) {
        checkModel(item.odu, item.model, item);
        deviceArr.push({
          title: item.title,
          model: item.model,
          odu: item.odu,
          power: item.acinfo.online
            ? item.acinfo[0]?.istst.onoff
              ? powerConvert(item.acinfo[0]?.istst.onoff) === "1"
                ? true
                : false
              : false
            : false,
          online: item.acinfo.online ? true : false,
          image: productImage(checkModel(item.odu, item.model)),
        });
      }

      return item;
    });

    setAcList(deviceArr);
  }, [room, building, floor, deviceList]);

  return (
    <div className="device-list">
      <div>
        <p className="device-list-header">NAME</p>
        {acList.map((item, index) => (
          <div className="device-info-container" key={index}>
            <img src={item.image} alt={checkModel(item.odu, item.model)} />
            <div className={item.online ? "" : "status-off"}>{item.title}</div>
          </div>
        ))}
      </div>
      <div>
        <p className="device-list-header">POWER</p>
        {acList.map((item, index) => (
          <div className="device-status-container" key={index}>
            <span
              className={
                item.online
                  ? item.power
                    ? "status-on"
                    : "status-off"
                  : "status-off"
              }
            >
              {item.online ? (item.power ? "ON" : "OFF") : "OFF"}
            </span>
          </div>
        ))}
      </div>
      <div>
        <p className="device-list-header">STATUS</p>
        {acList.map((item, index) => (
          <div className="device-status-container" key={index}>
            <span className={item.online ? "status-on" : "status-off"}>
              {item.online ? "ONLINE" : "OFFLINE"}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [building, setBuilding] = useState("");
  const [floor, setFloor] = useState("");
  const [room, setRoom] = useState("");
  const [roomTemp, setRoomTemp] = useState(0);
  const [carbon, setCarbon] = useState(0);
  const [pm, setPm] = useState(0);
  const [humidity, setHumidity] = useState(0);
  const [roomPressure, setRoomPressure] = useState(0);
  const [planUrl, setPlanUrl] = useState("");
  const [title, setTitle] = useState("");

  const { productList, setDeviceList } = useContext(MqttContext);

  const SERV_IMG =
    "http://node-env.eba-7xwegkmr.ap-southeast-1.elasticbeanstalk.com/api/v2.0/images/upload/";

  const interval = useRef(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      let decoded = jwt_decode(localStorage.getItem("token"));
      let exp = new Date(parseInt(decoded.exp) * 1000).getTime();
      let now = parseInt(new Date().getTime());

      if (exp < now) {
        navigate("/login_monitor");
      }
    } else {
      navigate("/login_monitor");
    }
  }, [navigate]);

  useEffect(() => {
    (async () => {
      try {
        let token = localStorage.getItem("token");
        let res = await building_list(token);
        let buildingList = [];

        res.data.data.map((i) => {
          if (i.floors) {
            i.floors.map((j) => {
              if (j.rooms) {
                j.rooms.map((k) => {
                  if (k.title.toUpperCase() !== "ALL") {
                    buildingList.push({
                      building: i.title,
                      floor: j.title,
                      room: k.title,
                      plan_img: SERV_IMG + k.plan_img,
                    });
                  }

                  return k;
                });
              }

              return j;
            });
          }

          return i;
        });

        let productArr = await products(token, "", "");
        productList.current = productArr.data.productsList;
        setDeviceList(productArr.data.productsList);

        if (buildingList.length) {
          setPlanUrl(buildingList[0].plan_img);
          setTitle(
            buildingList[0].building +
              " " +
              buildingList[0].floor +
              ", " +
              buildingList[0].room
          );
          setBuilding(buildingList[0].building);
          setFloor(buildingList[0].floor);
          setRoom(buildingList[0].room);

          let rt_val = 0;
          let carbon_val = 0;
          let pm_val = 0;
          let rh_val = 0;
          let rp_val = 0;

          productList.current.filter((item) => {
            if (
              checkModel(item.odu, item.model) === "IAQ" &&
              buildingList[0].building ===
                (item.location.building ? item.location.building : false) &&
              buildingList[0].floor ===
                (item.location.floor ? item.location.floor : false) &&
              buildingList[0].room ===
                (item.location.room ? item.location.room : false)
            ) {
              rt_val = item.acinfo[0]?.istst.RT2
                ? parseFloat(item.acinfo[0].istst.RT2)
                : 0;

              carbon_val = item.acinfo[0]?.istst.CO2_H
                ? parseInt(item.acinfo[0].istst.CO2_H) * 256
                : 0;
              carbon_val =
                carbon_val +
                (item.acinfo[0]?.istst.CO2_L
                  ? parseInt(item.acinfo[0].istst.CO2_L)
                  : 0);

              pm_val = item.acinfo[0]?.istst.PM2_2
                ? parseInt(item.acinfo[0].istst.PM2_2) * 65536
                : 0;

              pm_val =
                pm_val +
                (item.acinfo[0]?.istst.PM2_1
                  ? parseInt(item.acinfo[0].istst.PM2_1) * 256
                  : 0);

              pm_val =
                (pm_val +
                  (item.acinfo[0]?.istst.PM2_0
                    ? parseInt(item.acinfo[0].istst.PM2_0)
                    : 0)) /
                1000;

              rh_val = item.acinfo[0]?.istst.RH2
                ? parseFloat(item.acinfo[0].istst.RH2)
                : 0;

              rp_val = item.acinfo[0]?.istst.IND2_8
                ? (parseInt(item.acinfo[0].istst.IND2_8) - 3000) / 10
                : 0;

              setRoomTemp(rt_val);
              setCarbon(carbon_val);
              setPm(pm_val);
              setHumidity(rh_val);
              setRoomPressure(rp_val);

              return item;
            }

            return item;
          });

          let i = 1;
          interval.current = setInterval(() => {
            rt_val = 0;
            carbon_val = 0;
            pm_val = 0;
            rh_val = 0;
            rp_val = 0;

            if (i >= buildingList.length) {
              i = 0;

              setPlanUrl(buildingList[i].plan_img);
              setTitle(
                buildingList[i].building +
                  " " +
                  buildingList[i].floor +
                  ", " +
                  buildingList[i].room
              );
              setBuilding(buildingList[i].building);
              setFloor(buildingList[i].floor);
              setRoom(buildingList[i].room);

              productList.current.filter((item) => {
                if (
                  checkModel(item.odu, item.model) === "IAQ" &&
                  buildingList[i].building ===
                    (item.location.building ? item.location.building : false) &&
                  buildingList[i].floor ===
                    (item.location.floor ? item.location.floor : false) &&
                  buildingList[i].room ===
                    (item.location.room ? item.location.room : false)
                ) {
                  rt_val = item.acinfo[0]?.istst.RT2
                    ? parseFloat(item.acinfo[0].istst.RT2)
                    : 0;

                  carbon_val = item.acinfo[0]?.istst.CO2_H
                    ? parseInt(item.acinfo[0].istst.CO2_H) * 256
                    : 0;
                  carbon_val =
                    carbon_val +
                    (item.acinfo[0]?.istst.CO2_L
                      ? parseInt(item.acinfo[0].istst.CO2_L)
                      : 0);

                  pm_val = item.acinfo[0]?.istst.PM2_2
                    ? parseInt(item.acinfo[0].istst.PM2_2) * 65536
                    : 0;

                  pm_val =
                    pm_val +
                    (item.acinfo[0]?.istst.PM2_1
                      ? parseInt(item.acinfo[0].istst.PM2_1) * 256
                      : 0);

                  pm_val =
                    (pm_val +
                      (item.acinfo[0]?.istst.PM2_0
                        ? parseInt(item.acinfo[0].istst.PM2_0)
                        : 0)) /
                    1000;

                  rh_val = item.acinfo[0]?.istst.RH2
                    ? parseFloat(item.acinfo[0].istst.RH2)
                    : 0;

                  rp_val = item.acinfo[0]?.istst.IND2_8
                    ? (parseInt(item.acinfo[0].istst.IND2_8) - 3000) / 10
                    : 0;
                }

                return item;
              });

              setRoomTemp(rt_val);
              setCarbon(carbon_val);
              setPm(pm_val);
              setHumidity(rh_val);
              setRoomPressure(rp_val);

              i++;
            } else {
              setPlanUrl(buildingList[i].plan_img);
              setTitle(
                buildingList[i].building +
                  " " +
                  buildingList[i].floor +
                  ", " +
                  buildingList[i].room
              );
              setBuilding(buildingList[i].building);
              setFloor(buildingList[i].floor);
              setRoom(buildingList[i].room);

              productList.current.filter((item) => {
                if (
                  checkModel(item.odu, item.model) === "IAQ" &&
                  buildingList[i].building ===
                    (item.location.building ? item.location.building : false) &&
                  buildingList[i].floor ===
                    (item.location.floor ? item.location.floor : false) &&
                  buildingList[i].room ===
                    (item.location.room ? item.location.room : false)
                ) {
                  rt_val = item.acinfo[0]?.istst.RT2
                    ? parseFloat(item.acinfo[0].istst.RT2)
                    : 0;

                  carbon_val = item.acinfo[0]?.istst.CO2_H
                    ? parseInt(item.acinfo[0].istst.CO2_H) * 256
                    : 0;
                  carbon_val =
                    carbon_val +
                    (item.acinfo[0]?.istst.CO2_L
                      ? parseInt(item.acinfo[0].istst.CO2_L)
                      : 0);

                  pm_val = item.acinfo[0]?.istst.PM2_2
                    ? parseInt(item.acinfo[0].istst.PM2_2) * 65536
                    : 0;

                  pm_val =
                    pm_val +
                    (item.acinfo[0]?.istst.PM2_1
                      ? parseInt(item.acinfo[0].istst.PM2_1) * 256
                      : 0);

                  pm_val =
                    (pm_val +
                      (item.acinfo[0]?.istst.PM2_0
                        ? parseInt(item.acinfo[0].istst.PM2_0)
                        : 0)) /
                    1000;

                  rh_val = item.acinfo[0]?.istst.RH2
                    ? parseFloat(item.acinfo[0].istst.RH2)
                    : 0;

                  rp_val = item.acinfo[0]?.istst.IND2_8
                    ? (parseInt(item.acinfo[0].istst.IND2_8) - 3000) / 10
                    : 0;
                }

                return item;
              });

              setRoomTemp(rt_val);
              setCarbon(carbon_val);
              setPm(pm_val);
              setHumidity(rh_val);
              setRoomPressure(rp_val);

              i++;
            }
          }, 10000);
        }
      } catch (e) {
      } finally {
      }
    })();

    return () => clearInterval(interval.current);
  }, [productList, setDeviceList]);

  const roomTempColor = (val) => {
    switch (true) {
      case val < 50 / 6:
        return "#018cf1";
      case val < (50 / 6) * 2:
        return "#0197ea";
      case val < (50 / 6) * 3:
        return "#01a1e3";
      case val < (50 / 6) * 4:
        return "#01abdb";
      case val < (50 / 6) * 5:
        return "#00b6d4";
      default:
        return "#00c1cd";
    }
  };

  const carbonColor = (val) => {
    switch (true) {
      case val < 400:
        return "#01e2ad";
      case val < 800:
        return "#23e29c";
      case val < 1200:
        return "#45e28b";
      case val < 1600:
        return "#66e279";
      default:
        return "#88e268";
    }
  };

  const pmColor = (val) => {
    switch (true) {
      case parseFloat(val) < 12.1:
        return "#94DB7B";
      case parseFloat(val) < 35.5:
        return "#FCE750";
      case parseFloat(val) < 55.5:
        return "#FE9657";
      case parseFloat(val) < 150.5:
        return "#D4424F";
      case parseFloat(val) < 250.5:
        return "#AF2787";
      default:
        return "#94003B";
    }
  };

  const humidityColor = (val) => {
    switch (true) {
      case val < 25:
        return "#018cf1";
      case val < 50:
        return "#2ee296";
      case val < 75:
        return "#5be27f";
      default:
        return "#88e268";
    }
  };

  const roomPressureColor = (val) => {
    switch (true) {
      case val < -50:
        return "#018cf1";
      case val < 50:
        return "#01a1e3";
      case val < 250:
        return "#00b6d4";
      default:
        return "#00cbc6";
    }
  };

  const roomStatus = (val) => {
    switch (true) {
      case val < 24:
        return (
          <span className="circular-status" style={{ color: "#70a5f9" }}>
            Too Cool
          </span>
        );
      case val >= 24 && val < 27:
        return (
          <span className="circular-status" style={{ color: "#49db4d" }}>
            GOOD
          </span>
        );
      default:
        return (
          <span className="circular-status" style={{ color: "#F2C15C" }}>
            Too Hot
          </span>
        );
    }
  };

  const carbonStatus = (val) => {
    switch (true) {
      case val < 1000:
        return (
          <span className="circular-status" style={{ color: "#49db4d" }}>
            GOOD
          </span>
        );
      case val >= 1000 && val <= 2000:
        return (
          <span className="circular-status" style={{ color: "#70a5f9" }}>
            Moderate
          </span>
        );
      default:
        return (
          <span className="circular-status" style={{ color: "#F2C15C" }}>
            Service
          </span>
        );
    }
  };

  const pmStatus = (val) => {
    switch (true) {
      case val < 12:
        return (
          <span className="circular-status" style={{ color: "#49db4d" }}>
            GOOD
          </span>
        );
      case val >= 12 && val < 35:
        return (
          <span className="circular-status" style={{ color: "#70a5f9" }}>
            Moderate
          </span>
        );
      default:
        return (
          <span className="circular-status" style={{ color: "#F2C15C" }}>
            Service
          </span>
        );
    }
  };

  const humidityStatus = (val) => {
    switch (true) {
      case val < 40:
        return (
          <span className="circular-status" style={{ color: "#F2C15C" }}>
            Too Dry
          </span>
        );
      case val >= 40 && val < 60:
        return (
          <span className="circular-status" style={{ color: "#49db4d" }}>
            GOOD
          </span>
        );
      default:
        return (
          <span className="circular-status" style={{ color: "#70a5f9" }}>
            Too Humidity
          </span>
        );
    }
  };

  const twoDecimal = (val) => {
    return Math.floor(val * 10) / 10;
  };

  return (
    <div
      style={{
        backgroundColor: "#2e2e40",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="body-container-monitor">
        <img src={Logo} alt="saijo-denki" className="monitor-logo" />
        <div className="left-container">
          <div className="date-time-container">
            <div>
              <div className="date-container">
                <Clock format={"ddd, D.M.YYYY"} ticking={true} />
              </div>
              <div className="time-container">
                <Clock format="HH:mm:ss" ticking={true} />
              </div>
            </div>
          </div>
          <div className="device-list-container">
            <Devices building={building} floor={floor} room={room} />
          </div>
        </div>
        <div className="right-container">
          <div className="plan-container">
            <p className="plan-title">{title}</p>
            <LazyBackground src={planUrl} />
          </div>
          <div className="iaq-container">
            <div className="iaq-card">
              <p>Room Temperature (°C)</p>
              <div className="circular-container">
                <CircularProgressbarWithChildren
                  value={roomTemp}
                  maxValue={50}
                  strokeWidth={7}
                  circleRatio={0.75}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    rotation: 1 / 2 + 1 / 8,
                    trailColor: "#000000",
                    pathColor: roomTempColor(roomTemp),
                  })}
                >
                  <div className="circular-value-container">
                    {roomStatus(roomTemp)}
                    {roomTemp >= 63.75 ? "Error" : twoDecimal(roomTemp)}
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              <img src={tempIcon} alt="temp-icon" className="circular-icon" />
            </div>
            <div className="iaq-card">
              <p>Carbon Dioxide (ppm)</p>
              <div className="circular-container">
                <CircularProgressbarWithChildren
                  value={carbon}
                  maxValue={3000}
                  strokeWidth={7}
                  circleRatio={0.75}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    rotation: 1 / 2 + 1 / 8,
                    trailColor: "#000000",
                    pathColor: carbonColor(carbon),
                  })}
                >
                  <div className="circular-value-container">
                    {carbonStatus(carbon)}
                    {twoDecimal(carbon)}
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              <img
                src={carbonIcon}
                alt="carbon-icon"
                className="circular-icon"
              />
            </div>
            <div className="iaq-card">
              <p>PM 2.5 (μg/m3)</p>
              <div className="circular-container">
                <CircularProgressbarWithChildren
                  value={pm}
                  maxValue={250}
                  strokeWidth={7}
                  circleRatio={0.75}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    rotation: 1 / 2 + 1 / 8,
                    trailColor: "#000000",
                    pathColor: pmColor(pm),
                  })}
                >
                  <div className="circular-value-container">
                    {pmStatus(pm)}
                    {twoDecimal(pm)}
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              <img src={pmIcon} alt="pm-icon" className="circular-icon" />
            </div>
            <div className="iaq-card">
              <p>Humidity (%)</p>
              <div className="circular-container">
                <CircularProgressbarWithChildren
                  value={humidity}
                  maxValue={100}
                  strokeWidth={7}
                  circleRatio={0.75}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    rotation: 1 / 2 + 1 / 8,
                    trailColor: "#000000",
                    pathColor: humidityColor(humidity),
                  })}
                >
                  <div className="circular-value-container">
                    {humidityStatus(humidity)}
                    {humidity > 100 ? "Error" : twoDecimal(humidity)}
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              <img
                src={humidityIcon}
                alt="humidity-icon"
                className="circular-icon"
              />
            </div>
            <div className="iaq-card">
              <p>Room Pressure (Pa)</p>
              <div className="circular-container">
                <CircularProgressbarWithChildren
                  value={roomPressure}
                  maxValue={300}
                  minValue={-300}
                  strokeWidth={7}
                  circleRatio={0.75}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    rotation: 1 / 2 + 1 / 8,
                    trailColor: "#000000",
                    pathColor: roomPressureColor(roomPressure),
                  })}
                >
                  <div className="circular-value-container">
                    <span
                      className="circular-status"
                      style={{ color: "#49db4d" }}
                    >
                      GOOD
                    </span>
                    {twoDecimal(roomPressure)}
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              <img
                src={pressureIcon}
                alt="pressure-icon"
                className="circular-icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
