import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/header";
import Nav from "../components/nav";
import { Row, Col, Button } from "react-bootstrap";
import PageNotFound from "../assets/image/page_not_found/page_not_found.png";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="main-bg">
      <Header />
      <Nav page={null} />
      <div className="body-container mt-4 ps-sm-4">
        <div className="not-found-container">
          <div className="not-found-form-container py-sm-5">
            <Row className="w-100" style={{ alignItems: "center" }}>
              <Col
                xxl={6}
                className="h-100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={PageNotFound}
                  className="page-not-found-image"
                  alt="error-404"
                />
              </Col>
              <Col xxl={6}>
                <div className="page-not-found-message-container">
                  <p className="not-found-title mb-3">Oops!</p>
                  <p className="not-found-sub-title mb-0">
                    PAGE NOT FOUND ON THIS SERVER
                  </p>
                  <p className="not-found-text my-3 my-xl-5">
                    We're sorry, the page you requested could not be found.
                    Please go back to the homepage!
                  </p>
                  <Button
                    className="homepage-btn"
                    onClick={() => navigate("/")}
                  >
                    Go to Homepage
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
