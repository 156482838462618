import React, { useState, useEffect } from "react";
import { Row, Col, Table, Form, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Header from "../components/header";
import Nav from "../components/nav";
import PowerIcon from "../assets/image/report/power-icon.png";
import BathIcon from "../assets/image/report/thai-baht.png";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { odu_energy_report, odu_system_list } from "../api/report";
import Pagination from "../components/pagination";
import ExportExcel from "../components/export_excel";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const ReportMonthlyEnergy = (props) => {
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [systemList, setSystemList] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [gateway, setGateway] = useState("");
  const [gatewayFilter, setGatewayFilter] = useState("");
  const [cdu, setCdu] = useState("");
  const [cduFilter, setCduFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage] = useState(20);
  const [loaded, setLoaded] = useState(true);
  const [labels, setLabels] = useState([]);
  const [consumptionData, setConsumptionData] = useState([]);
  const [billData, setBillData] = useState([]);
  const [maxPowerData, setMaxPowerData] = useState(0);
  const [totalPower, setTotalPower] = useState(0);
  const [exportData, setExportData] = useState([]);

  const indexOfLastPost = currentPage * PerPage;
  const indexOfFirstPost = indexOfLastPost - PerPage;
  const currentPosts = reportList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    (async () => {
      try {
        let token = localStorage.getItem("token");

        let res_system = await odu_system_list(token);

        setSystemList(res_system.data.systemlist);
        setGateway(
          res_system.data.systemlist.length
            ? res_system.data.systemlist[0].gateway_sn
            : ""
        );
        setGatewayFilter(
          res_system.data.systemlist.length
            ? res_system.data.systemlist[0].gateway_sn
            : ""
        );
        setCdu(
          res_system.data.systemlist.length
            ? res_system.data.systemlist[0].cdu_no
            : ""
        );
        setCduFilter(
          res_system.data.systemlist.length
            ? res_system.data.systemlist[0].cdu_no
            : ""
        );

        let res_energy_report = await odu_energy_report(
          token,
          res_system.data.systemlist.length
            ? res_system.data.systemlist[0].gateway_sn
            : "",
          "month"
        );

        setReportList(res_energy_report.data.data);

        let label = [];
        let powerConsumptionData = [];
        let billData = [];
        let maxPower = [];
        let total = 0;
        let data = [];

        res_energy_report.data.data.map((item) => {
          label.push(item.uid);
          powerConsumptionData.push(item.energyUsage);
          billData.push(
            electricityChargeCalculator(item.energyUsage).toFixed(2)
          );
          maxPower.push(item.totalEnergy);

          total += parseInt(item.energyUsage);

          data.push({
            Date:
              moment().startOf("month").format("DD/MM/YYYY") +
              " - " +
              moment().format("DD/MM/YYYY"),
            UID: item.uid,
            Gateway: item.gateway_sn,
            "Used Energy (kW)": item.energyUsage,
            "Used Energy (%)": item.percentUsage,
            "Maximum Used Energy (kW)": item.totalEnergy,
            "Electricity Charge (฿)": electricityChargeCalculator(
              item.energyUsage
            ).toFixed(2),
          });

          return item;
        });

        setTotalPower(total);
        setLabels(label);
        setConsumptionData(powerConsumptionData);
        setBillData(billData);
        setMaxPowerData(maxPower);
        setExportData(data);

        setLoaded(false);
      } catch (error) {
        console.log(error);
        setLoaded(false);
      }
    })();
  }, []);

  const filterReport = async () => {
    setLoaded(true);
    setOpen(false);
    setGateway(gatewayFilter);
    setCdu(cduFilter);

    try {
      let token = localStorage.getItem("token");
      let res_energy_report = await odu_energy_report(
        token,
        gatewayFilter,
        "month"
      );

      setReportList(res_energy_report.data.data);

      let label = [];
      let powerConsumptionData = [];
      let billData = [];
      let maxPower = [];
      let total = 0;
      let data = [];

      res_energy_report.data.data.map((item) => {
        label.push(item.uid);
        powerConsumptionData.push(item.energyUsage);
        billData.push(electricityChargeCalculator(item.energyUsage).toFixed(2));
        maxPower.push(item.totalEnergy);

        total += parseInt(item.energyUsage);

        data.push({
          Date:
            moment().startOf("month").format("DD/MM/YYYY") +
            " - " +
            moment().format("DD/MM/YYYY"),
          UID: item.uid,
          Gateway: item.gateway_sn,
          "Used Energy (kW)": item.energyUsage,
          "Used Energy (%)": item.percentUsage,
          "Maximum Used Energy (kW)": item.totalEnergy,
          "Electricity Charge (฿)": electricityChargeCalculator(
            item.energyUsage
          ).toFixed(2),
        });

        return item;
      });

      setTotalPower(total);
      setLabels(label);
      setConsumptionData(powerConsumptionData);
      setBillData(billData);
      setMaxPowerData(maxPower);
      setExportData(data);

      setLoaded(false);
    } catch (error) {
      console.log(error);
      setLoaded(false);
    }
  };

  const electricityChargeCalculator = (powerConsumption) => {
    let electricityBill = 0;

    // 1-150 unit
    if (powerConsumption <= 150) {
      electricityBill += powerConsumption * 3.2484;
    } else {
      electricityBill += 150 * 3.2484;
    }

    if (powerConsumption <= 400 && powerConsumption > 150) {
      electricityBill += (powerConsumption - 250) * 4.2218; // 151-400 unit
    } else if (powerConsumption > 400) {
      electricityBill += 250 * 4.2218; // 151-400 unit
      electricityBill += (powerConsumption - 400) * 4.4217; // 400+ unit
    }

    // let ft = powerConsumption * 0.2477;

    // return ((electricityBill + 38.22 + ft) * 1.07).toFixed(2);

    return electricityBill;
  };

  const Consumption = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Power Consumption",
        padding: {
          bottom: 20,
        },
        font: {
          size: 16,
          family: "SukhumvitSet-Medium",
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: false,
      },
    },
  };

  const Bill = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Electricity Charge",
        padding: {
          bottom: 20,
        },
        font: {
          size: 16,
          family: "SukhumvitSet-Medium",
        },
      },
    },
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="d-flex d-xl-none dashboard-xs-header-container px-0 mb-3">
        <div className="dashboard-xs-header-container">
          <div className="position-relative px-3 px-sm-4 w-100">
            <h5 className="dashboard-header pb-0 pt-2">
              {state?.report_title ? state.report_title : "ODU Report"}
              {": "}
              {cdu ? cdu : "-"}
            </h5>
            <div
              className="control-select-dropdown mt-1"
              onClick={() => setOpen(!open)}
              id="power-dropdown"
            ></div>
            <div
              className="control-select-container p-2"
              style={{
                visibility: open ? "visible" : "hidden",
                opacity: open ? 1 : 0,
              }}
            >
              <div className="control-select">
                <p className="mb-0 pe-2">CDU No.</p>
                <Form.Select
                  size="sm"
                  onChange={(e) => {
                    setGatewayFilter(e.target.value);
                    setCduFilter(
                      e.nativeEvent.target[e.nativeEvent.target.selectedIndex]
                        .text
                    );
                  }}
                  defaultValue={gateway}
                >
                  {systemList.map((item, index) =>
                    item.gateway_sn ? (
                      <option value={item.gateway_sn} key={index}>
                        {item.cdu_no}
                      </option>
                    ) : null
                  )}
                </Form.Select>
              </div>
              <button
                className="control-search-btn my-2 me-2"
                onClick={() => filterReport()}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="body-container" id="power-report">
        <div className="table-container-title px-2 d-none d-xl-block">
          <Row className="mx-0 px-xl-4 px-sm-2">
            <Col xs={"auto"} className="position-relative ps-0">
              <h5 className="dashboard-header mb-1">
                {state?.report_title ? state.report_title : "ODU Report"}
                {": "}
                {cdu ? cdu : "-"}
              </h5>
              <div
                className="control-select-dropdown"
                onClick={() => setOpen(!open)}
              ></div>
              <div
                className="control-select-container p-2"
                style={{
                  visibility: open ? "visible" : "hidden",
                  opacity: open ? 1 : 0,
                }}
              >
                <div className="control-select">
                  <p className="mb-0 pe-2">CDU No.</p>
                  <Form.Select
                    size="sm"
                    onChange={(e) => {
                      setGatewayFilter(e.target.value);
                      setCduFilter(
                        e.nativeEvent.target[e.nativeEvent.target.selectedIndex]
                          .text
                      );
                    }}
                    defaultValue={gateway}
                  >
                    {systemList.map((item, index) =>
                      item.gateway_sn ? (
                        <option value={item.gateway_sn} key={index}>
                          {item.cdu_no}
                        </option>
                      ) : null
                    )}
                  </Form.Select>
                </div>
                <button
                  className="control-search-btn my-2 me-2"
                  onClick={() => filterReport()}
                >
                  Confirm
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="table-container-title px-2">
          <Row className="mx-0 px-xl-4 px-sm-2 w-100">
            <Col xs={12} xxl={6} className="ps-0 pe-0 pe-xl-2">
              <div className="dashboard-card">
                <div className="dashboard-card-header">
                  <div className="dashboard-card-icon-container">
                    <img src={PowerIcon} alt="power" className="mx-2" />
                    Power Consumption
                  </div>
                </div>
                <div className="dashboard-card-body">
                  <div className="graph-container pt-4 p-2 p-xl-4">
                    <Bar
                      options={Consumption}
                      data={{
                        labels,
                        datasets: [
                          {
                            label: "Used Energy (kW)",
                            data: consumptionData,
                            backgroundColor: "#22B348",
                            stack: "0",
                          },
                          {
                            label: "Maximum Used Energy (kW)",
                            data: maxPowerData,
                            backgroundColor: "rgba(34,179,72,0.5)",
                            stack: "0",
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col
              xs={12}
              xxl={6}
              className="ps-0 pe-0 pe-xl-2 mt-2 d-xxl-none d-block"
            >
              <div className="total-container py-3">
                Total Power Consumption: {totalPower} kW
              </div>
            </Col>
            <Col xs={12} xxl={6} className="ps-0 pe-0 pe-xl-2 mt-2 mt-xxl-0">
              <div className="dashboard-card">
                <div className="dashboard-card-header">
                  <div className="dashboard-card-icon-container">
                    <img src={BathIcon} alt="baht" className="mx-2" />
                    Electricity Charge
                  </div>
                </div>
                <div className="dashboard-card-body">
                  <div className="graph-container pt-4 p-2 p-xl-4">
                    <Bar
                      options={Bill}
                      data={{
                        labels,
                        datasets: [
                          {
                            label: "Electricity Charge (฿)",
                            data: billData,
                            backgroundColor: "#22B348",
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col
              xs={12}
              xxl={6}
              className="ps-0 pe-0 pe-xl-2 mt-2 d-none d-xxl-block"
            >
              <div className="total-container py-3">
                Total Power Consumption: {totalPower} kW
              </div>
            </Col>
            <Col xs={12} xxl={6} className="ps-0 pe-0 pe-xl-2 mt-2">
              <div className="total-container py-3">
                <p className="mb-0">
                  Total Electricity Charge:{" "}
                  {electricityChargeCalculator(totalPower).toFixed(2)} ฿
                </p>
                <span className="bill-note">
                  *Residential Service on Normal Tariff / Progressive Rate (Type
                  1.2)
                </span>
                <span className="bill-note ms-1">
                  **This is the estimated electricity charge.
                </span>
                {/* <div>
                  <span className="bill-note ms-1">
                    **Ft: 0.2477 (Baht/kW)
                  </span>
                  <span className="bill-note ms-1">
                    ***Service Charge: 38.22 ฿
                  </span>
                </div> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="body-container mt-2">
        <Row className="mx-0 px-xl-4">
          <Col className="ps-2 pe-3">
            <div
              className="table-container px-2 px-sm-3 px-xl-4 py-3 py-sm-4"
              style={{ height: "auto", minHeight: "auto" }}
            >
              <ExportExcel
                csvData={exportData}
                fileName={
                  "Energy Monthly Report_" +
                  moment().format("DD_MM_YYYY_HH_mm_ss") +
                  "_.csv"
                }
              />
              <div className="mt-sm-5 mx-2 mb-2 table-overflow">
                <Table hover className="mt-3 error-table mb-2">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>UID</th>
                      <th>Gateway</th>
                      <th>Used Energy (kW)</th>
                      <th>Used Energy (%)</th>
                      <th>Maximum Used Energy (kW)</th>
                      <th>Electricity Charge (฿)</th>
                    </tr>
                    {reportList.length ? (
                      currentPosts.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {moment().startOf("month").format("DD/MM/YYYY")} -{" "}
                            {moment().format("DD/MM/YYYY")}
                          </td>
                          <td>{item.uid}</td>
                          <td>{item.gateway_sn}</td>
                          <td>{item.energyUsage}</td>
                          <td>{item.percentUsage}</td>
                          <td>{item.totalEnergy}</td>
                          <td>
                            {electricityChargeCalculator(
                              item.energyUsage
                            ).toFixed(2)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7">No data</td>
                      </tr>
                    )}
                  </thead>
                </Table>
              </div>
              <Pagination
                postsPerPage={PerPage}
                totalPosts={reportList.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        show={loaded}
        backdrop="static"
        keyboard="false"
        centered
        id="loading-modal"
      >
        <div className="loader"></div>
      </Modal>
    </div>
  );
};

export default ReportMonthlyEnergy;
