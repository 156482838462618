import React, { useState, useContext, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

import logoutIcon from "../assets/image/header/logout.png";
import logo from "../assets/image/logo.png";
import menu from "../assets/image/menu.png";

import { ProfileContext } from "./profile_context";
import { MqttContext } from "./mqtt_context";

const Header = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [settingOpen, setSettingOpen] = useState(false);
  const [maintenanceOpen, setMaintenanceOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const { name, surname, company, image } = useContext(ProfileContext);
  const { disconnect_socket, connect } = useContext(MqttContext);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      let decoded = jwt_decode(localStorage.getItem("token"));
      let exp = new Date(parseInt(decoded.exp) * 1000).getTime();
      let now = parseInt(new Date().getTime());

      if (exp < now) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("user_id");
    localStorage.removeItem("company_id");
    localStorage.removeItem("user_role");
    localStorage.removeItem("socket");

    connect.current = false;
    disconnect_socket();

    navigate("/login");
  };

  return (
    <>
      <div className="header d-none d-xl-block">
        <Row className="header-row mx-0">
          <Col xs={9}>
            <h5 className="header-title px-4 pb-2">
              {company.length ? company : "-"}
            </h5>
          </Col>
          <Col>
            <div className="user-container mb-1">
              <p className="header-name mb-0">
                {name ? name : "Name"} {surname ? surname : "Surname"}
              </p>
              <img
                src={image}
                className="user-image"
                onClick={() => setOpen(!open)}
                alt="user"
                style={{ width: "39px", height: "39px" }}
              />
              <div
                className="user-dropdown"
                style={{
                  visibility: open ? "visible" : "hidden",
                  opacity: open ? 1 : 0,
                }}
              >
                {/* <div
                  className="user-profile-btn"
                  onClick={() => navigate("/profile")}
                >
                  User Profile
                </div>
                <div
                  className="user-profile-btn"
                  onClick={() => navigate("/change_password")}
                >
                  Change Password
                </div> */}
                <div
                  className="user-profile-btn"
                  onClick={() => {
                    logout();
                  }}
                >
                  Logout
                  <img src={logoutIcon} className="logout-icon" alt="logout" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="header-xs py-2 d-xl-none">
        <img
          src={logo}
          alt={"saijo-denki"}
          className="my-1"
          onClick={() => navigate("/")}
        />
        <img
          src={menu}
          alt={"menu"}
          className="menu-btn"
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
        />
      </div>
      <div
        className="menu-container d-xl-none"
        style={{
          visibility: openMenu ? "visible" : "hidden",
          opacity: openMenu ? 1 : 0,
        }}
      >
        <div className="menu-list py-2" onClick={() => navigate("/")}>
          Dashboard
        </div>
        <div className="menu-list py-2" onClick={() => navigate("/product")}>
          Product
        </div>
        <div
          className="menu-list py-2"
          onClick={() => {
            setReportOpen(false);
            setSettingOpen(false);
            setMaintenanceOpen(false);
            setErrorOpen(!errorOpen);
          }}
        >
          Error
          <div
            className="chevron"
            style={{
              transform: errorOpen ? "rotate(90deg)" : "rotate(0deg)",
            }}
          ></div>
        </div>
        <div
          className="nav-list-container"
          style={{
            visibility: errorOpen ? "visible" : "hidden",
            maxHeight: errorOpen ? "100%" : 0,
          }}
        >
          <div
            onClick={() => {
              navigate("/error_indoor");
              setErrorOpen(false);
            }}
          >
            Indoor
          </div>
          <div
            onClick={() => {
              navigate("/error_outdoor");
              setErrorOpen(false);
            }}
          >
            Outdoor
          </div>
        </div>
        <div
          className="menu-list py-2"
          onClick={() => {
            setReportOpen(false);
            setSettingOpen(false);
            setMaintenanceOpen(!maintenanceOpen);
            setErrorOpen(false);
          }}
        >
          Maintenance
          <div
            className="chevron"
            style={{
              transform: maintenanceOpen ? "rotate(90deg)" : "rotate(0deg)",
            }}
          ></div>
        </div>
        <div
          className="nav-list-container"
          style={{
            visibility: maintenanceOpen ? "visible" : "hidden",
            maxHeight: maintenanceOpen ? "100%" : 0,
          }}
        >
          <div
            onClick={() => {
              navigate("/maintenance");
              setMaintenanceOpen(false);
            }}
          >
            Maintenance List
          </div>
          <div
            onClick={() => {
              navigate("/maintenance_schedule");
              setMaintenanceOpen(false);
            }}
          >
            Maintenance Schedule
          </div>
        </div>
        <div
          className="menu-list py-2"
          onClick={() => {
            setReportOpen(!reportOpen);
            setSettingOpen(false);
            setMaintenanceOpen(false);
            setErrorOpen(false);
          }}
        >
          Report
          <div
            className="chevron"
            style={{
              transform: reportOpen ? "rotate(90deg)" : "rotate(0deg)",
            }}
          ></div>
        </div>
        <div
          className="nav-list-container"
          style={{
            visibility: reportOpen ? "visible" : "hidden",
            maxHeight: reportOpen ? "100%" : 0,
          }}
        >
          <div
            onClick={() => {
              navigate("/report_odu", {
                state: {
                  report_type: "odu_report",
                  report_title: "ODU Realtime Report",
                },
              });
              setReportOpen(false);
            }}
          >
            ODU Realtime Report
          </div>
          <div
            onClick={() => {
              navigate("/report_indoor", {
                state: {
                  report_type: "odu_indoor",
                  report_title: "ODU Realtime Indoor",
                },
              });
              setReportOpen(false);
            }}
          >
            Indoor Realtime Report
          </div>
          <div
            onClick={() => {
              navigate("/report_odu_history", {
                state: {
                  report_type: "report_odu_history",
                  report_title: "ODU History Report",
                },
              });
              setReportOpen(false);
            }}
          >
            ODU History Report
          </div>
          <div
            onClick={() => {
              navigate("/report_weekly_energy", {
                state: {
                  report_type: "report_weekly_energy",
                  report_title: "Energy Weekly Report",
                },
              });
              setReportOpen(false);
            }}
          >
            Energy Weekly Report
          </div>
          <div
            onClick={() => {
              navigate("/report_monthly_energy", {
                state: {
                  report_type: "report_monthly_energy",
                  report_title: "Energy Monthly Report",
                },
              });
              setReportOpen(false);
            }}
          >
            Energy Monthly Report
          </div>
        </div>
        <div
          className="menu-list py-2"
          onClick={() => {
            setReportOpen(false);
            setSettingOpen(!settingOpen);
            setMaintenanceOpen(false);
          }}
        >
          Setting
          <div
            className="chevron"
            style={{
              transform: settingOpen ? "rotate(90deg)" : "rotate(0deg)",
            }}
          ></div>
        </div>
        <div
          className="nav-list-container"
          style={{
            visibility: settingOpen ? "visible" : "hidden",
            maxHeight: settingOpen ? "100%" : 0,
          }}
        >
          {/* <div onClick={() => navigate("/setting_location")}>Location</div> */}
          {localStorage.getItem("user_role") === "1" && (
            <div onClick={() => navigate("/setting_gateway")}>Gateway</div>
          )}
          <div onClick={() => navigate("/setting_building")}>Building</div>
          <div onClick={() => navigate("/setting_floor")}>Floor</div>
          <div onClick={() => navigate("/setting_room")}>Room</div>
          <div onClick={() => navigate("/setting_ac")}>AC</div>
        </div>
        <div
          className="menu-list py-2"
          onClick={() => {
            logout();
          }}
        >
          Logout
        </div>
      </div>
    </>
  );
};

export default Header;
