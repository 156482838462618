import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import navLogo from "../assets/image/nav/nav_logo.png";
import dashboardIcon from "../assets/image/nav/dashboard.png";
import controlIcon from "../assets/image/nav/control.png";
import productIcon from "../assets/image/nav/product.png";
import errorIcon from "../assets/image/nav/error.png";
import reportIcon from "../assets/image/nav/report.png";
import settingIcon from "../assets/image/nav/setting.png";
import buildingIcon from "../assets/image/nav/building.png";
import toolIcon from "../assets/image/nav/tool.png";
import { building_list } from "../api/product";
import { MqttContext } from "./mqtt_context";

const Nav = (props) => {
  let navigate = useNavigate();

  const [dashboard] = useState(props.page === "dashboard" ? true : false);
  const [control, setControl] = useState(
    props.page === "control" ? true : false
  );
  const [controlOpen, setControlOpen] = useState(false);
  const [product] = useState(props.page === "product" ? true : false);
  const [error, setError] = useState(props.page === "error" ? true : false);
  const [report, setReport] = useState(props.page === "report" ? true : false);
  const [setting, setSetting] = useState(
    props.page === "setting" ? true : false
  );
  const [maintenance, setMaintenance] = useState(
    props.page === "maintenance" ? true : false
  );
  const [reportOpen, setReportOpen] = useState(false);
  const [settingOpen, setSettingOpen] = useState(false);
  const [maintenanceOpen, setMaintenanceOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [filteredBuilding, setFilterBuilding] = useState([]);

  const { building, floor, room } = useContext(MqttContext);

  useEffect(() => {
    (async () => {
      try {
        let token = localStorage.getItem("token");
        let companyId = localStorage.getItem("company_id");

        let res = await building_list(token);

        let buildingData = res.data.data.filter((item) => {
          return item.company_id === companyId;
        });

        if (buildingData.length !== undefined) {
          buildingData.sort((a, b) => {
            let x = a._id.toLowerCase();
            let y = b._id.toLowerCase();

            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          });

          buildingData.map((item) => {
            if (item.floors !== undefined) {
              item.floors.sort((a, b) => {
                let x = a.floorId.toLowerCase();
                let y = b.floorId.toLowerCase();

                if (x < y) {
                  return -1;
                }
                if (x > y) {
                  return 1;
                }
                return 0;
              });
            }

            return item;
          });

          buildingData.map((i) => {
            if (i.floors !== undefined) {
              i.floors.map((j) => {
                if (j.rooms !== undefined) {
                  j.rooms.sort((a, b) => {
                    let x = a.roomId.toLowerCase();
                    let y = b.roomId.toLowerCase();

                    if (x < y) {
                      return -1;
                    }
                    if (x > y) {
                      return 1;
                    }
                    return 0;
                  });
                }

                return j;
              });
            }

            return i;
          });
        }

        let buildings = [];

        buildingData.map((item) => {
          buildings.push({
            building: item.title ? item.title : "",
            building_id: item._id ? item._id : "",
            floor: item.floors?.length ? item.floors[0].title : "",
            room: item.floors
              ? item.floors[0]
                ? item.floors[0].rooms
                  ? item.floors[0].rooms[0].title
                  : ""
                : ""
              : "",
          });

          return item;
        });

        buildings.sort((a, b) => {
          let x = a.building_id.toLowerCase();
          let y = b.building_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        setFilterBuilding(buildings);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <div className="nav-container">
      <img
        src={navLogo}
        className="nav-logo"
        alt="saijo-denki"
        onClick={() => navigate("/")}
      />
      <div className="nav-btn-container">
        <div
          className={dashboard ? "nav-btn nav-active" : "nav-btn"}
          onClick={() => navigate("/")}
        >
          <img
            src={dashboardIcon}
            className="nav-icon"
            style={{ filter: dashboard ? "grayscale(0)" : "grayscale(1)" }}
            alt="dashboard"
          />
          <p className="nav-text">Dashboard</p>
          <div className="nav-bottom-border"></div>
        </div>
        <div
          className={control ? "nav-btn nav-active" : "nav-btn"}
          onClick={() => {
            setControl(props.page === "control" ? true : !control);
            setControlOpen(!controlOpen);
            setReport(props.page === "report" ? true : false);
            setReportOpen(false);
            setSetting(props.page === "setting" ? true : false);
            setSettingOpen(false);
            setMaintenance(props.page === "maintenance" ? true : false);
            setMaintenanceOpen(false);
            setError(props.page === "error" ? true : false);
            setErrorOpen(false);
          }}
        >
          <img
            src={controlIcon}
            className="nav-icon"
            style={{ filter: control ? "grayscale(0)" : "grayscale(1)" }}
            alt="control"
          />
          <p className="nav-text">Control</p>
          <div
            className="chevron"
            style={{
              transform: controlOpen ? "rotate(90deg)" : "rotate(0deg)",
            }}
          ></div>
          <div className="nav-bottom-border"></div>
        </div>
        <div
          className="nav-sub-container"
          style={{
            maxHeight: controlOpen ? 250 : 0,
          }}
        >
          {filteredBuilding.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                building.current = item.building;
                floor.current = item.floor;
                room.current = item.room;
                navigate("/control", {
                  state: {
                    building: item.building,
                    floor: item.floor,
                    room: item.room,
                    serialNumber: null,
                  },
                });
                setControlOpen(false);
              }}
            >
              {item.building}
            </div>
          ))}
        </div>
        <div
          className={product ? "nav-btn nav-active" : "nav-btn"}
          onClick={() => navigate("/product")}
        >
          <img
            src={productIcon}
            className="nav-icon"
            style={{ filter: product ? "grayscale(0)" : "grayscale(1)" }}
            alt="product"
          />
          <p className="nav-text">Product</p>
          <div className="nav-bottom-border"></div>
        </div>
        <div
          className={error ? "nav-btn nav-active" : "nav-btn"}
          onClick={() => {
            setControl(props.page === "control" ? true : false);
            setControlOpen(false);
            setReport(props.page === "report" ? true : false);
            setReportOpen(false);
            setSetting(props.page === "setting" ? true : false);
            setSettingOpen(false);
            setMaintenance(props.page === "maintenance" ? true : false);
            setMaintenanceOpen(false);
            setError(props.page === "error" ? true : !error);
            setErrorOpen(!errorOpen);
          }}
        >
          <img
            src={errorIcon}
            className="nav-icon"
            style={{ filter: error ? "grayscale(0)" : "grayscale(1)" }}
            alt="error"
          />
          <p className="nav-text">Error</p>
          <div
            className="chevron"
            style={{
              transform: errorOpen ? "rotate(90deg)" : "rotate(0deg)",
            }}
          ></div>
          <div className="nav-bottom-border"></div>
        </div>
        <div
          className="nav-sub-container"
          style={{
            maxHeight: errorOpen ? 250 : 0,
          }}
        >
          <div
            onClick={() => {
              navigate("/error_indoor");
              setErrorOpen(false);
            }}
          >
            Indoor
          </div>
          <div
            onClick={() => {
              navigate("/error_outdoor");
              setErrorOpen(false);
            }}
          >
            Outdoor
          </div>
        </div>
        <div
          className={maintenance ? "nav-btn nav-active" : "nav-btn"}
          onClick={() => {
            setControl(props.page === "control" ? true : false);
            setControlOpen(false);
            setReport(props.page === "report" ? true : false);
            setReportOpen(false);
            setSetting(props.page === "setting" ? true : false);
            setSettingOpen(false);
            setMaintenance(props.page === "maintenance" ? true : !maintenance);
            setMaintenanceOpen(!maintenanceOpen);
            setError(props.page === "error" ? true : false);
            setErrorOpen(false);
          }}
        >
          <img
            src={toolIcon}
            className="nav-icon"
            style={{ filter: maintenance ? "grayscale(0)" : "grayscale(1)" }}
            alt="maintenance"
          />
          <p className="nav-text">Maintenance</p>
          <div
            className="chevron"
            style={{
              transform: maintenanceOpen ? "rotate(90deg)" : "rotate(0deg)",
            }}
          ></div>
          <div className="nav-bottom-border"></div>
        </div>
        <div
          className="nav-sub-container"
          style={{
            maxHeight: maintenanceOpen ? 250 : 0,
          }}
        >
          <div
            onClick={() => {
              navigate("/maintenance");
              setMaintenanceOpen(false);
            }}
          >
            Maintenance List
          </div>
          <div
            onClick={() => {
              navigate("/maintenance_schedule");
              setMaintenanceOpen(false);
            }}
          >
            Maintenance Schedule
          </div>
        </div>
        <div
          className={report ? "nav-btn nav-active" : "nav-btn"}
          onClick={() => {
            setReport(props.page === "report" ? true : !report);
            setReportOpen(!reportOpen);
            setControl(props.page === "control" ? true : false);
            setControlOpen(false);
            setSetting(props.page === "setting" ? true : false);
            setSettingOpen(false);
            setMaintenance(props.page === "maintenance" ? true : false);
            setMaintenanceOpen(false);
            setError(props.page === "error" ? true : false);
            setErrorOpen(false);
          }}
        >
          <img
            src={reportIcon}
            className="nav-icon"
            style={{ filter: report ? "grayscale(0)" : "grayscale(1)" }}
            alt="report"
          />
          <p className="nav-text">Report</p>
          <div
            className="chevron"
            style={{ transform: reportOpen ? "rotate(90deg)" : "rotate(0deg)" }}
          ></div>
          <div className="nav-bottom-border"></div>
        </div>
        <div
          className="nav-sub-container"
          style={{
            maxHeight: reportOpen ? 250 : 0,
          }}
        >
          <div
            onClick={() => {
              navigate("/report_odu", {
                state: {
                  report_type: "odu_report",
                  report_title: "ODU Realtime Report",
                },
              });
              setReportOpen(false);
            }}
          >
            ODU Realtime Report
          </div>
          <div
            onClick={() => {
              navigate("/report_indoor", {
                state: {
                  report_type: "indoor_report",
                  report_title: "Indoor Realtime Report",
                },
              });
              setReportOpen(false);
            }}
          >
            Indoor Realtime Report
          </div>
          <div
            onClick={() => {
              navigate("/report_odu_history", {
                state: {
                  report_type: "odu_history_report",
                  report_title: "ODU History Report",
                },
              });
              setReportOpen(false);
            }}
          >
            ODU History Report
          </div>
          <div
            onClick={() => {
              navigate("/report_weekly_energy", {
                state: {
                  report_type: "energy_weekly_report",
                  report_title: "Energy Weekly Report",
                },
              });
              setReportOpen(false);
            }}
          >
            Energy Weekly Report
          </div>
          <div
            onClick={() => {
              navigate("/report_monthly_energy", {
                state: {
                  report_type: "report_monthly_energy",
                  report_title: "Energy Monthly Report",
                },
              });
              setReportOpen(false);
            }}
          >
            Energy Monthly Report
          </div>
        </div>
        <div
          className={setting ? "nav-btn nav-active" : "nav-btn"}
          onClick={() => {
            setControl(props.page === "control" ? true : false);
            setControlOpen(false);
            setReport(props.page === "report" ? true : false);
            setReportOpen(false);
            setSetting(props.page === "setting" ? true : !setting);
            setSettingOpen(!settingOpen);
            setMaintenance(props.page === "maintenance" ? true : false);
            setMaintenanceOpen(false);
            setError(props.page === "error" ? true : false);
            setErrorOpen(false);
          }}
        >
          <img
            src={settingIcon}
            className="nav-icon"
            style={{ filter: setting ? "grayscale(0)" : "grayscale(1)" }}
            alt="setting"
          />
          <p className="nav-text">Setting</p>
          <div
            className="chevron"
            style={{
              transform: settingOpen ? "rotate(90deg)" : "rotate(0deg)",
            }}
          ></div>
          {localStorage.getItem("user_role") === "1" && (
            <div className="nav-bottom-border"></div>
          )}
        </div>
        <div
          className="nav-sub-container"
          style={{
            maxHeight: settingOpen ? 250 : 0,
          }}
        >
          {/* <div onClick={() => navigate("/setting_location")}>Location</div> */}
          {localStorage.getItem("user_role") === "1" && (
            <div onClick={() => navigate("/setting_gateway")}>Gateway</div>
          )}
          <div onClick={() => navigate("/setting_building")}>Building</div>
          <div onClick={() => navigate("/setting_floor")}>Floor</div>
          <div onClick={() => navigate("/setting_room")}>Room</div>
          <div onClick={() => navigate("/setting_ac")}>AC</div>
        </div>
      </div>
      {localStorage.getItem("user_role") === "1" && (
        <div className={"nav-btn"} onClick={() => navigate("/company_list")}>
          <img
            src={buildingIcon}
            className="nav-icon"
            style={{ filter: "grayscale(1)" }}
            alt="building"
          />
          <p className="nav-text">Company List</p>
        </div>
      )}
    </div>
  );
};

export default Nav;
