import React, { useState, useEffect } from "react";
import { Row, Col, Table, Form } from "react-bootstrap";
import moment from "moment";

import Header from "../components/header";
import Nav from "../components/nav";
import Pagination from "../components/pagination";
import ScheduleIcon from "../assets/image/maintenance/schedule.png";
import { products, building_list } from "../api/product";
import ExportExcel from "../components/export_excel";

const MaintenanceSchedule = (props) => {
  const [maintenanceList, setMaintenanceList] = useState([]);
  const [maintenanceSearchResult, setMaintenanceSearchResult] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage] = useState(20);
  const [buildingListSearch, setBuildingListSearch] = useState([]);
  const [floorListSearch, setFloorListSearch] = useState([]);
  const [roomListSearch, setRoomListSearch] = useState([]);
  const [buildingSearch, setBuildingSearch] = useState("");
  const [floorSearch, setFloorSearch] = useState("");
  const [roomSearch, setRoomSearch] = useState("");
  const [acNameListSearch, setAcNameListSearch] = useState([]);
  const [acNameSearch, setAcNameSearch] = useState("");
  const [loaded, setLoaded] = useState(true);

  const indexOfLastPost = currentPage * PerPage;
  const indexOfFirstPost = indexOfLastPost - PerPage;
  const currentPosts = maintenanceSearchResult.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    (async () => {
      try {
        let token = localStorage.getItem("token");
        let companyId = localStorage.getItem("company_id");

        let res = await building_list(token);

        let buildingData = res.data.data.filter((item) => {
          return item.company_id === companyId;
        });

        let buildings = [];

        // Filler Building
        buildingData.map((item) => {
          if (item.title !== undefined) {
            buildings.push({
              building: item.title,
              building_id: item._id,
            });
          }

          return item;
        });

        buildings.sort((a, b) => {
          let x = a.building_id.toLowerCase();
          let y = b.building_id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        let productArr = await products(token, null, null);

        productArr.data.productsList.sort((a, b) => {
          let x = a._id.toLowerCase();
          let y = b._id.toLowerCase();

          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        let data = [];

        productArr.data.productsList.map((item, index) => {
          data.push({
            "No.": index + 1,
            "Due Date": moment().format("DD/MM/YYYY"),
            Service: "Clean",
            "AC Name": item.title,
            "Serial Number": item.serail_no,
            Model: checkModel(item.odu, item.model),
            Location:
              item.location.building +
              " " +
              item.location.floor +
              " ," +
              item.location.room,
          });

          return item;
        });

        setBuildingListSearch(buildings);
        setMaintenanceList(productArr.data.productsList);
        setMaintenanceSearchResult(productArr.data.productsList);
        setExportData(data);
        setLoaded(false);
      } catch (error) {
        setLoaded(false);
      }
    })();
  }, [loaded]);

  const changeBuildingSearch = async (val) => {
    setBuildingSearch(val);
    setAcNameSearch("");

    let floors = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (val === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              floors.push({
                floor: i.title,
                floor_id: i.floorId,
              });

              return i;
            });
          }

          floors.sort((a, b) => {
            if (a.floor_id.toLowerCase() < b.floor_id.toLowerCase()) {
              return -1;
            }

            if (a.floor_id.toLowerCase() > b.floor_id.toLowerCase()) {
              return 1;
            }

            return 0;
          });
        }

        return item;
      });

      setFloorListSearch(floors);
      setRoomListSearch([]);
      setFloorSearch("");
      setRoomSearch("");

      let productArr = await products(token, val, null);

      let acNames = [];

      productArr.data.productsList.map((item) => {
        acNames.push({
          title: item.title,
          id: item._id,
        });

        return item;
      });

      acNames.sort((a, b) => {
        let x = a.id.toLowerCase();
        let y = b.id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setAcNameListSearch(acNames);
    } catch (error) {
      console.log(error);
    }
  };

  const changeFloorSearch = async (val) => {
    setFloorSearch(val);
    setAcNameSearch("");

    let rooms = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (buildingSearch === item.title) {
          if (item.floors) {
            item.floors.map((i) => {
              if (i.title === val) {
                if (i.rooms !== undefined) {
                  i.rooms.map((j) => {
                    if (j.title !== "All") {
                      rooms.push({
                        room: j.title,
                        room_id: j.roomId,
                      });
                    }

                    return i;
                  });
                }
              }

              return i;
            });
          }
        }

        return item;
      });

      let productArr = await products(token, buildingSearch, val);

      let acNames = [];

      productArr.data.productsList.map((item) => {
        acNames.push({
          title: item.title,
          id: item._id,
        });

        return item;
      });

      acNames.sort((a, b) => {
        let x = a.id.toLowerCase();
        let y = b.id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setAcNameListSearch(acNames);
      setRoomListSearch(rooms);
      setRoomSearch("");
    } catch (error) {
      console.log(error);
    }
  };

  const changeRoomSearch = async (val) => {
    setRoomSearch(val);
    setAcNameSearch("");

    try {
      let token = localStorage.getItem("token");
      let productArr = await products(token, buildingSearch, floorSearch);

      let acNames = [];

      productArr.data.productsList.map((item) => {
        if (item.location.room === val || val === "") {
          acNames.push({
            title: item.title,
            id: item._id,
          });
        }

        return item;
      });

      acNames.sort((a, b) => {
        let x = a.id.toLowerCase();
        let y = b.id.toLowerCase();

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      setAcNameListSearch(acNames);
    } catch (error) {
      console.log(val);
    }
  };

  const search = () => {
    var result = maintenanceList.filter((item) => {
      if (
        item.location.building.indexOf(buildingSearch) >= 0 &&
        item.location.floor.indexOf(floorSearch) >= 0 &&
        item.location.room.indexOf(roomSearch) >= 0 &&
        item._id.indexOf(acNameSearch) >= 0
      ) {
        return true;
      } else {
        return false;
      }
    });

    let data = [];

    result.map((item, index) => {
      data.push({
        "No.": index + 1,
        "Due Date": moment().format("DD/MM/YYYY"),
        Service: "Clean",
        "AC Name": item.title,
        "Serial Number": item.serail_no,
        Model: checkModel(item.odu, item.model),
        Location:
          item.location.building +
          " " +
          item.location.floor +
          " ," +
          item.location.room,
      });

      return item;
    });

    setMaintenanceSearchResult(result);
    setExportData(data);
  };

  const clearSearch = () => {
    setBuildingSearch("");
    setFloorSearch("");
    setRoomSearch("");
    setAcNameSearch("");

    setFloorListSearch([]);
    setRoomListSearch([]);
    setAcNameListSearch([]);
    setMaintenanceSearchResult(maintenanceList);
  };

  const checkModel = (oud, model) => {
    // let val = oud.substr(4, 5);

    // switch (true) {
    //   case val * 1 >= 10 && val * 1 < 15:
    //     if (model.toUppercase() === "FFU" || model.toUppercase() === "EX") {
    //       return model.toUppercase();
    //     }
    //     return "FFU";
    //   case val * 1 >= 15 && val * 1 < 19:
    //     return "IAQ";
    //   default:
    //     return model.toUppercase();
    // }

    return model.toUpperCase();
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-4 mt-4 pt-5 pt-xl-0">
        <Row className="mx-0 px-xl-4">
          <Col xs={12}>
            <div className="table-container px-2 px-sm-3 px-xl-4 py-3 py-sm-4">
              <div
                className="table-container-title px-2"
                style={{ justifyContent: "space-between" }}
              >
                <div
                  style={{
                    alignItems: "center",
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  <img src={ScheduleIcon} alt="error" className="error-icon" />
                  <h5 className="ms-3 mb-0">Maintenance Schedule </h5>
                </div>
              </div>
              <ExportExcel
                csvData={exportData}
                fileName={
                  "Maintenance Schedule_" +
                  moment().format("DD_MM_YYYY_HH_mm_ss") +
                  "_.csv"
                }
              />
              <Row>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-3 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">Building</Form.Label>
                    <Form.Select
                      onChange={(e) => changeBuildingSearch(e.target.value)}
                      value={buildingSearch}
                    >
                      <option value="">Select Building</option>
                      {buildingListSearch.map((item, index) => (
                        <option value={item.building} key={index}>
                          {item.building}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-1 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">Floor</Form.Label>
                    <Form.Select
                      onChange={(e) => changeFloorSearch(e.target.value)}
                      value={floorSearch}
                    >
                      <option value="">Select Floor</option>
                      {floorListSearch.map((item, index) => (
                        <option value={item.floor} key={index}>
                          {item.floor}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-1 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">Room Name</Form.Label>
                    <Form.Select
                      onChange={(e) => changeRoomSearch(e.target.value)}
                      value={roomSearch}
                    >
                      <option value="">Select Room</option>
                      {roomListSearch.map((item, index) => (
                        <option value={item.room} key={index}>
                          {item.room}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-1 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">AC Name</Form.Label>
                    <Form.Select
                      onChange={(e) => setAcNameSearch(e.target.value)}
                      value={acNameSearch}
                    >
                      <option value="">Select AC Name</option>
                      {acNameListSearch.map((item, index) => (
                        <option value={item.id} key={index}>
                          {item.title}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={"auto"} className="pe-0 ms-2 ms-sm-0 mt-2 mt-sm-0">
                  <Form.Group className="mt-4 mb-2 px-2 d-none d-sm-block">
                    <Form.Label className="input-label mb-0">&nbsp;</Form.Label>
                  </Form.Group>
                  <button className="search-btn" onClick={() => search()}>
                    Search
                  </button>
                </Col>
                <Col xs={"auto"} className="mt-2 mt-sm-0">
                  <Form.Group className="mt-4 mb-2 px-2 d-none d-sm-block">
                    <Form.Label className="input-label mb-0">&nbsp;</Form.Label>
                  </Form.Group>
                  <button className="clear-btn" onClick={() => clearSearch()}>
                    Clear
                  </button>
                </Col>
              </Row>
              <div className="mx-2 mb-2 table-overflow">
                <Table hover className="mt-3 mb-2 setting-table align-middle">
                  <thead>
                    <tr>
                      <th className="px-4">No.</th>
                      <th className="text-center px-4">Due Date</th>
                      <th className="text-center px-4">Service</th>
                      <th className="text-center px-4">AC Name</th>
                      <th className="text-center px-4">Serial Number</th>
                      <th className="text-center px-4">Model</th>
                      <th className="text-center px-4">Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {maintenanceSearchResult.length ? (
                      currentPosts.map((item, index) => (
                        <tr key={index}>
                          <td className="px-4">
                            {index + 1 + (currentPage - 1) * PerPage}.
                          </td>
                          <td className="px-4">
                            {moment().format("DD/MM/YYYY")}
                          </td>
                          <td className="px-4">Clean</td>
                          <td className="px-4 text-center">{item.title}</td>
                          <td className="px-4 text-center">{item.serail_no}</td>
                          <td className="px-4 text-center">
                            {checkModel(item.odu, item.model)}
                          </td>
                          <td className="px-4 text-center">
                            {item.location.building} {item.location.floor},{" "}
                            {item.location.room}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={7} className="text-center">
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <Pagination
                postsPerPage={PerPage}
                totalPosts={maintenanceSearchResult.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MaintenanceSchedule;
