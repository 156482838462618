import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Table, Modal, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Header from "../components/header";
import Nav from "../components/nav";
import Pagination from "../components/pagination";
import Upload from "../assets/image/setting/upload.png";
import { building_list } from "../api/product";
import { update_room, add_room, delete_room } from "../api/setting";

const SettingRoom = (props) => {
  const [buildingList, setBuildingList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPage] = useState(20);
  const [show, setShow] = useState(false);
  const [addForm, setAddForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [buildingID, setBuildingID] = useState("");
  const [floor, setFloor] = useState("");
  const [room, setRoom] = useState("");
  const [roomImage, setRoomImage] = useState(null);
  const [validateImage, setValidateImage] = useState(true);
  const [buildingSearch, setBuildingSearch] = useState("");
  const [floorSearch, setFloorSearch] = useState("");
  const [roomSearch, setRoomSearch] = useState("");
  const [roomListSearchSearchResult, setRoomListSearchResult] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [buildingValidate, setBuildingValidate] = useState(true);
  const [floorValidate, setFloorValidate] = useState(true);
  const [roomValidate, setRoomValidate] = useState(true);
  const [buildingListSearch, setBuildingListSearch] = useState([]);
  const [floorListSearch, setFloorListSearch] = useState([]);
  const [roomListSearch, setRoomListSearch] = useState([]);

  const indexOfLastPost = currentPage * PerPage;
  const indexOfFirstPost = indexOfLastPost - PerPage;
  const currentPosts = roomListSearchSearchResult.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);

  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      let buildings = [];
      let floors = [];
      let rooms = [];

      try {
        let token = localStorage.getItem("token");
        let companyId = localStorage.getItem("company_id");

        let res = await building_list(token);

        let buildingData = res.data.data.filter((item) => {
          return item.company_id === companyId;
        });

        buildingData.map((i) => {
          buildings.push({
            building: i.title,
            building_id: i._id,
          });

          return i;
        });

        buildings.sort((a, b) => {
          if (a.building_id.toLowerCase() < b.building_id.toLowerCase()) {
            return -1;
          }

          if (a.building_id.toLowerCase() > b.building_id.toLowerCase()) {
            return 1;
          }

          return 0;
        });

        buildingData.map((item) => {
          if (buildings[0].building !== undefined) {
            if (buildings[0].building === item.title) {
              if (item.floors !== undefined) {
                item.floors.map((data) => {
                  floors.push({
                    floor: data.title,
                    floor_id: data.floorId,
                  });

                  return data;
                });
              }
            }
          }

          return item;
        });

        floors.sort((a, b) => {
          if (a.floor_id.toLowerCase() < b.floor_id.toLowerCase()) {
            return -1;
          }

          if (a.floor_id.toLowerCase() > b.floor_id.toLowerCase()) {
            return 1;
          }

          return 0;
        });

        buildingData.map((i) => {
          if (i.floors !== undefined) {
            i.floors.map((j) => {
              if (j.rooms !== undefined) {
                j.rooms.map((k) => {
                  rooms.push({
                    building: i.title,
                    building_id: i._id,
                    floor: j.title,
                    floor_id: j.floorId,
                    room: k.title,
                    room_image: k.plan_img,
                    room_id: k.roomId,
                  });

                  return k;
                });
              }

              return j;
            });
          }

          return i;
        });

        rooms.sort((a, b) => {
          if (a.building_id.toLowerCase() < b.building_id.toLowerCase()) {
            return -1;
          }

          if (a.building_id.toLowerCase() > b.building_id.toLowerCase()) {
            return 1;
          }

          return 0;
        });

        setBuildingList(buildings);
        setFloorList(floors);
        setRoomList(rooms);
        setRoomListSearchResult(rooms);

        setBuildingListSearch(buildings);
        setFloorListSearch([]);
        setRoomListSearch([]);
        setBuildingSearch("");
        setFloorSearch("");
        setRoomSearch("");

        setLoaded(false);
      } catch (error) {
        console.log(error);
        setLoaded(false);
      }
    })();
  }, [loaded]);

  const handleSubmit = async (type) => {
    if (!buildingID) {
      setBuildingValidate(false);
    }

    if (!floor) {
      setFloorValidate(false);
    }

    if (!room) {
      setRoomValidate(false);
    }

    if (!roomImage) {
      setValidateImage(false);
    }

    if (!buildingID || !floor || !room) {
      return;
    }

    try {
      let token = localStorage.getItem("token");
      if (type === "add") {
        await add_room(token, buildingID, floor, room, roomImage);
        alert("Room added successfully.");

        setAddForm(false);
        setLoaded(true);
      } else {
        await update_room(token, buildingID, floor, room, roomImage);
        alert("Room updated successfully.");

        setEditForm(false);
        setLoaded(true);
      }
    } catch (error) {
      console.log(error);

      alert(type === "add" ? "Room added failed!" : "Room updated failed!");
    }
  };

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);

      setValidateImage(true);
      setRoomImage(e.target.files[0]);
    }
  };

  const search = () => {
    var result = roomList.filter((item) => {
      if (
        item.building_id.indexOf(buildingSearch.toLowerCase()) >= 0 &&
        item.floor_id.toLowerCase().indexOf(floorSearch.toLowerCase()) >= 0 &&
        item.room_id.toLowerCase().indexOf(roomSearch.toLowerCase()) >= 0
      ) {
        return true;
      } else {
        return false;
      }
    });

    setRoomListSearchResult(result);
  };

  const clearSearch = () => {
    setBuildingSearch("");
    setFloorSearch("");
    setRoomSearch("");
    setFloorListSearch([]);
    setRoomListSearch([]);
    setRoomListSearchResult(roomList);
  };

  const changeBuilding = async (val) => {
    setBuildingID(val);

    let floors = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (val === item._id) {
          if (item.floors) {
            item.floors.map((i) => {
              floors.push({
                floor: i.title,
                floor_id: i.floorId,
                floor_image: i.plan_img,
              });

              return i;
            });
          }
        }

        return item;
      });

      floors.sort((a, b) => {
        if (a.floor_id.toLowerCase() < b.floor_id.toLowerCase()) {
          return -1;
        }

        if (a.floor_id.toLowerCase() > b.floor_id.toLowerCase()) {
          return 1;
        }

        return 0;
      });

      setFloorList(floors);
      setFloor(floors.length ? floors[0].floor : "");

      if (val.trim().length) {
        setBuildingValidate(true);
      } else {
        setBuildingValidate(false);
      }

      if (floors.length) {
        if (floors[0].floor) {
          setFloorValidate(true);
        } else {
          setFloorValidate(false);
        }
      } else {
        setFloorValidate(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editModalHandle = async (building_id, floor_id) => {
    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      let buildings = [];
      let floors = [];

      buildingData.map((item) => {
        if (item.title !== undefined) {
          buildings.push({
            building: item.title,
            building_id: item._id,
          });
        }

        return item;
      });

      buildings.sort((a, b) => {
        if (a.building_id.toLowerCase() < b.building_id.toLowerCase()) {
          return -1;
        }

        if (a.building_id.toLowerCase() > b.building_id.toLowerCase()) {
          return 1;
        }

        return 0;
      });

      // Filler Floor
      buildingData.map((item) => {
        if (building_id === item._id) {
          if (item.floors !== undefined) {
            item.floors.map((data) => {
              floors.push({
                floor: data.title,
                floor_id: data.floorId,
              });

              return data;
            });
          }
        }

        return item;
      });

      floors.sort((a, b) => {
        if (a.floor_id.toLowerCase() < b.floor_id.toLowerCase()) {
          return -1;
        }

        if (a.floor_id.toLowerCase() > b.floor_id.toLowerCase()) {
          return 1;
        }

        return 0;
      });

      setFloorList(floors);
    } catch {}
  };

  const deleteHandle = async () => {
    try {
      let token = localStorage.getItem("token");
      await delete_room(token, buildingID, floor, room);
      setDeleteModal(false);
      setLoaded(true);
    } catch (error) {
      console.log(error);
      setDeleteModal(false);
      setLoaded(true);
    }
  };

  const floorChangeHandle = (val) => {
    if (val.trim().length) {
      setFloor(val);
      setFloorValidate(true);
    } else {
      setFloor(val);
      setFloorValidate(false);
    }
  };

  const roomChangeHandle = (val) => {
    if (val.trim().length) {
      setRoom(val);
      setRoomValidate(true);
    } else {
      setRoom(val);
      setRoomValidate(false);
    }
  };

  const changeBuildingSearchHandle = async (val) => {
    setBuildingSearch(val);

    let floors = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (val === item._id) {
          if (item.floors) {
            item.floors.map((i) => {
              floors.push({
                floor: i.title,
                floor_id: i.floorId,
              });

              return i;
            });
          }

          floors.sort((a, b) => {
            let x = a.floor_id.toLowerCase();
            let y = b.floor_id.toLowerCase();

            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          });
        }

        return item;
      });

      setFloorListSearch(floors);
      setRoomListSearch([]);
      setFloorSearch("");
      setRoomSearch("");
    } catch (error) {
      console.log(error);
    }
  };

  const changeFloorSearchHandle = async (val) => {
    setFloorSearch(val);

    let rooms = [];

    try {
      let token = localStorage.getItem("token");
      let companyId = localStorage.getItem("company_id");

      let res = await building_list(token);

      let buildingData = res.data.data.filter((item) => {
        return item.company_id === companyId;
      });

      buildingData.map((item) => {
        if (buildingSearch === item._id) {
          if (item.floors) {
            item.floors.map((i) => {
              if (i.floorId === val) {
                if (i.rooms !== undefined) {
                  i.rooms.map((j) => {
                    rooms.push({
                      room: j.title,
                      room_id: j.roomId,
                    });

                    return i;
                  });
                }
              }

              return i;
            });
          }
        }

        return item;
      });

      setRoomListSearch(rooms);
      setRoomSearch("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav page={props.page} />
      <div className="body-container mt-4 mt-4 pt-5 pt-xl-0">
        <Row className="mx-0 px-xl-4">
          <Col xs={12}>
            <div className="table-container px-2 px-sm-3 px-xl-4 py-3 py-sm-4">
              <div
                className="table-container-title px-2"
                style={{ justifyContent: "space-between" }}
              >
                <h5 className="mb-1">Room Management</h5>
                <button
                  className="add-modal-btn"
                  onClick={() => {
                    setBuildingID("");
                    setFloor("");
                    setRoom("");
                    setRoomImage(null);
                    setAddForm(true);
                  }}
                >
                  Add Room
                </button>
              </div>
              <Row>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-3 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">
                      Building Name
                    </Form.Label>
                    <Form.Select
                      onChange={(e) =>
                        changeBuildingSearchHandle(e.target.value)
                      }
                      required
                      value={buildingSearch}
                    >
                      <option value="">Select Building</option>
                      {buildingListSearch.map((item, index) => (
                        <option value={item.building_id} key={index}>
                          {item.building}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-1 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">Floor Name</Form.Label>
                    <Form.Select
                      onChange={(e) => changeFloorSearchHandle(e.target.value)}
                      required
                      value={floorSearch}
                    >
                      <option value="">Select Floor</option>
                      {floorListSearch.map((item, index) => (
                        <option value={item.floor_id} key={index}>
                          {item.floor}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={3} xxl={2} className="pe-sm-0">
                  <Form.Group className="mt-1 mt-sm-4 mb-2 px-2">
                    <Form.Label className="input-label">Room Name</Form.Label>
                    <Form.Select
                      onChange={(e) => setRoomSearch(e.target.value)}
                      required
                      value={roomSearch}
                    >
                      <option value="">Select Room</option>
                      {roomListSearch.map((item, index) => (
                        <option value={item.room_id} key={index}>
                          {item.room}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={"auto"} className="pe-0 ms-2 ms-sm-0 mt-2 mt-sm-0">
                  <Form.Group className="mt-4 mb-2 px-2 d-none d-sm-block">
                    <Form.Label className="input-label mb-0">&nbsp;</Form.Label>
                  </Form.Group>
                  <button className="search-btn" onClick={() => search()}>
                    Search
                  </button>
                </Col>
                <Col xs={"auto"} className="mt-2 mt-sm-0">
                  <Form.Group className="mt-4 mb-2 px-2 d-none d-sm-block">
                    <Form.Label className="input-label mb-0">&nbsp;</Form.Label>
                  </Form.Group>
                  <button className="clear-btn" onClick={() => clearSearch()}>
                    Clear
                  </button>
                </Col>
              </Row>
              <div className="mx-2 mb-2 table-overflow">
                <Table hover className="mt-3 mb-2 setting-table align-middle">
                  <thead>
                    <tr>
                      <th className="px-4">No.</th>
                      <th className="text-center px-4">Image</th>
                      <th className="text-center px-4">Building Name</th>
                      <th className="text-center px-4">Floor Name</th>
                      <th className="text-center px-4">Room Name</th>
                      <th className="text-center px-4 manage-ac">
                        AC Position
                      </th>
                      <th className="text-center px-4">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {roomListSearchSearchResult.length ? (
                      currentPosts.map((item, index) => (
                        <tr key={index}>
                          <td className="px-4">
                            {currentPage === 1
                              ? index + 1
                              : index + 1 + (currentPage - 1) * PerPage}
                            .
                          </td>
                          <td className="px-4 text-center" width={"10%"}>
                            {item.room_image ? (
                              <img
                                src={
                                  "http://node-env.eba-7xwegkmr.ap-southeast-1.elasticbeanstalk.com/api/v2.0/images/upload/" +
                                  item.room_image
                                }
                                alt="floor"
                                className="table-image py-2"
                                onClick={() => {
                                  setModalImage(
                                    "http://node-env.eba-7xwegkmr.ap-southeast-1.elasticbeanstalk.com/api/v2.0/images/upload/" +
                                      item.room_image
                                  );
                                  setShow(true);
                                }}
                              />
                            ) : null}
                          </td>
                          <td className="px-4 text-center">{item.building}</td>
                          <td className="px-4 text-center">{item.floor}</td>
                          <td className="px-4 text-center">{item.room}</td>
                          <td className="px-4 text-center manage-ac">
                            <span
                              className="table-manage"
                              onClick={() =>
                                navigate("/setting_position", {
                                  state: {
                                    building: item.building,
                                    floor: item.floor,
                                    room: item.room,
                                  },
                                })
                              }
                            >
                              Manage
                            </span>
                          </td>
                          <td className="px-4 text-center">
                            <span
                              className="table-edit"
                              onClick={() => {
                                setBuildingID(item.building_id);
                                setFloor(item.floor);
                                setRoom(item.room);
                                setModalImage(
                                  item.room_image
                                    ? "http://node-env.eba-7xwegkmr.ap-southeast-1.elasticbeanstalk.com/api/v2.0/images/upload/" +
                                        item.room_image
                                    : null
                                );
                                editModalHandle(
                                  item.building_id,
                                  item.floor_id
                                );
                                setEditForm(true);
                              }}
                            >
                              Edit
                            </span>{" "}
                            /{" "}
                            <span
                              className="table-delete"
                              onClick={() => {
                                setBuildingID(item.building_id);
                                setFloor(item.floor);
                                setRoom(item.room);
                                setDeleteModal(true);
                              }}
                            >
                              Delete
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={7} className="text-center">
                          No Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <Pagination
                postsPerPage={PerPage}
                totalPosts={roomListSearchSearchResult.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Modal size="lg" show={show} onHide={() => setShow(false)} centered>
        <div className="position-relative">
          <img
            src={modalImage}
            alt="modal"
            className="w-100 h-auto"
            style={{ maxHeight: "80vh" }}
          />
          <button
            type="button"
            className="btn-close modal-close-btn"
            aria-label="Close"
            onClick={() => setShow(false)}
          />
        </div>
      </Modal>
      <Modal show={editForm} onHide={() => setEditForm(false)} centered>
        <Modal.Body>
          <h4 className="modal-title mb-2">Edit Room</h4>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Building Name</Form.Label>
            <Form.Select
              onChange={(e) => changeBuilding(e.target.value)}
              required
              value={buildingID}
              style={{
                borderColor: buildingValidate ? "#cccccc" : "#b31117",
              }}
            >
              <option value="">Select Building</option>
              {buildingList.map((item, index) => (
                <option value={item.building_id} key={index}>
                  {item.building}
                </option>
              ))}
            </Form.Select>
            {!buildingValidate && (
              <Form.Label
                className="input-label mt-1"
                style={{ color: "#b31117" }}
              >
                Please select building.
              </Form.Label>
            )}
          </Form.Group>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Floor Name</Form.Label>
            <Form.Select
              onChange={(e) => floorChangeHandle(e.target.value)}
              required
              value={floor}
              style={{
                borderColor: floorValidate ? "#cccccc" : "#b31117",
              }}
            >
              <option value="">Select Floor</option>
              {floorList.map((item, index) => (
                <option value={item.floor} key={index}>
                  {item.floor}
                </option>
              ))}
            </Form.Select>
            {!floorValidate && (
              <Form.Label
                className="input-label mt-1"
                style={{ color: "#b31117" }}
              >
                Please select floor.
              </Form.Label>
            )}
          </Form.Group>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Room Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Room"
              defaultValue={room}
              required
              onChange={(e) => roomChangeHandle(e.target.value)}
              style={{
                borderColor: roomValidate ? "#cccccc" : "#b31117",
              }}
            />
            {!roomValidate && (
              <Form.Label
                className="input-label mt-1"
                style={{ color: "#b31117" }}
              >
                Please provide a valid room name.
              </Form.Label>
            )}
          </Form.Group>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Room Image</Form.Label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                  display: "none",
                }}
              />
              <div
                className="form-image-container mb-2"
                onClick={() => imageUploader.current.click()}
              >
                <img
                  ref={uploadedImage}
                  src={modalImage ? modalImage : Upload}
                  className="form-image-input"
                  alt="plan"
                />
              </div>
            </div>
          </Form.Group>
          <div className="modal-btn-container mb-2">
            <Button
              type="button"
              onClick={() => {
                setEditForm(false);
                setValidateImage(true);
                setBuildingValidate(true);
                setFloorValidate(true);
                setRoomValidate(true);
              }}
            >
              Cancel
            </Button>
            <Button type="button" onClick={() => handleSubmit("edit")}>
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={addForm} onHide={() => setAddForm(false)} centered>
        <Modal.Body>
          <h4 className="modal-title mb-2">Add Room</h4>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Building Name</Form.Label>
            <Form.Select
              onChange={(e) => changeBuilding(e.target.value)}
              required
              style={{
                borderColor: buildingValidate ? "#cccccc" : "#b31117",
              }}
            >
              <option value="">Select Building</option>
              {buildingList.map((item, index) => (
                <option value={item.building_id} key={index}>
                  {item.building}
                </option>
              ))}
            </Form.Select>
            {!buildingValidate && (
              <Form.Label
                className="input-label mt-1"
                style={{ color: "#b31117" }}
              >
                Please select building.
              </Form.Label>
            )}
          </Form.Group>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Floor Name</Form.Label>
            <Form.Select
              onChange={(e) => floorChangeHandle(e.target.value)}
              value={floor}
              required
              style={{
                borderColor: floorValidate ? "#cccccc" : "#b31117",
              }}
            >
              <option value="">Select Floor</option>
              {floorList.map((item, index) => (
                <option value={item.floor} key={index}>
                  {item.floor}
                </option>
              ))}
            </Form.Select>
            {!floorValidate && (
              <Form.Label
                className="input-label mt-1"
                style={{ color: "#b31117" }}
              >
                Please select floor.
              </Form.Label>
            )}
          </Form.Group>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Room Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Room"
              onChange={(e) => roomChangeHandle(e.target.value)}
              required
              style={{
                borderColor: roomValidate ? "#cccccc" : "#b31117",
              }}
            />
            {!roomValidate && (
              <Form.Label
                className="input-label mt-1"
                style={{ color: "#b31117" }}
              >
                Please provide a valid room name.
              </Form.Label>
            )}
          </Form.Group>
          <Form.Group className="mb-4 px-2">
            <Form.Label className="input-label">Building Image</Form.Label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                  display: "none",
                }}
                required
              />
              <div
                className={
                  validateImage
                    ? "form-image-container"
                    : "form-image-container error-image-upload"
                }
                onClick={() => imageUploader.current.click()}
              >
                <img
                  ref={uploadedImage}
                  src={Upload}
                  className="form-image-input"
                  alt="profile"
                />
              </div>
              <p
                className="form-error mb-0"
                style={{ display: validateImage ? "none" : "block" }}
              >
                Please provide a building image.
              </p>
            </div>
          </Form.Group>
          <div className="modal-btn-container mb-2">
            <Button
              type="button"
              onClick={() => {
                setAddForm(false);
                setBuildingValidate(true);
                setFloorValidate(true);
                setRoomValidate(true);
                setValidateImage(true);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" onClick={() => handleSubmit("add")}>
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={deleteModal} centered>
        <Modal.Body>
          <h4 className="modal-title mb-4">Do you want delete this room ?</h4>
          <div className="modal-btn-container mb-2">
            <Button
              type="button"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={() => {
                deleteHandle();
              }}
            >
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={loaded}
        backdrop="static"
        keyboard="false"
        centered
        id="loading-modal"
      >
        <div className="loader"></div>
      </Modal>
    </div>
  );
};

export default SettingRoom;
