import React, { useState, useRef, useContext } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Header from "../components/header";
import Nav from "../components/nav";

import ProfileImage from "../assets/image/user_128.png";
import { ProfileContext } from "../components/profile_context";

const Profile = () => {
  const { name, surname, company, update_profile } = useContext(ProfileContext);

  const [inputName, setInputName] = useState(name);
  const [inputSurname, setInputSurname] = useState(surname);
  const [inputBuilding, setInputBuilding] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [errorName, setErrorName] = useState("");
  const [errorSurname, setErrorSurname] = useState("");
  const [errorBuilding, setErrorBuilding] = useState("");

  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);

  const navigate = useNavigate();

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;

        setProfileImage(current.src);
      };
      reader.readAsDataURL(file);
    }
  };

  const nameHandle = (e) => {
    setInputName(e.target.value);
    setErrorName("");
  };

  const surnameHandle = (e) => {
    setInputSurname(e.target.value);
    setErrorSurname("");
  };

  const buildingHandle = (e) => {
    setInputBuilding(e.target.value);
    setErrorBuilding("");
  };

  const confirm = () => {
    var confirm = true;

    if (inputName.length) {
      setErrorName("");
    } else {
      setErrorName("Name is required!");
      confirm = false;
    }

    if (inputSurname.length) {
      setErrorSurname("");
    } else {
      setErrorSurname("Surname is required!");
      confirm = false;
    }

    if (inputBuilding.length) {
      setErrorBuilding("");
    } else {
      setErrorBuilding("Building is required!");
      confirm = false;
    }

    if (confirm) {
      update_profile(inputName, inputSurname, inputBuilding, profileImage);

      navigate("/");
    }
  };

  return (
    <div className="main-bg">
      <Header />
      <Nav />
      <div className="body-container mt-4 ps-4">
        <div className="profile-container">
          <div className="profile-form-container">
            <p className="form-title pt-4">Edit Profile</p>
            <Form className="login-form">
              <Form.Group className="my-3">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    ref={imageUploader}
                    style={{
                      display: "none",
                    }}
                  />
                  <div
                    className="profile-image-container mb-2"
                    onClick={() => imageUploader.current.click()}
                  >
                    <img
                      ref={uploadedImage}
                      src={ProfileImage}
                      className="profile-image-input"
                      alt="profile"
                    />
                  </div>
                  <span className="input-label">Profile Image</span>
                </div>
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label className="input-label">Name</Form.Label>
                <div className="login-input-container">
                  <div className="login-input-box">
                    <input
                      placeholder="Name"
                      className="login-input"
                      onChange={(e) => nameHandle(e)}
                      defaultValue={name}
                    />
                  </div>
                </div>
                {errorName.length ? (
                  <p className="error-input">{errorName}</p>
                ) : null}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label className="input-label">Surname</Form.Label>
                <div className="login-input-container">
                  <div className="login-input-box">
                    <input
                      placeholder="Surname"
                      className="login-input"
                      onChange={(e) => surnameHandle(e)}
                      defaultValue={surname}
                    />
                  </div>
                </div>
                {errorSurname.length ? (
                  <p className="error-input">{errorSurname}</p>
                ) : null}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label className="input-label">Company</Form.Label>
                <div className="login-input-container">
                  <div className="login-input-box">
                    <input
                      placeholder="Building"
                      className="login-input"
                      onChange={(e) => buildingHandle(e)}
                      defaultValue={company}
                    />
                  </div>
                </div>
                {errorBuilding.length ? (
                  <p className="error-input">{errorBuilding}</p>
                ) : null}
              </Form.Group>
            </Form>
            <button
              type="button"
              className="login-btn mb-5"
              onClick={() => confirm()}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
