import { useEffect, useState } from "react";
import moment from "moment";
import { indoor_report_realtime, odu_report_realtime } from "../api/report";
import {
  carbon,
  dateTimeFormat,
  fanSpeed,
  functionPe,
  indoorModel,
  indoorOption,
  indoorProtection,
  indoorType,
  mode,
  pm,
  power,
  roomHumidity,
  roomPressure,
  roomTemp,
  swing,
} from "../utils/utils";

const useRealtimeClock = () => {
  const currentDate = moment().format("DD/MM/YYYY [TIME] HH:mm:ss");

  const [clock, setClock] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setClock(currentDate);
    }, 1000);

    return () => clearInterval(interval);
  }, [currentDate]);

  return [clock];
};

const useRealtimeIndoorError = () => {
  const [errorData, setErrorData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const token = localStorage.getItem("token");

        const result = await indoor_report_realtime(token);

        const errorFilter = [];

        result.data.data.map((i) => {
          if (i.acinfo.length) {
            i.acinfo.map((j) => {
              if (parseInt(j.istst.IND2_5) !== 0) {
                errorFilter.push({
                  system_name: i.cdu_no,
                  gateway_sn: i.gateway_sn,
                  location: i.location,
                  uid: i.cid,
                  error_code: parseInt(j.istst.IND2_5),
                });
              }

              return j;
            });
          }
          return i;
        });

        setErrorData(errorFilter);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();

    const interval = setInterval(async () => {
      try {
        const token = localStorage.getItem("token");

        const resReport = await indoor_report_realtime(token);

        const errorFilter = [];

        resReport.data.data.map((i) => {
          if (i.acinfo.length) {
            i.acinfo.map((j) => {
              if (parseInt(j.istst.IND2_5) !== 0) {
                errorFilter.push({
                  system_name: i.cdu_no,
                  gateway_sn: i.gateway_sn,
                  location: i.location,
                  uid: i.cid,
                  error_code: parseInt(j.istst.IND2_5),
                });
              }

              return j;
            });
          }
          return i;
        });

        setErrorData(errorFilter);
      } catch (e) {
        console.log(e);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
      setLoading(false);
    };
  }, []);

  return [errorData, loading];
};

const useRealtimeOutdoorError = () => {
  const [errorData, setErrorData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const token = localStorage.getItem("token");

        const result = await odu_report_realtime(token, "");
        const errorFilter = [];

        result.data.data.map((i) => {
          if (i.oduinfo.length) {
            i.oduinfo.map((j, k) => {
              if (parseInt(j.outdoor_led) !== 0) {
                errorFilter.push({
                  system_name: i.system_name,
                  gateway_sn: i.gateway_sn,
                  location: i.location,
                  oduinfo: i.oduinfo[k],
                });
              }

              return j;
            });
          }
          return i;
        });

        setErrorData(errorFilter);
        setLoading(false);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();

    const interval = setInterval(async () => {
      try {
        const token = localStorage.getItem("token");

        const result = await odu_report_realtime(token, "");
        const errorFilter = [];

        result.data.data.map((i) => {
          if (i.oduinfo.length) {
            i.oduinfo.map((j, k) => {
              if (parseInt(j.outdoor_led) !== 0) {
                errorFilter.push({
                  system_name: i.system_name,
                  gateway_sn: i.gateway_sn,
                  location: i.location,
                  oduinfo: i.oduinfo[k],
                });
              }

              return j;
            });
          }
          return i;
        });

        setErrorData(errorFilter);
      } catch (e) {
        console.log(e);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
      setLoading(false);
    };
  }, []);

  return [errorData, loading];
};

const useRealtimeOduReport = (cdu) => {
  const [reportData, setReportData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const token = localStorage.getItem("token");

        const result = await odu_report_realtime(token, cdu);

        const data = [];

        result.data.data.map((i) => {
          if (i.oduinfo.length) {
            i.oduinfo.map((j) => {
              data.push({
                "Date Time": moment(dateTimeFormat(j.datetime))
                  .add(7, "hours")
                  .format("DD/MM/YYYY HH:mm:ss"),
                "System Name": i.system_name,
                Gateway: i.gateway_sn,
                Location: i.location.length
                  ? i.location.map((k) => {
                      return k.building + " " + k.floor + " " + k.room + ", ";
                    })
                  : "-",
                UID: j.uid,
                "ODU ID": j.oduid,
                "Mid Coil": j.mid_coil,
                "Outlet Temp.": j.outlet_temp,
                "Discharge Pipe Temp. 1": j.discharge_pipe_temp1,
                "Discharge Pipe Temp. 2": j.discharge_pipe_temp2,
                "Ambient Temp.": j.ambient_temp,
                "Suction Pipe Temp.": j.suction_pipe_temp,
                "Control Device Temp. 1": j.control_device_temp1,
                "Control Device Temp. 2": j.control_device_temp2,
                "Compressor Actual Speed 1": j.compressor1_actual_speed,
                "Compressor Actual Speed 2": j.compressor2_actual_speed,
                "Compressor Current 1": j.compressor1_current,
                "Compressor Current 2": j.compressor2_current,
                "Outdoor Fan": j.outdoor_fan,
                "System Mode": j.system_mode,
                "Unit Capacity": j.unit_capacity,
                "Demand Running 1": j.demand_running1,
                "Demand Running 2": j.demand_running2,
                "System Status Comp. 1": j.system_status_comp1,
                "System Status Comp. 2": j.system_status_comp2,
                "Protect Status System 1": j.protect_status_system1,
                "Protect Status System 2": j.protect_status_system2,
                "System Power Input": j.system_power_input,
                "Outdoor EXV": j.outdoor_exv,
              });

              return j;
            });
          } else {
            data.push({
              "Date Time": "-",
              "System Name": i.system_name,
              Gateway: i.gateway_sn,
              Location: i.location.length
                ? i.location.map((k) => {
                    return k.building + " " + k.floor + " " + k.room + ", ";
                  })
                : "-",
              UID: "-",
              "ODU ID": "-",
              "Mid Coil": "-",
              "Outlet Temp.": "-",
              "Discharge Pipe Temp. 1": "-",
              "Discharge Pipe Temp. 2": "-",
              "Ambient Temp.": "-",
              "Suction Pipe Temp.": "-",
              "Control Device Temp. 1": "-",
              "Control Device Temp. 2": "-",
              "Compressor Actual Speed 1": "-",
              "Compressor Actual Speed 2": "-",
              "Compressor Current 1": "-",
              "Compressor Current 2": "-",
              "Outdoor Fan": "-",
              "System Mode": "-",
              "Unit Capacity": "-",
              "Demand Running 1": "-",
              "Demand Running 2": "-",
              "System Status Comp. 1": "-",
              "System Status Comp. 2": "-",
              "Protect Status System 1": "-",
              "Protect Status System 2": "-",
              "System Power Input": "-",
              "Outdoor EXV": "-",
            });
          }

          return i;
        });

        const reportFilter = result.data.data.filter((item) => {
          if (cdu === "" || cdu.toLowerCase() === "all") {
            return true;
          } else {
            return cdu.toLowerCase() === item.system_name.toLowerCase();
          }
        });

        setReportData(reportFilter);
        setExportData(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();

    const interval = setInterval(async () => {
      try {
        const token = localStorage.getItem("token");

        const result = await odu_report_realtime(token, cdu);

        const data = [];

        result.data.data.map((i) => {
          if (i.oduinfo.length) {
            i.oduinfo.map((j) => {
              data.push({
                "Date Time": moment(dateTimeFormat(j.datetime))
                  .add(7, "hours")
                  .format("DD/MM/YYYY HH:mm:ss"),
                "System Name": i.system_name,
                Gateway: i.gateway_sn,
                Location: i.location.length
                  ? i.location.map((k) => {
                      return k.building + " " + k.floor + " " + k.room + ", ";
                    })
                  : "-",
                UID: j.uid,
                "ODU ID": j.oduid,
                "Mid Coil": j.mid_coil,
                "Outlet Temp.": j.outlet_temp,
                "Discharge Pipe Temp. 1": j.discharge_pipe_temp1,
                "Discharge Pipe Temp. 2": j.discharge_pipe_temp2,
                "Ambient Temp.": j.ambient_temp,
                "Suction Pipe Temp.": j.suction_pipe_temp,
                "Control Device Temp. 1": j.control_device_temp1,
                "Control Device Temp. 2": j.control_device_temp2,
                "Compressor Actual Speed 1": j.compressor1_actual_speed,
                "Compressor Actual Speed 2": j.compressor2_actual_speed,
                "Compressor Current 1": j.compressor1_current,
                "Compressor Current 2": j.compressor2_current,
                "Outdoor Fan": j.outdoor_fan,
                "System Mode": j.system_mode,
                "Unit Capacity": j.unit_capacity,
                "Demand Running 1": j.demand_running1,
                "Demand Running 2": j.demand_running2,
                "System Status Comp. 1": j.system_status_comp1,
                "System Status Comp. 2": j.system_status_comp2,
                "Protect Status System 1": j.protect_status_system1,
                "Protect Status System 2": j.protect_status_system2,
                "System Power Input": j.system_power_input,
                "Outdoor EXV": j.outdoor_exv,
              });

              return j;
            });
          } else {
            data.push({
              "Date Time": "-",
              "System Name": i.system_name,
              Gateway: i.gateway_sn,
              Location: i.location.length
                ? i.location.map((k) => {
                    return k.building + " " + k.floor + " " + k.room + ", ";
                  })
                : "-",
              UID: "-",
              "ODU ID": "-",
              "Mid Coil": "-",
              "Outlet Temp.": "-",
              "Discharge Pipe Temp. 1": "-",
              "Discharge Pipe Temp. 2": "-",
              "Ambient Temp.": "-",
              "Suction Pipe Temp.": "-",
              "Control Device Temp. 1": "-",
              "Control Device Temp. 2": "-",
              "Compressor Actual Speed 1": "-",
              "Compressor Actual Speed 2": "-",
              "Compressor Current 1": "-",
              "Compressor Current 2": "-",
              "Outdoor Fan": "-",
              "System Mode": "-",
              "Unit Capacity": "-",
              "Demand Running 1": "-",
              "Demand Running 2": "-",
              "System Status Comp. 1": "-",
              "System Status Comp. 2": "-",
              "Protect Status System 1": "-",
              "Protect Status System 2": "-",
              "System Power Input": "-",
              "Outdoor EXV": "-",
            });
          }

          return i;
        });

        const reportFilter = result.data.data.filter((item) => {
          if (cdu === "" || cdu.toLowerCase() === "all") {
            return true;
          } else {
            return cdu.toLowerCase() === item.system_name;
          }
        });

        setReportData(reportFilter);
        setExportData(data);
      } catch (e) {
        console.log(e);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
      setLoading(false);
    };
  }, [cdu]);

  return [reportData, exportData, loading];
};

const useRealtimeIndoorReport = (building, floor, room) => {
  const [reportData, setReportData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const token = localStorage.getItem("token");

        const result = await indoor_report_realtime(token);

        const reportFilter = result.data.data.filter((item) => {
          if (room.toLowerCase() === "all") {
            return (
              item.location.building.toLowerCase() === building.toLowerCase() &&
              item.location.floor.toLowerCase() === floor.toLowerCase()
            );
          } else {
            return (
              item.location.building.toLowerCase() === building.toLowerCase() &&
              item.location.floor.toLowerCase() === floor.toLowerCase() &&
              item.location.room.toLowerCase() === room.toLowerCase()
            );
          }
        });

        const data = [];

        reportFilter.map((item) => {
          data.push({
            "System Name": item.cdu_no,
            Gateway: item.gateway_sn,
            Location:
              item.location.building +
              " " +
              item.location.floor +
              " , " +
              item.location.room,
            Title: item.title,
            Model: item.model ? item.model : "SRV",
            ODU: item.odu,
            CID: item.cid,
            UID: item.uid,
            "Set Temp. (°C)": item.acinfo[0] ? item.acinfo[0].istst.st : "-",
            "Room Temp. (°C)": roomTemp(item),
            "Set Humidity (%)": item.acinfo[0]
              ? item.acinfo[0].istst.SetRH
              : "-",
            "Room Humidity (%)": roomHumidity(item),
            CO2: carbon(item),
            "PM2.5": pm(item),
            "Room Pressure": roomPressure(item),
            Power: item.acinfo[0] ? power(item.acinfo[0].istst.onoff) : "-",
            Mode: item.acinfo[0] ? mode(item.acinfo[0].istst.mode) : "-",
            "Fan Speed": item.acinfo[0]
              ? fanSpeed(item.acinfo[0].istst.fspeed)
              : "-",
            Swing: item.acinfo[0] ? swing(item.acinfo[0].istst.louver) : "-",
            Function: item.acinfo[0] ? functionPe(item) : "-",
            uPtRem: item.acinfo[0]
              ? parseInt(item.acinfo[0].istst.uPtRem) === 1
                ? "Auto Function: Active"
                : "Auto Function: Inactive"
              : "-",
            APS: item.acinfo[0] ? item.acinfo[0].istst.APS : "-",
            TrbAPS: item.acinfo[0]
              ? parseInt(item.acinfo[0].istst.TrbAPS) === 1
                ? "Ozone Filter Disinfection: Active"
                : "Ozone Filter Disinfection: Inactive"
              : "-",
            "RH2 (%)": item.acinfo[0] ? item.acinfo[0].istst.RH2 : "-",
            "RT2 (°C)": item.acinfo[0]
              ? parseFloat(item.acinfo[0].istst.RT2) === 255
                ? "Error"
                : item.acinfo[0].istst.RT2
              : "-",
            "Indoor Option":
              item.modal !== "IAQ"
                ? item.acinfo[0]
                  ? indoorOption(item.acinfo[0].istst.IND4_1)
                  : "-"
                : "-",
            "Type of Indoor": item.acinfo[0]
              ? indoorType(item.acinfo[0].istst.IND4_3)
              : "-",
            "Model of Indoor": item.acinfo[0]
              ? indoorModel(item.acinfo[0].istst.IND4_4)
              : "-",
            "CO2 (Byte High)": item.acinfo[0]
              ? item.acinfo[0].istst.CO2_H
              : "-",
            "CO2 (Byte Low)": item.acinfo[0] ? item.acinfo[0].istst.CO2_L : "-",
            "PM 2.5 (Byte 2)": item.acinfo[0]
              ? item.acinfo[0].istst.PM2_2
              : "-",
            "PM 2.5 (Byte 1)": item.acinfo[0]
              ? item.acinfo[0].istst.PM2_1
              : "-",
            "PM 2.5 (Byte 0)": item.acinfo[0]
              ? item.acinfo[0].istst.PM2_0
              : "-",
            "Indoor unit Coil Inlet Temp.(°C)":
              item.type === "SRV"
                ? item.acinfo[0]
                  ? parseInt(item.acinfo[0].istst.IND2_0) === 255
                    ? "Error"
                    : parseInt(item.acinfo[0].istst.IND2_0) - 40
                  : "-"
                : "-",
            "Indoor unit Coil Outlet Temp.(°C)":
              item.type === "SRV"
                ? item.acinfo[0]
                  ? parseInt(item.acinfo[0].istst.IND2_1) === 255
                    ? "Error"
                    : parseInt(item.acinfo[0].istst.IND2_1) - 40
                  : "-"
                : "-",
            "Indoor Unit EXV Position": item.acinfo[0]
              ? item.type === "SRV"
                ? item.acinfo[0].istst.IND2_2
                : "-"
              : "-",
            "Indoor %demand": item.acinfo[0]
              ? item.type === "SRV"
                ? item.acinfo[0].istst.IND2_3
                : "-"
              : "-",

            "Indoor Capacity":
              item.type === "SRV"
                ? item.acinfo[0]
                  ? item.acinfo[0].istst.IND2_4
                  : "-"
                : "-",
            "Status (LED Code)": item.acinfo[0]
              ? item.acinfo[0].istst.IND2_5
              : "-",
            "Indoor Unit Protection":
              item.type === "SRV"
                ? item.acinfo[0]
                  ? indoorProtection(item.acinfo[0].istst.IND2_6)
                  : "-"
                : "-",
            "Mid-coil Temp. (°C)":
              item.model === "PE" || item.model === "IC" || item.model === "FFU"
                ? item.acinfo[0]
                  ? parseInt(item.acinfo[0].istst.IND2_0) === 255
                    ? "Error"
                    : parseInt(item.acinfo[0].istst.IND2_0) - 40
                  : "-"
                : "-",
            "Outlet Temp. (°C)":
              item.model === "PE" || item.model === "IC" || item.model === "FFU"
                ? item.acinfo[0]
                  ? parseInt(item.acinfo[0].istst.IND2_1) === 255
                    ? "Error"
                    : parseInt(item.acinfo[0].istst.IND2_1) - 40
                  : "-"
                : "-",

            "Indoor Hepa Filter": item.acinfo[0]
              ? item.model === "PE" ||
                item.model === "IC" ||
                item.model === "FFU"
                ? item.acinfo[0].istst.IND2_2
                : "-"
              : "-",
            "Medium Filter": item.acinfo[0]
              ? item.model === "PE" ||
                item.model === "IC" ||
                item.model === "FFU"
                ? item.acinfo[0].istst.IND2_3
                : "-"
              : "-",
            "Pre Filter":
              item.model === "PE" || item.model === "IC" || item.model === "FFU"
                ? item.acinfo[0]
                  ? item.acinfo[0].istst.IND2_4
                  : "-"
                : "-",
            "UV Timing Running":
              item.model === "PE" || item.model === "IC" || item.model === "FFU"
                ? item.acinfo[0]
                  ? item.acinfo[0].istst.IND2_6
                  : "-"
                : "-",
            "PM 2.5 Sensor (Byte Low)":
              item.model === "PE" || item.model === "IC" || item.model === "FFU"
                ? item.acinfo[0]
                  ? item.acinfo[0].istst.IND2_7
                  : "-"
                : "-",
            "PM 2.5 Sensor (Byte High)":
              item.model === "PE" || item.model === "IC" || item.model === "FFU"
                ? item.acinfo[0]
                  ? item.acinfo[0].istst.IND2_8
                  : "-"
                : "-",
            "Extreme Ozone": item.acinfo[0]
              ? item.model === "PE" ||
                item.model === "IC" ||
                item.model === "FFU"
                ? item.acinfo[0].istst.IND2_9
                : "-"
              : "-",
            "Set Room Pa": item.acinfo[0]
              ? item.model === "IAQ"
                ? (item.acinfo[0].istst.IND2_2 - 3000) / 10
                : "-"
              : "-",

            "Set Anti Room": item.acinfo[0]
              ? item.model === "IAQ"
                ? (item.acinfo[0].istst.IND2_3 - 3000) / 10
                : "-"
              : "-",
            "Diff Pressure Room": item.acinfo[0]
              ? item.model === "IAQ"
                ? (item.acinfo[0].istst.IND2_8 - 3000) / 10
                : "-"
              : "-",
            "Anti Room": item.acinfo[0]
              ? item.model === "IAQ"
                ? (item.acinfo[0].istst.IND2_9 - 3000) / 10
                : "-"
              : "-",
          });

          return item;
        });

        setReportData(reportFilter);
        setExportData(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();

    const interval = setInterval(async () => {
      try {
        const token = localStorage.getItem("token");

        const result = await indoor_report_realtime(token);

        const reportFilter = result.data.data.filter((item) => {
          if (room.toLowerCase() === "all") {
            return (
              item.location.building.toLowerCase() === building.toLowerCase() &&
              item.location.floor.toLowerCase() === floor.toLowerCase()
            );
          } else {
            return (
              item.location.building.toLowerCase() === building.toLowerCase() &&
              item.location.floor.toLowerCase() === floor.toLowerCase() &&
              item.location.room.toLowerCase() === room.toLowerCase()
            );
          }
        });

        const data = [];

        reportFilter.map((item) => {
          data.push({
            "System Name": item.cdu_no,
            Gateway: item.gateway_sn,
            Location:
              item.location.building +
              " " +
              item.location.floor +
              " , " +
              item.location.room,
            Title: item.title,
            Model: item.model ? item.model : "SRV",
            ODU: item.odu,
            CID: item.cid,
            UID: item.uid,
            "Set Temp. (°C)": item.acinfo[0] ? item.acinfo[0].istst.st : "-",
            "Room Temp. (°C)": roomTemp(item),
            "Set Humidity (%)": item.acinfo[0]
              ? item.acinfo[0].istst.SetRH
              : "-",
            "Room Humidity (%)": roomHumidity(item),
            CO2: carbon(item),
            "PM2.5": pm(item),
            "Room Pressure": roomPressure(item),
            Power: item.acinfo[0] ? power(item.acinfo[0].istst.onoff) : "-",
            Mode: item.acinfo[0] ? mode(item.acinfo[0].istst.mode) : "-",
            "Fan Speed": item.acinfo[0]
              ? fanSpeed(item.acinfo[0].istst.fspeed)
              : "-",
            Swing: item.acinfo[0] ? swing(item.acinfo[0].istst.louver) : "-",
            Function: item.acinfo[0] ? functionPe(item) : "-",
            uPtRem: item.acinfo[0]
              ? parseInt(item.acinfo[0].istst.uPtRem) === 1
                ? "Auto Function: Active"
                : "Auto Function: Inactive"
              : "-",
            APS: item.acinfo[0] ? item.acinfo[0].istst.APS : "-",
            TrbAPS: item.acinfo[0]
              ? parseInt(item.acinfo[0].istst.TrbAPS) === 1
                ? "Ozone Filter Disinfection: Active"
                : "Ozone Filter Disinfection: Inactive"
              : "-",
            "RH2 (%)": item.acinfo[0] ? item.acinfo[0].istst.RH2 : "-",
            "RT2 (°C)": item.acinfo[0]
              ? parseFloat(item.acinfo[0].istst.RT2) === 255
                ? "Error"
                : item.acinfo[0].istst.RT2
              : "-",
            "Indoor Option":
              item.modal !== "IAQ"
                ? item.acinfo[0]
                  ? indoorOption(item.acinfo[0].istst.IND4_1)
                  : "-"
                : "-",
            "Type of Indoor": item.acinfo[0]
              ? indoorType(item.acinfo[0].istst.IND4_3)
              : "-",
            "Model of Indoor": item.acinfo[0]
              ? indoorModel(item.acinfo[0].istst.IND4_4)
              : "-",
            "CO2 (Byte High)": item.acinfo[0]
              ? item.acinfo[0].istst.CO2_H
              : "-",
            "CO2 (Byte Low)": item.acinfo[0] ? item.acinfo[0].istst.CO2_L : "-",
            "PM 2.5 (Byte 2)": item.acinfo[0]
              ? item.acinfo[0].istst.PM2_2
              : "-",
            "PM 2.5 (Byte 1)": item.acinfo[0]
              ? item.acinfo[0].istst.PM2_1
              : "-",
            "PM 2.5 (Byte 0)": item.acinfo[0]
              ? item.acinfo[0].istst.PM2_0
              : "-",
            "Indoor unit Coil Inlet Temp.(°C)":
              item.type === "SRV"
                ? item.acinfo[0]
                  ? parseInt(item.acinfo[0].istst.IND2_0) === 255
                    ? "Error"
                    : parseInt(item.acinfo[0].istst.IND2_0) - 40
                  : "-"
                : "-",
            "Indoor unit Coil Outlet Temp.(°C)":
              item.type === "SRV"
                ? item.acinfo[0]
                  ? parseInt(item.acinfo[0].istst.IND2_1) === 255
                    ? "Error"
                    : parseInt(item.acinfo[0].istst.IND2_1) - 40
                  : "-"
                : "-",
            "Indoor Unit EXV Position": item.acinfo[0]
              ? item.type === "SRV"
                ? item.acinfo[0].istst.IND2_2
                : "-"
              : "-",
            "Indoor %demand": item.acinfo[0]
              ? item.type === "SRV"
                ? item.acinfo[0].istst.IND2_3
                : "-"
              : "-",

            "Indoor Capacity":
              item.type === "SRV"
                ? item.acinfo[0]
                  ? item.acinfo[0].istst.IND2_4
                  : "-"
                : "-",
            "Status (LED Code)": item.acinfo[0]
              ? item.acinfo[0].istst.IND2_5
              : "-",
            "Indoor Unit Protection":
              item.type === "SRV"
                ? item.acinfo[0]
                  ? indoorProtection(item.acinfo[0].istst.IND2_6)
                  : "-"
                : "-",
            "Mid-coil Temp. (°C)":
              item.model === "PE" || item.model === "IC" || item.model === "FFU"
                ? item.acinfo[0]
                  ? parseInt(item.acinfo[0].istst.IND2_0) === 255
                    ? "Error"
                    : parseInt(item.acinfo[0].istst.IND2_0) - 40
                  : "-"
                : "-",
            "Outlet Temp. (°C)":
              item.model === "PE" || item.model === "IC" || item.model === "FFU"
                ? item.acinfo[0]
                  ? parseInt(item.acinfo[0].istst.IND2_1) === 255
                    ? "Error"
                    : parseInt(item.acinfo[0].istst.IND2_1) - 40
                  : "-"
                : "-",

            "Indoor Hepa Filter": item.acinfo[0]
              ? item.model === "PE" ||
                item.model === "IC" ||
                item.model === "FFU"
                ? item.acinfo[0].istst.IND2_2
                : "-"
              : "-",
            "Medium Filter": item.acinfo[0]
              ? item.model === "PE" ||
                item.model === "IC" ||
                item.model === "FFU"
                ? item.acinfo[0].istst.IND2_3
                : "-"
              : "-",
            "Pre Filter":
              item.model === "PE" || item.model === "IC" || item.model === "FFU"
                ? item.acinfo[0]
                  ? item.acinfo[0].istst.IND2_4
                  : "-"
                : "-",
            "UV Timing Running":
              item.model === "PE" || item.model === "IC" || item.model === "FFU"
                ? item.acinfo[0]
                  ? item.acinfo[0].istst.IND2_6
                  : "-"
                : "-",
            "PM 2.5 Sensor (Byte Low)":
              item.model === "PE" || item.model === "IC" || item.model === "FFU"
                ? item.acinfo[0]
                  ? item.acinfo[0].istst.IND2_7
                  : "-"
                : "-",
            "PM 2.5 Sensor (Byte High)":
              item.model === "PE" || item.model === "IC" || item.model === "FFU"
                ? item.acinfo[0]
                  ? item.acinfo[0].istst.IND2_8
                  : "-"
                : "-",
            "Extreme Ozone": item.acinfo[0]
              ? item.model === "PE" ||
                item.model === "IC" ||
                item.model === "FFU"
                ? item.acinfo[0].istst.IND2_9
                : "-"
              : "-",
            "Set Room Pa": item.acinfo[0]
              ? item.model === "IAQ"
                ? (item.acinfo[0].istst.IND2_2 - 3000) / 10
                : "-"
              : "-",

            "Set Anti Room": item.acinfo[0]
              ? item.model === "IAQ"
                ? (item.acinfo[0].istst.IND2_3 - 3000) / 10
                : "-"
              : "-",
            "Diff Pressure Room": item.acinfo[0]
              ? item.model === "IAQ"
                ? (item.acinfo[0].istst.IND2_8 - 3000) / 10
                : "-"
              : "-",
            "Anti Room": item.acinfo[0]
              ? item.model === "IAQ"
                ? (item.acinfo[0].istst.IND2_9 - 3000) / 10
                : "-"
              : "-",
          });

          return item;
        });

        setReportData(reportFilter);
        setExportData(data);
      } catch (e) {
        console.log(e);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
      setLoading(false);
    };
  }, [building, floor, room]);

  return [reportData, exportData, loading];
};

export {
  useRealtimeClock,
  useRealtimeIndoorError,
  useRealtimeOutdoorError,
  useRealtimeOduReport,
  useRealtimeIndoorReport,
};
